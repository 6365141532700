import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1
  },
  description: {
    width: "25%",
  },
  unit: {
    width: "10%",
  },
  qty: {
    width: "10%",
  },
  rate: {
    width: "10%",
  },
  amount: {
    width: "15%",
  },
  task: {
    width: "30%",
  }
});

const InvoiceTableHeader = ({ salesorderId }) => {
  return (
    <React.Fragment>
    { salesorderId === "" ?
      <>
        <View style={styles.container}>
          <Text style={styles.task}> Task </Text>
          <Text style={styles.description}> Description </Text>
          <Text style={styles.unit}>Unit</Text>
          <Text style={styles.qty}>Qty</Text>
          <Text style={styles.rate}>Rate</Text>
          <Text style={styles.amount}>Amount</Text>
        </View>
      </>
      :
      <>
        <View style={styles.container}>
          <Text style={{ width: "20%" }}> Stockname </Text>
          <Text style={{ width: "15%" }}> Productcode </Text>
          <Text style={{ width: "15%" }}>Batch</Text>
          <Text style={{ width: "10%" }}>Qty</Text>
          <Text style={{ width: "10%" }}>Rate</Text>
          <Text style={{ width: "15%" }}>CGST/SGST</Text>
          <Text style={{ width: "15%" }}>Amount</Text>
        </View>
      </>
    }
    </React.Fragment>
  )
};

export default InvoiceTableHeader;
