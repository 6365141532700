import React from "react";
import { Input, Row, Col } from "reactstrap";
import axios from "axios";
import { connect } from "react-redux";
import config from "react-global-configuration";
import consoleFunction from "Helpers";
import "../../../../config";
import getDateTimeField from "../../../../Utils";

const authApi = config.get("apiDomain");
let s = 0;

class AddLedgerType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ledger_typeName: "",
      ledger_typenameState: null,
      ledger_groupData: [],
      ledger_typegroupname: "",
      ledger_typegroupnameid: "",
    };
  }

  componentDidMount() {
    fetch(`${authApi}ledgergroupfilter/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          ledger_groupData: json,
        });
      });
  }

  handleLedgertypeName = (event) => {
    this.setState({
      ledger_typeName: event.target.value,
    });
    if (event.target.value === "") {
      this.setState({
        ledger_typenameState: "invalid",
      });
    } else {
      this.setState({
        ledger_typenameState: "valid",
      });
    }
  };

  handleLedgerTypegroupname = (event) => {
    this.setState({
      ledger_typegroupname: event.target.value,
    });
    const value1 = event.target.value.replace(/ /g, "%20");
    fetch(`${authApi}ledgergroupfilter/?ledgergroupname=${value1}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          ledger_typegroupnameid: json[0].id,
        });
      });
  };

  Formvalidation = () => {
    if (this.state.ledger_typeName !== "") {
      this.setState({
        ledger_typenameState: "valid",
      });
      this.AddLedgerdetailstype();
    } else {
      this.setState({
        ledger_typenameState: "invalid",
      });
    }
  };

  AddLedgerdetailstype = () => {
    let datetime_field = getDateTimeField();
    axios({
      method: "post",
      url: `${authApi}ledgertype/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        ledgertypename: this.state.ledger_typeName,
        isvisible: true,
        ledgertypegroup: this.state.ledger_typegroupnameid,
        createdby: this.props.username,
        createdat: datetime_field,
        modifiedby: this.props.username,
        modifiedat: datetime_field,
      },
    })
      .then((res) => {
        if (res.status) {
          this.props.closebtn();
          this.props.callbackfunction();
        }
      })
      .catch((err) => {
        consoleFunction(
          "ledgertype name creation error",
          err.response.request.response
        );
        this.setState({ error: err.message });
      });
  };

  render() {
    const details = this.state.ledger_groupData.map((q) => q.ledgergroupname);
    s = details.filter((q, idx) => details.indexOf(q) === idx);
    return (
      <React.Fragment>
        <button className="x" onClick={this.props.closebtn}>
          x
        </button>
        <div className="header">Add Ledgertype</div>
        <div className="Scroll-Content">
          <Row style={{ marginBottom: 10 }}>
            <Col md="4">
              <label className="form-control-label">Name</label>
              <Input
                type="text"
                defaultValue={this.state.ledger_typeName}
                valid={this.state.ledger_typenameState === "valid"}
                invalid={this.state.ledger_typenameState === "invalid"}
                onChange={this.handleLedgertypeName}
                placeholder="enter ledgertype name"
              />
              <div className="invalid-feedback">
                Please enter a ledgertypename.
              </div>
            </Col>
            <Col md="4">
              <label className="form-control-label">LedgerGroup</label>
              <Input
                type="select"
                className="form-control-alternative"
                style={{ border: "1px solid #dee2e6" }}
                value={this.state.ledger_typegroupname}
                onChange={this.handleLedgerTypegroupname}
              >
                <option value="">Select Ledgergroup</option>
                {s
                  .sort((a, b) => a.localeCompare(b))
                  .map((item, key) => (
                    <option key={key} value={item}>
                      {item}
                    </option>
                  ))}
              </Input>
            </Col>
            <Col md="2">
              <div className="button">
                <button
                  id="buttonsave"
                  className="btn btn-dark"
                  style={{ float: "right", marginRight: 15 }}
                  onClick={this.Formvalidation}
                >
                  Add
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
    username: state.username,
  };
};
export default connect(mapStateToProps, null)(AddLedgerType);
