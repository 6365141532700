import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  description: {
    width: "54.7%",
    textAlign: "center",
    paddingLeft: 4,
    paddingTop: 4,
  },
  amount: {
    width: "15.8%",
    textAlign: "center",
    paddingRight: 16,
    paddingTop: 4,
  },
  discount: {
    width: "15.8%",
    textAlign: "center",
    paddingRight: 16,
    paddingTop: 4,
  },
  total: {
    width: "15.3%",
    textAlign: "right",
    paddingRight: 20,
    paddingTop: 4,
  },
  rows: {
    flexDirection: "row",
    alignItems: "center",
    fontStyle: "bold"
  },
  descriptions: {
    width: "33%",
    textAlign: "left",
    paddingLeft: 28,
    paddingTop: 4,
  },
  units: {
    width: "7%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
  },
  qtys: {
    width: "7%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
  },
  rates: {
    width: "7.8%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
  },
  amounts: {
    width: "15.8%",
    textAlign: "center",
    paddingRight: 16,
    paddingTop: 4,
  }
});

const InvoicePrinting = ({ printChar, draftChar, discount }) => 
{
  const invoicePrintTot = printChar.reduce((i,p) => i + parseInt(p.amount, 0), 0);
  const invoiceTot = parseInt(draftChar) + parseInt(discount);
  const totals = invoiceTot + invoicePrintTot;

  const InvoiceprintCharge = printChar.map((pr) => (
    <View style={styles.rows} key={pr.id}>
      <Text style={styles.descriptions}>{pr.types}</Text>
      <Text style={styles.units}>{pr.unit}</Text>
      <Text style={styles.qtys}>{pr.quantity}</Text>
      <Text style={styles.rates}>{pr.rate}</Text>
      <Text style={styles.amounts}>{pr.amount}</Text>
    </View>
  ));

  return( 
    <React.Fragment>
      { invoicePrintTot === 0 ? null :
        <View style={styles.rows}>
          <Text style={styles.description}>Printing Charges</Text>
          <Text style={styles.amount}>{invoicePrintTot}</Text>
          <Text style={styles.discount}></Text>
          { draftChar === 0 ? 
            <Text style={styles.total}>{totals.toFixed(2)}</Text>
          : null }
        </View>
      }
      { invoicePrintTot === 0 ? null :
        <View>
        {InvoiceprintCharge}
        </View>
      }
    </React.Fragment>
  )
};

export default InvoicePrinting;
