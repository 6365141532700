import React from 'react';
import { connect } from 'react-redux';
import config from "react-global-configuration";
import '../../../../../config';
import {
    Input,
} from "reactstrap";
import axios from "axios";
const authApi = config.get('apiDomain');

class EditPurchaseStocks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Stockname: this.props.stock.name,
            Productcode: this.props.stock.productcode,
            Quantity: this.props.stock.quantity,
            Rate: this.props.stock.rate,
            Amount: this.props.stock.amount,
            CGSTDiscount: this.props.stock.cgstdiscount,
            SGSTDiscount: this.props.stock.sgstdiscount,
            CGSTDiscountPercentage: this.props.stock.cgstdiscountpercentage,
            SGSTDiscountPercentage: this.props.stock.sgstdiscountpercentage,
            Totalamount: this.props.stock.total,
            Gstcheck: this.props.gstCheck,
            AvailableStockQty: "",
            Validquantity: "none",
            Validrate: "none",
            Validamount: "none",
            Validcgstdiscount: "none",
            Validsgstdiscount: "none",
            Validsgstdiscountpercentage: "1px solid #d1cdcb",
            Validcgstdiscountpercentage: "1px solid #d1cdcb",
            ValidateStockname: "none",
        }
    }

    componentDidMount() {
        let count = 0, cgstcount = 0;
        count = (this.props.stock.sgstdiscountpercentage.match(/%/g) || []).length;
        cgstcount = (this.props.stock.cgstdiscountpercentage.match(/%/g) || []).length;
        this.setState({
            CGSTDiscountPercentage: count === 0 ? this.props.stock.sgstdiscountpercentage + '%' : this.props.stock.sgstdiscountpercentage,
            SGSTDiscountPercentage: cgstcount === 0 ? this.props.stock.cgstdiscountpercentage + '%' : this.props.stock.cgstdiscountpercentage,
        })
        if (this.props.stock.id === 9999) {
            this.setState({
                Amount: this.props.taxableamount,
                CGSTDiscount: this.props.totalcgstdiscount,
                SGSTDiscount: this.props.totalsgstdiscount,
                Totalamount: this.props.granttotalamount,
            })
        }
        if (this.props.stock.id !== 9999) {
            if (this.props.stock.name !== "") {
                this.updateAvailablequantity(this.props.stock.name);
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.stock.id === 9999) {
            this.setState({
                Amount: nextProps.taxableamount,
                CGSTDiscount: nextProps.totalcgstdiscount,
                SGSTDiscount: nextProps.totalsgstdiscount,
                Totalamount: nextProps.granttotalamount,
                Quantity: nextProps.totalquantity,
            })
        }
    }

    updateAvailablequantity = async (stockname) => {
        let available = 0
        let totalavailabe = await axios({
            method: "post",
            url: `${authApi}getavailableqtytemplate/`,
            headers: {
                'content-type': "application/json",
                'Authorization': `token ${this.props.token}`,
            },
        })
  
        let availabledata = await totalavailabe.data;
        availabledata.forEach(stock => {
            if (stock.StockName === stockname) {
                available = stock.TotalQty;
            }
        });
        this.setState({
            AvailableStockQty: available,
        })
    }


    changeStockName = (event) => {
        this.setState({
            Stockname: event.target.value,
        }, function () {
            this.updateBasedOnSelectedStock(event.target.value);
            this.updateAvailablequantity(event.target.value);
        })
        let alreadythere = false;
        this.props.totalstockdetails.forEach(stock => {
            if (stock.name === event.target.value) {
                alreadythere = true;
            }
        });
        this.setState({
            ValidateStockname: alreadythere === true ? "1px solid Red" : "none",
        })
    }

    updateBasedOnSelectedStock = (stockname) => {
        this.props.stockname.forEach(stock => {
            if (stockname === stock.stockname) {
                this.setState({
                    Productcode: stock.productcode,
                    Rate: 0,
                    Quantity: 1,
                    Amount: parseFloat(0).toFixed(2),
                    Totalamount: parseFloat(0).toFixed(2),
                    CGSTDiscountPercentage: this.props.gstCheck === false ? "0%" : parseFloat(stock.cgstpercentage) + '%',
                    SGSTDiscountPercentage: this.props.gstCheck === false ? "0%" : parseFloat(stock.sgstpercentage) + '%',
                }, function () {
                    this.updateStockdetailsusingonblur();
                })
            }
        });
    }

    changeQuantity = (event) => {
        let amount = parseFloat(parseFloat(event.target.value) * parseFloat(this.state.Rate)).toFixed(2);
        let fixedamount = parseFloat(amount) || 0;
        let cgstdiscount = parseFloat(this.state.CGSTDiscount) || 0;
        let sgstdiscount = parseFloat(this.state.SGSTDiscount) || 0;
        let totalamount = fixedamount + cgstdiscount + sgstdiscount;
        this.setState({
            Quantity: event.target.value,
            Amount: fixedamount,
            Totalamount: totalamount.toFixed(2),

        }, function () {
            this.updateStockdetailsusingonblur();
        })
        if (event.target.value === "") {
            this.setState({
                Validquantity: "1px solid red",
            })
            this.props.checkifanynull(true);
        }
        else {
            this.setState({
                Validquantity: "none",
            })
            this.props.checkifanynull(false);
        }
    }

    changeRate = (event) => {
        let cgstcount = (this.state.CGSTDiscountPercentage.match(/%/g) || []).length;
        let nocgstpercentagesymbol = this.state.CGSTDiscountPercentage;
        if (cgstcount > 0) {
            nocgstpercentagesymbol = this.state.CGSTDiscountPercentage.substring(0, this.state.CGSTDiscountPercentage.length - 1);
        }
        let sgstcount = (this.state.SGSTDiscountPercentage.match(/%/g) || []).length;
        let nosgstpercentagesymbol = this.state.SGSTDiscountPercentage;
        if (sgstcount > 0) {
            nosgstpercentagesymbol = this.state.SGSTDiscountPercentage.substring(0, this.state.SGSTDiscountPercentage.length - 1);
        }
        let amountfloat = parseFloat(event.target.value).toFixed(2);
        let amount = (amountfloat * parseFloat(this.state.Quantity)).toFixed(2);
        let cgstdiscountamount = parseFloat((parseFloat(amount) * parseFloat(nocgstpercentagesymbol)) / 100).toFixed(2);
        let sgstdiscountamount = parseFloat((parseFloat(amount) * parseFloat(nosgstpercentagesymbol)) / 100).toFixed(2);
        let totalamount = parseFloat(amount) + parseFloat(cgstdiscountamount) + parseFloat(sgstdiscountamount);
        let fixedtotalamount = parseFloat(totalamount).toFixed(2);
        this.setState({
            Rate: event.target.value,
            Amount: amount,
            Totalamount: fixedtotalamount,
            CGSTDiscount: parseFloat(cgstdiscountamount),
            SGSTDiscount: parseFloat(sgstdiscountamount),
        }, function () {
            this.updateStockdetailsusingonblur();
        })
        if (event.target.value === "") {
            this.setState({
                Validrate: "1px solid red",
            })
            this.props.checkifanynull(true);
        }
        else {
            this.setState({
                Validrate: "none",
            })
            this.props.checkifanynull(false);
        }
    }


    removeStock = () => {
        this.props.removestockdetails(this.props.stock.id);
    }



    updatePercentagesymbolCGST = () => {
        let count = (this.state.CGSTDiscountPercentage.match(/%/g) || []).length;
        let nopercentagesymbol = this.state.CGSTDiscountPercentage;
        if (count > 0) {
            nopercentagesymbol = this.state.CGSTDiscountPercentage.substring(0, this.state.CGSTDiscountPercentage.length - 1);
        }
        this.setState({
            CGSTDiscountPercentage: nopercentagesymbol + '%',
        }, function () {
            this.updateStockdetailsusingonblur();
        })
    }


    addStock = () => {
        this.props.addstock();
    }

    changeCGSTDDiscount = (event) => {
        this.setState({
            CGSTDiscount: event.target.value,
        })
        if (event.target.value !== "") {
            let percentage = parseFloat((event.target.value / parseFloat(this.state.Amount)) * 100).toFixed(2);
            let convertpercentage = percentage + '%';
            let cgstdiscount = parseFloat(event.target.value) || 0;
            let sgstdiscount = parseFloat(this.state.SGSTDiscount) || 0;
            let totalamount = parseFloat(this.state.Amount) + cgstdiscount + sgstdiscount;
            this.setState({
                CGSTDiscountPercentage: convertpercentage,
                Totalamount: totalamount.toFixed(2),
            }, function () {
                this.updateStockdetailsusingonblur();
            })
        }
        if (event.target.value === "" || event.target.value === "") {
            this.setState({
                Validcgstdiscount: "1px solid red",
            })
            this.props.checkifanynull(true);
        }
        else {
            this.setState({
                Validcgstdiscount: "none",
                Validsgctdiscountpercentage: "1px solid #d1cdcb",
            })
            this.props.checkifanynull(false);
        }
    }




    changeSGSTDiscount = (event) => {
        this.setState({
            SGSTDiscount: event.target.value,
        })
        let cgstdiscount = parseFloat(this.state.CGSTDiscount) || 0;
        let sgstdiscount = parseFloat(event.target.value) || 0;
        let totalamount = parseFloat(this.state.Amount) + cgstdiscount + sgstdiscount;
        let getamount = parseInt(this.state.Amount) || 0;
        let percentage = parseFloat((event.target.value / getamount) * 100).toFixed(2);
        let convertpercentage = percentage + '%';
        this.setState({
            SGSTDiscountPercentage: convertpercentage,
            Totalamount: totalamount.toFixed(2),
        }, function () {
            this.updateStockdetailsusingonblur();
        })
        if (event.target.value === "" || event.target.value === ".") {
            this.setState({
                Validsgstdiscount: "1px solid red",
            })
            this.props.checkifanynull(true);
        }
        else {
            this.setState({
                Validsgstdiscountpercentage: "1px solid #d1cdcb",
                Validsgstdiscount: "none",
            })
            this.props.checkifanynull(false);
        }
    }

    changeCGSTDiscounPercentage = (event) => {
        let count = (event.target.value.match(/%/g) || []).length;
        let nopercentagesymbol = event.target.value;
        if (count > 0) {
            nopercentagesymbol = this.state.CGSTDiscountPercentage.substring(0, this.state.CGSTDiscountPercentage.length - 1);
        }
        this.setState({
            CGSTDiscountPercentage: nopercentagesymbol,
        })
        let calculatediscountamount = parseFloat((parseFloat(this.state.Amount) * (nopercentagesymbol)) / 100).toFixed(2);
        let sgstdiscount = parseFloat(this.state.SGSTDiscount) || 0;
        let amount = parseFloat(this.state.Amount) || 0;
        let totalamount = amount + sgstdiscount + parseFloat(calculatediscountamount);
        this.setState({
            CGSTDiscount: calculatediscountamount,
            Totalamount: totalamount.toFixed(2),
        }, function () {
            this.updateStockdetailsusingonblur();
        })
        if (event.target.value === "" || event.target.value === "%" || Number.isNaN(calculatediscountamount)) {
            this.setState({
                Validcgstdiscountpercentage: "1px solid red",
            })
            this.props.checkifanynull(true);
        }
        else {
            this.setState({
                Validcgstdiscountpercentage: "1px solid #d1cdcb",
                Validcgstdiscount: "none",
            })
            this.props.checkifanynull(false);
        }
    }



    changeSGSTDiscounPercentage = (event) => {
        let count = (event.target.value.match(/%/g) || []).length;
        let nopercentagesymbol = event.target.value;
        if (count > 0) {
            nopercentagesymbol = this.state.SGSTDiscountPercentage.substring(0, this.state.SGSTDiscountPercentage - 1);
        }
        this.setState({
            SGSTDiscountPercentage: nopercentagesymbol,
        })
        let calculatediscountamount = parseFloat((parseFloat(this.state.Amount) * (nopercentagesymbol)) / 100).toFixed(2);
        let cgstdiscount = parseFloat(this.state.CGSTDiscount) || 0;
        let amount = parseFloat(this.state.Amount) || 0;
        let totalamount = amount + cgstdiscount + parseFloat(calculatediscountamount);
        this.setState({
            SGSTDiscount: calculatediscountamount,
            Totalamount: totalamount.toFixed(2),
        }, function () {
            this.updateStockdetailsusingonblur();
        })
        if (event.target.value === "" || event.target.value === "%") {
            this.setState({
                Validsgstdiscountpercentage: "1px solid red",
            })
            this.props.checkifanynull(true);
        }
        else {
            this.setState({
                Validsgstdiscountpercentage: "1px solid #d1cdcb",
                Validsgstdiscount: "none",
            })
            this.props.checkifanynull(false);
        }

    }

    updatePercentagesymbolSGST = () => {
        let count = (this.state.SGSTDiscountPercentage.match(/%/g) || []).length;
        let nopercentagesymbol = this.state.SGSTDiscountPercentage;
        if (count > 0) {
            nopercentagesymbol = this.state.SGSTDiscountPercentage.substring(0, this.state.SGSTDiscountPercentage.length - 1);
        }
        this.setState({
            SGSTDiscountPercentage: nopercentagesymbol + '%',
        }, function () {
            this.updateStockdetailsusingonblur();
        })
    }

    updateStockdetailsusingonblur = () => {
        this.props.updatestock(this.props.stock.id, this.state.Stockname, this.state.Productcode, this.state.Quantity, this.state.Rate, this.state.Amount, this.state.CGSTDiscount, this.state.SGSTDiscount, this.state.CGSTDiscountPercentage, this.state.SGSTDiscountPercentage, this.state.Totalamount);
    }

    render() {
        let totalstock = this.props.stockname.map(q => q.stockname);
        let orderstocks = totalstock.filter((q, idx) => totalstock.indexOf(q) === idx);
        return (
            <tr>
                <td style={{ width: "100px" }}>
                    {this.props.stock.id === 9999 ?
                        <button className="btn btn-primary btn-sm" style={{ marginRight: '5px' }} onClick={this.addStock}>
                            <i className="fas fa-plus-circle fa-1x" />
                        </button> :
                        <button className="btn btn-primary btn-sm" style={{ marginRight: '5px' }} onClick={this.removeStock}>
                            <i className="fa fa-trash" />
                        </button>
                    }
                </td>
                <td style={{}}>
                    {this.props.stock.id === 9999 ? "" :
                        <Input type="select"
                            value={this.state.Stockname}
                            style={{
                                border: this.state.ValidateStockname,
                                textAlign: "center",
                                position: "relative",
                                display: "block",
                                top: 0,
                                left: -22,
                                margin: "0",
                                height: "100%",
                                width: "115%",
                            }}
                            onChange={this.changeStockName}
                        >
                            <option value="Select All">Select Stock</option>
                            {
                                orderstocks.map((item, key) =>
                                    (<option key={key} value={item}> {item} </option>))
                            }
                        </Input>}
                </td>
                <td style={{ color: "1px solid #d9d7d0", textAlign: "center" }}>
                    {this.props.stock.id === 9999 ? "" :
                        <label>{this.state.Productcode}</label>
                    }
                </td>
                <td style={{ textAlign: "center" }}>
                    {this.props.stock.id === 9999 ? "" :
                        <label >{this.state.AvailableStockQty}</label>}
                </td>
                <td style={{ textAlign: "center" }}>
                    {this.props.stock.id === 9999 ?
                        <label>{this.state.Quantity}</label>
                        :
                        <Input
                            type="text"
                            style={{ textAlign: "center", border: this.state.Validquantity, width: "100px" }}
                            value={this.state.Quantity}
                            onChange={this.changeQuantity}
                        />}
                </td>
                <td >
                    {this.props.stock.id === 9999 ? "" :
                        <Input
                            type="text"
                            style={{ textAlign: "center", height: "40px", width: "100px", border: this.state.Validrate }}
                            value={this.state.Rate}
                            onChange={this.changeRate}
                        />
                    }
                </td>
                <td style={{ textAlign: "center" }}>
                    <label>{this.state.Amount}</label>
                </td>
                {this.props.gstCheck === false ? null :
                    <td style={{ textAlign: "center" }}>
                        {this.props.stock.id === 9999 ?
                            <label>{this.state.CGSTDiscount}</label>
                            :
                            <label>{this.state.CGSTDiscount} / {this.state.CGSTDiscountPercentage}</label>
                        }
                    </td>}
                {this.props.gstCheck === false ? null :
                    <td style={{ textAlign: "center" }}>
                        {this.props.stock.id === 9999 ?
                            <label>{this.state.SGSTDiscount}</label> :
                            <label> {this.state.SGSTDiscount} / {this.state.SGSTDiscountPercentage} </label>
                        }
                    </td>}
                {this.props.gstCheck === false ? null :
                    <td style={{ textAlign: "center" }}>
                        <label>{this.state.Totalamount}</label>
                    </td>}
            </tr>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.token,
    }
}
export default connect(mapStateToProps, null)(EditPurchaseStocks);