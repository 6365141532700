import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 50,
  },
  rows: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    fontStyle: "bold"
  },
  dates: {
    width: "13%",
    textAlign: "left",
    paddingRight: 4,
    paddingLeft: 6,
    paddingTop: 4,
  },
  tasks: {
    width: "37%",
    textAlign: "left",
    paddingRight: 10,
    paddingLeft: 6,
    paddingTop: 4,
  },
  subclients: {
    width: "25%",
    textAlign: "left",
    paddingRight: 2,
    paddingLeft: 10,
    paddingTop: 4,
  },
  endDate: {
    width: "15%",
    textAlign: "left",
    paddingLeft: 24,
    paddingTop: 4,
  },
  times: {
    width: "10%",
    textAlign: "left",
    paddingLeft: 21,
    paddingTop: 4,
  }
});

const ClientReportsTask = ({ taskData }) => 
{
   const reportTasks = taskData.map((t) => (
    <React.Fragment>
      <View style={styles.rows} key={t.task} wrap={false}>
        <Text style={styles.dates}>{t.startdate}</Text>
        <Text style={styles.tasks}>{t.task}</Text>
        <Text style={styles.subclients}>{t.subclient}</Text>
        <Text style={styles.endDate}>{t.enddate}</Text>
        <Text style={styles.times}>{t.time}</Text>
      </View>
    </React.Fragment>
   ));

  return( 
    <React.Fragment>
      {reportTasks}
    </React.Fragment>
  )
};

export default ClientReportsTask;
