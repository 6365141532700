import React, { useEffect, useState, useMemo } from "react";
import ConPreLoader from "../../../../components/Loader/Con_PreLoader";
import TableContainerExpand from "../../Filters/TableContainerExpand";
import { SelectColumnFilter } from "../../Filters/filters";
import "reactjs-popup/dist/index.css";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../../config";
import { Row, Container, NavLink, Table } from "reactstrap";
import { Link } from "react-router-dom";
import Header from "components/Headers/SimpleHeader";
import { CurrentLocation } from "../../../../components/loginRedux/loginReducers";
import store from "../../../../components/loginRedux/Store";

const authApi = config.get("apiDomain");

function PaymentsVoucher(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(true);

  useEffect(
    () => {
      const doFetch = async () => {
        store.dispatch({
          type: CurrentLocation,
          data: props.location.pathname,
        });
        const response = await fetch(
          `${authApi}vouchermanagementfilter_new/?vouchertype=Payment`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${props.token}`,
            },
          }
        );
        const myTaskData = await response.json();
        if (myTaskData.length === 0) {
          alert("Payment Vouchers are empty.!");
          setLoading(false);
        } else {
          const details = myTaskData.sort((a, b) => b.id - a.id);
          setData(details);
          setLoading(false);
          console.log(setChecked(true));
        }
      };
      doFetch();
    },
    // eslint-disable-next-line
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander",
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <i className="ni ni-bold-down" />
            ) : (
              <i className="ni ni-bold-right" />
            )}
          </span>
        ),
      },
      {
        Header: "Date",
        accessor: "date",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "ClientName",
        accessor: "clientname",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "VoucherSubtype",
        accessor: "vouchersubtype",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Amount",
        accessor: "amount",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Company",
        accessor: "companyname",
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Paymentmode",
        accessor: "paymentmode",
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "User",
        accessor: "username",
        Filter: SelectColumnFilter,
        filter: "equals",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
    ],
    // eslint-disable-next-line
    []
  );

  const renderRowSubComponent = (row) => {
    return (
      <React.Fragment>
        <br />
        <h6 className="thead-light">Working Details :</h6>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th className="text-center">Type</th>
              <th className="text-center">Reference</th>
              <th className="text-center">Amount</th>
            </tr>
          </thead>
          {row.original.vouchermanagement.map((r, id) => {
            return (
              <tbody key={id}>
                <tr>
                  <td className="text-center">{r.vouchertype}</td>
                  <td className="text-center">{r.voucherreferencetype}</td>
                  <td className="text-center">{r.amount}</td>
                </tr>
              </tbody>
            );
          })}
        </Table>
        <br />
      </React.Fragment>
    );
  };

  return (
    <>
      <Header />
      <Container className="mt--6" fluid>
        <br />
        <Row>
          <div
            className="addRow"
            style={{ position: "absolute", right: "75px", marginTop: -10 }}
          >
            <NavLink to="/admin/voucher/add-paymentvoucher" tag={Link}>
              <button
                className="btn btn-primary btn-sm"
                style={{ width: "135%", height: 40 }}
              >
                Make Payment
              </button>
            </NavLink>
          </div>
        </Row>
        <br />
        <br />
        <Row>
          {loading === true ? (
            <div>
              <ConPreLoader />
            </div>
          ) : (
            <div className=" col">
              <TableContainerExpand
                columns={columns}
                data={data}
                checkedToggle={checked}
                renderRowSubComponent={renderRowSubComponent}
              />
            </div>
          )}
        </Row>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, null)(PaymentsVoucher);
