import React, { Component } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import OverviewReports from "./OverviewReports";
import {Row, Card} from "reactstrap";
import 'config';
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import config from "react-global-configuration";
const authApi = config.get('apiDomain');


let rowData = "";
let group_Data = "";
let year=new Date().getFullYear(),month=new Date().getMonth(),previousyear=new Date().getFullYear()-1;
let selectedcompany = 0

class CGDetailedReportsApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientReport: this.props.dataFilter,
      client_details: this.props.clientDetails,
      jsonObject: "",
      startDate : "",
      endDate : "",
      loading : true,
      groupData : [],
      currenttaxyearstartdate:month>3 ? moment(new Date(`${year}-04-01`)).format("YYYY-MM-DD"):moment(new Date(`${previousyear}-04-01`)).format("YYYY-MM-DD"),
      currenttaxyearenddate:month<4 ? moment(new Date(`${year}-03-31`)).format("YYYY-MM-DD"):moment(new Date(`${previousyear}-03-31`)).format("YYYY-MM-DD"),
    };
  }
  
  componentDidMount() {
    rowData = [];
    let d1 = new Date(this.props.dateStart)-1;
    const startdate = moment(d1).format("YYYY-MM-DD");
    const enddate = moment(this.props.dateEnd).format("YYYY-MM-DD");
    this.setState({
      startDate: startdate,
      endDate: enddate,
    })
    this.downloadFunction(startdate, enddate);
  }

  downloadFunction = async(sd , ed) => {
    
    this.state.clientReport.forEach((d)=> {
      rowData = [...rowData, d.original]
    })

    group_Data = this.multipleGroupByArray(
      rowData,
      (property) => property.ClientName
    );
    
    await group_Data.forEach(async(element) => {
      const a = this.state.client_details.filter((cd) => cd.company_name === element[0].ClientName)
      let jsonData = await this.calculateOpeningBalance(a[0].id,sd)
      element[0].openingbalance = jsonData;
    })

    await group_Data.forEach(async(element) => {
      const a = this.state.client_details.filter((cd) => cd.company_name === element[0].ClientName)
      let jsonData = await this.calculateClosingBalance(a[0].id,ed);
      element[0].closingbalance = jsonData
    })

    await this.HandleLoading();
  } 

  multipleGroupByArray = (dataArray, groupPropertyArray) => {
    const groups = {};
    dataArray.forEach((item) => {
      const group = JSON.stringify(groupPropertyArray(item));
      groups[group] = groups[group] || [];
      groups[group].push(item);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    });
  }

  calculateOpeningBalance = async(clientid, sdate) => {
    // return await fetch(`${authApi}calculateopeningbalance/?clientid=${clientid}&fromdate=${this.state.currenttaxyearstartdate}&todate=${sdate}&companyid=${selectedcompany}`)
    // .then(res=> { return res.json() })
    // .then(json=>{
    //   return json[0][0]
    // })
    const response=await axios({
      method: "post",
      url: `${authApi}sp_calculateopeningbalance/`,
      headers: { 'content-type': "application/json",
      'Authorization': `token ${this.props.token}`, },
      data: {
        company_id:selectedcompany,
        client_id:clientid,
        taxyear_start_date:this.state.currenttaxyearstartdate,
        start_date:sdate
      }
    })
    .then(res=>res.data)
    return response.balance>0?response.balance:0
  }

  calculateClosingBalance=async(clientid,edate)=>{
      let closeingdate=new Date(edate).getDate()+1;
      let closingmonth=new Date(edate).getMonth()+1;
      let closingyear=new Date(edate).getFullYear();
      let storeproccloseend=moment(new Date(`${closingyear}-${closingmonth}-${closeingdate}`)).format("YYYY-MM-DD");
       const data = await axios({
        method: "post",
        url: `${authApi}sp_calculateclosingbalance/`,
        headers: { 'content-type': "application/json",
        'Authorization': `token ${this.props.token}`, },
        data: {
        client_id:clientid,
        company_id:selectedcompany,
        startdate: this.state.currenttaxyearstartdate,
        enddate:storeproccloseend
        }
      })
      .then(res=>res.data)
      return data.balance>0?data.balance:0
      // return await fetch(`${authApi}calculateclosingbalance/?clientid=${clientid}&fromdate=${this.state.currenttaxyearstartdate}&todate=${storeproccloseend}&companyid=${selectedcompany}`)
      // .then(res=> { return res.json() })
      // .then(json=>{
      //   return json[0][0];
      // })
    }

  HandleLoading = () => {
    this.setState({
      groupData : group_Data,
      loading : false,
    })
  }

  render() {
    if(this.state.loading)
    {
      return null;
    }
    else
    {
      return (
        <React.Fragment>
          <button className="x" onClick={this.props.closebtn}>
            x
          </button>
          <div className="header">
            Clientgroup Deatiled Report
          </div>
          <Row>
            <div className=" col">
              <div className="Scrolls-Contents">
                <Card className="shadow">
                  <PDFViewer width="100%" height="600" className="app">
                    <OverviewReports
                      GenerateData = {this.state.groupData}
                      sD = { this.state.startDate }
                      eD = { this.state.endDate }
                      clientdetail = { this.props.clientfilter }
                    />
                  </PDFViewer>
                </Card>
              </div>
            </div>
          </Row>
        </React.Fragment>
      );
    }
    }
  }

const mapStateToProps = state => {
  return {
    token:state.token,
    dataFilter: state.filterData,
    clientfilter: state.clientdata
  }
}

export default connect(mapStateToProps, null)(CGDetailedReportsApp);