import React, { Component } from 'react';
import { Input } from "reactstrap";
import config from "react-global-configuration";
import '../../../config';
import {connect} from "react-redux";
const authApi = config.get('apiDomain');

class Subtask extends Component {
    constructor(props){
        super(props);
        this.state={
            id:this.props.id,
            maintaskid:this.props.mainid,
            assignto:this.props.assignto,
            time:this.props.time,
            Rate:"",
            users:this.props.user,
            invoice:[],
            maintaskname:"",
            rateUpdate:0,
            amount:0,
        }
    }

async componentDidMount(){
    await this.getmaintaskname();
    const response= await fetch(`${authApi}userfilter/?name=${this.props.assignto}`,{
        method: 'GET',
        headers: {
        'content-type': "application/json",
        'Authorization': `token ${this.props.token}`,
        }})  
       .then(res => res.json())
       console.log(response);
       await this.updateRate(response);
}

getmaintaskname=async()=>{
    const response= await fetch(`${authApi}Tasklist/${this.state.maintaskid}/`,{
        method: 'GET',
        headers: {
        'content-type': "application/json",
        'Authorization': `token ${this.props.token}`,
        }})  
       .then(res => res.json())
      console.log(response);
      this.setState({
        maintaskname:response.task, 
      })
}

updateRate=async(rate)=>{
    const response= await fetch(`${authApi}invoicefilter/?user=${this.state.assignto}&tasklistrowid=${this.state.maintaskid}`,{
        method: 'GET',
        headers: {
        'content-type': "application/json",
        'Authorization': `token ${this.props.token}`,
        }})  
       .then(res => res.json())

       console.log(response);
       this.setState({
        invoice:response,
    },function(){
        rate.forEach((item)=>(
            this.setState({
                Rate:item.default_rate,
            },function(){
               this.PostData();
            })
        ))
    })
}

PostData=()=>{
    let timevalue=0;
    this.state.invoice.forEach((item)=>{
        if(item.user === this.state.assignto){
            timevalue=item.unit;
        }
    })
    let user=this.state.assignto;
    let times=this.state.time.split(':');
    let mins= times[0]*60 + +times[1];
    let unit=this.state.time;
    let rate=this.state.Rate;
    let amount= parseInt((mins*this.state.Rate)/60);
    let tasklistrowid=this.state.maintaskid;
    this.props.createDictionaryforDrafting(user,rate,unit,amount,tasklistrowid);
    if(this.state.invoice.length === 0){
        this.props.Drafting(amount,user);
    }
    else{
        this.props.Drafting(amount,user);
        if(this.state.rateUpdate===0){
            this.setState({
                amount:amount,
                rateUpdate:1,
            })}
     if(timevalue !== this.state.time){
        this.StoreChangeRate();
    }}
}

ChangeRate=(event)=>{
this.setState({
    Rate:event.target.value,
},function(){
    this.StoreChangeRate();
})
}

StoreChangeRate=()=>{
    let user=this.state.assignto;
    let times=this.state.time.split(':');
    let mins= times[0]*60 + +times[1];
    let rate=this.state.Rate;
    let amount= parseInt((mins*this.state.Rate)/60);
    if(this.state.rateUpdate===0){
    this.setState({
        amount:amount,
        rateUpdate:1,
    })}
    this.props.updateDrafting(amount,user);
    this.setState({
        amount:amount,
    })
    this.props.createDictionaryforDraftingupdate(user,rate,amount);
}

render() {
   let times=this.state.time.split(':');
   let mins= times[0]*60 + +times[1];
        return (
            <React.Fragment> 
                <tr>
                    <td className="text-center"> {this.state.assignto} </td>
                    <td className="text-center"> {this.state.time} </td>
                    <td>
                        <Input
                            type="text"
                            className="form-controls-alternatives text-center"
                            style={{ background : 'transparent', border: "1px solid #dee2e6"}}
                            value={this.state.Rate}
                            onChange={this.ChangeRate}
                        />
                    </td>
                     <td className="text-center"> {parseInt((mins*this.state.Rate)/60)}</td>
                </tr>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
      token:state.token, 
    }
  }
  export default connect(mapStateToProps, null)(Subtask);