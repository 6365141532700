import config from "react-global-configuration";
import dev from "./dev";
import prod from "./prod";
import caterpillar from "./caterpillar";

if (window.location.hostname === "localhost") {
  config.set(prod, { freeze: false });
  config.set(caterpillar, { freeze: false });
  config.set(dev, { assign: true });
} else if (window.location.hostname === "login.pgcadd.com") {
  config.set(caterpillar, { freeze: false });
  config.set(dev, { freeze: false });
  config.set(prod, { assign: true });
} else if (window.location.hostname === "caterpillar.stringconsultants.com") {
  config.set(prod, { freeze: false });
  config.set(dev, { freeze: false });
  config.set(caterpillar, { assign: true });
} else {
  config.set(caterpillar, { freeze: false });
  config.set(dev, { freeze: false });
  config.set(prod, { assign: true });
}
