import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, Row, Table, Col, Container, Button, NavLink } from "reactstrap";
import config from "react-global-configuration";
import "../../../../config";
import Header from "components/Headers/SimpleHeader";
import { DragDropContext, DropTarget, DragSource } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import update from "immutability-helper";
import { connect } from "react-redux";

const authApi = config.get("apiDomain");

const labels = ["ToDo", "InProgress", "Pending", "ReadyToBill"];
const labelsMap = {
  ToDo: "ToDo",
  InProgress: "InProgress",
  Pending: "Pending",
  ReadyToBill: "ReadyToBill",
};

const classes = {
  board: {
    display: "flex",
    margin: 2,
    color: "#525f7f",
    fontFamily: 'Arial, "Helvetica Neue", sans-serif',
  },
  column: {
    width: "20vw",
    marginBottom: 5,
    paddingTop: 10,
    paddingBottom: 10,
    marginLeft: -15,
    marginRight: -15,
  },
  columnHead: {
    textAlign: "center",
    padding: 10,
    margin: 3,
    fontSize: "1.2em",
    backgroundColor: "#f6f9fc",
    color: "#525f7f",
  },
  item: {
    padding: 10,
    margin: 10,
    fontSize: "0.8em",
    cursor: "pointer",
    backgroundColor: "#fff",
  },
};

class KanbanBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      assignee: this.props.username,
      loading: true,
    };
  }

  componentDidMount() {
    fetch(
      `${authApi}tasklistfilter/?assignto=${this.props.username}&status__in=ToDo%2CPending%2CInProgress%2CReadyToInvoice%2CReadyToBill&isdeleted=false`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          items: json,
          loading: false,
        });
      });
  }

  update = (id, status) => {
    const task = this.state.items.find((task) => task.id === id);
    task.status = status;
    const taskIndex = this.state.items.indexOf(task);
    const newTasks = update(this.state.items, {
      [taskIndex]: { $set: task },
    });

    this.setState({ items: newTasks });
  };

  render() {
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <br />
          <Row>
            <Col md="0.5" style={{ marginTop: 25 }}>
              <NavLink className="text-dark" to="/admin/mytask" tag={Link}>
                <Button className="mr-4" color="white" size="sm">
                  Details View
                </Button>
              </NavLink>
            </Col>
            <Col md="11.5"></Col>
          </Row>
          <br />
          <Row>
            <div className="col">
              <Card className="shadow">
                <Table className="align-items-center table-flush" responsive>
                  <section style={classes.board}>
                    {labels.map((channel) => (
                      <Col
                        md={12 / parseInt(labels.length)}
                        style={{ backgroundColor: "#ebecf0", margin: 3 }}
                      >
                        <KanbanColumn status={channel}>
                          <div style={classes.column}>
                            <div style={classes.columnHead}>
                              {labelsMap[channel]}
                            </div>
                            <div>
                              {this.state.items
                                .filter((item) => item.status === channel)
                                .map((item) => (
                                  <KanbanItem id={item.id} onDrop={this.update}>
                                    <div style={classes.item}>{item.task}</div>
                                  </KanbanItem>
                                ))}
                            </div>
                          </div>
                        </KanbanColumn>
                      </Col>
                    ))}
                  </section>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    username: state.username,
  };
};

export default DragDropContext(HTML5Backend)(
  connect(mapStateToProps, null)(KanbanBoard)
);

const boxTarget = {
  drop(props) {
    return { name: props.status };
  },
};

class KanbanColumn extends React.Component {
  render() {
    return this.props.connectDropTarget(<div>{this.props.children}</div>);
  }
}

KanbanColumn = DropTarget("kanbanItem", boxTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
}))(KanbanColumn);

const boxSource = {
  beginDrag(props) {
    return {
      name: props.id,
    };
  },

  endDrag(props, monitor) {
    const item = monitor.getItem();
    const dropResult = monitor.getDropResult();
    if (dropResult) {
      props.onDrop(item.name, dropResult.name);
    }
  },
};

class KanbanItem extends React.Component {
  render() {
    return this.props.connectDragSource(<div>{this.props.children}</div>);
  }
}

KanbanItem = DragSource("kanbanItem", boxSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
}))(KanbanItem);
