import React from "react";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../../../config";
import ReactTooltip from "react-tooltip";
import {
  Container,
  Card,
  Row,
  Col,
  Input,
  Table,
  CardHeader,
} from "reactstrap";
import moment from "moment";
import ReceiptEditRow from "./ReceiptEditRow";
import axios from "axios";

let StoreprocData = [],
  Locatiodetails = [];
const authApi = config.get("apiDomain");
class ReceiptEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Clientdetails: [],
      SupplierName: this.props.Purchaseorder.supplier,
      Supplierid: "",
      Reference: this.props.Purchaseorder.reference,
      Date: moment(new Date()).format("YYYY-MM-DD"),
      InvoiceDate: moment(new Date()).format("YYYY-MM-DD"),
      CompanyDetails: [],
      SelectedSupplier: [],
      Company: this.props.Purchaseorder.companyid,
      Companyid: "",
      Status: this.props.Purchaseorder.status,
      Stockdetails: [],
      Stockname: [],
      gstcheck: true,
      TotalTaxableValue: 0,
      TotalCGSTAmount: 0,
      TotalSGSTAmount: 0,
      TotalAmount: 0,
      TotalQuantity: 0,
      Adjustamount: this.props.Purchaseorder.roundoff,
      GrandTotalAmount: this.props.Purchaseorder.amount,
      ValidCompany: "none",
      ValidSupplierName: "none",
      ValidAdjustamount: "none",
      nullvalueisthere: false,
      PurchaseOrderid: [],
      Invoiceno: "",
      ValidInvoiceno: "none",
      Location: [],
      Batch: [],
      updateReceivedQty: false,
      AdvanceAmount: "",
      Orderid: this.props.Purchaseorder.id,
      userid: "",
    };
  }

  async componentDidMount() {
    let advance =
      parseFloat(this.props.Purchaseorder.advancepaid) -
      parseFloat(this.props.Purchaseorder.advanceused);
    let stocknamedetails = await this.getTotalStockdetails();
    await this.getuserid();
    this.getLocations();
    this.getBatchs();
    this.setState({
      Stockname: stocknamedetails,
      AdvanceAmount: parseFloat(advance).toFixed(2),
    });
    await this.getClientDetails();
    await this.getCompanydetails();
    this.props.Purchaseorder.purchasestock.forEach((stock) => {
      let stockid = stock.name === "Total" ? 9999 : stock.id;
      let quantitynumber = parseInt(stock.quantity) - parseInt(stock.received);
      if (stock.isdeleted !== true) {
        if (quantitynumber > 0) {
          let addStock = {
            id: stockid,
            stockname: stock.name,
            productcode: stock.productcode,
            quantity: quantitynumber,
            receivedquantity: 0,
            rate: stock.rate,
            amount: 0,
            cgstpercentage: stock.cgstdiscountpercentage,
            sgstpercentage: stock.sgstdiscountpercentage,
            total: 0,
            batch: "",
            location: "",
          };
          this.setState({
            Stockdetails: [...this.state.Stockdetails, addStock],
          });
        }
      }
    });
  }

  getuserid = async () => {
    let response = await fetch(
      `${authApi}userfilter/?name=${this.props.username}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    ).then((res) => res.json());
    response.forEach((userid) => {
      this.setState({
        userid: userid.id,
      });
    });
  };

  getLocations = async () => {
    let response = await fetch(`${authApi}location/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());
    Locatiodetails = [];
    response.forEach((location) => {
      let data = {
        id: location.id,
        name: location.shortlocation + "-" + location.godown,
      };
      Locatiodetails = [...Locatiodetails, data];
    });
    this.setState({
      Location: response,
    });
  };

  getBatchs = async () => {
    let response = await fetch(`${authApi}batch/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());
    this.setState({
      Batch: response,
    });
  };

  getTotalStockdetails = async () => {
    let response = await fetch(`${authApi}stocks/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());
    return response;
  };

  getCompanydetails = () => {
    fetch(`${authApi}companyfilter/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        json.forEach((company) => {
          if (this.props.Purchaseorder.companyid === company.companyname) {
            this.setState({
              CompanyDetails: json,
              Companyid: company.id,
              gstcheck: company.isgst === true ? true : false,
            });
          }
        });
      });
  };

  getClientDetails = async () => {
    this.props.clientdetails.forEach((client) => {
      if (client.company_name === this.props.Purchaseorder.supplier) {
        this.setState({
          Clientdetails: this.props.clientdetails,
          Supplierid: client.id,
        });
      }
    });
  };

  selectClient = (event) => {
    if (event.target.value === "Select Supplier") {
      this.setState({
        SupplierName: "",
      });
    } else {
      this.setState(
        {
          SupplierName: event.target.value,
        },
        function () {
          this.getClientid(event.target.value);
        }
      );
    }
  };

  getClientid = (clientname) => {
    this.state.Clientdetails.forEach((client) => {
      if (client.company_name === clientname) {
        this.setState({
          Supplierid: client.id,
        });
      }
    });
  };

  getReference = (event) => {
    this.setState({
      Reference: event.target.value,
    });
  };

  changeDate = (event) => {

    this.setState({
      Date: event.target.value,
    });
  };

  selectCompany = (event) => {
    if (event.target.value === "Select Company") {
      this.setState({
        Company: "",
      });
    } else {
      this.state.CompanyDetails.forEach((company) => {
        if (company.companyname === event.target.value) {
          this.setState({
            Company: event.target.value,
            Companyid: company.id,
            gstcheck: company.isgst,
          });
        }
      });
    }
  };

  changeStatus = (event) => {
    this.setState({
      Status: event.target.value,
    });
  };

  updateStockDetails = (
    id,
    stockname,
    productcode,
    quantity,
    receivedqty,
    rate,
    amount,
    cgstdiscountpercentage,
    sgstdiscountpercentage,
    totalamount,
    batch,
    location
  ) => {
    this.state.Stockdetails.forEach((stock) => {
      if (stock.id === id) {
        stock.stockname = stockname;
        stock.productcode = productcode;
        stock.quantity = quantity;
        stock.receivedquantity = receivedqty;
        stock.rate = rate;
        stock.amount = amount;
        stock.cgstpercentage = cgstdiscountpercentage;
        stock.sgstpercentage = sgstdiscountpercentage;
        stock.total = totalamount;
        stock.batch = batch;
        stock.location = location;
      }
    });
    this.calculateTotalAmount();
  };

  calculateTotalAmount = () => {
    let taxableamount = 0.0,
      granttotalamount = 0.0,
      totalquantity = 0.0;
    this.state.Stockdetails.forEach((stock) => {
      if (stock.id !== 9999) {
        let CheckAmount = parseFloat(stock.amount) || 0;
        let CheckGrandtotal = parseFloat(stock.total) || 0;
        let CheckQuantity = parseFloat(stock.receivedquantity) || 0;
        taxableamount = parseFloat(taxableamount) + CheckAmount;
        granttotalamount = parseFloat(granttotalamount) + CheckGrandtotal;
        totalquantity = parseFloat(totalquantity) + CheckQuantity;
      }
    });

    this.state.Stockdetails.forEach((stock) => {
      if (stock.id === 9999) {
        let adjustamount = parseFloat(this.state.Adjustamount);
        let grandamount = granttotalamount - adjustamount;
        stock.amount = taxableamount.toFixed(2);
        stock.total = grandamount.toFixed(2);
        stock.receivedquantity = totalquantity.toFixed(2);
        this.setState({
          TotalTaxableValue: taxableamount.toFixed(2),
          TotalAmount: granttotalamount.toFixed(2),
          GrandTotalAmount: grandamount.toFixed(2),
          TotalQuantity: totalquantity.toFixed(2),
        });
      }
    });
  };

  adjustAmount = (event) => {
    let total = parseFloat(this.state.TotalAmount) || 0;
    let totalamount = total - parseFloat(event.target.value);
    let totalwithtofixed = totalamount.toFixed(2);
    this.setState({
      GrandTotalAmount: totalwithtofixed,
      Adjustamount: event.target.value,
    });
  };

  isvalidEntryInForm = async () => {
    let isvalid = false,
      count = 0;
    this.state.Stockdetails.forEach((stock) => {
      if (stock.batch === "") {
        stock.batch = 9999;
      }
      if (stock.receivedquantity === 0) {
        count = count + 1;
      }
    });
    if (count < 1) {
      if (this.state.Company !== "") {
        this.setState({ updateReceivedQty: false });
        if (this.state.SupplierName !== "") {
          if (
            this.state.Adjustamount !== "" &&
            this.state.Adjustamount !== null &&
            this.state.Adjustamount !== "."
          ) {
            if (this.state.Invoiceno !== "") {
              if (this.state.nullvalueisthere === false) {
                let remainingdata = this.state.Stockdetails.filter(
                  (item) => item.itemname !== ""
                );
                this.setState(
                  {
                    updateReceivedQty: false,
                    ValidCompany: "none",
                    ValidSupplierName: "none",
                    Stockdetails: remainingdata,
                    ValidAdjustamount: "none",
                  },
                  function () {
                    this.stockDataPreparationForStock();
                  }
                );
                isvalid = true;
              } else {
                alert("Please specify value for the required fields");
              }
            } else {
              this.setState({
                ValidCompany: "none",
                ValidSupplierName: "none",
                ValidAdjustamount: "none",
                ValidInvoiceno: "1px solid red",
              });
            }
          } else {
            this.setState({
              ValidCompany: "none",
              ValidSupplierName: "none",
              ValidAdjustamount: "1px solid red",
            });
          }
        } else {
          this.setState({
            ValidCompany: "none",
            ValidSupplierName: "1px solid red",
          });
        }
      } else {
        this.setState({
          ValidCompany: "1px solid red",
          ValidSupplierName: "none",
        });
      }
    } else {
      this.setState({
        updateReceivedQty: true,
      });
    }
    return isvalid;
  };

  onSubmitForm = async (createStatus) => {
    if (await this.isvalidEntryInForm()) {
      axios({
        method: "post",
        url: `${authApi}sp_receipthandling/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          receiptdate: this.state.Date,
          company: this.state.Companyid,
          invoiceno: this.state.Invoiceno,
          supplier: this.state.Supplierid,
          totalamount: parseInt(this.state.GrandTotalAmount),
          reference: this.state.Reference,
          roundoff: this.state.Adjustamount,
          advanceamount: parseInt(this.state.AdvanceAmount),
          userid: this.state.userid,
          purchaseorderid: this.props.Purchaseorder.id,
          inventorydetails: StoreprocData,
          invoicedate: this.state.InvoiceDate,
        },
      })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            StoreprocData = [];
            this.BacktoReceipt();
          }
        })
        .catch((err) => {
          StoreprocData = [];
        });
    }
  };

  stockDataPreparationForStock = () => {
    let RemoveTotalAmount = this.state.Stockdetails.filter(
      (item) => item.stockname !== "Total" && item.receivedquantity !== 0
    );

    RemoveTotalAmount.forEach((inventorydetails) => {
      let newinventorydata = {
        purchasestockid: inventorydetails.id,
        stockname: inventorydetails.stockname,
        productcode: inventorydetails.productcode,
        orderquantity: inventorydetails.quantity,
        quantity: inventorydetails.receivedquantity,
        rate: inventorydetails.rate,
        amount: inventorydetails.amount,
        cgstpercentage: inventorydetails.cgstpercentage,
        sgstpercentage: inventorydetails.sgstpercentage,
        total: inventorydetails.total,
        location: inventorydetails.location,
        batch: inventorydetails.batch
      }
      StoreprocData = [...StoreprocData, newinventorydata]
 
    });
  };

  checkanynullvalue = (check) => {
    this.setState({
      nullvalueisthere: check,
    });
  };

  checkUpdateSupplierid = () => {
    this.setState({
      ValidSupplierName: "1px solid red",
    });
  };

  getInvoiceNo = (event) => {
    this.setState({
      Invoiceno: event.target.value,
    });
  };

  BacktoReceipt = () => {
    this.props.backfunction();
  };

  getInvoicedate = (event) => {
    this.setState({
      InvoiceDate: event.target.value,
    });
  };

  render() {
    let data = this.state.Stockdetails.sort((a, b) => (a.id > b.id ? 1 : -1));
    const stockdata = data.map((items) => (
      <ReceiptEditRow
        key={items.id}
        stock={items}
        gstCheck={this.state.gstcheck}
        taxableamount={this.state.TotalTaxableValue}
        totalcgstdiscount={this.state.TotalCGSTAmount}
        totalsgstdiscount={this.state.TotalSGSTAmount}
        granttotalamount={this.state.TotalAmount}
        totalquantity={this.state.TotalQuantity}
        stockname={this.state.Stockname}
        updatestock={this.updateStockDetails}
        checkifanynull={this.checkanynullvalue}
        nullvalueisthere={this.state.nullvalueisthere}
        location={this.state.Location}
        batch={this.state.Batch}
        godownlocation={Locatiodetails}
      />
    ));
    return (
      <React.Fragment>
        <Container className="mt--7" fluid>
          <br />
          <Row>
            <Col md="10">
              <CardHeader
                style={{
                  backgroundColor: "transparent",
                  padding: 10,
                  marginTop: 20,
                }}
              >
                <h3 className="mb-0">
                  <i style={{ marginRight: 5 }} class="fas fa-cart-plus"></i>
                  Purchase Receipt
                </h3>
              </CardHeader>
            </Col>
            <Col md="1"></Col>
            <Col md="1" style={{ marginTop: 25 }}>
              <div
                className="addRow"
                style={{ position: "absolute", right: "10", marginTop: 0 }}
              >
                <button
                  data-tip
                  data-for="completed-Tip"
                  className="btn btn-primary btn-sm"
                  style={{ width: "135%", height: 40 }}
                  onClick={this.BacktoReceipt}
                >
               
                  Back
                </button>
                <ReactTooltip
                  id="completed-Tip"
                  place="left"
                  type="dark"
                  effect="solid"
                >
                  Go To Receipt
                </ReactTooltip>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                Company
              </label>
            </Col>
            <Col md="3">
              <Input type="text" value={this.state.Company} />
            </Col>
            <Col md="2">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                Supplier Name
              </label>
            </Col>
            <Col md="3">
              <Input
                type="text"
                value={this.state.SupplierName}
                style={{ marginLeft: -90 }}
              />
            </Col>
            <Col md="1">
              <label
                className="form-control-label"
                style={{ marginTop: 12, marginLeft: -90 }}
              >
                Date
              </label>
            </Col>
            <Col md="2">
              <Input
                className="form-control-alternative"
                type="date"
                style={{ marginLeft: -90 }}
                Value={this.state.Date}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 10 }}>
                Invoice Date
              </label>
            </Col>
            <Col md="3">
              <Input
                type="date"
                value={this.state.InvoiceDate}
                onChange={this.getInvoicedate}
              />
            </Col>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                Invoice No
              </label>
            </Col>
            <Col md="3">
              <Input
                type="text"
                style={{ border: this.state.ValidInvoiceno, marginLeft: 30 }}
                value={this.state.Invoiceno}
                onChange={this.getInvoiceNo}
              />
            </Col>
            <Col md="2">
              <label
                className="form-control-label"
                style={{ marginTop: 12, marginLeft: 20 }}
              >
                Reference
              </label>
            </Col>
            <Col md="2">
              <Input
                type="text"
                style={{ marginLeft: -90 }}
                value={this.state.Reference}
                onChange={this.getReference}
              />
            </Col>
            
          </Row>
          <br />
          <Row>
            <div className=" col">
              <br />
              <Card className="shadow">
                <Table className="align-items-center table-flush" responsive-sm>
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center">GOODS NAME</th>
                      <th className="text-center">PRO.CODE</th>
                      <th className="text-center">ORT.QTY</th>
                      <th className="text-center">REC.QTY</th>
                      <th className="text-center">BATCH</th>
                      <th className="text-center">LOCATION</th>
                      <th className="text-center">RATE</th>
                      {this.state.gstcheck === true ? (
                        <th className="text-center">AMOUNT</th>
                      ) : null}
                      {this.state.gstcheck === true ? (
                        <th className="text-center">CGST/SGST %</th>
                      ) : null}
                      <th className="text-center">TOTAL</th>
                    </tr>
                  </thead>
                  <tbody>{stockdata}</tbody>
                </Table>
              </Card>
            </div>
          </Row>
          <Row>
            <Col md="2">
              <ul
                class="list-group list-group-flush"
                style={{ paddingLeft: 80, width: 290 }}
              >
                <li class="list-group-item">AdvanceAmount</li>
              </ul>
            </Col>
            <Col md="2">
              <ul
                class="list-group list-group-flush"
                style={{ paddingLeft: 60, width: 290 }}
              >
                <li class="list-group-item">{this.state.AdvanceAmount}</li>
              </ul>
            </Col>
            <Col md="4"></Col>
            <Col md="2">
              <ul
                class="list-group list-group-flush"
                style={{ paddingLeft: 90, width: 290 }}
              >
                {this.state.gstcheck === true ? <li class="list-group-item">TaxableValue</li> : null}
                {this.state.gstcheck === true ? <li class="list-group-item">CGST/SGST</li> : null}
                
                <li class="list-group-item">Amount</li>
                <li class="list-group-item" style={{ height: 62 }}>
                  Adjustment
                </li>
                <li class="list-group-item">GrandTotalAmount</li>
                <li class="list-group-item">Total</li>
              </ul>
            </Col>
            <Col md="2">
              <ul
                class="list-group list-group-flush align-center"
                style={{ paddingLeft: 60 }}
              >
                {this.state.gstcheck === true ? (
                  <li class="list-group-item">
                    {this.state.TotalTaxableValue}
                  </li>
                ) : null}
                {this.state.gstcheck === true ? (
                  <li class="list-group-item">
                    {parseFloat(
                      parseFloat(this.state.TotalAmount) -
                        parseFloat(this.state.TotalTaxableValue)
                    ).toFixed(2)}
                  </li>
                ) : null}
                {this.state.gstcheck === true ? (
                  <li class="list-group-item">{this.state.TotalAmount}</li>
                ) : (
                  <li class="list-group-item">
                    {this.state.TotalTaxableValue}
                  </li>
                )}
                <li class="list-group-item">
                  <Input
                    type="text"
                    style={{
                      border: this.state.ValidAdjustamount,
                      textAlign: "left",
                      position: "relative",
                      display: "block",
                      top: 0,
                      fontSize: "15px",
                      margin: "0",
                      left: -12,
                      height: 33,
                      width: "110%",
                    }}
                    Value={this.state.Adjustamount}
                    onChange={this.adjustAmount}
                  />
                </li>
                {this.state.gstcheck === true ? (
                  <li class="list-group-item">
                    {parseFloat(this.state.GrandTotalAmount).toFixed(2)}
                  </li>
                ) : (
                  <li class="list-group-item">
                    {parseFloat(
                      parseFloat(this.state.TotalTaxableValue) -
                        parseFloat(this.state.Adjustamount)
                    ).toFixed(2)}
                  </li>
                )}
                {this.state.gstcheck === true ? (
                  <li class="list-group-item">
                    {parseFloat(
                      parseFloat(this.state.GrandTotalAmount) -
                        parseFloat(this.state.AdvanceAmount)
                    ).toFixed(2)}
                  </li>
                ) : (
                  <li class="list-group-item">
                    {parseFloat(
                      parseFloat(this.state.TotalTaxableValue) -
                        parseFloat(this.state.Adjustamount) -
                        parseFloat(this.state.AdvanceAmount)
                    ).toFixed(2)}
                  </li>
                )}
              </ul>
            </Col>
          </Row>
          {this.state.nullvalueisthere === true ? (
            <Row>
              <Col md="5"></Col>
              <Col>
               
                <h3 style={{ color: "red" }}>
                  Please fill the required fields marked in red
                </h3>
              </Col>
            </Row>
          ) : null}
          {this.state.updateReceivedQty === true ? (
            <Row>
              <Col md="5"></Col>
              <Col>
             
                <h3 style={{ color: "red" }}>Please check Received quantity</h3>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col md="2"></Col>
            <Col md="7"></Col>
            <Col md="2"></Col>
            <Col md="1">
              <button
                id="buttonsave"
                className="btn btn-dark"
                onClick={() => this.onSubmitForm()}
              >
                Submit
              </button>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    username: state.name,
    clientdetails: state.clientdetails,
  };
};
export default connect(mapStateToProps, null)(ReceiptEdit);
