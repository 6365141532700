import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    marginTop: 12,
    fontSize: 12,
    textTransform: "uppercase"
  },
  notetitleContainer: {
    flexDirection: "row",
    marginTop: 12,
    color: "#808080",
    fontSize: 12
  },
  reportTitle: {
    textAlign: "center",
  }
});

const InvoiceThankYouMsg = () => (
  <>
  <View style={styles.notetitleContainer}>
    <Text style={styles.reportTitle}>* Note : Closing Balance = Opening Balance + Total Invoiced - Total Received</Text>
  </View>
  <View style={styles.titleContainer}>
    <Text style={styles.reportTitle}>Thank you for your business</Text>
  </View>
  </>
);

export default InvoiceThankYouMsg;
