import React, { Component } from "react";
import InvoicePopup from "./InvoicePopup";
import { Table, Row, Card } from "reactstrap";
import config from "react-global-configuration";
import axios from "axios";
import moment from "moment";
import "../../../config";
import "./TotalInvoiceTask.css";
import { connect } from "react-redux";
import { renderToString } from "react-dom/server";
import jsPDF from "jspdf";
import consoleFunction from "../../../Helpers";
import getDateTimeField from "../../../Utils";

const authApi = config.get("apiDomain");
let Total = 0,
  PreTotalAmount = 0;
class TotalInvoiceTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Task: this.props.TotalTask,
      useramount: 0,
      taskamount: 0,
      discountamount: 0,
      discountpercentage: 0,
      received_amount: "0",
      InStatus: "",
      clientid: "",
      date: new Date(),
      dummyvalue: "",
      advanceamount: 0,
      Referenceno: 0,
      PaymentMode: "Cash",
    };
  }

  componentDidMount() {
    this.setState({
      clientid: this.state.Task[0].client,
    });
    this.Updateadvoiceamount();
  }

  Updateadvoiceamount = () => {
    this.state.Task.forEach((element) => {
      if (element.advanceamount !== null) {
        this.setState({
          advanceamount:
            this.state.advanceamount + parseInt(element.advanceamount),
        });
      }
    });
  };

  UserrecallFunction = (x) => {
    this.setState({
      useramount: parseInt(this.state.useramount) + parseInt(x),
    });
  };

  StockrecallFunction = (x) => {
    this.setState({
      taskamount: parseInt(this.state.taskamount) + parseInt(x),
    });
  };

  DiscountpercentageFunction = (event) => {
    let total =
      parseInt(this.state.taskamount) + parseInt(this.state.useramount);
    let amount = Math.floor((total * event.target.value) / 100);
    this.setState({
      discountamount: amount,
      discountpercentage: event.target.value,
    });
  };

  discountamount = (event) => {
    let action = event.target.value.slice(0, 1);
    let amount = event.target.value.substring(1);
    if (action === "-") {
      let total =
        parseInt(this.state.taskamount) + parseInt(this.state.useramount);
      let discountamount = parseInt(amount);
      let percent = (discountamount / total) * 100;
      let percentage = percent.toFixed(1);
      this.setState({
        discountamount: event.target.value,
        discountpercentage: percentage,
      });
    } else {
      let discount = event.target.value;
      let total =
        parseInt(this.state.taskamount) + parseInt(this.state.useramount);
      let discountamount = parseInt(discount);
      let percent = (discountamount / total) * 100;
      let percentage = percent.toFixed(1);
      this.setState({
        discountamount: event.target.value,
        discountpercentage: percentage,
      });
    }
  };

  OnSubmitInvoice = () => {
    let Totalreceivedamount = 0,
      TotalUpdate = 0,
      Status = "0";
    if (this.state.advanceamount !== "NaN") {
      Totalreceivedamount =
        this.state.advanceamount + parseInt(this.state.received_amount);
      TotalUpdate = Total - Totalreceivedamount;
      if (Total === Totalreceivedamount) {
        Status = "Paid";
        this.StoreSummarydata(Totalreceivedamount, TotalUpdate, Status);
      } else {
        if (Totalreceivedamount === 0) {
          Status = "Unpaid";
          this.StoreSummarydata(Totalreceivedamount, TotalUpdate, Status);
        } else {
          let convertstring = TotalUpdate.toString();
          let letnegative = convertstring.slice(0, 1);
          let string = letnegative.toString();
          if (string === "-") {
            Status = "Paid";
            this.StoreSummarydata(Totalreceivedamount, TotalUpdate, Status);
          } else {
            Status = "Partially Paid";
            this.StoreSummarydata(Totalreceivedamount, TotalUpdate, Status);
          }
        }
      }
    } else {
      Totalreceivedamount = parseInt(this.state.received_amount);
      TotalUpdate = Total - Totalreceivedamount;
      if (Total === Totalreceivedamount) {
        Status = "Paid";
        this.StoreSummarydata(Totalreceivedamount, TotalUpdate, Status);
      } else {
        if (Totalreceivedamount === 0) {
          Status = "Unpaid";
          this.StoreSummarydata(Totalreceivedamount, TotalUpdate, Status);
        } else {
          let convertstring = TotalUpdate.toString();
          let letnegative = convertstring.slice(0, 1);
          let string = letnegative.toString();
          if (string === "-") {
            Status = "Paid";
            this.StoreSummarydata(Totalreceivedamount, TotalUpdate, Status);
          } else {
            Status = "Partially Paid";
            this.StoreSummarydata(Totalreceivedamount, TotalUpdate, Status);
          }
        }
      }
    }
  };

  StoreSummarydata = (Totalreceivedamount, TotalUpdate, Status) => {
    let d = new Date();
    d.setMonth(d.getMonth() + 3);
    let duedates = moment(d).format("YYYY-MM-DD");
    let date = moment(this.state.date).format("YYYY-MM-DD");
    let datetime_field = getDateTimeField();
    axios({
      method: "post",
      url: `${authApi}summary/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        date: date,
        client: this.state.clientid,
        invoice_amount: Total,
        amount_received: Totalreceivedamount,
        invoicestatus: Status,
        discount: this.state.discountamount,
        subtotal: PreTotalAmount,
        balancedue: TotalUpdate,
        paymentmode: this.state.PaymentMode,
        referenceno: this.state.Referenceno,
        duedate: duedates,
        comments: "",
        originaldeliverynoteid: 0,
        originalcompanyid: 0,
        taskname: "",
        isMoved: false,
        deliverynoteid: "",
        companyid: "1",
        tbltasklistid: this.state.Task[0].id,
        createdby: this.props.username,
        createdat: datetime_field,
        modifiedby: this.props.username,
        modifiedat: datetime_field,
      },
    })
      .then((res) => {
        if (res.status === 201) {
          this.updateMaintaskinvoicedetails(res.data.id);
          this.props.closebtn();
        }
      })
      .catch((err) => {
        consoleFunction(
          "Store summary data error",
          err.response.request.response
        );
        this.props.reloadfunction();
      });
  };
  updateMaintaskinvoicedetails = (invoicedid) => {
    let datetime_field = getDateTimeField();
    this.state.Task.forEach((item) => {
      axios({
        method: "put",
        url: `${authApi}Tasklist/${item.id}/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          date: item.date,
          task: item.task,
          assignto: item.assignto,
          priority: item.periority,
          startdate: item.startdate,
          enddate: item.enddate,
          status: "Completed",
          time: item.time,
          plantype: item.plantype,
          isdeleted: item.isdeleted,
          subclient: item.subclient,
          task_starttime: item.task_starttime,
          client: item.client,
          file_path: item.file_path,
          completed_date: item.completed_date,
          company: item.company,
          companyname: item.companyname,
          modifiedby: this.props.username,
          modifiedat: datetime_field,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.props.reloadfunction();
          }
        })
        .catch((err) => {
          consoleFunction(
            "Update task details error",
            err.response.request.response
          );
          this.props.reloadfunction();
        });
    });
  };

  print = () => {
    const string = renderToString(<TotalInvoiceTask />);
    const pdf = new jsPDF();
    pdf.fromHTML(string);
    pdf.save("invoice");
  };

  TotaladdSubtract = (event) => {
    this.setState({
      dummyvalue: event.target.value,
    });
  };

  pretotalcal = (event) => {
    this.setState({
      dummyvalue: event.target.value,
    });
  };

  ChangeAdvanceAdmount = (event) => {
    this.setState({
      advanceamount: event.target.value,
    });
  };
  ChangeReference = (event) => {
    this.setState({
      Referenceno: event.target.value,
    });
  };
  Paymentmodeoption = (event) => {
    this.setState({
      PaymentMode: event.target.value,
    });
  };
  ChangeReceivedAmount = (event) => {
    this.setState({
      received_amount: event.target.value,
    });
  };

  render() {
    const AddRowDetails = [...this.state.Task].map((item) => (
      <InvoicePopup
        key={item.id}
        id={item.id}
        EditRowSend={item.id}
        task={item.task}
        usertotaclcalculate={this.UserrecallFunction}
        Stocktotaclcalculate={this.StockrecallFunction}
        closebutton={this.props.closebtn}
      />
    ));
    PreTotalAmount =
      parseInt(this.state.taskamount) + parseInt(this.state.useramount);
    Total = parseInt(PreTotalAmount) + parseInt(this.state.discountamount);
    return (
      <React.Fragment>
        <button className="x" onClick={this.props.closebtn}>
          x
        </button>
        <div className="header">Invoice Details</div>
        <Row>
          <div className=" col">
            <div className="Scrolls-Contents">
              <Card className="shadow">
                <Table className="align-items-center table-flush" responsive>
                  <tbody>{AddRowDetails}</tbody>
                </Table>
                <Table>
                  <tbody>
                    <tr>
                      <th className="footTotal">
                        <label>Total :</label>&nbsp;&nbsp;&nbsp;&nbsp;
                        <label>
                          <input
                            type="text"
                            className="text-center"
                            name="DiscountAmount"
                            size="8"
                            onChange={this.pretotalcal}
                            value={PreTotalAmount}
                          />
                        </label>
                      </th>
                    </tr>
                    <tr>
                      <th className="footTotal">
                        <label>Adjustment :</label>&nbsp;&nbsp;&nbsp;&nbsp;
                        <label>
                          <input
                            type="text"
                            name="DiscountAmount"
                            className="text-center"
                            size="8"
                            value={this.state.discountamount}
                            onChange={this.discountamount}
                          />
                        </label>
                      </th>
                      <th className="footTotal">
                        <label>Discount % :</label>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <label>
                          <input
                            type="text"
                            name="DiscountAmount"
                            className="text-center"
                            size="8"
                            value={this.state.discountpercentage}
                            onChange={this.DiscountpercentageFunction}
                          />
                        </label>
                      </th>
                      <th className="footTotal">
                        <label>Advance:</label>&nbsp;&nbsp;&nbsp;&nbsp;
                        <label>
                          <input
                            type="text"
                            name="DiscountAmount"
                            className="text-center"
                            size="8"
                            value={this.state.advanceamount}
                            onChange={this.ChangeAdvanceAdmount}
                          />
                        </label>
                      </th>
                    </tr>
                    <tr>
                      <th className="footTotal">
                        <label>InvoiceAmount :</label>&nbsp;&nbsp;&nbsp;&nbsp;
                        <label>
                          <input
                            type="text"
                            name="DiscountAmount"
                            className="text-center"
                            size="8"
                            value={Total}
                            onChange={this.TotaladdSubtract}
                          />
                        </label>
                      </th>
                    </tr>
                    <tr>
                      <th className="footTotal">
                        <label>Referenceno :</label>&nbsp;&nbsp;&nbsp;&nbsp;
                        <label>
                          <input
                            type="text"
                            name="DiscountAmount"
                            className="text-center"
                            size="8"
                            value={this.state.Referenceno}
                            onChange={this.ChangeReference}
                          />
                        </label>
                      </th>
                      <th className="footTotal">
                        <label>PaymentMode :</label>&nbsp;&nbsp;&nbsp;&nbsp;
                        <label>
                          <select
                            name="DiscountAmount"
                            className="text-center"
                            onChange={this.Paymentmodeoption}
                          >
                            <option value="Cash">Cash</option>
                            <option value="Cheque">Cheque</option>
                            <option value="UPI">UPI</option>
                          </select>
                        </label>
                      </th>
                    </tr>
                    <tr>
                      <th className="footTotal">
                        <label>ReceivedAmount:</label>&nbsp;&nbsp;&nbsp;&nbsp;
                        <label>
                          <input
                            type="text"
                            name="DiscountAmount"
                            className="text-center"
                            size="8"
                            value={this.state.received_amount}
                            onChange={this.ChangeReceivedAmount}
                          />
                        </label>
                      </th>
                    </tr>
                  </tbody>
                </Table>
                <div>
                  <button
                    id="buttonsave"
                    className="btn btn-dark"
                    style={{ float: "right", marginRight: 15 }}
                    onClick={this.OnSubmitInvoice}
                  >
                    Save
                  </button>
                </div>
              </Card>
            </div>
          </div>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    username: state.username,
  };
};
export default connect(mapStateToProps, null)(TotalInvoiceTask);
