import React from "react";
import {Col,Row} from "reactstrap";
class CancelSales extends React.Component{
    constructor(props){
        super(props);
        this.state={

        }
    }
cancelPurchase=()=>{
    this.props.cancelSalesorder(this.props.Editdata.id);
}
    
render(){
        return(
            <React.Fragment>
            <button className="x" onClick={this.props.closebtn}>
            x
            </button>
            <div className="header">
             Cancel Purchase
            </div>
            <Row style={{paddingTop:10}}>
                <Col md="3">
                </Col>
                <Col md="7"><h3>Do you want to cancel this Sale order?</h3></Col>
                <Col md="2"></Col>
            </Row>
            <Row style={{paddingBottom:10}}>
                <Col md="4"></Col>
                <Col md="1"><button type="button" class="btn btn-outline-success" onClick={this.cancelPurchase}>Yes</button></Col>
                <Col md="2" style={{paddingLeft:50}}><button type="button" class="btn btn-outline-danger" onClick={this.notCancelPurchase}>No</button></Col>
                <Col md="5"></Col>
            </Row>
            </React.Fragment>
        );
    }
}

export  default CancelSales;