import React, { Component } from "react";
import InvoiceUserRow from "./InvoiceUserRow";
import config from "react-global-configuration";
import "../../../config";
import { connect } from "react-redux";
import InvoicePopupRow from "./InvoicePopupRow";

const authApi = config.get("apiDomain");

class InvoicePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      task: this.props.task,
      invoiceUser: [],
      StockTotal: 0,
      UserTotal: 0,
      Pretotal: 0,
      DiscountAmount: 0,
    };
  }

  componentDidMount() {
    this.invoiceStock();
    this.invoiceUserdetails();
  }

  invoiceStock = () => {
    fetch(
      `${authApi}stockinvoicefilter/?tasklistrow=${this.props.EditRowSend}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        this.setState(
          {
            items: json,
          },
          function () {
            this.CalculateStcokTotal();
          }
        );
      });
  };

  CalculateStcokTotal = () => {
    let total = 0;
    this.state.items.forEach((cal) => {
      total = total + parseInt(cal.amount);
      this.setState(
        {
          StockTotal: total,
        },
        function () {
          this.props.Stocktotaclcalculate(this.state.StockTotal);
        }
      );
    });
  };

  invoiceUserdetails = () => {
    fetch(
      `${authApi}invoicefilter/?tasklistrowid=${this.props.EditRowSend}&isdeleted=false`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        this.setState(
          {
            invoiceUser: json,
          },
          function () {
            this.CalculateUserTotal();
          }
        );
      });
  };

  CalculateUserTotal = () => {
    let total = 0;
    this.state.invoiceUser.forEach((cal) => {
      total = total + parseInt(cal.amount);
      this.setState(
        {
          UserTotal: total,
        },
        function () {
          this.props.usertotaclcalculate(this.state.UserTotal);
        }
      );
    });
  };

  DiscountFunction = (event) => {
    this.setState({
      DiscountAmount: event.target.value,
    });
  };

  render() {
    const AddRowDetails = [...this.state.items].map((item) => (
      <InvoicePopupRow
        key={item.id}
        id={item.id}
        types={item.types}
        unit={item.unit}
        rate={item.rate}
        amount={item.amount}
        tasklistrow={item.tasklistrow}
      />
    ));

    const Subtaskdetails = [...this.state.invoiceUser].map((subtask) => (
      <InvoiceUserRow
        key={subtask.id}
        id={subtask.id}
        user={subtask.user}
        unit={subtask.unit}
        rate={subtask.rate}
        amount={subtask.amount}
      />
    ));
    return (
      <React.Fragment>
        <h5>{this.state.task}</h5>
        {Subtaskdetails.length !== 0 ? (
          <tr
            style={{
              borderBottomWidth: 1,
              borderBottomStyle: "solid ",
              borderTopWidth: 1,
              borderTopStyle: "solid ",
            }}
          >
            <td>Drafting Charges</td>
          </tr>
        ) : null}
        {Subtaskdetails}
        {AddRowDetails.length !== 0 ? (
          <tr>
            <td>Printing Charges</td>
          </tr>
        ) : null}
        {AddRowDetails}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};
export default connect(mapStateToProps, null)(InvoicePopup);
