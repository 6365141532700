import React, { useEffect, useState, useMemo } from "react";
import ConPreLoader from "../../../../components/Loader/Con_PreLoader";
import TableContainerExpand from "../../Filters/TableContainerExpand";
import { SelectColumnFilter } from "../../Filters/filters";
import "reactjs-popup/dist/index.css";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../../config";
import { Row, Container, NavLink, Nav, NavItem, Table } from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { CurrentLocation } from "../../../../components/loginRedux/loginReducers";	
import store from "../../../../components/loginRedux/Store";

const authApi = config.get("apiDomain");
let group_data = "";
let currentActiveTab = "1";
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function InventoryMonthly(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(true);

  useEffect(
    () => {
      const doFetch = async () => {
        store.dispatch({ type: CurrentLocation, data: props.location.pathname })
        const response = await fetch(`${authApi}inventorytransactionfilter/`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${props.token}`,
          },
        });
        const myTaskData = await response.json();
        if (myTaskData.length === 0) {
          alert("Inventories are empty.!");
          setLoading(false);
        } else {
          const details = myTaskData.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          );
          group_data = multipleGroupByArray(details, (property) => [
            new Date(property.date).getFullYear(),
            new Date(property.date).getMonth() + 1,
          ]);
          getInventoryDetails(group_data);
          setData(group_data);
          setLoading(false);
          console.log(setChecked(true));
        }
      };
      doFetch();
    },
    // eslint-disable-next-line
    []
  );

  function multipleGroupByArray(dataArray, groupPropertyArray) {
    const groups = {};
    dataArray.forEach((item) => {
      const group = JSON.stringify(groupPropertyArray(item));
      groups[group] = groups[group] || [];
      groups[group].push(item);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    });
  }

  function getInventoryDetails(body) {
    body.forEach((e) => {
      e.quantity = calculateStockQuantity(e);
    });
    body.forEach((e) => {
      e.monthname = setMonthname(e);
    });
    body.forEach((e) => {
      e.inward = calculateInwardStock(e);
    });
    body.forEach((e) => {
      e.outward = calculateOutwardStock(e);
    });
  }

  function calculateStockQuantity(e) {
    let total = 0;
    total = e.reduce((i, i_bal) => i + parseInt(i_bal.quantity, 0), 0);
    return total;
  }

  function setMonthname(e) {
    let mn = "";
    mn =
      monthNames[new Date(e[0].date).getMonth()] +
      " " +
      new Date(e[0].date).getFullYear();
    return mn;
  }

  function calculateInwardStock(e) {
    let total = 0;
    total = e.reduce((sum, record) => {
      if (Math.sign(parseInt(record.quantity)) === 1) {
        return sum + parseInt(record.quantity);
      }

      return sum;
    }, 0);
    return total;
  }

  function calculateOutwardStock(e) {
    let total = 0;
    total = e.reduce((sum, record) => {
      if (Math.sign(parseInt(record.quantity)) === -1) {
        return sum + parseInt(record.quantity);
      }

      return sum;
    }, 0);
    return total;
  }

  const renderRowSubComponent = (row) => {
    return (
      <React.Fragment>
        <br />
        <h6 className="thead-light"> Recent Stock details </h6>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th className="text-center">Date</th>
              <th className="text-center">Stockname</th>
              <th className="text-center">Quantity</th>
              <th className="text-center">Status</th>
              <th className="text-center">Purchaseorderid</th>
            </tr>
          </thead>
          {row.original
            .sort((a, b) => a.date - b.date)
            .reverse()
            .map((r, id) => {
              return (
                <tbody key={id}>
                  <tr>
                    <td className="text-center">{r.date}</td>
                    <td className="text-center">{r.stockname}</td>
                    <td className="text-center">{r.quantity}</td>
                    <td className="text-center">{r.status}</td>
                    <td className="text-center">{r.purchaseorderid}</td>
                  </tr>
                </tbody>
              );
            })}
        </Table>
        <br />
      </React.Fragment>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander",
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <i className="ni ni-bold-down" />
            ) : (
              <i className="ni ni-bold-right" />
            )}
          </span>
        ),
      },
      {
        Header: "Month",
        accessor: "monthname",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Available Quantity",
        accessor: "quantity",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Inward",
        accessor: "inward",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Outward",
        accessor: "outward",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <div style={{ marginTop: 0 }}>
        <Nav tabs style={{ padding: "25px 20px 0", fontSize: 20, backgroundColor: '#11cdef' }}>
          <NavItem>
            <NavLink style={{ color: 'white' }}
              className={classnames({
                active: currentActiveTab === "3",
              })}
              to="/admin/management/inventory"
              tag={Link}
            >
              Inventory
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink style={{ color: 'white' }}
              className={classnames({
                active: currentActiveTab === "2",
              })}
              to="/admin/inventory/inventorydetails"
              tag={Link}
            >
              Inventory Details
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink 
              className={classnames({
                active: currentActiveTab === "1",
              })}
              to="/admin/inventory/inventorymonthly"
              tag={Link}
            >
              Monthly Data
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink style={{ color: 'white' }}
              className={classnames({
                active: currentActiveTab === "4",
              })}
              to="/admin/inventory/lowstock"
              tag={Link}
            >
              Low Stock
            </NavLink>
          </NavItem>
        </Nav>
        <Container className="mt--6" fluid>
          <br />
          <Row>
            <div
              className="addRow"
              style={{ position: "absolute", right: "65px" }}
            >
              <NavLink to="/admin/inventory/conversiontable" tag={Link}>
                <button className="btn btn-primary btn-sm"
                    style={{ width: "135%", height: 40 }}>
                  <i className="ni ni-single-02"></i>
                </button>
              </NavLink>
            </div>
          </Row>
          <br />
          <br />
          <Row style={{ marginTop: 5 }}>
            {loading === true ? (
              <div>
                
                <ConPreLoader />
              </div>
            ) : (
              <div className=" col">
                <TableContainerExpand
                  columns={columns}
                  data={data.reverse()}
                  renderRowSubComponent={renderRowSubComponent}
                  checkedToggle={checked}
                />
              </div>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, null)(InventoryMonthly);
