import React from 'react';
import { Page, Document, StyleSheet, Text, View } from "@react-pdf/renderer";
import InvoiceTableFooter from "./InvoiceTableFooter";
import InvoiceThankYouMsg from "./InvoiceThankYouMsg";
import InvoiceTable from "./InvoiceTable";
import InvoiceTitle from "./InvoiceTitle";
import SummarizedTable from "./SummarizedTable";
import SummarizedTitle from "./SummarizedTitle";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 40,
    paddingLeft: 60,
    paddingRight: 60,
    paddingBottom: 40,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 16,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  
  titleContainer: {
    marginTop: 0,
    width: 300,
    height: 66,
    marginLeft: "auto",
    marginRight: -50,
    marginBottom: 5,
  },

  containerSummary: {
    marginTop: 10,
  },

  reportTitle: {
    marginTop: -20,
    marginRight: -75,
    color: "#11cdef",
    letterSpacing: 1,
    fontSize: 20,
    marginLeft: 23,
    textAlign: "left",
    textTransform: "uppercase"
  },

  titlelocation: {
    marginTop: -60,
    width: 140,
    height: 66,
    marginLeft: "auto",
    marginRight: 15,
    marginBottom: 5,
    fontSize: 12,
    textAlign: "right",
  },
  titlelocation1: {
    marginTop: -55,
    width: 140,
    height: 66,
    marginLeft: "auto",
    marginRight: 15,
    marginBottom: 5,
    fontSize: 12,
    textAlign: "right",
  },

  invoiceClientContainer: {
    width: 200,
    height: 66,
    marginRight: "auto",
    marginLeft: 0,
    marginTop : 0,
  },
  waterMarkdiv : {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 250,
    width: '100%',
  },
  waterMark : {
    color: '#e6e2d3',
    fontSize: 50,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    transform: 'rotate(-45deg)',
    userSelect: 'none',
  }
});

const Invoice = ({ sD, eD, groupData,client_groupname }) => {

  const groupClientdata = groupData.map((d) =>
    <>
      <Text style={styles.clientTitle}> Client : </Text>
      <View style={styles.invoiceClientContainer}>
        <Text style={styles.clientname}> {d[0].clientname} </Text>
        <Text style={styles.clientcompany}> {d[0].clientCompany} </Text>
      </View>
      <InvoiceTable manageData={d.sort((a, b) => a.id - b.id)} /> 
      <InvoiceTableFooter itemsTotal={d.sort((a, b) => a.id - b.id)} />
    </>
  )

  return(
  <Document>
    <Page size="A4" style={styles.page} orientation="landscape">

      <View render={({ pageNumber, totalPages }) => (
        <View style={styles.waterMarkdiv}>
          <Text style={styles.waterMark}> {client_groupname} </Text>
        </View>
      )} fixed/>

      <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}> PG Cadd Printers </Text>
      </View>
      <Text style={styles.titlelocation} >
        334, Mettur road,
      </Text>
      <Text style={styles.titlelocation1} >
        Erode - 638001.
      </Text>

      <InvoiceTitle stDa={sD} edDa={eD} clientgroupname={client_groupname} />
      {groupClientdata}
      <InvoiceThankYouMsg />

      <View style={styles.containerSummary} wrap={false}>
        <SummarizedTitle />
        <SummarizedTable gd = {groupData} />
      </View>

      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}  ${client_groupname} `
      )} fixed />

     </Page>
  </Document>
  )
};

export default Invoice;