import React, { Component } from 'react';

class ClientpanelTransaction extends Component {
    render() {
        return (
                <tbody>
                  <tr>
                      <td>{this.props.transaction.date}</td>
                      <td>{this.props.transaction.deliverynoteid}</td>
                      <td>{this.props.transaction.amount}</td>
                      <td>{this.props.transaction.transactiontype}</td>
                      <td>{this.props.clientname}</td>
                  </tr>
                </tbody>
        );
    }
}

export default ClientpanelTransaction;