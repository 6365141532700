import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  rows: {
    flexDirection: "row",
    alignItems: "center",
    fontStyle: "bold"
  },
  openingbalance: {
    width: "25%",
    textAlign: "center",
    paddingTop: 4,
  },
  totalinvoice: {
    width: "25%",
    textAlign: "center",
    paddingTop: 4,
  },
  totalreceive: {
    width: "25%",
    textAlign: "center",
    paddingTop: 4,
  },
  closingbalance: {
    width: "25%",
    textAlign: "center",
    paddingTop: 4,
  }
});

const PaymentRow = ({ taskData, OB, CB }) => 
{
   const totalinvoiced = taskData.reduce(function(i, record) { return (record.transactiontype !== 'invoice') ? i : i + parseInt(record.Amount, 0) }, 0);
   const totalreceived = taskData.reduce(function(i, record) { return (record.transactiontype !== 'received') ? i : i + parseInt(record.Amount, 0) }, 0);

  return( 
    <React.Fragment>
      <View style={styles.rows} wrap={false}>
        <Text style={styles.openingbalance}>{taskData[0].openingbalance}</Text>
        <Text style={styles.totalinvoice}>{totalinvoiced}</Text>
        <Text style={styles.totalreceive}>{totalreceived}</Text>
        <Text style={styles.closingbalance}>{taskData[0].closingbalance}</Text>
      </View>
    </React.Fragment>
  )
};

export default PaymentRow;
