/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */

export default {
    apiKey: "AIzaSyBFVqVJbMLZnWnWqhUXJRLWFeNRneIvbUI",
    authDomain: "pg-cadd.firebaseapp.com",
    databaseURL: "https://pg-cadd.firebaseio.com",
    projectId: "pg-cadd",
    storageBucket: "pg-cadd.appspot.com",
    messagingSenderId: "693456965610",
    appId: "1:693456965610:web:ff808c9f2e28b472872c93",
    measurementId: "G-K4RZS9J5LS"
};
