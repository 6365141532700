import React, { useEffect, useState, useMemo } from "react";
import { Container, Row, Form, Col, Button, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import TableContainer from "../Filters/TableContainer";
import { SelectColumnFilter } from "../Filters/filters";
import ConPreLoader from "../../../components/Loader/Con_PreLoader";
import Popup from "reactjs-popup";
import Editrow from "./Editrow";
import Addtask from "./Addtask";
import StockPopup from "../Myday/Stock/StockPopup";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../config";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import "react-dragswitch/dist/index.css";
import Header from "components/Headers/SimpleHeader";
import { CurrentLocation } from "../../../components/loginRedux/loginReducers";
import store from "../../../components/loginRedux/Store";

const Present_day = new Date();
const CurrentDay = moment(Present_day).format("YYYY-MM-DD");
const authApi = config.get("apiDomain");
let btnProperty = "";
let adimincheck = "0";

function Todolist(props) {
  const [data, setData] = useState([]);
  const [q, setQ] = useState("");
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(true);
  const [assignee, setAssignee] = useState("");
  const [btnClass, setBtnClass] = useState("btn btn-danger btn-sm");
  const [showAddtask, setshowAddtask] = useState(false);

  useEffect(
    () => {
      const doFetch = async () => {
        store.dispatch({
          type: CurrentLocation,
          data: props.location.pathname,
        });
        let currentuser = props.allusers.filter((users) => {
          return users.name === props.username;
        });
        adimincheck = currentuser[0].isadmin;
        setAssignee(props.username);
        const response = await fetch(
          `${authApi}tasklistfilter/?assignto=${props.username}&status__in=ToDo%2CPending%2CInProgress%2CReadyToInvoice%2CReadyToBill&isdeleted=false`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${props.token}`,
            },
          }
        );

        const myTaskData = await response.json();
        if (myTaskData.length === 0) {
          alert("No Task available for My Tasks.!");
          setLoading(false);
        } else {
          const body = myTaskData;
          callback(body);
        }
      };
      doFetch();
    },
    // eslint-disable-next-line
    []
  );

  function callback(body) {
    setData(body.reverse());
    setLoading(false);
  }

  function getFilterButton(event) {
    if (event === true) {
      setChecked(false);
      setBtnClass("btn btn-success btn-sm");
    } else {
      setChecked(true);
      setBtnClass("btn btn-danger btn-sm");
    }
  }

  function search(rows) {
    const cols = rows[0] && Object.keys(rows[0]);
    return rows.filter((r) =>
      cols.some((c) =>
        r[c]
          ? r[c].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          : null
      )
    );
  }

  function totaltaskupdate() {
    const doFetch = async () => {
      const response = await fetch(
        `${authApi}tasklistfilter/?assignto=${props.username}&status__in=ToDo%2CPending%2CInProgress%2CReadyToInvoice%2CReadyToBill&isdeleted=false`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${props.token}`,
          },
        }
      );
      const myTaskData = await response.json();
      const body = myTaskData;
      callback(body);
    };
    doFetch();
  }

  const showAddtaskfullpage = () => {
    setshowAddtask(true);
  };

  const Backtomytask = () => {
    setshowAddtask(false);
    totaltaskupdate();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: (row) => {
          const row_data = row.row.original;
          if (
            CurrentDay > row_data.enddate &&
            row_data.status !== "ReadyToInvoice"
          ) {
            btnProperty = "btn btn-danger btn-sm";
          } else {
            btnProperty = "btn btn-primary btn-sm";
          }
          return (
            <React.Fragment>
              {adimincheck === "1" ? (
                <Popup
                  modal
                  trigger={
                    <button
                      className={btnProperty}
                      style={{ marginRight: "5px" }}
                    >
                      <i className="fa fa-edit" />
                    </button>
                  }
                >
                  {(close) => (
                    <Editrow
                      EditRowSend={row_data}
                      calbackfunction={totaltaskupdate}
                      closebtn={close}
                    />
                  )}
                </Popup>
              ) : null}
              <Popup
                modal
                trigger={
                  <button
                    className={btnProperty}
                    style={{ marginRight: "5px" }}
                  >
                    <i className="ni ni-cart"></i>
                  </button>
                }
              >
                {(close) => (
                  <StockPopup
                    closebtn={close}
                    EditRowSend={row_data.subtask}
                    maintaskid={row_data.id}
                    itsmytask="1"
                  />
                )}
              </Popup>
            </React.Fragment>
          );
        },
      },
      {
        Header: "ClientName",
        accessor: "clientname",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
        ReferencePage: "MyTask",
      },
      {
        Header: "PlanType",
        accessor: "plantype",
        Filter: SelectColumnFilter,
        filter: "equals",
        ReferencePage: "MyTask",
      },
      {
        Header: "Task",
        accessor: "task",
        disableSortBy: true,
        Cell: (row) => {
          return <span data-tip={row.value}>{row.value}</span>;
        },
      },
      {
        Header: "AssignTo",
        accessor: "assignto",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
        ReferencePage: "MyTask",
      },
      {
        Header: "Status",
        accessor: "status",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
        ReferencePage: "MyTask",
      },
      {
        Header: "Priority",
        accessor: "priority",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
        ReferencePage: "MyTask",
      },
      {
        Header: "EndDate",
        accessor: "enddate",
      },
    ],
    // eslint-disable-next-line
    []
  );

  function DownloadData(json) {
    const items = json;
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(items[0]).filter(
      (field) =>
        field !== "id" &&
        field !== "subtask" &&
        field !== "isdeleted" &&
        field !== "task_starttime" &&
        field !== "time" &&
        field !== "clientname"
    );
    let csv = items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(header.join(","));
    csv = csv.join("\r\n");
    const ConvertData = csv;
    const element = document.createElement("a");
    const file = new Blob([ConvertData], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "Tasklist.csv";
    document.body.appendChild(element);
    element.click();
  }

  return (
    <React.Fragment>
      <Header />
      {showAddtask === false ? (
        <Container className="mt--7" fluid>
          <br />
          <ReactTooltip id="addTask-Tip" place="top" type="dark" effect="solid">
            Add New Task
          </ReactTooltip>
          <div
            className="invoicegenerate"
            style={{ marginTop: 16, marginRight: 75 }}
          >
            <button
              className="btn btn-primary btn-sm"
              style={{ width: "135%", height: 40 }}
              data-tip
              data-for="addTask-Tip"
              onClick={showAddtaskfullpage}
            >
              <i className="fas fa-plus-circle fa-1x"></i>
            </button>
          </div>
          <Row>
            <Col md="0.5" style={{ marginTop: 25, marginLeft: -10 }}>
              <NavLink
                className="text-light"
                to="/admin/kanban/kanbanboard"
                tag={Link}
              >
                <Button className="mr-4" color="dark" size="sm">
                  Kanban View
                </Button>
              </NavLink>
            </Col>
            <Col md="11.5">
              <Form
                className="searchForm"
                style={{ marginTop: 16, right: "155px" }}
              >
                <button
                  className={btnClass}
                  style={{ width: "135%", height: 40 }}
                  onClick={(e) => getFilterButton(checked)}
                >
                  <i className="fa fa-filter" aria-hidden="true"></i>
                </button>
              </Form>
              <Form
                className="searchForm"
                style={{ marginTop: 14, right: "195px" }}
              >
                <input
                  className="searchBox "
                  type="search"
                  placeholder="Search"
                  value={q}
                  onChange={(e) => setQ(e.target.value)}
                />
              </Form>
            </Col>
          </Row>
          <br />
          {loading === true ? (
            <div>
              <ConPreLoader />
            </div>
          ) : (
            <Row>
              <div className=" col">
                <ReactTooltip place="top" type="dark" effect="float" />
                <TableContainer
                  columns={columns}
                  data={search(data)}
                  checkedToggle={checked}
                ></TableContainer>
              </div>
            </Row>
          )}
          <br />
          <button
            className="Export btn-info"
            id="download"
            style={{ marginRight: "5px" }}
            onClick={() => DownloadData(data)}
          >
            Export
          </button>
          <br />
          <br />
        </Container>
      ) : (
        <Container className="mt--7" fluid>
          <Addtask assigneeName={assignee} calbackfunction={Backtomytask} />
        </Container>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    username: state.username,
    allusers: state.allusers,
  };
};
export default connect(mapStateToProps, null)(Todolist);
