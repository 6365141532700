  import React, { Component } from "react";
  import { connect } from "react-redux";
  import { Col,Row,Card,Table,Input } from "reactstrap";
 
  class RestoreProjectTask extends Component {
    constructor(props) {
      super(props);
      this.state = {
      delted_data:this.props.deldata,
      };
    }
    componentDidMount() {

    }

  
    getdrafingamount=(dartingdetails)=>{
      dartingdetails.forEach(element => {
        if(element.defaultRate==="Total"){
          return element.amount
        }
      });
    }
    Restorethedata=()=>{
      this.props.closebtn();
    }

    handleAddRow=(data)=>{
      this.props.restoredata(data);
      this.setState({
        delted_data:this.state.delted_data.filter((element) =>element.id !== data.id),
      })
    }


    render() {
      return (
        <>
          <button className="x" onClick={this.props.closebtn}>
            x
          </button>
          <div className="header">Restore Project Task</div>
          <div className="Scroll-Content">
          <Card className="shadow">
                                        <Table
                                          className="align-items-center table-flush"
                                          responsive-sm
                                        >
                                          
                                          <thead className="thead-light">
                                            <tr>
                                              <th className="text-center">
                                              TASK NAME
                                              </th>
                                              <th className="text-center">
                                              DRAFTING CHARAGES
                                              </th>
                                              <th className="text-center">
                                              PRINTING CHARGES
                                              </th>
                                              <th className="text-center">
                                              TOTAL AMOUNT
                                              </th>
                                              <th className="text-center">
                                              ACTION
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                          {this.state.delted_data.map((draft) => (
                                              <tr>
                                                <td className="text-center">
                                                <Input 
                                                style={{border:'none'}}
                                                value={draft.taskname}
                                                  />
                                                </td>
                                                <td className="text-center">
                                                {draft.draftingdetails.length===0 ? 0 : draft.draftingdetails[draft.draftingdetails.length-1].amount}
                                                </td>
                                                <td className="text-center">
                                                {draft.printingdetails.length===0 ? 0 : draft.printingdetails[draft.printingdetails.length-1].amount}
                                                </td>
                                                <td className="text-center">
                                                {draft.tasktotalamount}
                                                </td>
                                                <td className="text-center">
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={()=>this.handleAddRow(draft)}
                                                  >
                                                    <i className="fas fa-trash-restore fa-1x"></i>
                                                  </button>
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </Table>
                                      </Card>
                                      <Row>
                                        <Col md="10"></Col>
                                        <Col md="2">
                                        <button
                                          id="buttonsave"
                                          className="btn btn-dark "
                                          style={{marginLeft:40}}
                                          onClick={this.Restorethedata}
                                        >
                                          OK
                                        </button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </>
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      token: state.token,
    };
  };

  export default connect(mapStateToProps, null)(RestoreProjectTask);
