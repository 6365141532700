import React from "react";
import * as firebase from "firebase";
import { Link } from "react-router-dom";
import axios from "axios";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  NavLink,
} from "reactstrap";

import { userLogin } from "../../components/loginRedux/loginReducers";
import store from "../../components/loginRedux/Store";
import getDateTimeField from "../../Utils";
import config from "react-global-configuration";
import "../../config";
import consoleFunction from "../../Helpers";

const authApi = config.get("apiDomain");

export default class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      password: "",
      error: null,
      items: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    fetch(`${authApi}users/`)
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          items: json,
        });
      });
  }

  CreateDjangoUser = (event, data) => {
    event.preventDefault();
    fetch(`${authApi}user/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).catch((err) =>
      consoleFunction(
        "Create superuser credential error",
        err.response.request.response
      )
    );
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (event) => {
    let data = {
      username: this.state.username.replace(/\s/g, ""),
      password: this.state.password,
    };
    event.preventDefault();
    const { username, email, password } = this.state;
    let usernameexist = false;
    event.preventDefault();
    this.state.items.forEach((item) => {
      if (item.name === username) {
        usernameexist = true;
      }
    });
    if (usernameexist === true) {
      return alert("Username already exist");
    } else {
      let datetime_field = getDateTimeField();
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(() => {
          firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then((response) => {
              firebase.auth().currentUser.updateProfile({
                displayName: username,
              });
              var info = firebase.auth().currentUser;
              store.dispatch({ type: userLogin, detail: info });
            })
            .then(() => {
              axios
                .post(`${authApi}users/`, {
                  name: username,
                  mailid: email,
                  isadmin: "0",
                  isapproved: "0",
                  default_rate: 0,
                  superuser: "0",
                  userprofile: "",
                  task: "0",
                  admin: "0",
                  report: "0",
                  sales: "0",
                  purchase: "0",
                  voucher: "0",
                  isdeleted: false,
                  createdby: username,
                  createdat: datetime_field,
                  modifiedby: username,
                  modifiedat: datetime_field,
                })
                .then(() => {
                  firebase.auth().signOut();
                  this.CreateDjangoUser(event, data);
                  alert("User Login Created Successfully. Welcome ! ");
                  window.location.reload();
                });
            })
            .catch((error) => {
              consoleFunction("User creation credential error", error.message);
              this.setState({ error: error.message });
            });
        });
    }
  };

  render() {
    return (
      <React.Fragment>
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small> Sign Up </small>
              </div>
              <Form role="form" onSubmit={this.handleSubmit}>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      name="username"
                      placeholder="Required Username"
                      value={this.state.username}
                      onChange={this.handleInputChange}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      name="email"
                      placeholder="Required Email"
                      value={this.state.email}
                      onChange={this.handleInputChange}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="password"
                      name="password"
                      placeholder="Required Password"
                      value={this.state.password}
                      onChange={this.handleInputChange}
                    />
                  </InputGroup>
                </FormGroup>
                <Row className="my-4">
                  <Col xs="12">
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="customCheckRegister"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheckRegister"
                      >
                        <span className="text-muted">
                          I Agree with the Privacy Policy{" "}
                        </span>
                      </label>
                    </div>
                  </Col>
                </Row>
                <div className="text-center">
                  <Button className="mt-4" color="primary" type="submit">
                    Sign Up
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <span>{this.state.error}</span>
          <Row className="mt-3">
            <Col xs="6"></Col>
            <Col className="text-right" xs="6">
              <NavLink className="text-light" to="/auth/login" tag={Link}>
                <small> Have an already account ? </small>
              </NavLink>
            </Col>
          </Row>
        </Col>
      </React.Fragment>
    );
  }
}
