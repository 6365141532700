import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1
  },
  description: {
    width: "20%",
  },
  invoiceno: {
    width: "10%",
  },
  unit: {
    width: "5%",
  },
  qty: {
    width: "5%",
  },
  rate: {
    width: "5%",
  },
  amount: {
    width: "10%",
  },
  task: {
    width: "15%",
  },
  date: {
    width: "10%",
  },
  discount: {
    width: "10%",
  },
  total: {
    width: "10%",
  }
});

const InvoiceTableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.date}> Date </Text>
    <Text style={styles.invoiceno}> DelNote </Text>
    <Text style={styles.task}> Task </Text>
    <Text style={styles.description}> Description </Text>
    <Text style={styles.unit}>Unit</Text>
    <Text style={styles.qty}>Qty</Text>
    <Text style={styles.rate}>Rate</Text>
    <Text style={styles.amount}>Amount</Text>
    <Text style={styles.discount}>Adj</Text>
    <Text style={styles.total}>Total</Text>
  </View>
);

export default InvoiceTableHeader;
