import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    marginTop: -15
  },
  reportTitle: {
    fontSize: 12,
    textAlign: "center",
    textTransform: "uppercase",
    marginTop: -5
  }
});

const InvoiceTitle = ({ stDa, edDa, clientgroupname }) => (
  <React.Fragment>
    <View style={styles.titleContainer}>
      <Text style={{ fontSize : 12, textAlign: "center", textTransform: "uppercase", marginTop: -40 }}>{clientgroupname} consolidate statement</Text>
    </View>
    <View style={styles.titleContainer}>
      <Text style={styles.reportTitle}> {stDa} - {edDa}</Text>
    </View>
  </React.Fragment>
);

export default InvoiceTitle;
