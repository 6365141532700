import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ReactTooltip from "react-tooltip";
import ConPreLoader from "../../../../components/Loader/Con_PreLoader";
import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";
import AddUnitgrp from "./AddUnitgroup";
import EditUnitgrp from "./EditUnitgroup";
import { connect } from 'react-redux';
import config from "react-global-configuration";
import '../../../../config';
import { Link } from "react-router-dom";
import classnames from 'classnames';
import {
    Card,
    Row, NavLink, Nav, NavItem, Container
} from "reactstrap";

const authApi = config.get('apiDomain');
let currentActiveTab = '1'

const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: 50,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Show
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }
                entries.
            </label>
        </div>
    ),
});

function UnitGroups(props) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const doFetch = async () => {
            const response = await fetch(`${authApi}unittypefilter/`, {
                method: 'GET',
                headers: {
                    'content-type': "application/json",
                    'Authorization': `token ${props.token}`,
                }
            })
            const myTaskData = await response.json();
            if (myTaskData.length === 0) {
                alert("No Task available for My Tasks.!");
                setLoading(false);
            }
            else {
                const details = myTaskData.sort((a, b) => (a.id - b.id));
                setData(details);
                setLoading(false);
            }
        }
        doFetch();
    },
        // eslint-disable-next-line  
        []
    );

    function unitupdate() {
        const doFetch = async () => {
            const response = await fetch(`${authApi}unittypefilter/`, {
                method: 'GET',
                headers: {
                    'content-type': "application/json",
                    'Authorization': `token ${props.token}`,
                }
            })
            const myTaskData = await response.json();
            if (myTaskData.length === 0) {
                alert("No Task available for My Tasks.!");
                setLoading(false);
            }
            else {
                const details = myTaskData.sort((a, b) => (a.id - b.id));
                setData(details);
                setLoading(false);
            }
        }
        doFetch();
    }

  

    function unitgroupActionFormatter(cell, row, rowIndex, formatExtraData) {
        return (
            <React.Fragment>
                <Popup
                    modal
                    trigger={
                        <button className="btn btn-primary btn-sm" style={{ marginRight: '5px' }}>
                            <i className="fa fa-edit" />
                        </button>
                    }
                    position="center"
                >
                    {(close) => (
                        <EditUnitgrp
                            EditRowSend={row}
                            closebtn={close}
                            callbackfunction={unitupdate}
                        />
                    )}
                </Popup>
            </React.Fragment>
        );
    }

    return (
        <>
            <div style={{ marginTop: 0 }}>
                <Nav tabs style={{ padding: "25px 20px 0", fontSize: 20, backgroundColor: '#11cdef' }}>
                    <NavItem>
                        <NavLink style={{ color: 'white' }}
                            className={classnames({
                                active:
                                    currentActiveTab === '2'
                            })}
                            to="/admin/stocklist/stocks"
                            tag={Link}
                        >
                            Stocks
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink style={{ color: 'white' }}
                            className={classnames({
                                active:
                                    currentActiveTab === '3'
                            })}
                            to="/admin/stocklist/stockgroups"
                            tag={Link}
                        >
                            Stock Groups
                        </NavLink>

                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({
                                active:
                                    currentActiveTab === '1'
                            })}
                            to="/admin/stocklist/unittype"
                            tag={Link}
                        >
                            Unit Type
                        </NavLink>
                    </NavItem>
                </Nav>
                <Container className="mt--6" fluid>
                    <br />
                    <Row>
                        <div className="addRow" style={{ position: "absolute", right: "65px" }}>
                            <ReactTooltip id="addstocks-Tip" place="left" type="dark" effect="solid">
                                Add New Unitgroup
                            </ReactTooltip>
                            <Popup
                                modal
                                position="center"
                                trigger={
                                    <button className="btn btn-primary btn-sm"
                                        style={{ width: "135%", height: 40 }} data-tip data-for="addstocks-Tip">
                                        <i className="ni ni-single-02"></i>
                                    </button>
                                }
                            >
                                {
                                    (close) => (
                                        <AddUnitgrp
                                            closebtn={close}
                                            callbackfunction={unitupdate}
                                        />
                                    )}
                            </Popup>
                        </div>
                    </Row>
                    <br /><br /><br />
                    <Row>
                        {loading === true ? <div> <ConPreLoader /></div> :
                            <div className="col">
                                <Card>
                                    <ToolkitProvider
                                        data={data}
                                        keyField="name"
                                        columns={[
                                            {
                                                dataField: "edit",
                                                text: "Edit",
                                                sort: false,
                                                formatter: unitgroupActionFormatter,
                                            },
                                            {
                                                dataField: "id",
                                                text: "Id",
                                                sort: true,
                                            },
                                            {
                                                dataField: "unitname",
                                                text: "Unit Type Name",
                                                sort: true,
                                                filter: textFilter()
                                            }
                                        ]}
                                        search
                                    >
                                        {(props) => (
                                            <div className="table-responsive">
                                                <BootstrapTable
                                                    {...props.baseProps}
                                                    keyField='id'
                                                    bootstrap4={true}
                                                    pagination={pagination}
                                                    bordered={false}
                                                    filter={filterFactory()}
                                                    striped
                                                    hover
                                                />
                                            </div>
                                        )}
                                    </ToolkitProvider>
                                </Card>
                            </div>
                        }
                    </Row>
                </Container>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {
        token: state.token,
    }
}

export default connect(mapStateToProps, null)(UnitGroups);