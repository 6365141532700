import React, { Component } from "react";
import moment from "moment";
import axios from "axios";
import { Row, Col, Input, Container, NavLink, Card, Table } from "reactstrap";
import Header from "components/Headers/SimpleHeader";
import config from "react-global-configuration";
import "../../../config";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import TaskRow from "./TaskRow";
import getDateTimeField from "../../../Utils";

let StoreprocData = [];
const authApi = config.get("apiDomain");
let rowid = 0;
class AddProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      ChangeDate: "",
      ChangeProject: "",
      ChangeStart: moment(new Date()).format("YYYY-MM-DD"),
      ChangeEnd: moment(new Date()).format("YYYY-MM-DD"),
      ChangeAssign: this.props.username,
      ChangePriority: "Medium",
      ChangeStatus: "ToDo",
      subclient: "",
      phonenumber: "",
      Clientname: "",
      clientid: [],
      id_value: "",
      plan: [],
      currentuser: this.props.username,
      items: [],
      nickname: [],
      Client_id: "",
      client_alldetails: this.props.clientdetails,
      subclient_details: [],
      current_client_data: [],
      select_clientName: [],
      select_client_id: [],
      companydetails: [],
      planId: "",
      Phonenum: "",
      pgcaddTaskNames: [],
      todaydate: moment(new Date()).format("YYYYMMDD"),
      error: null,
      enableprinting: 0,
      border: "1px solid #dee2e6",
      checked: false,
      userid: 0,
      drawingTitle: "",
      checkClientid: 0,
      clientidborder: "1px solid #dee2e6",
      subclientborder: "1px solid #dee2e6",
      companyborder: "1px solid #dee2e6",
      Unpaiddetails: [],
      isButtonDisabled: false,
      companyid: "",
      company_name: "",
      projectnameborder: "1px solid #dee2e6",
      q: "",
      taskRow: [],
      taskdetails: "",
    };
  }

  async componentDidMount() {
    await this.PlanTypeFunction();
    let currentuser = this.props.username;
    let currentuserdetails = this.props.allusers.filter(function (item) {
      return item.name === currentuser;
    });
    let rowData = {
      id: 999,
      plantype: "",
      drawingtitle: "",
      taskname: "",
    };
    this.setState(
      {
        userid: currentuserdetails[0].id,
        taskRow: [...this.state.taskRow, rowData],
        items: this.props.allusers,
      },
      function () {
        this.CompanyDetails();
        this.filtereditclient();
      }
    );
  }

  PlanTypeFunction = () => {
    fetch(`${authApi}Plans/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          plan: json,
        });
      });
  };

  CompanyDetails = () => {
    fetch(`${authApi}companyfilter/?isactive=1`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          companydetails: json,
          companyid: json[0].id,
          company_name: json[0].companyname,
        });
      });
  };

  filtereditclient = () => {
    if (this.state.id_value !== "" && this.state.id_value !== undefined) {
      let currentclient = this.props.clientdetails.filter((clientdata) => {
        return clientdata.id === this.state.id_value;
      });
      this.setState(
        {
          current_client_data: currentclient,
        },
        function () {
          this.updatenickname();
        }
      );
    }
  };

  HandleChangeProject = (event) => {
    this.setState({
      ChangeProject: event.target.value,
    });
  };

  HandleChangeStatus = (event) => {
    const g = event.target.value;
    this.setState({
      ChangeStatus: g,
    });
  };

  Formvalidation = (e) => {
    e.preventDefault();
    this.prepareData();
    if (this.state.id_value !== "") {
      if (this.state.subclient !== "") {
        if (this.state.company_name !== "") {
          if (this.state.ChangeProject !== "") {
            this.setState({
              isButtonDisabled: true,
            });
            this.subclientUpdation();
            this.UpdationRow();
          } else {
            this.setState({
              projectnameborder: "1px solid #fb6340",
              companyborder: "1px solid #dee2e6",
              subclientborder: "1px solid #dee2e6",
              clientidborder: "1px solid #dee2e6",
            });
          }
        } else {
          this.setState({
            companyborder: "1px solid #fb6340",
            subclientborder: "1px solid #dee2e6",
            clientidborder: "1px solid #dee2e6",
          });
        }
      } else {
        this.setState({
          subclientborder: "1px solid #fb6340",
          clientidborder: "1px solid #dee2e6",
        });
      }
    } else {
      this.setState({
        clientidborder: "1px solid #fb6340",
      });
    }
  };

  prepareData = () => {
    let otherthenadd = this.state.taskRow.filter((data) => data.id !== 999);
    let data = otherthenadd.filter((data) => data.plantype !== "");
    data.forEach((task) => {
      let newtask = {
        plantype: task.plantype,
        drawingtitle: task.drawingtitle,
        taskname: task.taskname,
      };
      StoreprocData = [...StoreprocData, newtask];
    });
  };

  subclientUpdation = () => {
    let SubName = this.state.subclient;
    let nameSub = this.state.subclient_details.filter(function (item) {
      return item.name === SubName;
    });
    if (nameSub.length === 0) {
      let datetime_field = getDateTimeField();
      axios({
        method: "post",
        url: `${authApi}subclients/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          name: this.state.subclient,
          phoneno: this.state.Phonenum,
          clients: this.state.id_value,
          createdby: this.props.username,
          createdat: datetime_field,
          modifiedby: this.props.username,
          modifiedat: datetime_field,
        },
      });
    }
  };

  UpdationRow = async () => {
    let datetime_field = getDateTimeField();
    axios({
      method: "post",
      url: `${authApi}sp_createproject/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        clientid: this.state.id_value,
        clientname: this.state.Clientname,
        subclient: this.state.subclient,
        phonenumber: this.state.Phonenum,
        status: "initiate",
        company: this.state.companyid,
        companyname: this.state.company_name,
        projectname: this.state.ChangeProject,
        projectlead: this.state.ChangeAssign,
        tasks: StoreprocData,
        modifiedby: this.props.username,
        modifiedat: datetime_field,
        createdby: this.props.username,
        createdat: datetime_field,
      },
    })
      .then((res) => {
        if (res.status === 201) {
          StoreprocData = [];
          this.props.history.push("/admin/projects");
        }
      })
      .catch((err) => {
        StoreprocData = [];
        this.props.calbackfunction("AddTasks");
      });
  };

  selectClient_Name = (event) => {
    if (
      event.target.value === "General-Printing" ||
      this.state.Clientname === "General"
    ) {
      let startdate = moment(this.state.todaydate).format("YYYY-MM-DD");
      let enddate = moment(this.state.todaydate).format("YYYY-MM-DD");
      this.setState({
        enableprinting: 1,
        ChangeStart: startdate,
        ChangeEnd: enddate,
      });
    } else {
      this.setState({
        enableprinting: 0,
      });
    }
    let currentclient = this.props.clientdetails.filter((clientdata) => {
      return clientdata.company_name === event.target.value;
    });
    this.setState(
      {
        select_clientName: currentclient,
        Clientname: event.target.value,
      },
      function () {
        this.updateClientID();
      }
    );
  };

  updateClientID = () => {
    this.state.select_clientName.map((item) => {
      this.setState({
        Client_id: item.clientid,
        subclient_details: item.clients,
        id_value: item.id,
        Phonenum: item.phoneno,
      });
      return this.state.Client_id;
    });
  };

  selectid = (event) => {
    if (event.target.value === "00300" || event.target.value === "00100") {
      let startdate = moment(this.state.todaydate).format("YYYY-MM-DD");
      let enddate = moment(this.state.todaydate).format("YYYY-MM-DD");
      this.setState({
        enableprinting: 1,
        ChangeStart: startdate,
        ChangeEnd: enddate,
      });
    } else {
      this.setState({
        enableprinting: 0,
      });
    }

    let currentclientdata = this.props.clientdetails.filter((clientdata) => {
      return clientdata.clientid === event.target.value;
    });
    this.setState(
      {
        select_client_id: currentclientdata,
        Client_id: event.target.value,
      },
      function () {
        this.SelectClient_nickname();
      }
    );
  };

  SelectClient_nickname = () => {
    this.state.select_client_id.map((item) => {
      this.setState({
        Clientname: item.company_name,
        subclient_details: item.clients,
        id_value: item.id,
        Phonenum: item.phoneno,
      });
      return this.state.Clientname;
    });
  };

  updatenickname = () => {
    this.state.current_client_data.map((item) => {
      if (item.clientid === "00300") {
        this.setState({
          Clientname: item.company_name,
          Client_id: item.clientid,
          enableprinting: 1,
        });
      } else {
        this.setState({
          Clientname: item.company_name,
          Client_id: item.clientid,
        });
      }
      return this.state.Clientname;
    });
  };

  handlesubclient = (event) => {
    this.setState(
      {
        subclient: event.target.value,
        clientname: event.target.value,
        Phonenum: "",
        phonenumber: "",
      },
      function () {
        this.PhonenumberUpd();
      }
    );
  };

  PhonenumberUpd = () => {
    this.state.subclient_details.forEach((ph) => {
      if (ph.name === this.state.subclient) {
        this.setState({
          Phonenum: ph.phoneno,
          phonenumber: ph.phoneno,
        });
      }
    });
  };

  HandlePhonenum = (event) => {
    this.setState({
      Phonenum: event.target.value,
      phonenumber: event.target.value,
    });
  };

  HandleCompany = (event) => {
    event.persist();
    this.setState({
      company_name: event.target.value,
    });
    fetch(`${authApi}companyfilter/?companyname=${event.target.value}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.length !== 0) {
          this.setState({
            companyid: json[0].id,
          });
        }
      });
  };

  addTaskrow = (e) => {
    e.preventDefault();
    if (this.state.id_value !== "") {
      if (this.state.subclient !== "") {
        if (this.state.company_name !== "") {
          if (this.state.ChangeProject !== "") {
            let totalRowdata = this.state.taskRow;
            let rowData = {
              id: rowid + 1,
              plantype: "",
              drawingtitle: "General",
              taskname: "",
            };
            totalRowdata = [...totalRowdata, rowData];
            this.setState({
              taskRow: [],
            });
            let sortedRowdata = totalRowdata.sort((a, b) =>
              a.id > b.id ? 1 : -1
            );
            this.setState({
              taskRow: sortedRowdata,
            });
            rowid = rowid + 1;
          } else {
            this.setState({
              projectnameborder: "1px solid #fb6340",
              companyborder: "1px solid #dee2e6",
              subclientborder: "1px solid #dee2e6",
              clientidborder: "1px solid #dee2e6",
            });
          }
        } else {
          this.setState({
            companyborder: "1px solid #fb6340",
            subclientborder: "1px solid #dee2e6",
            clientidborder: "1px solid #dee2e6",
          });
        }
      } else {
        this.setState({
          subclientborder: "1px solid #fb6340",
          clientidborder: "1px solid #dee2e6",
        });
      }
    } else {
      this.setState({
        clientidborder: "1px solid #fb6340",
      });
    }
  };

  removeTaskRow = (id) => {
    let remainingdata = this.state.taskRow.filter((item) => item.id !== id);
    this.setState({
      taskRow: remainingdata,
    });
  };

  updateJsondataObject = (id, plantype, drawingtitle, taskname) => {
    this.state.taskRow.forEach((element) => {
      if (element.id === id) {
        element.plantype = plantype;
        element.drawingtitle = drawingtitle;
        element.taskname = taskname;
      }
    });
  };

  HandleChangeAssign = (event) => {
    this.setState({
      ChangeAssign: event.target.value,
    });
  };

  keyEventOperation = (event) => {
    if (!event.shiftKey && event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if (form.elements[index + 1] !== undefined) {
        form.elements[index + 1].focus();
      }
      event.preventDefault();
    } else if (event.key.toLowerCase() === "enter" && event.shiftKey) {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if (form.elements[index - 1] !== undefined) {
        form.elements[index - 1].focus();
      }
      event.preventDefault();
    }
  };

  render() {
    const rowDetails = this.state.taskRow.map((q) => (
      <TaskRow
        data={q}
        addTaskrow={this.addTaskrow}
        removeTaskRow={this.removeTaskRow}
        updateJsondataObject={this.updateJsondataObject}
        taskRow={this.state.taskRow}
        plan={this.state.plan}
        Client_id={this.state.Client_id}
        subclient={this.state.subclient}
        date={this.state.todaydate}
      />
    ));
    return (
      <React.Fragment>
        <Header />
        <Container className="mt--7" fluid>
          <div className="Scroll-Content" style={{ marginTop: 10 }}>
            <br />
            <Row>
              <div
                className="addRow"
                style={{ position: "absolute", right: "90px", marginTop: 16 }}
              >
                <NavLink
                  style={{ marginTop: -5 }}
                  to="/admin/projects"
                  tag={Link}
                >
                  <button
                    className="btn btn-primary btn-sm"
                    style={{ width: "135%", height: 40 }}
                    data-tip
                    data-for="completed-Tip"
                  >
                    Back
                  </button>
                </NavLink>
                <ReactTooltip
                  id="completed-Tip"
                  place="left"
                  type="dark"
                  effect="solid"
                >
                  Add Projects
                </ReactTooltip>
              </div>
            </Row>
            <form>
              <Row style={{ marginTop: 60 }}>
                <Col md="3">
                  <label className="form-control-label">Client Id</label>
                  <Input
                    type="option"
                    list="id_value-list"
                    className="form-control-alternative"
                    value={this.state.Client_id}
                    style={{ border: this.state.clientidborder }}
                    onChange={this.selectid}
                    onKeyDown={this.keyEventOperation}
                  />
                  <datalist id="id_value-list">
                    {this.state.client_alldetails.map((item, key) => (
                      <option key={key} value={item.clientid} />
                    ))}
                  </datalist>
                  {this.state.clientidborder !== "1px solid #dee2e6" ? (
                    <h5 style={{ color: "#fb6340" }}>Please select clientid</h5>
                  ) : null}
                </Col>
                <Col md="3">
                  <label className="form-control-label">Client Name</label>
                  <Input
                    type="option"
                    list="nickname-list"
                    className="form-control-alternative"
                    defaultValue={this.state.Clientname}
                    style={{ border: this.state.clientidborder }}
                    onChange={this.selectClient_Name}
                    onKeyDown={this.keyEventOperation}
                  />
                  <datalist id="nickname-list">
                    {this.state.client_alldetails.map((item, name) => (
                      <option key={name} value={item.company_name} />
                    ))}
                  </datalist>
                  {this.state.clientidborder !== "1px solid #dee2e6" ? (
                    <h5 style={{ color: "#fb6340" }}>Please select name</h5>
                  ) : null}
                </Col>
                <Col md="3">
                  <label className="form-control-label">Subclient</label>
                  <Input
                    type="option"
                    list="subclient-list"
                    className="form-control-alternative"
                    style={{ border: this.state.subclientborder }}
                    value={this.state.subclient}
                    onChange={this.handlesubclient}
                    onKeyDown={this.keyEventOperation}
                  ></Input>
                  <datalist id="subclient-list">
                    {this.state.subclient_details.map((item, name) => (
                      <option key={name} value={item.name} />
                    ))}
                  </datalist>
                  {this.state.subclientborder !== "1px solid #dee2e6" ? (
                    <h5 style={{ color: "#fb6340" }}>Please enter subclient</h5>
                  ) : null}
                </Col>
                <Col md="3">
                  <label className="form-control-label">Phonenumber</label>
                  <Input
                    className="form-control-alternative"
                    type="text"
                    style={{ border: "1px solid #dee2e6" }}
                    value={this.state.Phonenum}
                    onChange={this.HandlePhonenum}
                    onKeyDown={this.keyEventOperation}
                  />
                </Col>
              </Row>
              &nbsp;
              <Row style={{ marginTop: -5 }}>
                <Col md="4">
                  <label className="form-control-label">Project Lead</label>
                  <Input
                    type="select"
                    className="form-control-alternative"
                    style={{ border: "1px solid #dee2e6" }}
                    value={this.state.ChangeAssign}
                    onChange={this.HandleChangeAssign}
                    onKeyDown={this.handleEnter}
                  >
                    <option>Select</option>
                    {this.state.items.map((obj, assign) => (
                      <option key={assign} value={obj.name}>
                        {obj.name}
                      </option>
                    ))}
                  </Input>
                </Col>
                <Col md="4">
                  <label className="form-control-label">Company</label>
                  <Input
                    type="select"
                    className="form-control-alternative"
                    value={this.state.company_name}
                    style={{ border: this.state.companyborder }}
                    onChange={this.HandleCompany}
                    onKeyDown={this.keyEventOperation}
                  >
                    <option value="">Select Company</option>
                    {this.state.companydetails.map((item, key) => (
                      <option key={key} value={item.companyname}>
                        {item.companyname}
                      </option>
                    ))}
                  </Input>
                  {this.state.companyborder !== "1px solid #dee2e6" ? (
                    <h5 style={{ color: "#fb6340" }}>
                      Please select companyname
                    </h5>
                  ) : null}
                </Col>
                <Col md="4">
                  <label className="form-control-label">Project Name</label>
                  <Input
                    type="text"
                    style={{ border: "1px solid #dee2e6" }}
                    className="form-control-alternative"
                    value={this.state.ChangeProject}
                    onChange={this.HandleChangeProject}
                  />
                  {this.state.projectnameborder === "1px solid #fb6340" ? (
                    <h5 style={{ color: "#fb6340" }}>
                      Please Enter Projectname
                    </h5>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <div className=" col">
                  <br />
                  <Card className="shadow">
                    <Table
                      className="align-items-center table-flush"
                      responsive-sm
                    >
                      <thead className="thead-light">
                        <tr>
                          <th className="text-center">ACTION</th>
                          <th className="text-center">PLAN TYPE</th>
                          <th className="text-center">DRAWING TITLE</th>
                          <th className="text-center">TASK NAME</th>
                        </tr>
                      </thead>
                      <tbody>{rowDetails}</tbody>
                    </Table>
                  </Card>
                </div>
              </Row>
              <Row>
                <Col md="10"></Col>
                <Col md="2" style={{ textAlign: "center" }}>
                  <label className="form-control-label">
                    &nbsp;&nbsp;
                    <button
                      id="buttonsave"
                      className="btn btn-dark"
                      onClick={this.Formvalidation}
                      disabled={this.state.isButtonDisabled}
                    >
                      Save
                    </button>
                  </label>
                </Col>
              </Row>
            </form>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    clientdetails: state.clientdetails,
    allusers: state.allusers,
    username: state.username,
  };
};
export default connect(mapStateToProps, null)(AddProjects);
