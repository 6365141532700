import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#bff0fd";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 30,
    fontSize: 12,
    fontStyle: "bold",
    color: "#808080",
  },
  description: {
    width: "25%",
    textAlign: "right",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    paddingRight: 8,
    paddingTop: 4,
  },
  total: {
    width: "25%",
    textAlign: "right",
    paddingRight: 15,
    paddingTop: 4,
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
});

const InvoiceTableFooter = ({ itemsTotal }) => {
  const total_received_amount = itemsTotal[0].total_amount_received;
  const invoiceAmt = itemsTotal.reduce(
    (i, a) => i + parseInt(a.invoice_amount, 0),
    0
  );

  return (
    <React.Fragment>     
      <View style={styles.row}>
        <Text style={styles.description}>Total Invoiced</Text>
        <Text style={styles.total}>{parseFloat(invoiceAmt).toFixed(2)}</Text>
        <Text style={styles.description}>Received Amount</Text>
        <Text style={styles.total}>
          {parseFloat(total_received_amount).toFixed(2)}
        </Text>
      </View>
    </React.Fragment>
  );
};

export default InvoiceTableFooter;
