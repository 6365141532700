import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  rows: {
    flexDirection: "row",
    alignItems: "center",
    fontStyle: "bold"
  },
  subtasks: {
    width: "65%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
  },
  times: {
    width: "35%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
  }
});

const WorktimeDetails = ({ subtasktime }) => 
{
  const Subtask = subtasktime.map((pr) => (
    <View style={styles.rows} key={pr.id}>
      <Text style={styles.subtasks}>{pr.subtaskname}</Text>
      <Text style={styles.times}>{pr.time}</Text>
    </View>
  ));

  return( 
    <React.Fragment>
      {Subtask}
    </React.Fragment>
  )
};

export default WorktimeDetails;
