import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1
  },
  date: {
    width: "10%",
  },
  task: {
    width: "25%",
  },
  subclient: {
    width: "15%",
  },
  subtask: {
    width: "15%",
  },
  assign: {
    width: "15%",
  },
  time: {
    width: "10%",
  },
  total: {
    width: "10%",
  },
});

const ClientReportsTableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.date}> Date </Text>
    <Text style={styles.task}> Task </Text>
    <Text style={styles.subclient}> Subclient </Text>
    <Text style={styles.subtask}>Subtask</Text>
    <Text style={styles.assign}>Assign</Text>
    <Text style={styles.time}>Time</Text>
    <Text style={styles.total}>Total</Text>
  </View>
);

export default ClientReportsTableHeader;
