import React, { useEffect, useState, useMemo } from "react";
import { Container, Row, Input, NavLink, Nav, NavItem } from "reactstrap";
import TableContainer from "../../Filters/TableContainer";
import { Link } from "react-router-dom";
import moment from "moment";
import ConPreLoader from "../../../../components/Loader/Con_PreLoader";
import "../../Teamtask/Todotask.css";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../../config";
import ReactTooltip from "react-tooltip";
import DateRangePicker from "react-bootstrap-daterangepicker";
import classnames from "classnames";
import { CurrentLocation } from "../../../../components/loginRedux/loginReducers";	
import store from "../../../../components/loginRedux/Store";

const authApi = config.get("apiDomain");
let sdToday = new Date();
let Date1 = new Date(sdToday.getFullYear(), sdToday.getMonth(), 1);
let Date2 = new Date(sdToday.getFullYear(), sdToday.getMonth() + 1, 0);
let currentActiveTab = "1";

function InventoryDetails(props) {
  const [data, setData] = useState([]);
  const [Filterdata, setFilterData] = useState([]);
  const [stockname, setStockname] = useState("");
  const [status, setStatus] = useState("");
  const [user, setUser] = useState("");
  const [StockFilterdata, setStockFilterdata] = useState([]);
  const [UserFilterdata, setUserFilterData] = useState([]);
  const [StatusFilterdata, setStatusFilterData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(true);
  const [dateRangeSt, setDateRangeSt] = useState(Date1);
  const [dateRangeEd, setDateRangeEd] = useState(Date2);

  useEffect(
    () => {
      const doFetch = async () => {
        store.dispatch({ type: CurrentLocation, data: props.location.pathname })
        const response = await fetch(`${authApi}inventorytransactionfilter/`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${props.token}`,
          },
        });
        const body = await response.json();
        StockFilter(body);
        Userfilter(body);
        StatusFilter(body);
        defaultrangepicker(body);
        setData(body);
        console.log(setChecked(true));
      };
      doFetch();
    },
    // eslint-disable-next-line
    []
  );

  function defaultrangepicker(body) {
    let PresentDay = new Date();
    let firstDay = new Date(PresentDay.getFullYear(), PresentDay.getMonth(), 1);
    let lastDay = new Date(
      PresentDay.getFullYear(),
      PresentDay.getMonth() + 1,
      0
    );
    setDateRangeSt(firstDay);
    setDateRangeEd(lastDay);
    let D1 = moment(firstDay).format("YYYY-MM-DD");
    let D2 = moment(lastDay).format("YYYY-MM-DD");
    let defaultVal = body.filter(function (d) {
      return d.date >= D1 && d.date <= D2;
    });
    if (defaultVal.length === 0) {
      setFilterData(defaultVal);
      setLoading(false);
    } else {
      callback(defaultVal);
    }
  }

  function callback(body) {
    setFilterData(body.reverse());
    setLoading(false);
  }

  function handleDateRangeCallback(event, picker) {
    setStockname("");
    setStatus("");
    setUser("");
    Date1 = picker.startDate._d.toISOString();
    Date2 = picker.endDate._d.toISOString();
    setLoading(true);
    const sd = picker.startDate;
    const ed = picker.endDate;
    setDateRangeSt(picker.startDate);
    setDateRangeEd(picker.endDate);
    let a_sd = moment(sd).format("YYYY-MM-DD");
    let b_ed = moment(ed).format("YYYY-MM-DD");
    let data1 = data.filter(function (d) {
      return d.date >= a_sd && d.date <= b_ed;
    });
    if (data1.length === 0) {
      alert("No Task available for the Selected Filter.!");
      setFilterData([]);
      setLoading(false);
    } else {
      callback(data1);
    }
  }

  function StockFilter(fil) {
    const a = [...new Map(fil.map((o) => [o.stockname, o.stockname])).values()];
    const b = a.filter((x) => x !== null && x !== "");
    setStockFilterdata(b);
  }

  function StatusFilter(fil) {
    const a = [...new Map(fil.map((o) => [o.status, o.status])).values()];
    const b = a.filter((x) => x !== null && x !== "");
    setStatusFilterData(b);
  }

  function Userfilter(fil) {
    const a = [...new Map(fil.map((o) => [o.username, o.username])).values()];
    const b = a.filter((x) => x !== null && x !== "");
    setUserFilterData(b);
  }

  function selectStock(event) {
    setStockname(event.target.value);
    setStatus("");
    setUser("");
    let a_sd = moment(dateRangeSt).format("YYYY-MM-DD");
    let b_ed = moment(dateRangeEd).format("YYYY-MM-DD");
    let filter_val = data.filter(function (d) {
      return d.date >= a_sd && d.date <= b_ed;
    });
    const cols1 = filter_val.filter(
      (r) => (r.username || "").toLowerCase().indexOf("".toLowerCase()) > -1
    );
    const cols2 = cols1.filter(
      (r) => (r.status || "").toLowerCase().indexOf("".toLowerCase()) > -1
    );
    const cols3 = cols2.filter(
      (r) =>
        r.stockname
          .toString()
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) > -1
    );
    callback(cols3);
  }

  function selectStatus(event) {
    setStatus(event.target.value);
    setUser("");
    let a_sd = moment(dateRangeSt).format("YYYY-MM-DD");
    let b_ed = moment(dateRangeEd).format("YYYY-MM-DD");
    let filter_val = data.filter(function (d) {
      return d.date >= a_sd && d.date <= b_ed;
    });
    const cols1 = filter_val.filter(
      (r) => (r.username || "").toLowerCase().indexOf("".toLowerCase()) > -1
    );
    const cols2 = cols1.filter(
      (r) =>
        (r.status || "")
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) > -1
    );
    const cols3 = cols2.filter(
      (r) =>
        r.stockname.toString().toLowerCase().indexOf(stockname.toLowerCase()) >
        -1
    );
    callback(cols3);
  }

  function selectUser(event) {
    setUser(event.target.value);
    let a_sd = moment(dateRangeSt).format("YYYY-MM-DD");
    let b_ed = moment(dateRangeEd).format("YYYY-MM-DD");
    let filter_val = data.filter(function (d) {
      return d.date >= a_sd && d.date <= b_ed;
    });
    const cols1 = filter_val.filter(
      (r) =>
        (r.username || "")
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) > -1
    );
    const cols2 = cols1.filter(
      (r) => (r.status || "").toLowerCase().indexOf(status.toLowerCase()) > -1
    );
    const cols3 = cols2.filter(
      (r) =>
        r.stockname.toString().toLowerCase().indexOf(stockname.toLowerCase()) >
        -1
    );
    callback(cols3);
  }

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Username",
        accessor: "username",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Stockname",
        accessor: "stockname",
        disableSortBy: true,
        Cell: (row) => {
          return <span data-tip={row.value}>{row.value}</span>;
        },
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        disableSortBy: true,
        Cell: (row) => {
          return <span data-tip={row.value}>{row.value}</span>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableSortBy: true,
        Cell: (row) => {
          return <span data-tip={row.value}>{row.value}</span>;
        },
      },
      {
        Header: "Invoiceno",
        accessor: "invoiceno",
        disableSortBy: true,
        Cell: (row) => {
          return <span data-tip={row.value}>{row.value}</span>;
        },
      },
      {
        Header: "PurchaseOrderid",
        accessor: "purchaseorderid",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Batch",
        accessor: "batch",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Location",
        accessor: "location",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <div style={{ marginTop: 0 }}>
        <Nav
          tabs
          style={{
            padding: "25px 20px 0",
            fontSize: 20,
            backgroundColor: "#11cdef",
          }}
        >
          <NavItem>
            <NavLink
              style={{ color: "white" }}
              className={classnames({
                active: currentActiveTab === "2",
              })}
              to="/admin/management/inventory"
              tag={Link}
            >
              Inventory
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: currentActiveTab === "1",
              })}
              to="/admin/inventory/inventorydetails"
              tag={Link}
            >
              Inventory Details
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ color: "white" }}
              className={classnames({
                active: currentActiveTab === "3",
              })}
              to="/admin/inventory/inventorymonthly"
              tag={Link}
            >
              Monthly Data
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ color: "white" }}
              className={classnames({
                active: currentActiveTab === "4",
              })}
              to="/admin/inventory/lowstock"
              tag={Link}
            >
              Low Stock
            </NavLink>
          </NavItem>
        </Nav>
        <Container className="mt--7" fluid>
          <br />
          <br />
          <Row>
            <div
              className="addRow"
              style={{ position: "absolute", right: "65px" }}
            >
              <NavLink to="/admin/inventory/conversiontable" tag={Link}>
                <button
                  className="btn btn-primary btn-sm"
                  style={{ width: "135%", height: 40 }}
                >
                  <i className="ni ni-single-02"></i>
                </button>
              </NavLink>
            </div>
          </Row>
          <div
            role="tabpanel"
            class="tab-pane"
            style={{ marginLeft: -25, marginTop: 10 }}
          >
            <div class="tab-para">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-3">
                  <div class="single-tab-select-box">
                    <h5
                      style={{
                        color: "black",
                        fontWeight: 700,
                        fontSize: 13,
                        marginLeft: "-5px",
                      }}
                    >
                      Date
                    </h5>
                    <DateRangePicker
                      autoUpdateInput={false}
                      onApply={handleDateRangeCallback}
                      initialSettings={{
                        locale: {
                          format: 'DD/MM/YYYY'
                        }
                      }}
                    >
                      <button
                        className="form-control"
                        style={{
                          height: "35px",
                          width: "90%",
                          overflow: "hidden",
                          padding: "2px 2px 2px",
                          marginLeft: "-5px",
                        }}
                      >
                        <i
                          className="fa fa-calendar-o"
                          style={{ paddingRight: 15 }}
                          aria-hidden="true"
                        ></i>
                        {moment(Date1).format("DD/MM/YYYY")} -
                        {moment(Date2).format("DD/MM/YYYY")}
                      </button>
                    </DateRangePicker>
                  </div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2">
                  <div class="single-tab-select-box">
                    <h5
                      style={{
                        color: "black",
                        fontWeight: 700,
                        fontSize: 13,
                        marginLeft: "-15px",
                      }}
                    >
                  
                      Stock
                    </h5>
                    <Input
                      style={{
                        height: "35px",
                        overflow: "hidden",
                        padding: "2px 6px 2px",
                        marginLeft: "-15px",
                      }}
                      type="select"
                      className="form-control"
                      value={stockname}
                      onChange={selectStock}
                    >
                      <option value="">All Stocknames</option>
                      {StockFilterdata.sort((a, b) => a.localeCompare(b)).map(
                        (item, key) => (
                          <option key={key} value={item}>
                            
                            {item}
                          </option>
                        )
                      )}
                    </Input>
                  </div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2">
                  <div class="single-tab-select-box">
                    <h5
                      style={{
                        color: "black",
                        fontWeight: 700,
                        fontSize: 13,
                        marginLeft: "-15px",
                      }}
                    >
              
                      Status
                    </h5>
                    <Input
                      style={{
                        height: "35px",
                        overflow: "hidden",
                        padding: "2px 6px 2px",
                        marginLeft: "-15px",
                      }}
                      type="select"
                      className="form-control"
                      value={status}
                      onChange={selectStatus}
                    >
                      <option value="">All Status</option>
                      {StatusFilterdata.sort((a, b) => a.localeCompare(b)).map(
                        (item, key) => (
                          <option key={key} value={item}>
                         
                            {item}
                          </option>
                        )
                      )}
                    </Input>
                  </div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2">
                  <div class="single-tab-select-box">
                    <h5
                      style={{
                        color: "black",
                        fontWeight: 700,
                        fontSize: 13,
                        marginLeft: "-15px",
                      }}
                    >
                      Username
                    </h5>
                    <Input
                      style={{
                        height: "35px",
                        overflow: "hidden",
                        padding: "2px 6px 2px",
                        marginLeft: "-15px",
                      }}
                      type="select"
                      value={user}
                      className="form-control"
                      onChange={selectUser}
                    >
                      <option value="">All Users</option>
                      {UserFilterdata.sort((a, b) => a.localeCompare(b)).map(
                        (item, key) => (
                          <option key={key} value={item}>
                        
                            {item}
                          </option>
                        )
                      )}
                    </Input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loading === true ? (
            <div>
          
              <ConPreLoader />
            </div>
          ) : (
            <Row style={{ marginTop: -15 }}>
              <div className=" col">
                <ReactTooltip place="top" type="dark" effect="float" />
                <TableContainer
                  columns={columns}
                  data={Filterdata}
                  checkedToggle={checked}
                />
              </div>
            </Row>
          )}
          <br />
          <br />
        </Container>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, null)(InventoryDetails);
