import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1
  },
  InvoiceNo: {
    width: "15%",
  },
  Clientname: {
    width: "40%",
  },
  Inamount: {
    width: "15%",
  },
  Reamount: {
    width: "15%",
  },
  Balance: {
    width: "15%",
  }
});

const InvoiceTableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.Clientname}> Client </Text>
    <Text style={styles.InvoiceNo}> DelNote </Text>
    <Text style={styles.Inamount}> Invoice </Text>
    <Text style={styles.Reamount}> Received </Text>   
    <Text style={styles.Balance}>Balance</Text>   
  </View>
);
  
export default InvoiceTableHeader;
