import React from "react";
import { Input, Row, Col } from "reactstrap";
import axios from "axios";
import { connect } from "react-redux";
import config from "react-global-configuration";
import { AllClientDetails } from "../../../components/loginRedux/loginReducers";
import store from "../../../components/loginRedux/Store";
import consoleFunction from "../../../Helpers";
import "../../../config";
import getDateTimeField from "../../../Utils";

const authApi = config.get("apiDomain");
let s = 0;

class AddClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      CompanyName: "",
      Engineer_Name: "",
      Emailid: "",
      PhoneNo: "",
      PanNo: "",
      GstNo: "",
      ClientId: "",
      ChangeIsdeleted: 0,
      Clientgroupname: "",
      Clientgroupdetails: [],
      Clientgroupid: "",
      error: null,
      clientborder: "1px solid #dee2e6",
      companyborder: "1px solid #dee2e6",
      Engineernameborder: "1px solid #dee2e6",
      Pannoborder: "1px solid #dee2e6",
      Gstnoborder: "1px solid #dee2e6",
      Emailborder: "1px solid #dee2e6",
      phonenumberborder: "1px solid #dee2e6",
      isButtonDisabled: false,
      Clientdetails: this.props.clientdetails,
      clientcolor: "",
    };
  }

  async componentDidMount() {
    this.clientgroupdetails();
  }

  getClientDetails = () => {
    fetch(`${authApi}clientfilter_redux/?isdeleted=False`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        localStorage.setItem("clientdetails", JSON.stringify(json));
        store.dispatch({ type: AllClientDetails, data: json });
        this.setState({
          Clientdetails: json,
        });
      });
  };

  clientgroupdetails = () => {
    fetch(`${authApi}clientgroupfilter_new/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          Clientgroupdetails: json,
        });
      });
  };

  Formvalidation = () => {
    if (this.state.ClientId !== "" && this.state.ClientId.length === 5) {
      if (this.state.CompanyName !== "") {
        if (this.state.Engineer_Name !== "") {
          if (this.state.Emailid !== "") {
            if (this.state.PhoneNo !== "") {
              if (this.state.PanNo !== "") {
                if (this.state.GstNo !== "") {
                  this.setState({
                    isButtonDisabled: true,
                  });
                  this.AddUser();
                } else {
                  this.setState({
                    phonenumberborder: "1px solid #dee2e6",
                    companyborder: "1px solid #dee2e6",
                    clientborder: "1px solid #dee2e6",
                    Engineernameborder: "1px solid #dee2e6",
                    Emailborder: "1px solid #dee2e6",
                    Pannoborder: "1px solid #dee2e6",
                    Gstnoborder: "1px solid #fb6340",
                  });
                }
              } else {
                this.setState({
                  phonenumberborder: "1px solid #dee2e6",
                  Engineernameborder: "1px solid #dee2e6",
                  companyborder: "1px solid #dee2e6",
                  clientborder: "1px solid #dee2e6",
                  Emailborder: "1px solid #dee2e6",
                  Pannoborder: "1px solid #fb6340",
                });
              }
            } else {
              this.setState({
                phonenumberborder: "1px solid #fb6340",
                companyborder: "1px solid #dee2e6",
                clientborder: "1px solid #dee2e6",
                Engineernameborder: "1px solid #dee2e6",
                Emailborder: "1px solid #dee2e6",
              });
            }
          } else {
            this.setState({
              companyborder: "1px solid #dee2e6",
              clientborder: "1px solid #dee2e6",
              Engineernameborder: "1px solid #dee2e6",
              Emailborder: "1px solid #fb6340",
            });
          }
        } else {
          this.setState({
            companyborder: "1px solid #dee2e6",
            clientborder: "1px solid #dee2e6",
            Engineernameborder: "1px solid #fb6340",
          });
        }
      } else {
        this.setState({
          companyborder: "1px solid #fb6340",
          clientborder: "1px solid #dee2e6",
        });
      }
    } else {
      this.setState({
        clientborder: "1px solid #fb6340",
      });
    }
  };

  AddUser = () => {
    if (this.state.id === "") {
      let datetime_field = getDateTimeField();
      axios({
        method: "post",
        url: `${authApi}client/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          company_name: this.state.CompanyName,
          engineer_name: this.state.Engineer_Name,
          emailid: this.state.Emailid,
          phoneno: this.state.PhoneNo,
          pannumber: this.state.PanNo,
          gstnumber: this.state.GstNo,
          clientid: this.state.ClientId,
          isdeleted: this.state.ChangeIsdeleted,
          clientgroup: this.state.Clientgroupid,
          createdby: this.props.username,
          createdat: datetime_field,
          modifiedby: this.props.username,
          modifiedat: datetime_field,
        },
      })
        .then((res) => {
          if (res.status) {
            this.getClientDetails();
            this.props.closebtn();
            this.props.callbackfunction();
          }
        })
        .catch((err) => {
          consoleFunction(
            "Client ID Already Exists.Better Try With New Client ID...!",
            err.response.request.response
          );
          this.setState({ error: err.message });
        });
    }
  };

  UserClient_Id = (event) => {
    this.setState(
      {
        ClientId: event.target.value,
      },
      function () {
        this.userclientCheck(event.target.value);
      }
    );
  };

  userclientCheck = (id) => {
    let clientidalreadythere = 0;
    this.state.Clientdetails.forEach((element) => {
      if (element.clientid === id) {
        clientidalreadythere = 1;
      }
    });
    if (clientidalreadythere === 1 || this.state.ClientId.length !== 5) {
      this.setState({
        clientcolor: "red",
      });
    } else {
      this.setState({
        clientcolor: "#14cc1b",
      });
    }
  };

  UserClient_Name = (event) => {
    this.setState({
      CompanyName: event.target.value,
    });
  };

  UserClient_FstName = (event) => {
    this.setState({
      Engineer_Name: event.target.value,
    });
  };

  UserClient_EmailId = (event) => {
    this.setState({
      Emailid: event.target.value,
    });
  };

  UserClient_PhoneNo = (event) => {
    this.setState({
      PhoneNo: event.target.value,
    });
  };
  UserClient_PanNo = (event) => {
    this.setState({
      PanNo: event.target.value,
    });
  };
  UserClient_GstNo = (event) => {
    this.setState({
      GstNo: event.target.value,
    });
  };

  maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  handleClientgroupname = (event) => {
    this.setState({
      Clientgroupname: event.target.value,
    });
    const value1 = event.target.value.replace(/ /g, "%20");
    fetch(`${authApi}clientgroupfilter_new/?name=${value1}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          Clientgroupid: json[0].id,
        });
      });
  };

  keyEventOperation = (event) => {
    if (!event.shiftKey && event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if (form.elements[index + 1] !== undefined) {
        form.elements[index + 1].focus();
      }
      event.preventDefault();
    } else if (event.key.toLowerCase() === "enter" && event.shiftKey) {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if (form.elements[index - 1] !== undefined) {
        form.elements[index - 1].focus();
      }
      event.preventDefault();
    }
  };

  render() {
    const details = this.state.Clientgroupdetails.map((q) => q.name);
    s = details.filter((q, idx) => details.indexOf(q) === idx);
    return (
      <React.Fragment>
        <button className="x" onClick={this.props.closebtn}>
          x
        </button>
        <div className="header">Add Client</div>
        <div className="Scroll-Content">
          <form>
            <Row>
              <Col md="4">
                <label className="form-control-label">Client ID</label>
                <Input
                  type="option"
                  list="id_value-list"
                  placeholder="Must be 5 Digits"
                  className="form-control-alternative"
                  style={{
                    border: this.state.clientborder,
                    color: this.state.clientcolor,
                    fontSize: "15px",
                  }}
                  value={this.state.ClientId}
                  onChange={this.UserClient_Id}
                  maxLength="5"
                  onInput={this.maxLengthCheck}
                  onKeyDown={this.keyEventOperation}
                />
                <datalist id="id_value-list">
                  {this.state.Clientdetails.map((item, key) => (
                    <option key={key} value={item.clientid} />
                  ))}
                </datalist>
                {this.state.clientborder !== "1px solid #dee2e6" ? (
                  <h5 style={{ color: "#fb6340" }}>
                    Client id must be in 5 Digits
                  </h5>
                ) : null}
              </Col>
              <Col md="4">
                <label className="form-control-label">Company Name</label>
                <Input
                  type="text"
                  className="form-control-alternative"
                  defaultValue={this.state.CompanyName}
                  style={{ border: this.state.companyborder }}
                  onChange={this.UserClient_Name}
                  onKeyDown={this.keyEventOperation}
                />
                {this.state.companyborder !== "1px solid #dee2e6" ? (
                  <h5 style={{ color: "#fb6340" }}>Please enter companyname</h5>
                ) : null}
              </Col>
              <Col md="4">
                <label className="form-control-label">Engineer Name</label>
                <Input
                  type="text"
                  className="form-control-alternative"
                  defaultValue={this.state.Engineer_Name}
                  style={{ border: this.state.Engineernameborder }}
                  onChange={this.UserClient_FstName}
                  onKeyDown={this.keyEventOperation}
                />
                {this.state.Engineernameborder !== "1px solid #dee2e6" ? (
                  <h5 style={{ color: "#fb6340" }}>
                    Please enter engineername
                  </h5>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <label className="form-control-label">Email Id</label>
                <Input
                  type="text"
                  className="form-control-alternative"
                  defaultValue={this.state.Emailid}
                  style={{ border: this.state.Emailborder }}
                  onChange={this.UserClient_EmailId}
                  onKeyDown={this.keyEventOperation}
                />
                {this.state.Emailborder !== "1px solid #dee2e6" ? (
                  <h5 style={{ color: "#fb6340" }}>Please enter emailid</h5>
                ) : null}
              </Col>
              <Col md="4">
                <label className="form-control-label">Phone No.</label>
                <Input
                  type="text"
                  className="form-control-alternative"
                  defaultValue={this.state.PhoneNo}
                  style={{ border: this.state.phonenumberborder }}
                  onChange={this.UserClient_PhoneNo}
                  onKeyDown={this.keyEventOperation}
                />
                {this.state.phonenumberborder !== "1px solid #dee2e6" ? (
                  <h5 style={{ color: "#fb6340" }}>Please enter phonenumber</h5>
                ) : null}
              </Col>
              <Col md="4">
                <label className="form-control-label">Clientgroup</label>
                <Input
                  type="select"
                  className="form-control"
                  defaultValue={this.state.Clientgroupname}
                  onChange={this.handleClientgroupname}
                  onKeyDown={this.keyEventOperation}
                >
                  <option value="">Select Clientgroup</option>
                  {s
                    .sort((a, b) => a.localeCompare(b))
                    .map((item, key) => (
                      <option key={key} value={item}>
                        {item}
                      </option>
                    ))}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <label className="form-control-label">Pan Number</label>
                <Input
                  type="text"
                  className="form-control-alternative"
                  defaultValue={this.state.PanNo}
                  style={{ border: this.state.Pannoborder }}
                  onChange={this.UserClient_PanNo}
                  onKeyDown={this.keyEventOperation}
                />
                {this.state.Pannoborder !== "1px solid #dee2e6" ? (
                  <h5 style={{ color: "#fb6340" }}>Please enter pan no.</h5>
                ) : null}
              </Col>
              <Col md="4">
                <label className="form-control-label">Gst Number</label>
                <Input
                  type="text"
                  className="form-control-alternative"
                  style={{ border: this.state.Gstnoborder }}
                  defaultValue={this.state.GstNo}
                  onChange={this.UserClient_GstNo}
                  onKeyDown={this.keyEventOperation}
                />
                {this.state.Gstnoborder !== "1px solid #dee2e6" ? (
                  <h5 style={{ color: "#fb6340" }}>Please enter gst no.</h5>
                ) : null}
              </Col>
              <Col md="4">
                <div className="button">
                  <button
                    id="buttonsave"
                    className="btn btn-dark"
                    style={{ float: "right", marginRight: 15 }}
                    onClick={this.Formvalidation}
                    disabled={this.state.isButtonDisabled}
                  >
                    Add
                  </button>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
    clientdetails: state.clientdetails,
    username: state.username,
  };
};
export default connect(mapStateToProps, null)(AddClient);
