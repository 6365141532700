import React, { useEffect, useState, useMemo } from "react";
import ConPreLoader from "../../../../components/Loader/Con_PreLoader";
import TableContainerExpand from "../../Filters/TableContainerExpand";
import { SelectColumnFilter } from "../../Filters/filters";
import "reactjs-popup/dist/index.css";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../../config";
import axios from "axios";
import { Row, Container, NavLink, Nav, NavItem, Table } from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { CurrentLocation } from "../../../../components/loginRedux/loginReducers";	
import store from "../../../../components/loginRedux/Store";

const authApi = config.get("apiDomain");
let group_data = "";
let currentActiveTab = "1";
let availableStockdata = "";

function Inventory(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(true);

  useEffect(
    () => {
      const doFetch = async () => {
        store.dispatch({ type: CurrentLocation, data: props.location.pathname })
        await axios({
          method: "post",
          url: `${authApi}getavailableqtytemplate/`,
          headers: {
            "content-type": "application/json",
            Authorization: `token ${props.token}`,
          },
        })
          .then((res) => {
            console.log(res);
            availableStockdata = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
        const response = await fetch(`${authApi}inventorytransactionfilter/`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${props.token}`,
          },
        });
        const myTaskData = await response.json();
        if (myTaskData.length === 0) {
          alert("Inventories are empty.!");
          setLoading(false);
        } else {
          const details = myTaskData.sort((a, b) => a.id - b.id);
          group_data = multipleGroupByArray(
            details,
            (property) => property.stockname
          );
          getInventoryDetails(group_data);
          setData(group_data);
          setLoading(false);
          console.log(setChecked(true));
        }
      };
      doFetch();
    },
    // eslint-disable-next-line
    []
  );

  function multipleGroupByArray(dataArray, groupPropertyArray) {
    const groups = {};
    dataArray.forEach((item) => {
      const group = JSON.stringify(groupPropertyArray(item));
      groups[group] = groups[group] || [];
      groups[group].push(item);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    });
  }

  const getInventoryDetails = (body) => {
    body.forEach((e) => {
      e.quantity = calculateStockQuantity(e);
    });
    body.forEach((e) => {
      e.stockname = setStockname(e);
    });
    body.forEach((e) => {
      e.productcode = setProductcode(e);
    });
    body.forEach((e) => {
      e.inventoryquantity = setInventoryQuantity(e);
    });
    body.forEach((e) => {
      e.purchaseorderquantity = setPurchaseorderQuantity(e);
    });
    body.forEach((e) => {
      e.salesorderquantity = setSalesorderQuantity(e);
    });
  };

  function calculateStockQuantity(e) {
    let total = 0;
    total = e.reduce((i, i_bal) => i + parseInt(i_bal.quantity, 0), 0);
    return total;
  }

  function setStockname(e) {
    let sn = "";
    sn = e[0].stockname;
    return sn;
  }

  function setProductcode(e) {
    let pc = "";
    pc = e[0].productcode;
    return pc;
  }

  function setInventoryQuantity(e) {
    let inq = "";
    availableStockdata.forEach((storeproc) => {
      if (e[0].stockname === storeproc.StockName) {
        inq = storeproc.InventoryQty;
      }
    });
    return inq;
  }

  function setPurchaseorderQuantity(e) {
    let poq = "";
    availableStockdata.forEach((storeproc) => {
      if (e[0].stockname === storeproc.StockName) {
        poq = storeproc.POQty;
      }
    });
    return poq;
  }

  function setSalesorderQuantity(e) {
    let soq = "";
    availableStockdata.forEach((storeproc) => {
      if (e[0].stockname === storeproc.StockName) {
        soq = storeproc.SOQty;
      }
    });
    return soq;
  }

  const renderRowSubComponent = (row) => {
    return (
      <React.Fragment>
        <br />
        <h6 className="thead-light"> Recent Stock details </h6>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th className="text-center">Date</th>
              <th className="text-center">Stockname</th>
              <th className="text-center">Quantity</th>
              <th className="text-center">Status</th>
              <th className="text-center">Purchaseorderid</th>
            </tr>
          </thead>
          {row.original
            .sort((a, b) => a.date - b.date)
            .reverse()
            .slice(0, 5)
            .map((r, id) => {
              return (
                <tbody key={id}>
                  <tr>
                    <td className="text-center">{r.date}</td>
                    <td className="text-center">{r.stockname}</td>
                    <td className="text-center">{r.quantity}</td>
                    <td className="text-center">{r.status}</td>
                    <td className="text-center">{r.purchaseorderid}</td>
                  </tr>
                </tbody>
              );
            })}
        </Table>
        <br />
      </React.Fragment>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander",
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <i className="ni ni-bold-down" />
            ) : (
              <i className="ni ni-bold-right" />
            )}
          </span>
        ),
      },
      {
        Header: "Stockname",
        accessor: "stockname",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Productcode",
        accessor: "productcode",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "InventoryQty",
        accessor: "inventoryquantity",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "PurchaseorderQty",
        accessor: "purchaseorderquantity",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "SalesorderQty",
        accessor: "salesorderquantity",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "AvailableQty",
        accessor: "quantity",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Low Stock",
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {parseInt(row.original.quantity) > 100 ? (
              <div style={{ textAlign: "center" }}>Have Good Quantity</div>
            ) : null}
            {parseInt(row.original.quantity) > 50 &&
            parseInt(row.original.quantity) <= 100 ? (
              <div style={{ textAlign: "center" }}>Have Limited Stocks</div>
            ) : null}
            {parseInt(row.original.quantity) > 20 &&
            parseInt(row.original.quantity) <= 50 ? (
              <div style={{ textAlign: "center" }}>
                Stocks Almost Sales / Purchase
              </div>
            ) : null}
            {parseInt(row.original.quantity) > 0 &&
            parseInt(row.original.quantity) <= 20 ? (
              <div style={{ textAlign: "center" }}></div>
            ) : null}
            {parseInt(row.original.quantity) <= 0 ? (
              <div style={{ textAlign: "center" }}>Out of Stock</div>
            ) : null}
          </span>
        ),
      },
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <div style={{ marginTop: 0 }}>
        <Nav
          tabs
          style={{
            padding: "25px 20px 0",
            fontSize: 20,
            backgroundColor: "#11cdef",
          }}
        >
          <NavItem>
            <NavLink
              className={classnames({
                active: currentActiveTab === "1",
              })}
              to="/admin/management/inventory"
              tag={Link}
            >
              Inventory
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ color: "white" }}
              className={classnames({
                active: currentActiveTab === "2",
              })}
              to="/admin/inventory/inventorydetails"
              tag={Link}
            >
              Inventory Details
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ color: "white" }}
              className={classnames({
                active: currentActiveTab === "3",
              })}
              to="/admin/inventory/inventorymonthly"
              tag={Link}
            >
              Monthly Data
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ color: "white" }}
              className={classnames({
                active: currentActiveTab === "4",
              })}
              to="/admin/inventory/lowstock"
              tag={Link}
            >
              Low Stock
            </NavLink>
          </NavItem>
        </Nav>
        <Container className="mt--6" fluid>
          <br />
          <Row>
            <div
              className="addRow"
              style={{ position: "absolute", right: "65px" }}
            >
              <ReactTooltip
                id="Conversion-Tip"
                place="left"
                type="dark"
                effect="solid"
              >
                Conversion
              </ReactTooltip>
              <NavLink to="/admin/inventory/conversiontable" tag={Link}>
                <button
                  className="btn btn-primary btn-sm"
                  data-tip
                  data-for="Conversion-Tip"
                  style={{ width: "135%", height: 40 }}
                >
                  <i className="ni ni-basket"></i>
                </button>
              </NavLink>
            </div>
          </Row>
          <br />
          <br />
          <Row style={{ marginTop: 5 }}>
            {loading === true ? (
              <div>
             
                <ConPreLoader />
              </div>
            ) : (
              <div className=" col">
                <TableContainerExpand
                  columns={columns}
                  data={data}
                  renderRowSubComponent={renderRowSubComponent}
                  checkedToggle={checked}
                />
              </div>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, null)(Inventory);
