import React from 'react';
import {connect} from 'react-redux';
import config from "react-global-configuration";
import '../../../../../../config';
import {Row,Card,Table} from "reactstrap";
const authApi = config.get('apiDomain');
class SalesOrder extends React.Component{
    constructor(props){
        super(props);
        this.state={
           PurchaseStocksdetails:[],
           SalesOrder:[],
           Userdetails:[],
           CompanyDetails:this.props.companydetails,
           Clientdetails:this.props.clientdetails,
           Supplier:this.props.supplier,
        }
    }

async componentDidMount(){

    this.getpurchaseStock();
}

getpurchaseStock=()=>{

  fetch(`${authApi}salesfilter/?supplier=${this.props.supplier}&status__in=Partially+Dispatched%2CIssued`, {
    method: 'GET',
    headers: {
    'content-type': "application/json",
    'Authorization': `token ${this.props.token}`,
    }
    })
    .then(res=>res.json())
    .then(json=>{
      console.log(json);
      json.forEach(salesorder => {
          salesorder.supplier=this.props.suppliername;
        this.props.companydetails.forEach(company => {
            if(parseInt(salesorder.company)===company.id){
                salesorder.company=company.companyname;
            }
        });
      });
        this.setState({
           SalesOrder:json,
       })
    })
    .catch(err=>{
      console.log(err);
    })
}

updatepurchasedetails=(salesorder)=>{
  this.props.supplierdetails(salesorder);
  this.props.closebtn();
}



render(){
        return(
            <React.Fragment>
            <button className="x" onClick={this.props.closebtn}>
            x
            </button>
            <div className="header">
               Add Sales Order 
            </div>
            <div className="Scroll-Content">
            <Row>
            <div className=" col"><br />
              <Card className="shadow">
                <Table className="align-items-center table-flush" responsive-sm>
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center">ACTION</th>
                      <th className="text-center">PURCHASE ID</th>
                      <th className="text-center">COMPANY NAME</th>
                      <th className="text-center">SUPPLIER NAME</th>
                    </tr>
                  </thead>
                 <tbody>{this.state.SalesOrder.map((sale)=>(
                     <tr>
                         <td><button className = "btn btn-primary btn-sm" style={{marginRight: '5px'}} onClick={()=>this.updatepurchasedetails(sale)}>Add Dispatch</button></td>
                         <td>{sale.id}</td>
                         <td>{sale.company}</td>
                         <td>{sale.supplier}</td>
                     </tr>
                 ))}</tbody>
                </Table>
              </Card>
            </div>
          </Row>
          </div>
          </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
      token:state.token, 
      name:state.name,
    }
  }
  export default connect(mapStateToProps, null)(SalesOrder);