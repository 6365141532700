import React from "react";
import { View, StyleSheet,Text } from "@react-pdf/renderer";
import PurchaseorderTableHeader from "./PurchaseorderTableHeader";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 2,
    borderWidth: 1,
    borderColor: "#bff0fd"
  },
  rows: {
    flexDirection: "row",
    alignItems: "center",
    fontStyle: "bold"
  },
});

const PurchaseorderTable = ({ manageData }) => {

  const tableRows = manageData.purchasestock.filter((d) => d.name !== "Total" && d.isdeleted === false).map((t) => (
    <View style={styles.rows}>
      <Text style={{ width: "10%",textAlign: "left",paddingLeft: 8,paddingTop: 4,}}>{t.productcode}</Text>
      <Text style={{ width: "25%",textAlign: "left",paddingLeft: 8,paddingTop: 4,}}>{t.name}</Text>
      <Text style={{ width: "15%",textAlign: "center",paddingRight: 8,paddingTop: 4,}}>{t.quantity}</Text>
      <Text style={{ width: "15%",textAlign: "center",paddingRight: 8,paddingTop: 4,}}>{t.rate}</Text>
      <Text style={{ width: "20%",textAlign: "center",paddingRight: 8,paddingTop: 4,}}>{t.cgstdiscountpercentage} / {t.sgstdiscountpercentage}</Text>
      <Text style={{ width: "15%",textAlign: "center",paddingRight: 0,paddingTop: 4,}}>{Number.parseFloat(t.total).toFixed(2)}</Text>
    </View>
  ))

  return( 
    <View style={styles.tableContainer}>
      <PurchaseorderTableHeader />
      {tableRows}
    </View>
  )
};

export default PurchaseorderTable;