import React from 'react';
import {connect} from 'react-redux';
import config from "react-global-configuration";
import PurchaseOrderRow from "./PurchaseOrderRow";
import '../../../../../config';
import {Row,Card,Table} from "reactstrap";
const authApi = config.get('apiDomain');
class PurchaseOrder extends React.Component{
    constructor(props){
        super(props);
        this.state={
           PurchaseStocksdetails:[],
           purchaseOrder:[],
           Userdetails:[],
           CompanyDetails:this.props.companydetails,
           Clientdetails:this.props.clientdetails,
           Supplier:this.props.supplier,
        }
    }

async componentDidMount(){
    this.getpurchaseStock();
}

getpurchaseStock=()=>{
  fetch(`${authApi}purchaseorderfilter/?supplier=${this.props.supplier}&status__in=Issued%2CPartially+Received&isdeleted=false`, {
    method: 'GET',
    headers: {
    'content-type': "application/json",
    'Authorization': `token ${this.props.token}`,
    }
    })
    .then(res=>res.json())
    .then(json=>{
      console.log("Check REceived");
      console.log(json);
        this.setState({
           purchaseOrder:json,
       })
    })
    .catch(err=>{
      console.log(err);
    })
}

updatepurchasedetails=(order)=>{
  this.props.supplierdetails(order);
  this.props.closebtn();
}



render(){
       const requestedstockdetails=this.state.purchaseOrder.map((order)=>(
         <PurchaseOrderRow 
         key={order.id}
         orderdata={order}
         clients={this.state.Clientdetails}
         company={this.state.CompanyDetails}
         AddPurchase={this.updatepurchasedetails}
         purchaseorderid={this.props.purchaseorderid}
         />
       ))
        return(
            <React.Fragment>
            <button className="x" onClick={this.props.closebtn}>
            x
            </button>
            <div className="header">
               Add Purchase Order 
            </div>
            <div className="Scroll-Content">
            <Row>
            <div className=" col"><br />
              <Card className="shadow">
                <Table className="align-items-center table-flush" responsive-sm>
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center">ACTION</th>
                      <th className="text-center">PURCHASE ID</th>
                      <th className="text-center">COMPANY NAME</th>
                      <th className="text-center">SUPPLIER NAME</th>
                    </tr>
                  </thead>
                 <tbody>{requestedstockdetails}</tbody>
                </Table>
              </Card>
            </div>
          </Row>
          </div>
          </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
      token:state.token, 
      name:state.name,
    }
  }
  export default connect(mapStateToProps, null)(PurchaseOrder);