import React, { Component } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import ClientReports from "./ClientReports";
import {Row, Card} from "reactstrap";
import '../../../../config';
import { connect } from "react-redux";
import moment from "moment";

let rowData = "";
let TotalTime = "";

class ClientReportsAppDF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientReport: this.props.dataFilter,
      jsonObject: "",
      startDate : "",
      endDate : "",
      loading : true,
    };
  }
  
  componentDidMount() {
    rowData = [];
    TotalTime = "00:00"
    const a = moment(this.props.dateStart).format("YYYY-MM-DD");
    const b = moment(this.props.dateEnd).format("YYYY-MM-DD");
    this.setState({
      startDate: a,
      endDate: b,
    })
    this.downloadFunction();
  }

  downloadFunction = () => {
    this.state.clientReport.forEach((d)=> {
      rowData = [...rowData, d.original]
    })
    this.setState({
      jsonObject: rowData,
    },function(){
      this.HandleTotalTime();
    })
  } 

  HandleTotalTime() {
    let total=0 , mins=0,times=0,stringtime=0 ,h=0,m=0;
    this.state.jsonObject.forEach((d) => {
      stringtime=d.time;
      times=stringtime.split(':');
      mins= times[0]*60 + +times[1];
      total+= mins;
      h = (total/60 |0);
      m = total % 60;
      function z(n){return (n<10? '0':'') + n;}
        TotalTime = z(h) + ':' + z(m);
        this.setState({
          loading: false
        })
    });
  }

  render() {
    if(this.state.loading)
    {
      return null;
    }
    else
    {
      return (
        <React.Fragment>
          <button className="x" onClick={this.props.closebtn}>
            x
          </button>
          <div className="header">
            Report
          </div>
          <Row>
            <div className=" col">
              <div className="Scrolls-Contents">
                <Card className="shadow">
                  <PDFViewer width="100%" height="600" className="app">
                    <ClientReports
                      GenerateData = {this.state.jsonObject}
                      timeTotal = {TotalTime}
                      sD = { this.state.startDate }
                      eD = { this.state.endDate }
                    />
                  </PDFViewer>
                </Card>
              </div>
            </div>
          </Row>
        </React.Fragment>
      );
    }
    }
  }

const mapStateToProps = state => {
  return {
    token:state.token,
    dataFilter: state.filterData
  }
}
export default connect(mapStateToProps, null)(ClientReportsAppDF);