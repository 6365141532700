import React,{useEffect, useState, useMemo} from "react";
import ReactTooltip from "react-tooltip";
import ConPreLoader from "components/Loader/Con_PreLoader";
import EditLedgergroup from "./EditLedgergroup";
import TableContainerExpand from "../../../Filters/TableContainerExpand";
import { SelectColumnFilter } from "../../../Filters/filters";
import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";
import AddLedgergroup from "./AddLedgergroup";
import {connect} from 'react-redux';
import config from "react-global-configuration";
import '../../../../../config';
import { Link } from "react-router-dom";
import classnames from 'classnames';
import { Row,NavLink, Nav, NavItem,Container,Table } from "reactstrap";
import { CurrentLocation } from "../../../../../components/loginRedux/loginReducers";	
import store from "../../../../../components/loginRedux/Store";

const authApi = config.get('apiDomain');
let currentActiveTab = '1'

function Ledgergroups(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(true);
  const [btnClass, setBtnClass] = useState("btn btn-danger btn-sm")
  
  useEffect(() => {
      const doFetch = async () => {
        store.dispatch({ type: CurrentLocation, data: props.location.pathname })
        const response = await fetch(`${authApi}ledgergroupfilter/`, {
          method: 'GET',
          headers: {
          'content-type': "application/json",
          'Authorization': `token ${props.token}`,
          }
        }) 
        const ledgergroups = await response.json();
        if(ledgergroups.length === 0){
          alert("Not available ledgergroups.!");
          setLoading(false);
        }
        else {
          const details = ledgergroups.sort((a, b) => (a.id - b.id));
          setData(details);
          setLoading(false);
        }}
      doFetch();
    },
// eslint-disable-next-line  
  []
);

function ledgergroupupdate(){
  const doFetch = async () => {
    const response = await fetch(`${authApi}ledgergroupfilter/`, {
      method: 'GET',
      headers: {
      'content-type': "application/json",
      'Authorization': `token ${props.token}`,
      }
    }) 
    const ledgergroups = await response.json();
    if(ledgergroups.length === 0){
      alert("Not available ledgergroups.!");
      setLoading(false);
    }
    else {
      const details = ledgergroups.sort((a, b) => (a.id - b.id));
      setData(details);
      setLoading(false);
    }}
  doFetch();
}

function getFilterButton(event){
  if(event === true ){
    setChecked(false)
    setBtnClass("btn btn-success btn-sm")
  }
  else{
    setChecked(true)
    setBtnClass("btn btn-danger btn-sm")
  }
}

const columns = useMemo(
  () => [
    {
      Header: () => null,
      id: "expander",
      Cell: ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? (
            <i className="ni ni-bold-down" />
          ) : (
            <i className="ni ni-bold-right" />
          )}
        </span>
      ),
    },
    {
      Header: "Actions",
      Cell: (row) => {
        const row_data = row.row.original;
        return (
          <div>
            <Popup
              modal
              trigger={
                <button className="btn btn-primary btn-sm" style={{marginRight: '5px'}}>
                  <i className="fa fa-edit" />
                </button>
              }
              position="center"
            >
              {(close) => (
                <EditLedgergroup
                  EditRowSend={row_data}
                  closebtn={close}
                  callbackfunction={ledgergroupupdate}
                />
              )}
            </Popup>
          </div>
        );
      },
    },
    {
      Header: "Id",
      accessor: "id",
      disableSortBy: true,
      Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
    },
    {
      Header: "Ledgergroupname",
      accessor: "ledgergroupname",
      disableSortBy: true,
      Filter: SelectColumnFilter,
      filter: "equals",
      Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
    }
  ],
  // eslint-disable-next-line
  []
);

const renderRowSubComponent = (row) => {
  return (
    <React.Fragment>
      <h6 className="thead-light">Ledgertype Details :</h6>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th className="text-center">Id</th>
            <th className="text-center">TypeName</th>
          </tr>
        </thead>
        {row.original.ledgertypegroup.filter((i) => i.isvisible === true).map((r, id) => {
          return (
            <tbody key={id}>
              <tr>
                <th className="text-center">{r.id}</th>
                <td className="text-center">{r.ledgertypename}</td>
              </tr>
            </tbody>
          );
        })}
      </Table>
    </React.Fragment>
  );
};

  return (
    <>
      <div style={{ marginTop: 0}}>
        <Nav tabs style={{ padding: "25px 20px 0", fontSize: 20, backgroundColor: '#11cdef' }}>
			  	<NavItem>
			  		<NavLink style={{ color: 'white' }}
			  			className={classnames({
			  				active:
			  					currentActiveTab === '2'
			  			})}
			  			to="/admin/ledger/ledgertypes"
                  		tag={Link}
			  		>
			  			Ledger Type
			  		</NavLink>
			  	</NavItem>
          <NavItem>
			  		<NavLink
			  			className={classnames({
			  				active:
			  					currentActiveTab === '1'
			  			})}
			  			to="/admin/ledger/ledgergroups"
                  		tag={Link}
			  		>
			  			Ledger Group
			  		</NavLink>
			  	</NavItem>
			  </Nav>
        <Container className="mt--6" fluid>
        <br />
        <Row>
          <div className="addRow" style={{position:"absolute", right:"105px"}}>
            <ReactTooltip id="addledgergroup-Tip" place="left" type="dark" effect="solid">
              Add New Ledgergroup
            </ReactTooltip>
            <Popup 
              modal
              position="center"
              trigger={
              <button className="btn btn-primary btn-sm"
                style={{ width: "135%", height: 40 }} data-tip data-for="addledgergroup-Tip">
                <i className="fas fa-plus-circle fa-1x"></i>
              </button>
              }
              >
              {
                (close) => (
                  <AddLedgergroup
                    closebtn={close}
                    callbackfunction={ledgergroupupdate}
                  />
                )}
            </Popup>
          </div>
          <div className="addRow" style={{position:"absolute", right:"55px"}}>
            <button className={btnClass} style = {{ width:'135%', height:40 }} onClick={(e) => getFilterButton(checked)}>
              <i className="fa fa-filter" aria-hidden="true"></i>
            </button>
          </div>
        </Row>
        <br /><br />
        <Row style={{ marginTop: 10}}>
        { loading === true ? <div> <ConPreLoader /></div> :
          <div className="col">
            <TableContainerExpand
              columns={columns}
              data={data}
              checkedToggle={checked}
              renderRowSubComponent={renderRowSubComponent}
            />
          </div>
          }
        </Row>
        </Container>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    token:state.token, 
  }
}

export default connect(mapStateToProps, null)(Ledgergroups);