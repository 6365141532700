import React, { Component } from "react";
import config from "react-global-configuration";
import { Input, Row, Col } from "reactstrap";
import "../../../config";
import getDateTimeField from "../../../Utils";
import consoleFunction from "../../../Helpers";
import axios from "axios";
import { connect } from "react-redux";

let taxyearstartdate = "",
  taxyearenddate = "";

const authApi = config.get("apiDomain");

class CancelInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: "",
      commentsborder: "1px solid #dee2e6",
      MainTask: "",
      Invoicedetails: [],
      transaction: [],
      userid: "",
      TransactionClientBalance: 0,
      TransactionInvoiceBalance: 0,
      isButtonDisabled: false,
      alreadyCancelled: 0,
      companyid: "",
    };
  }
  async componentDidMount() {
    this.handleCancellInvoice();
    let currentuser = this.props.allusers.filter((userdetails) => {
      return userdetails.name === this.props.username;
    });
    this.setState({
      userid: currentuser[0].id,
    });
    let maxdata = 0,
      max = 0;
    const responseyear = await this.checkTaxYear();
    responseyear.forEach((currentyear) => {
      if (currentyear.id > max) {
        max = currentyear.id;
        maxdata = currentyear;
      }
    });
    taxyearstartdate = maxdata.startdate;
    taxyearenddate = maxdata.enddate;
  }

  checkTaxYear = () => {
    return fetch(`${authApi}taxyear/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());
  };

  handleCancellInvoice = () => {
    this.setState({
      alreadyCancelled:
        this.props.invoicedetails.invoicestatus === "Cancelled" ? 1 : 0,
    });
    if (this.props.invoicedetails.invoicestatus === "Cancelled") {
      this.setState({
        isButtonDisabled: true,
      });
    }
  };

  EnterComments = (event) => {
    this.setState({
      comments: event.target.value,
    });
  };

  Formvalidation = () => {
    if (this.state.alreadyCancelled === 0) {
      if (this.state.comments !== "") {
        this.setState({
          isButtonDisabled: true,
        });
        if (this.props.projectid === null) {
          this.CancelInvoice();
        } else {
          this.ProjectInvoiceCancel();
        }
      } else {
        this.setState({
          commentsborder: "1px solid #fb6340",
        });
      }
    } else {
      this.setState({
        isButtonDisabled: true,
      });
    }
  };

  CancelInvoice = () => {
    let userdetails = "UserId:" + this.state.userid.toString();
    let comments = this.state.comments.replace(/ /g, "%20");
    let totalcomments = userdetails + "_" + comments;
    axios({
      method: "put",
      url: `${authApi}sp_cancelinvoice/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        deliverynoteid: this.props.deliverynoteid,
        companyid: this.props.companyid,
        usercomments: totalcomments,
      },
    })
      .then((res) => {
        if (
          this.props.invoicedetails.client !== 110 &&
          this.props.invoicedetails.client !== 182
        ) {
          this.updatetransactionunpaid();
        } else {
          this.props.callbackfunction();
        }
      })
      .catch((err) => {
        if (
          this.props.invoicedetails.client !== 110 &&
          this.props.invoicedetails.client !== 182
        ) {
          this.updatetransactionunpaid();
        }
        consoleFunction("Cancel invoice error", err.response.request.response);
        this.props.callbackfunction();
      });
  };

  ProjectInvoiceCancel = () => {
    axios({
      method: "post",
      url: `${authApi}projectinvoicecancel/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        deliverynoteid: this.props.deliverynoteid,
        companyid: this.props.companyid,
      },
    })
      .then((res) => {
        if (
          this.props.invoicedetails.client !== 110 &&
          this.props.invoicedetails.client !== 182
        ) {
          this.updatetransactionunpaid();
        } else {
          this.props.callbackfunction();
        }
      })
      .catch((err) => {
        if (
          this.props.invoicedetails.client !== 110 &&
          this.props.invoicedetails.client !== 182
        ) {
          this.updatetransactionunpaid();
        }
        consoleFunction("Cancel invoice error", err.response.request.response);
        this.props.callbackfunction();
      });
  };

  updatetransactionunpaid = () => {
    axios({
      method: "post",
      url: `${authApi}sp_payforunpaidinvoice_cancel/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        client_id: this.props.invoicedetails.client,
        company_id_value: this.props.companyid,
        startdate: taxyearstartdate,
        enddate: taxyearenddate,
        userid: this.props.currentuserid,
        deliverynoteid: this.props.deliverynoteid,
        description: "The invoice has been cancelled",
      },
    })
      .then((res) => {
        this.props.closebtn();
        this.props.callbackfunction();
      })
      .catch((err) => {
        consoleFunction(
          "Update transaction unpaid error",
          err.response.request.response
        );
        this.props.callbackfunction();
      });
  };

  render() {
    return (
      <React.Fragment>
        <button className="x" onClick={this.props.closebtn}>
          x
        </button>
        {this.props.projectid === null ? (
          <div className="header">Cancel Invoice</div>
        ) : (
          <div className="header">Project Invoice Cancellation..!</div>
        )}
        <Row>
          <Col md="12">
            <Input
              className="form-control-alternative"
              type="textarea"
              value={this.state.comments}
              placeholder="Reasons for Cancellation"
              style={{ border: this.state.commentsborder }}
              onChange={this.EnterComments}
            />
            {this.state.commentsborder !== "1px solid #dee2e6" ? (
              <h5 style={{ color: "#fb6340" }}>
                Enter valid reason for cancellation
              </h5>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col md="2"></Col>
          <Col md="8">
            {this.state.alreadyCancelled !== 0 ? (
              <h5 style={{ color: "#fb6340", textAlign: "center" }}>
                Already cancelled this invoice
              </h5>
            ) : null}
          </Col>
          <Col md="2"></Col>
        </Row>
        <Row>
          <Col md="10"></Col>
          <Col md="2">
            <button
              id="buttonsave"
              className="btn btn-dark"
              onClick={this.Formvalidation}
              disabled={this.state.isButtonDisabled}
            >
              Save
            </button>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    username: state.username,
    allusers: state.allusers,
  };
};
export default connect(mapStateToProps, null)(CancelInvoice);
