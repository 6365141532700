import React from "react";
import {
  Page,
  Document,
  StyleSheet,
  Image,
  View,
  Text,
} from "@react-pdf/renderer";
import logo from "../../../assets/img/brand/pgcadd.png";
import InvoiceTableFooter from "./InvoiceTableFooter";
import InvoiceThankYouMsg from "./InvoiceThankYouMsg";
import InvoiceTable from "./InvoiceTable";
import InvoiceTitle from "./InvoiceTitle";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    marginTop: -125,
    width: 75,
    height: 100,
    marginLeft: "auto",
    marginBottom: 5,
  },

  titleContainer: {
    width: 300,
    height: 66,
    marginRight: "auto",
    marginLeft: -50,
    marginTop: 15,
  },
  reportTitle: {
    color: "#11cdef",
    letterSpacing: 1,
    fontSize: 20,
    marginLeft: 23,
    textAlign: "left",
    textTransform: "uppercase",
  },
  titlelocation: {
    width: 140,
    height: 66,
    marginRight: "auto",
    marginLeft: -25,
    marginTop: -33,
  },

  invoiceNoContainer: {
    marginTop: -70,
    width: 200,
    height: 66,
    marginLeft: "auto",
    marginRight: -90,
    marginBottom: 5,
  },

  invoiceClientContainer: {
    width: 200,
    height: 66,
    marginRight: "auto",
    marginLeft: -25,
    marginTop: 15,
  },

  invoiceDate: {
    fontSize: 12,
    fontStyle: "bold",
  },
});

const Invoice = ({ individualInvoicedata, salesOrderId }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>


        <View style={styles.titleContainer}>
          <Text style={styles.reportTitle}>
            
            {individualInvoicedata.companyname}
          </Text>
        </View>
        <View>
          <Text style={styles.titlelocation}>
            334, opp. Indusind bank, Mettur road, Erode - 638001.
          </Text>
        </View>
        <View>
          <Image style={styles.logo} src={logo} />
        </View>

        <View>
          <View style={styles.invoiceClientContainer}>
            {individualInvoicedata.clientCompany === "General" ||
            individualInvoicedata.clientCompany === "General-Printing" ? (
              <>
                <Text style={styles.clientname}>
                  
                  {individualInvoicedata.subclient}
                </Text>
                <Text style={styles.clientcompany} />
                <Text style={styles.clientemail} />
                <Text style={styles.clientnumber} />
              </>
            ) : (
              <>
                <Text style={styles.clientname}>
                  
                  {individualInvoicedata.clientname}
                </Text>
                <Text style={styles.clientcompany}>
                  
                  {individualInvoicedata.clientCompany}
                </Text>
                <Text style={styles.clientemail}>
                  
                  {individualInvoicedata.Mobilenumber}
                </Text>
                <Text style={styles.clientnumber}>
                  
                  {individualInvoicedata.emailId}
                </Text>
              </>
            )}
          </View>
        </View>

        <View style={styles.invoiceNoContainer}>
          {individualInvoicedata.companyinvoiceid !== null ? (
            <Text>Invoice : {individualInvoicedata.companyinvoiceid}</Text>
          ) : null}
          <Text>SalesorderId : {individualInvoicedata.invoiceno}</Text>
          <Text>Date : {individualInvoicedata.invoicedate}</Text>
        </View>

        <InvoiceTitle />

        <InvoiceTable
          manageData={individualInvoicedata}
          sales_Orderid={salesOrderId}
        />

        <InvoiceTableFooter
          itemsTotal={individualInvoicedata}
          sales_Orderid={salesOrderId}
        />

        <InvoiceThankYouMsg />
      </Page>
    </Document>
  );
};

export default Invoice;
