import React, { Component } from 'react'

export default class InvoiceUserRow extends Component {
    constructor(props){
        super(props);
        this.state={
            id:this.props.id,
            user:this.props.user,
            unit:this.props.unit,
            rate:this.props.rate,
            amount:this.props.amount,
        }
    }
    render() {
        return (
            <tr>
                <td className="text-center">{this.state.user}</td>
                <td className="text-center">{this.state.unit}</td>
                <td className="text-center">{this.state.rate}</td>
                <td className="text-center">{this.state.amount}</td>
            </tr>
        )
    }
}
