import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTask from "./InvoiceFolder/InvoiceTaskName";

const borderColor = "#bff0fd";
const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 30,
    fontSize: 12,
    fontStyle: "bold",
  },
  description: {
    width: "90%",
    textAlign: "center",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    paddingRight: 8,
    paddingTop: 4,
  },
  total: {
    width: "10%",
    textAlign: "center",
    paddingRight: 6,
    paddingTop: 4,
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
});

const InvoiceTable = ({ manageData, ob }) => {
  const total_received_amount = manageData[0].total_amount_received;
  const invoiceAmt = manageData.reduce(
    (i, a) => i + parseInt(a.invoice_amount, 0),
    0
  );

  const closing_balance = invoiceAmt + ob - total_received_amount ;

  const detailsInvoice = manageData
    .filter((i) => i.invoicestatus !== "Paid")
    .map((d) => (
      <InvoiceTask
        task={d.TaskDetails}
        invoiceid={d.invoiceno}
        invoicedate={d.invoicedate}
        discount={d.discount}
        invoice_amount={d.invoice_amount}
      />
    ));

  return (
    <View style={styles.tableContainer}>
      <InvoiceTableHeader />
      <View style={styles.row}>
        <Text style={styles.description}>Opening Balance</Text>
        <Text style={styles.total}>{parseFloat(ob).toFixed(2)}</Text>
      </View>
      {detailsInvoice}
      <View style={styles.row}>
        <Text style={styles.description}>Closing Balance</Text>
        <Text style={styles.total}>{parseFloat(closing_balance).toFixed(2)}</Text>
      </View>
    </View>
  );
};

export default InvoiceTable;
