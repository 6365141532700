import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 50,
    borderWidth: 1,
    borderColor: "#bff0fd"
  },
  rows: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    fontStyle: "bold"
  },
  dates: {
    width: "30%",
    textAlign: "left",
    paddingRight: 4,
    paddingLeft: 2,
    paddingTop: 4,
  },
  users: {
    width: "40%",
    textAlign: "center",
    paddingRight: 4,
    paddingLeft: 2,
    paddingTop: 4,
  },
  total: {
    width: "30%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
  },
});

const SummarizedTotal = ({ task }) => 
{
  const details = task.sort((a, b) => a[0].username.localeCompare(b[0].username));
  const summary_details = details.sort((a, b) => a[0].date.localeCompare(b[0].date));

   const taskdetails = summary_details.map((t) => (
    <React.Fragment>
      <View style={styles.rows} wrap={false}>
        <Text style={styles.dates}>{t[0].date}</Text>
        <Text style={styles.users}>{t[0].username}</Text>
        <Text style={styles.total}>{t.totaltime}</Text>
      </View>
    </React.Fragment>
   ));

  return( 
    <React.Fragment>
      {taskdetails}
    </React.Fragment>
  )
};

export default SummarizedTotal;
