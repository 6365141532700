import React from "react";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../../config";
import axios from "axios";
const authApi = config.get("apiDomain");
let totalQuantity = 0;
let s = [];

class InwardTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stockItems: [],
      id: this.props.id,
      stocknames: this.props.stockname,
      units: this.props.unit,
      quantities: this.props.quantity,
      productcodes: this.props.productcode,
      stockavailables: this.props.availablequantity,
      stockbatchnames: this.props.stockbatchname,
      stockbatchamounts: this.props.stockbatchamount,
      inwardrowtotals: this.props.inwardrowtotal,
      stockbatchavailables: "",
      stockbatchgroup: [],
    };
  }

  componentDidMount() {
    fetch(`${authApi}stockfilter/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          stockItems: json,
        });
      });
  }

  selectInStock = (event) => {
    fetch(
      `${authApi}inventorytransactionfilter/?stockname=${event.target.value}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        const data = json;
        totalQuantity = data.reduce(
          (i, i_bal) => i + parseInt(i_bal.quantity, 0),
          0
        );
        if (parseInt(totalQuantity) > 0) {
          this.getMultiGroupby(event.target.value);
        } else {
          this.setState({
            stockbatchgroup: [],
          });
        }
        this.setState({
          stockavailables: totalQuantity,
        });
      });
    this.state.stockItems.forEach((i) => {
      if (i.stockname === event.target.value) {
        this.setState({
          stocknames: i.stockname,
          units: i.unit,
          productcodes: i.productcode,
          stockavailables: totalQuantity,
        });
      }
    });
    this.setState(
      {
        stocknames: event.target.value,
      },
      function () {
        this.props.updateAddRow(this.state);
      }
    );
  };

  selectInStockQuantity = (event) => {
    let { value, max } = event.target;
    const values = Math.max(Math.min(Number(max), Number(value)));
    this.setState(
      {
        quantities: values,
        inwardrowtotals:
          parseInt(values) * parseInt(this.state.stockbatchamounts),
      },
      function () {
        this.props.updateAddRow(this.state);
      }
    );
  };

  deleteInwardRow = () => {
    this.props.deleteRow(this.state);
  };

  getMultiGroupby(d) {
    this.setState({
      stockbatchgroup: [],
    });

    // sp_availablestockbatch
    axios({
      method: "post",
      url: `${authApi}sp_availablestockbatch/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        stock: d,
      },
    })
      .then((res) => {
        s = [];
        res.data.forEach((stock) => {
          s.push({
            batch: stock.batch,
            quantity: stock.quantity,
            amount: stock.rate,
          });
          return s;
        });
        this.setState({
          stockbatchgroup: s,
        });
      })
      .catch((err) => {
        console.log(err);
      });

  }

  selectInBatchgroup = (event) => {
    let q = 0;
    if (this.state.quantities !== "") {
      q = parseInt(this.state.quantities);
    }
    this.state.stockbatchgroup.forEach((d) => {
      if (d.batch === event.target.value) {
        let rateDefault = 0;
        if (
          parseInt(d.amount) === 0 ||
          d.amount === null ||
          d.amount === undefined ||
          d.amount === ""
        ) {
          this.state.stockItems.forEach((i) => {
            if (i.stockname === this.state.stocknames) {
              rateDefault = i.defaultrate;
            }
          });
        } else {
          rateDefault = d.amount;
        }
        this.setState({
          stockbatchnames: event.target.value,
          stockbatchavailables: d.quantity,
          stockbatchamounts: rateDefault,
          inwardrowtotals: q * parseInt(rateDefault),
        });
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <tr>
          <td>
            <input
              type="option"
              list="inwardstocklist"
              className="form-control-alternative"
              style={{
                width: 200,
                border: "none",
                height: 25,
                marginLeft: -10,
              }}
              defaultValue={this.state.stocknames}
              onChange={this.selectInStock}
            />
            <datalist id="inwardstocklist">
              {this.state.stockItems.map((item, i) => (
                <option key={i.stockname} value={item.stockname} />
              ))}
            </datalist>
          </td>
          <td>
            <input
              type="text"
              className="form-control-alternative"
              style={{
                marginLeft: -10,
                width: 100,
                border: "none",
                height: 25,
              }}
              defaultValue={this.state.stockavailables}
              disabled
            />
            <input
              type="text"
              className="form-control-alternative"
              style={{ marginLeft: 2, width: 80, border: "none", height: 25 }}
              defaultValue={this.state.units}
              disabled
            />
          </td>
          <td>
            <select
              type="option"
              className="form-control-alternative"
              style={{
                marginLeft: -10,
                width: 100,
                border: "none",
                height: 25,
              }}
              value={this.state.stockbatchnames}
              onChange={this.selectInBatchgroup}
            >
              <option value="">Batch</option>
              {this.state.stockbatchgroup
                .sort((a, b) => a.batch - b.batch)
                .map((item, key) => (
                  <option key={key.batch} value={item.batch}>
                    {item.batch}
                  </option>
                ))}
            </select>
            <input
              type="text"
              className="form-control-alternative"
              style={{ width: 80, border: "none", height: 25 }}
              defaultValue={this.state.stockbatchavailables}
              disabled
            />
            <input
              type="text"
              className="form-control-alternative"
              style={{ marginLeft: 2, width: 100, border: "none", height: 25 }}
              defaultValue={this.state.stockbatchamounts}
              disabled
            />
          </td>
          <td>
            <input
              type="number"
              className="form-control-alternative"
              style={{ marginLeft: -10, width: 80, border: "none", height: 25 }}
              value={this.state.quantities}
              onChange={this.selectInStockQuantity}
              max={parseInt(this.state.stockbatchavailables)}
            />
            <input
              type="text"
              className="form-control-alternative"
              style={{ marginLeft: 2, width: 80, border: "none", height: 25 }}
              defaultValue={this.state.inwardrowtotals}
              disabled
            />
          </td>
          <td>
            <button
              className="btn-primary btn-sm"
              onClick={this.deleteInwardRow}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, null)(InwardTableRow);
