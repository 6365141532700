import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  rows: {
    flexDirection: "row",
    alignItems: "center",
    fontStyle: "bold"
  },
  invoices: {
    width: "25%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
  },
  amount: {
    width: "25%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
  },
  received: {
    width: "25%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
  },
  balance: {
    width: "25%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
  }
});

const BalanceDetails = ({ grp_invoiceBal }) => 
{
  const invoiceAmt = grp_invoiceBal.reduce((i,i_bal) => i + parseInt(i_bal.invoice_amount, 0), 0);
  const invoiceAmtRecv = grp_invoiceBal.reduce((i,i_bal) => i + parseInt(i_bal.amount_received, 0), 0);
  const invoiceDue = grp_invoiceBal.reduce((i,i_bal) => i + parseInt(i_bal.balancedue, 0), 0);

  const Invoices = grp_invoiceBal.map((i_bal) => (
    <View style={styles.rows} key={i_bal.id}>
      <Text style={styles.invoices}>{i_bal.invoiceno}</Text>
      <Text style={styles.amount}>{i_bal.invoice_amount}</Text>
      <Text style={styles.received}>{i_bal.amount_received}</Text>
      <Text style={styles.balance}>{i_bal.balancedue}</Text>
    </View>
  ));

  return( 
    <React.Fragment>
      <View style={styles.rows}>
        <Text style={styles.invoices}></Text>
        <Text style={styles.amount}>{invoiceAmt}</Text>
        <Text style={styles.received}>{invoiceAmtRecv}</Text>
        <Text style={styles.balance}>{invoiceDue}</Text>
      </View>
      <View>
        {Invoices}
      </View>
    </React.Fragment>
  )
};

export default BalanceDetails;
