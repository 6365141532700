import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 50,
    borderWidth: 1,
    borderColor: "#bff0fd"
  },
  rows: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    fontStyle: "bold"
  },
  dates: {
    width: "25%",
    textAlign: "left",
    paddingRight: 4,
    paddingLeft: 2,
    paddingTop: 4,
  }
});

const SummarizedTotal = ({ groupdata }) => 
{
   const taskdetails = groupdata.map((t) => (
    <React.Fragment>
      <View style={styles.rows} wrap={false}>
        <Text style={styles.dates}>{t[0].clientname}</Text>
        <Text style={styles.dates}>{t.reduce((i,g) => i + parseInt(g.subtotal, 0), 0)}</Text>
        <Text style={styles.dates}>{t.reduce((i,g) => i + parseInt(g.amount_received, 0), 0)}</Text>
        <Text style={styles.dates}>{t.reduce((i,g) => i + parseInt(g.balancedue, 0), 0)}</Text>
      </View>
    </React.Fragment>
   ));

  return( 
    <React.Fragment>
      {taskdetails}
    </React.Fragment>
  )
};

export default SummarizedTotal;
