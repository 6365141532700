import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  rows: {
    flexDirection: "row",
    alignItems: "center",
    fontStyle: "bold"
  },
  subtasks: {
    width: "30%",
    textAlign: "left",
    paddingLeft: 24,
    paddingRight: 5,
    paddingTop: 4,
  },
  assigns: {
    width: "31%",
    textAlign: "left",
    paddingLeft: 24,
    paddingTop: 4,
  },
  times: {
    width: "21%",
    textAlign: "left",
    paddingRight: 8,
    paddingLeft: 24,
    paddingTop: 4,
  },
  totals: {
    width: "18%",
    textAlign: "right",
    paddingRight: 8,
    paddingTop: 4,
  },
  subtask: {
    width: "82%",
    textAlign: "left",
    paddingLeft: 24,
    paddingRight: 5,
    paddingTop: 4,
  },
  total: {
    width: "18%",
    textAlign: "left",
    paddingRight: 8,
    paddingLeft: 20,
    paddingTop: 4,
  },
});

const ClientReportsSubtasks = ({ subtaskRow, timeTot }) => 
{
  const reportSubtasks = subtaskRow.map((sr) => (
    <View style={styles.rows} key={sr.assignto}>
      <Text style={styles.subtasks}>{sr.subtask}</Text>
      <Text style={styles.assigns}>{sr.assignto}</Text>
      <Text style={styles.times}>{sr.time}</Text>
      <Text style={styles.totals}></Text>
    </View>
  ));

  return( 
    <React.Fragment>
      {reportSubtasks } 
      <View style={styles.rows}>
        <Text style={styles.subtask}>Task Total</Text>
        <Text style={styles.total}>{timeTot}</Text>
      </View>
    </React.Fragment>
  )
};

export default ClientReportsSubtasks;
