import React, { Fragment } from "react";
import {
  useTable,
  useExpanded,
  useSortBy,
  useFilters,
  usePagination,
} from "react-table";
import { Table, Row, Col, Button, Input, Card } from "reactstrap";
import { Filter, DefaultColumnFilter } from "./filters";
import { connect } from "react-redux";

const TableContainerExpand = (props) => {
  const { columns, data, renderRowSubComponent, checkedToggle } = props;

  const overall_fil = () => {
    let fil_data = "";
    if (props.cur_location === "/admin/teamtask") {
      fil_data = props.teamtask_fil.filter(
        (item) => item.value !== "" && item.value !== undefined
      );
    } else if (props.cur_location === "/admin/projects") {
      fil_data = props.projects_fil.filter(
        (item) => item.value !== "" && item.value !== undefined
      );
    } else {
      fil_data = [];
    }
    return fil_data;
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { pageIndex: 0, pageSize: 50, filters: overall_fil() },
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <i className="fa fa-arrow-up" aria-hidden="true"></i>
      ) : (
        <i className="fa fa-arrow-down" aria-hidden="true"></i>
      )
    ) : (
      ""
    );
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  return (
    <React.Fragment>
      <Card className="shadow">
        <Table
          className="align-items-center table-flush"
          bordered
          hover
          {...getTableProps()}
          responsive
        >
          <thead className="thead-light">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th className="text-center" {...column.getHeaderProps()}>
                    <div {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    {checkedToggle === false ? null : (
                      <Filter column={column} />
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map((cell) => {
                      let style = { maxWidth: 150, overflow: "hidden" };
                      return (
                        <td {...cell.getCellProps({ style })}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                  {row.isExpanded && (
                    <tr>
                      <td colSpan={visibleColumns.length}>
                        {renderRowSubComponent(row)}
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </Card>
      <br />
      <Row style={{ maxWidth: 1000, margin: "0 auto", textAlign: "center" }}>
        <Col md={3}>
          <Button
            color="primary"
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            {"<"}
          </Button>
        </Col>
        <Col md={3} style={{ marginTop: 7 }}>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </Col>
        <Col md={3}>
          <Input type="select" value={pageSize} onChange={onChangeInSelect}>
            {[50, 75, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Input>
        </Col>
        <Col md={3}>
          <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
            {">"}
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    cur_location: state.current_location,
    teamtask_fil: state.teamtask_filter,
    projects_fil: state.project_filter,
  };
};

export default connect(mapStateToProps, null)(TableContainerExpand);
