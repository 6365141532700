import React from "react";
import logo from "../../../assets/img/brand/pgcadd.png";

const ViewIndividualinvoice = ({ individualInvoicedata, salesorderid }) => {
  return (
    <>
      <div>
        <div style={{ width: 300, marginRight: "auto", marginTop: 40 }}>
          <h2
            style={{
              color: "#11cdef",
              letterSpacing: 1,
              fontSize: 20,
              marginLeft: 23,
              textAlign: "left",
              textTransform: "uppercase",
            }}
          >
            {individualInvoicedata.companyname}
          </h2>
        </div>
        <div>
          <p
            style={{
              width: 300,
              height: 66,
              marginRight: "auto",
              marginLeft: 23,
              fontFamily: "sans-serif",
            }}
          >
            334, opp. Indusind bank, Mettur road <br />
            Erode - 638001.
          </p>
        </div>
        <div>
          <img
            style={{
              marginTop: -135,
              width: 75,
              height: 100,
              marginRight: "30px",
              marginBottom: 5,
              float: "right",
            }}
            src={logo}
            alt=""
          />
        </div>

        <div
          style={{
            width: 200,
            height: 66,
            marginLeft: 23,
            marginTop: 20,
            fontFamily: "sans-serif",
          }}
        >
          {individualInvoicedata.clientCompany === "General" ||
          individualInvoicedata.clientCompany === "General-Printing" ? (
            <>
              <p style={{ width: 200, fontSize: 15 }}>
                
                {individualInvoicedata.subclient}
              </p>
              <p style={{ width: 200, marginTop: -20, fontSize: 15 }} />
              <p style={{ width: 200, marginTop: -20, fontSize: 15 }} />
              <p style={{ width: 200, marginTop: -20, fontSize: 15 }} />
            </>
          ) : (
            <>
              <p style={{ width: 200, fontSize: 15 }}>
                
                {individualInvoicedata.clientname}
              </p>
              <p style={{ width: 200, marginTop: -20, fontSize: 15 }}>
                
                {individualInvoicedata.clientCompany}
              </p>
              <p style={{ width: 200, marginTop: -20, fontSize: 15 }}>
                
                {individualInvoicedata.Mobilenumber}
              </p>
              <p style={{ width: 200, marginTop: -20, fontSize: 15 }}>
                
                {individualInvoicedata.emailId}
              </p>
            </>
          )}
        </div>

        <div
          style={{
            marginTop: -65,
            width: 200,
            height: 66,
            marginLeft: "auto",
            marginRight: 0,
            fontFamily: "sans-serif",
          }}
        >
          {individualInvoicedata.companyinvoiceid !== null ? (
            <p style={{ width: 200, fontSize: 15 }}>
              Invoice : {individualInvoicedata.companyinvoiceid}
            </p>
          ) : null}
          <p style={{ width: 200, fontSize: 15 }}>
            SalesorderId : {individualInvoicedata.invoiceno}
          </p>
          <p style={{ width: 200, marginTop: -20, fontSize: 15 }}>
            Date : {individualInvoicedata.invoicedate}
          </p>
        </div>
        <div
          style={{
            height: 24,
            textAlign: "center",
            marginTop: 30,
            marginBottom: -30,
          }}
        >
          <p
            style={{
              fontFamily: "sans-serif",
              fontWeight: 650,
              fontSize: 18,
              textTransform: "uppercase",
            }}
          >
            
            Invoice
          </p>
        </div>
        {salesorderid === "" ? (
          <>
            <table
              className="align-items-center table-flush"
              style={{
                fontFamily: "sans-serif",
                marginLeft: 5,
                marginRight: 5,
                marginTop: 60,
                border: "1px solid #bff0fd"
              }}
            >
              <thead
                style={{
                  flexDirection: "row",
                  backgroundColor: "#bff0fd",
                  alignItems: "center",
                  height: 24,
                  textAlign: "center",
                  flexGrow: 1,
                }}
              >
                <tr>
                  <th style={{ width: "30%" }}>Task</th>
                  <th style={{ width: "25%" }}>Description</th>
                  <th style={{ width: "10%" }}>Unit</th>
                  <th style={{ width: "10%" }}>Qty</th>
                  <th style={{ width: "10%" }}>Rate</th>
                  <th style={{ width: "15%" }}>Amount</th>
                </tr>
              </thead>
              {individualInvoicedata.TaskDetails.map((t, id) => {
                return (
                  <tbody key={id}>
                    <tr style={{ flexDirection: "row", alignItems: "center", border: "1px solid #bff0fd" }}>
                      <td
                        className="text-center"
                        colSpan={1}
                        style={{
                          width: "30%",
                          textAlign: "left",
                          paddingRight: 8,
                          paddingLeft: 2,
                          paddingTop: 4,
                          borderRight: "1px solid #bff0fd",
                          fontWeight: 600
                        }}
                      >
                        {t.Name}
                      </td>
                      <td colSpan={5}>
                        {parseInt(t.DraftingCharge) === 0 ? null : (
                          <tr
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <td></td>
                            <td
                              colSpan={4}
                              style={{
                                width: "80%",
                                textAlign: "left",
                                paddingLeft: 8,
                                paddingTop: 4,
                                fontWeight: 600
                              }}
                            >
                              
                              Drafting Charges
                            </td>
                            <td
                              colSpan={1}
                              style={{
                                width: "20%",
                                textAlign: "center",
                                paddingRight: 0,
                                paddingTop: 4,
                                fontWeight: 600
                              }}
                            >
                              {Number.parseFloat(t.DraftingCharge).toFixed(2)}
                            </td>
                          </tr>
                        )}
                        {parseInt(
                          t.PrintingCharge.reduce(
                            (i, p) => i + parseInt(p.amount, 0),
                            0
                          )
                        ) === 0 ? null : (
                          <tr
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              borderBottom: "1px solid #bff0fd",
                            }}
                          >
                            <td></td>
                            <td
                              colSpan={4}
                              style={{
                                width: "80%",
                                textAlign: "left",
                                paddingLeft: 8,
                                paddingTop: 4,
                                fontWeight: 600
                              }}
                            >
                              
                              Printing Details
                            </td>
                            <td
                              colSpan={1}
                              style={{
                                width: "20%",
                                textAlign: "center",
                                paddingRight: 0,
                                paddingTop: 4,
                              }}
                            >
                            </td>
                          </tr>
                        )}
                        {t.PrintingCharge.map((pr) => (
                          <tr
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                            key={pr.id}
                          >
                            <td></td>
                            <td
                              style={{
                                width: "35%",
                                textAlign: "left",
                                paddingLeft: 20,
                                paddingTop: 4,
                              }}
                            >
                              {pr.types}
                            </td>
                            <td
                              style={{
                                width: "15%",
                                textAlign: "center",
                                paddingRight: 8,
                                paddingTop: 4,
                              }}
                            >
                              {pr.unit}
                            </td>
                            <td
                              style={{
                                width: "15%",
                                textAlign: "center",
                                paddingRight: 8,
                                paddingTop: 4,
                              }}
                            >
                              {pr.quantity}
                            </td>
                            <td
                              style={{
                                width: "15%",
                                textAlign: "center",
                                paddingRight: 8,
                                paddingTop: 4,
                              }}
                            >
                              {pr.rate}
                            </td>
                            <td
                              style={{
                                width: "16%",
                                textAlign: "center",
                                paddingRight: 0,
                                paddingTop: 4,
                              }}
                            >
                              {Number.parseFloat(pr.amount).toFixed(2)}
                            </td>
                          </tr>
                        ))}
                        {/* {parseInt(
                          t.PrintingCharge.reduce(
                            (i, p) => i + parseInt(p.amount, 0),
                            0
                          )
                        ) === 0 ? null : (
                          <tr
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              borderTop: "1px solid #bff0fd",
                            }}
                          >
                            <td></td>
                            <td
                              colSpan={4}
                              style={{
                                width: "80%",
                                textAlign: "center",
                                paddingLeft: 8,
                                paddingTop: 4,
                                fontWeight: 600
                              }}
                            >
                              
                              Total Printing Charges
                            </td>
                            <td
                              colSpan={1}
                              style={{
                                width: "20%",
                                textAlign: "center",
                                paddingRight: 0,
                                paddingTop: 4,
                                fontWeight: 600
                              }}
                            >
                              {Number.parseFloat(
                                t.PrintingCharge.reduce(
                                  (i, p) => i + parseInt(p.amount, 0),
                                  0
                                )
                              ).toFixed(2)}
                            </td>
                          </tr>
                        )} */}
                      </td>
                    </tr>
                  </tbody>
                );
              })}
              <tfoot>
                <tr style={{ flexDirection: "row", alignItems: "center", border: "1px solid #bff0fd" }}>
                  <td
                    colSpan={5}
                    style={{
                      width: "80%",
                      textAlign: "right",
                      paddingLeft: 8,
                      paddingTop: 4,
                      fontWeight: 600
                    }}
                  >
                    
                    Total
                  </td>
                  <td
                    colSpan={1}
                    style={{
                      width: "20%",
                      textAlign: "center",
                      paddingLeft : 5,
                      paddingRight: 0,
                      paddingTop: 4,
                      fontWeight: 600
                    }}
                  >
                    {Number.parseFloat(individualInvoicedata.subtotal).toFixed(
                      2
                    )}
                  </td>
                </tr>
                {individualInvoicedata.discount !== "0" ? (
                  <>
                    {individualInvoicedata.discount > "0" ? (
                      <tr
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 30,
                          border: "1px solid #bff0fd"
                        }}
                      >
                        <td
                          colSpan={5}
                          style={{
                            width: "80%",
                            textAlign: "right",
                            paddingLeft: 8,
                            paddingTop: 4,
                            fontWeight: 600
                          }}
                        >
                          
                          Additional Drafting Charges
                        </td>
                        <td
                          colSpan={1}
                          style={{
                            width: "20%",
                            textAlign: "center",
                            paddingLeft : 5,
                            paddingRight: 0,
                            paddingTop: 4,
                            fontWeight: 600
                          }}
                        >
                          {Number.parseFloat(
                            individualInvoicedata.discount
                          ).toFixed(2)}
                        </td>
                      </tr>
                    ) : (
                      <tr
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 30,
                          border: "1px solid #bff0fd"
                        }}
                      >
                        <td
                          colSpan={5}
                          style={{
                            width: "80%",
                            textAlign: "right",
                            paddingLeft: 8,
                            paddingTop: 4,
                            fontWeight: 600
                          }}
                        >
                          
                          Discount
                        </td>
                        <td
                          colSpan={1}
                          style={{
                            width: "20%",
                            textAlign: "center",
                            paddingLeft : 5,
                            paddingRight: 0,
                            paddingTop: 4,
                            fontWeight: 600
                          }}
                        >
                          {Number.parseFloat(
                            individualInvoicedata.discount
                          ).toFixed(2)}
                        </td>
                      </tr>
                    )}
                  </>
                ) : null}
                <tr
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 30,
                    border: "1px solid #bff0fd"
                  }}
                >
                  <td
                    colSpan={5}
                    style={{
                      width: "80%",
                      textAlign: "right",
                      paddingLeft: 8,
                      paddingTop: 4,
                      fontWeight: 600
                    }}
                  >
                    
                    Invoice Amount
                  </td>
                  <td
                    colSpan={1}
                    style={{
                      width: "20%",
                      textAlign: "center",
                      paddingLeft : 5,
                      paddingRight: 0,
                      paddingTop: 4,
                      fontWeight: 600
                    }}
                  >
                    {Number.parseFloat(
                      individualInvoicedata.invoice_amount
                    ).toFixed(2)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </>
        ) : (
          <>
            <table
              className="align-items-center table-flush"
              style={{
                fontFamily: "sans-serif",
                marginLeft: 5,
                marginRight: 5,
                marginTop: 60,
              }}
            >
              <thead
                style={{
                  flexDirection: "row",
                  backgroundColor: "#bff0fd",
                  alignItems: "center",
                  height: 24,
                  textAlign: "center",
                  flexGrow: 1,
                  border: "1px solid #bff0fd"
                }}
              >
                <tr>
                  <th style={{ width: "20%" }}>Stockname</th>
                  <th style={{ width: "15%" }}>Productcode</th>
                  <th style={{ width: "15%" }}>Batch</th>
                  <th style={{ width: "10%" }}>Qty</th>
                  <th style={{ width: "10%" }}>Rate</th>
                  <th style={{ width: "20%" }}>CGST/SGST </th>
                  <th style={{ width: "20%" }}>Amount</th>
                </tr>
              </thead>
              {individualInvoicedata.TaskDetails.map((t, id) => {
                return (
                  <tbody key={id}>
                    <tr style={{ flexDirection: "row", alignItems: "center", border: "1px solid #bff0fd" }}>
                      <td
                        className="text-center"
                        colSpan={1}
                        style={{
                          width: "20%",
                          textAlign: "left",
                          paddingRight: 8,
                          paddingLeft: 2,
                          paddingTop: 4,
                        }}
                      >
                        {t.stockname}
                      </td>
                      <td
                        className="text-center"
                        colSpan={1}
                        style={{
                          width: "15%",
                          textAlign: "left",
                          paddingRight: 8,
                          paddingLeft: 2,
                          paddingTop: 4,
                        }}
                      >
                        {t.productcode}
                      </td>
                      <td
                        className="text-center"
                        colSpan={1}
                        style={{
                          width: "15%",
                          textAlign: "left",
                          paddingRight: 8,
                          paddingLeft: 2,
                          paddingTop: 4,
                        }}
                      >
                        {t.batch}
                      </td>
                      <td
                        className="text-center"
                        colSpan={1}
                        style={{
                          width: "10%",
                          textAlign: "left",
                          paddingRight: 8,
                          paddingLeft: 2,
                          paddingTop: 4,
                        }}
                      >
                        {Math.abs(t.quantity)}
                      </td>
                      <td
                        className="text-center"
                        colSpan={1}
                        style={{
                          width: "10%",
                          textAlign: "left",
                          paddingRight: 8,
                          paddingLeft: 2,
                          paddingTop: 4,
                        }}
                      >
                        {t.rate}
                      </td>
                      <td
                        className="text-center"
                        colSpan={1}
                        style={{
                          width: "20%",
                          textAlign: "left",
                          paddingRight: 8,
                          paddingLeft: 2,
                          paddingTop: 4,
                        }}
                      >
                        {t.cgstpercentage} / {t.sgstpercentage}
                      </td>
                      <td
                        className="text-center"
                        colSpan={1}
                        style={{
                          width: "20%",
                          textAlign: "left",
                          paddingRight: 8,
                          paddingLeft: 2,
                          paddingTop: 4,
                        }}
                      >
                        {t.total}
                      </td>
                    </tr>
                  </tbody>
                );
              })}
              <tfoot>
                <tr style={{ flexDirection: "row", alignItems: "center", border: "1px solid #bff0fd" }}>
                  <td
                    colSpan={6}
                    style={{
                      width: "80%",
                      textAlign: "right",
                      paddingLeft: 8,
                      paddingTop: 4,
                      fontWeight: 600
                    }}
                  >
                    
                    Total
                  </td>
                  <td
                    colSpan={1}
                    style={{
                      width: "20%",
                      textAlign: "center",
                      paddingLeft : 5,
                      paddingRight: 0,
                      paddingTop: 4,
                      fontWeight: 600
                    }}
                  >
                    {Number.parseFloat(individualInvoicedata.subtotal).toFixed(
                      2
                    )}
                  </td>
                </tr>
                {individualInvoicedata.discount !== "0" ? (
                  <>
                    {individualInvoicedata.discount > "0" ? (
                      <tr
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 30,
                          border: "1px solid #bff0fd"
                        }}
                      >
                        <td
                          colSpan={6}
                          style={{
                            width: "80%",
                            textAlign: "right",
                            paddingLeft: 8,
                            paddingTop: 4,
                            fontWeight: 600
                          }}
                        >
                          
                          Additional Drafting Charges
                        </td>
                        <td
                          colSpan={1}
                          style={{
                            width: "20%",
                            textAlign: "center",
                            paddingLeft : 5,
                            paddingRight: 0,
                            paddingTop: 4,
                            fontWeight: 600
                          }}
                        >
                          {Number.parseFloat(
                            individualInvoicedata.discount
                          ).toFixed(2)}
                        </td>
                      </tr>
                    ) : (
                      <tr
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 30,
                          border: "1px solid #bff0fd"
                        }}
                      >
                        <td
                          colSpan={6}
                          style={{
                            width: "80%",
                            textAlign: "right",
                            paddingLeft: 8,
                            paddingTop: 4,
                            fontWeight: 600
                          }}
                        >
                          
                          Discount
                        </td>
                        <td
                          colSpan={1}
                          style={{
                            width: "20%",
                            textAlign: "center",
                            paddingLeft : 5,
                            paddingRight: 0,
                            paddingTop: 4,
                            fontWeight: 600
                          }}
                        >
                          {Number.parseFloat(
                            individualInvoicedata.discount
                          ).toFixed(2)}
                        </td>
                      </tr>
                    )}
                  </>
                ) : null}
                <tr
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 30,
                    border: "1px solid #bff0fd"
                  }}
                >
                  <td
                    colSpan={6}
                    style={{
                      width: "80%",
                      textAlign: "right",
                      paddingLeft: 8,
                      paddingTop: 4,
                      fontWeight: 600
                    }}
                  >
                    
                    Invoice Amount
                  </td>
                  <td
                    colSpan={1}
                    style={{
                      width: "20%",
                      textAlign: "center",
                      paddingLeft : 5,
                      paddingRight: 0,
                      paddingTop: 4,
                      fontWeight: 600
                    }}
                  >
                    {Number.parseFloat(
                      individualInvoicedata.invoice_amount
                    ).toFixed(2)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </>
        )}
        <br />
      </div>
    </>
  );
};

export default ViewIndividualinvoice;
