import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import consoleFunction from "../../../Helpers";
import getDateTimeField from "../../../Utils";
import AddTime from "../Teamtask/AddTime";
import Editsubtask from "../Teamtask/Editsubtask";
import AccountPopup from "../ReadyToBill/AccountPopup";
import TotalInvoiceTask from "../InvoiceDetails/TotalInvoiceTask";
import Addsubtask from "../Teamtask/Addsubtask";
import TeamEditRow from "../Teamtask/TeamEditRow";
import Header from "../../../components/Headers/SimpleHeader";
import ConPreLoader from "../../../components/Loader/Con_PreLoader";
import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../config";
// reactstrap components
import { NavLink, Card, Container, Row } from "reactstrap";
const authApi = config.get("apiDomain");
let adimicheck = "0";
let assigneeName = "";
let subtask = [],
  maintaskid = "",
  plantype = "",
  maintask = "";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPage: 50,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

function TeamtaskFilter(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [billingpanel, showBillingpanel] = useState(false);

  useEffect(
    () => {
      const doFetch = async () => {
        const response = await fetch(
          `${authApi}tasklistfilter/?isdeleted=false&status__in=ReadyToInvoice%2CReadyToBill`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${props.token}`,
            },
          }
        );
        const body = await response.json();
        if (body.length === 0) {
          setData(body);
          setLoading(false);
        } else {
          checkadmin();
          CalculateTaskTime(body.reverse());
          UpdateClientName(body);
        }
      };
      doFetch();
    },
    // eslint-disable-next-line
    []
  );

  function checkadmin() {
    const doFetch = async () => {
      let currentuser = props.allusers.filter((userdata) => {
        return userdata.name === props.username;
      });
      adimicheck = currentuser[0].isadmin;
    };
    doFetch();
  }

  function CalculateTaskTime(body) {
    body.forEach((e) => {
      e.time = calculateSubtaskTime(e);
    });
  }

  function calculateSubtaskTime(e) {
    let timeSub = "00:00";
    let timetotal = 0,
      mins = 0,
      times = 0,
      stringtime = 0,
      h = 0,
      m = 0;

    e.subtask.forEach((st) => {
      if (st.isdeleted === false) {
        st.time = calculateSecondLayerSubtaskTime(st);
        if (st.time === 0) {
          timeSub = "00:00";
        } else {
          stringtime = st.time;
          times = stringtime.split(":");
          mins = times[0] * 60 + +times[1];
          timetotal += mins;
          h = (timetotal / 60) | 0;
          m = timetotal % 60;
          function z(n) {
            return (n < 10 ? "0" : "") + n;
          }
          timeSub = z(h) + ":" + z(m);
        }
      }
    });
    return timeSub;
  }

  function calculateSecondLayerSubtaskTime(st2) {
    let total = 0,
      mins = 0,
      times = 0,
      stringtime = 0,
      h = 0,
      m = 0,
      subTime = "00:00";
    st2.subtaskTime.forEach((d) => {
      stringtime = d.time;
      times = stringtime.split(":");
      mins = times[0] * 60 + +times[1];
      total += mins;
      h = (total / 60) | 0;
      m = total % 60;
      function z(n) {
        return (n < 10 ? "0" : "") + n;
      }
      subTime = z(h) + ":" + z(m);
    });
    return subTime;
  }

  function UpdateClientName(body) {
    callback(body);
  }

  function callback(body) {
    const details = body.sort((a, b) => a.status.localeCompare(b.status));
    setData(details);
    setLoading(false);
  }

  function callbackfunction() {
    const doFetch = async () => {
      const response = await fetch(
        `${authApi}tasklistfilter/?isdeleted=false&status__in=ReadyToInvoice%2CReadyToBill`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${props.token}`,
          },
        }
      );
      const body = await response.json();
      CalculateTaskTime(body.reverse());
      UpdateClientName(body);
    };
    doFetch();
  }

  function deleteRow(d) {
    let datetime_field = getDateTimeField();
    const link = `${authApi}subtasks/${d.id}/`;
    axios({
      method: "put",
      url: link,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${props.token}`,
      },
      data: {
        date: d.date,
        subtask: d.subtask,
        assignto: d.assignto,
        priority: d.priority,
        status: d.status,
        time: d.time,
        isdeleted: true,
        completed_date: d.completed_date,
        task_starttime: d.task_starttime,
        client_id: d.client_id,
        tasklist: d.tasklist,
        modifiedby: this.props.username,
        modifiedat: datetime_field,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          callbackfunction();
        }
      })
      .catch((err) => {
        consoleFunction("Task deletion error", err.response.request.response);
      });
  }

  function DownloadData(json) {
    const items = json;
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(items[0]).filter(
      (field) =>
        field !== "id" &&
        field !== "subtask" &&
        field !== "isdeleted" &&
        field !== "task_starttime" &&
        field !== "time"
    );
    let csv = items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(header.join(","));
    csv = csv.join("\r\n");
    const ConvertData = csv;
    const element = document.createElement("a");
    const file = new Blob([ConvertData], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "TeamTask.csv";
    document.body.appendChild(element);
    element.click();
  }

  const showbillingdetails = (editdata) => {
    subtask = editdata.subtask;
    maintaskid = editdata.id;
    plantype = editdata.plantype;
    maintask = editdata;
    // EditRowSend={row_data.subtask}
    // maintaskid={row_data.id}
    // functionreload={callbackfunction}
    // itsmytask="0"
    // plantype={row_data.plantype}
    // maintask={row_data}
    showBillingpanel(true);
  };

  const showtaskdetails = () => {
    showBillingpanel(false);
  };

  const subtaskexpandRow = {
    renderer: (row) => (
      <div>
        <ToolkitProvider
          data={row.subtaskTime}
          keyField="id"
          columns={[
            {
              dataField: "date",
              text: "Date",
            },
            {
              dataField: "start_time",
              text: "StartTime",
            },
            {
              dataField: "endtime",
              text: "EndTime",
            },
            {
              dataField: "time",
              text: "Time",
            },
            {
              dataField: "comments",
              text: "Comments",
            },
          ]}
          search
        >
          {(props) => (
            <div className="py-4 table-responsive">
              <BootstrapTable
                {...props.baseProps}
                keyField="id"
                bootstrap4={true}
                bordered={false}
                filter={filterFactory()}
                striped
                hover
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,
  };

  function subtaskActionFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <React.Fragment>
        {adimicheck === "1" ? (
          <Popup
            modal
            trigger={
              <button
                className="btn btn-primary btn-sm"
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-plus-circle" aria-hidden="true"></i>
              </button>
            }
          >
            {(close) => (
              <AddTime
                EditRowSend={row}
                closebtn={close}
                functionreload={callbackfunction}
              />
            )}
          </Popup>
        ) : null}
        <Popup
          modal
          trigger={
            <button
              className="btn btn-primary btn-sm"
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-edit" aria-hidden="true"></i>
            </button>
          }
        >
          {(close) => (
            <Editsubtask
              EditRowSend={row}
              closebtn={close}
              functionreload={callbackfunction}
            />
          )}
        </Popup>
        {adimicheck === "1" ? (
          <button
            className="btn btn-primary btn-sm"
            onClick={() => deleteRow(row)}
            style={{ marginRight: "5px" }}
          >
            <i className="fa fa-trash" aria-hidden="true"></i>
          </button>
        ) : null}
      </React.Fragment>
    );
  }

  const subtaskexpand = (row) => {
    const rowdata = row.subtask.filter(function (item) {
      return item.isdeleted !== true;
    });
    return rowdata;
  };

  const teamtaskexpandRow = {
    renderer: (row) => (
      <div>
        <ToolkitProvider
          data={subtaskexpand(row)}
          keyField="assignto"
          columns={[
            {
              dataField: "edit",
              text: "Actions",
              sort: false,
              formatter: subtaskActionFormatter,
            },
            {
              dataField: "subtask",
              text: "Subtask",
            },
            {
              dataField: "date",
              text: "Date",
            },
            {
              dataField: "assignto",
              text: "Assignto",
            },
            {
              dataField: "time",
              text: "Time",
            },
            {
              dataField: "status",
              text: "Status",
            },
            {
              dataField: "priority",
              text: "Priority",
            },
          ]}
          search
        >
          {(props) => (
            <div className="py-4 table-responsive">
              <BootstrapTable
                {...props.baseProps}
                keyField="id"
                bootstrap4={true}
                bordered={false}
                filter={filterFactory()}
                expandRow={subtaskexpandRow}
                striped
                hover
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,
  };

  function teamtaskActionFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <React.Fragment>
        <Popup
          modal
          data-backdrop="static"
          trigger={
            <button
              className="btn btn-primary btn-sm"
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-plus-circle" aria-hidden="true"></i>
            </button>
          }
        >
          {(close) => (
            <Addsubtask
              EditRowSend={row.id}
              Totaltask={row}
              ClientId={row.client}
              AssigneeName={assigneeName}
              closebtn={close}
              functionreload={callbackfunction}
            />
          )}
        </Popup>
        {adimicheck === "1" ? (
          <Popup
            modal
            trigger={
              <button
                className="btn btn-primary btn-sm"
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit" aria-hidden="true"></i>
              </button>
            }
          >
            {(close) => (
              <TeamEditRow
                EditRowSend={row}
                closebtn={close}
                functionreload={callbackfunction}
              />
            )}
          </Popup>
        ) : null}
        {row.status === "ReadyToBill" ? (
          // <Popup
          //     modal
          //     trigger={
          <button
            className="btn btn-primary btn-sm"
            onClick={() => showbillingdetails(row)}
          >
            <i className="ni ni-cart"></i>
          </button>
        ) : //   }
        // >
        //  {
        //    (close) => (
        //      <AccountPopup
        //        closebtn={close}
        //        EditRowSend={row.subtask}
        //        maintaskid={row.id}
        //        functionreload={callbackfunction}
        //        itsmytask="0"
        //        plantype={row.plantype}
        //        maintask={row}
        //      />
        //    )
        //  }
        // </Popup>
        null}
        {row.status === "ReadyToInvoice" ? (
          <Popup
            modal
            data-backdrop="static"
            trigger={
              <button className="btn btn-primary btn-sm">
                <i className="fas fa fa-money fa-1x"></i>
              </button>
            }
          >
            {(close) => (
              <TotalInvoiceTask
                TotalTask={[row]}
                closebtn={close}
                reloadfunction={callbackfunction}
              />
            )}
          </Popup>
        ) : null}
      </React.Fragment>
    );
  }

  return (
    <>
      <Header name="React Tables" parentName="Tables" />
      {billingpanel === false ? (
        <Container className="mt--6" fluid>
          <Row>
            <ReactTooltip
              id="teamTask-Tip"
              place="right"
              type="dark"
              effect="solid"
            >
              Back to Team Task
            </ReactTooltip>
            <NavLink style={{ marginTop: 8 }} to="/admin/teamtask" tag={Link}>
              <button
                className="btn btn-primary btn-sm"
                style={{ width: "135%", height: 40 }}
                data-tip
                data-for="teamTask-Tip"
              >
                <i className="fa fa-arrow-left"></i>
              </button>
            </NavLink>
            <div
              className="addRow"
              style={{ position: "absolute", right: "35px", marginTop: 2 }}
            >
              <NavLink
                style={{ marginTop: 8 }}
                to="/admin/filter/Completedteamfilter"
                tag={Link}
              >
                <button
                  className="btn btn-primary btn-sm"
                  style={{ width: "135%", height: 40 }}
                  data-tip
                  data-for="completed-Tip"
                >
                  <i className="fa fa-list-alt" aria-hidden="true"></i>
                </button>
              </NavLink>
              <ReactTooltip
                id="completed-Tip"
                place="left"
                type="dark"
                effect="solid"
              >
                Go to Complete Tasks
              </ReactTooltip>
            </div>
          </Row>
          <br />
          <Row>
            {loading === true ? (
              <div>
                <ConPreLoader />
              </div>
            ) : (
              <div className="col">
                <Card>
                  <ToolkitProvider
                    data={data}
                    keyField="id"
                    columns={[
                      {
                        dataField: "edit",
                        text: "Edit",
                        sort: false,
                        formatter: teamtaskActionFormatter,
                      },
                      {
                        dataField: "clientname",
                        text: "Client",
                        sort: true,
                        filter: textFilter(),
                      },
                      {
                        dataField: "companyname",
                        text: "CompanyName",
                        sort: true,
                        filter: textFilter(),
                      },
                      {
                        dataField: "plantype",
                        text: "PlanType",
                        sort: true,
                        filter: textFilter(),
                      },
                      {
                        dataField: "task",
                        text: "Task",
                      },
                      {
                        dataField: "assignto",
                        text: "AssignTo",
                        sort: true,
                        filter: textFilter(),
                      },
                      {
                        dataField: "time",
                        text: "Time",
                      },
                      {
                        dataField: "status",
                        text: "Status",
                        sort: true,
                        filter: textFilter(),
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="py-4 table-responsive">
                        <div
                          id="datatable-basic_filter"
                          className="dataTables_filter px-4 pb-1"
                        >
                          <label>
                            Search:
                            <SearchBar
                              className="form-control-sm"
                              placeholder=""
                              {...props.searchProps}
                            />
                          </label>
                        </div>
                        <BootstrapTable
                          {...props.baseProps}
                          keyField="id"
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                          filter={filterFactory()}
                          expandRow={teamtaskexpandRow}
                          striped
                          hover
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </Card>
              </div>
            )}
          </Row>
          <br />
          <button
            className="Export btn-info"
            id="download"
            style={{ marginRight: "5px" }}
            onClick={() => DownloadData(data)}
          >
            Export
          </button>
          <br />
          <br />
        </Container>
      ) : (
        <AccountPopup
          closebtn={showtaskdetails}
          EditRowSend={subtask}
          maintaskid={maintaskid}
          functionreload={callbackfunction}
          itsmytask="0"
          plantype={plantype}
          maintask={maintask}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    username: state.username,
    allusers: state.allusers,
  };
};

export default connect(mapStateToProps, null)(TeamtaskFilter);
