import React from "react";
import Header from "../../../../components/Headers/SimpleHeader";
import config from "react-global-configuration";
import {
  Container,
  Row,
  Card,
  Table,
  Col,
  Input,
  CardBody,
  CardTitle,
} from "reactstrap";
import "../../../../config";
import { connect } from "react-redux";
import axios from "axios";
import InvoiceCorrectionRow from "./InvoiceCorrectionRow";

const authApi = config.get("apiDomain");
let userid = 0,
  username = "";
class InvoiceCorrection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      InvoiceTransaction: [],
      Deliverynoteid: "",
      Companyid: "",
      Invoice: [],
      invoiceid: "",
      bank: [],
      searchValidation: "",
    };
  }

  async componentDidMount() {
    let currentusers=this.props.allusers.filter((userdetails)=>{
      return userdetails.name === this.props.username
    })
    userid=currentusers[0].id;
  }

  getInvoiceId = (event) => {
    if (event.target.value.length !== 3) {
      this.setState(
        {
          invoiceid: event.target.value,
        },
        function () {
          this.splitTransaction(event.target.value);
        }
      );
    } else {
      this.setState(
        {
          invoiceid: event.target.value + "-",
        },
        function () {
          this.splitTransaction(event.target.value);
        }
      );
    }
  };

  splitTransaction = (data) => {
    let invoicedata = data.split("-");
    this.setState({
      Deliverynoteid: invoicedata[1],
      Companyid: invoicedata[0].substring(0, 1),
    });
  };

  searchValidation = () => {
    let invoicedata = this.state.invoiceid.split("-");
    if (invoicedata.length !== 1) {
      if (invoicedata[0].length >= 3 && invoicedata[1].length >= 5) {
        this.setState({
          searchValidation: "none",
        });
        this.getInvoiceTransactiondetails();
      } else {
        this.setState({
          searchValidation: "1px solid red",
          InvoiceTransaction: [],
          Deliverynoteid: "",
          Companyid: "",
          Invoice: [],
        });
      }
    } else {
      this.setState({
        searchValidation: "1px solid red",
        InvoiceTransaction: [],
        Deliverynoteid: "",
        Companyid: "",
        Invoice: [],
      });
    }
  };

  getInvoiceTransactiondetails = async () => {
    await this.getInvoiceDetails();
    let spendtransaction = "spend";
  
    await fetch(
      `${authApi}transactionfilter/?deliverynoteid=${this.state.Deliverynoteid}&company_id=${this.state.Companyid}&transactiontype=${spendtransaction}
    `,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then(async (json) => {
        console.log(json);
        if (json.length !== 0) {
          let remainingdata = json;
          await json.forEach(async (transactionloop1) => {
            if (transactionloop1.amount < 0) {
              remainingdata = remainingdata.filter(
                (item) =>
                  item.amount !== transactionloop1.amount * -1 &&
                  item.amount !== transactionloop1.amount
              );
            }
          });
          this.setState({
            InvoiceTransaction: remainingdata,
          });
        } else {
          this.setState({
            InvoiceTransaction: [],
          });
        }
      });
  };

  getInvoiceDetails = async () => {
    await fetch(
      `${authApi}summaryfilter/?deliverynoteid=${this.state.Deliverynoteid}&invoice_company=${this.state.Companyid}
    `,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        this.setState({
          Invoice: json,
        });
      });
    this.getBankDetails();
  };

  getBankDetails = () => {
    fetch(`${authApi}bankfilter/?companydetails=${this.state.Companyid}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          bank: json,
        });
      });
  };

  updatetransactionAmount = (
    id,
    amount,
    paymentmode,
    PaymentReference,
    date
  ) => {
  
    this.state.InvoiceTransaction.forEach((element) => {
      if (element.id === id) {
        element.date = date;
        element.amount = amount;
        element.pmtmode = paymentmode;
        element.pmtreference = PaymentReference;
      }
    });
  };

  updateTransaction_Invoice = () => {
    axios({
      method: "post",
      url: `${authApi}paidtoounapid/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        transaction_data: this.state.InvoiceTransaction,
        userid: userid,
        username: username,
      },
    })
      .then((res) => {
        console.log(res);
        this.props.history.push("/admin/invoice/invoice-management");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const transactiodetails = [...this.state.InvoiceTransaction]
      .reverse()
      .map((item) => (
        <InvoiceCorrectionRow
          invoicedata={item}
          updatetransactiondetails={this.updatetransactionAmount}
          bank={this.state.bank}
        />
      ));
    return (
      <React.Fragment>
        <Header />
        <Container className="mt--5" fluid>
          <br />
          <Row style={{ marginTop: "20px" }}>
            <div className=" col">
              <br />
              <Row>
                <Col md="2"></Col>
                <Col md="1"></Col>
                <Col md="1" style={{ marginTop: 10 }}>
                  InvoiceNo
                </Col>
                <Col md="3">
                  <Input
                    value={this.state.invoiceid}
                    onChange={this.getInvoiceId}
                    placeholder="100-10111 format"
                    style={{
                      textAlign: "center",
                      border:
                        this.state.searchValidation === "1px solid red"
                          ? "1px solid red"
                          : "none",
                    }}
                  />
                  {this.state.searchValidation === "1px solid red" ? (
                    <h6 style={{ color: "red" }}>
                      please enter valid invoice(100-11011)
                    </h6>
                  ) : (
                    ""
                  )}
                </Col>
                <Col md="2">
                  <button
                    className="btn btn-dark"
                    onClick={this.searchValidation}
                  >
                    Search
                  </button>
                </Col>
              </Row>
              <br />
              {this.state.Invoice.length >= 1 ? (
                <Row>
                  <Col lg="3" xl="3">
                    <Card className="card-stats">
                      <CardBody style={{ padding: "0.5rem 0.75rem" }}>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              ClientName
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {this.state.Invoice.length >= 1 ? (
                                <h5>
                                  {
                                    this.state.Invoice[0].invoicesummaryclient
                                      .company_name
                                  }
                                </h5>
                              ) : (
                                0
                              )}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                              <i className="fas fa-user" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="2" xl="2">
                    <Card className="card-stats">
                      <CardBody style={{ padding: "0.5rem 0.75rem" }}>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Amount
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {parseInt(
                                this.state.Invoice.length >= 1
                                  ? this.state.Invoice[0].invoice_amount
                                  : 0
                              ).toLocaleString(navigator.language)}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                              <i className="fa fa-money" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="2" xl="2">
                    <Card className="card-stats">
                      <CardBody style={{ padding: "0.5rem 0.75rem" }}>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Received
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {parseInt(
                                this.state.Invoice.length >= 1
                                  ? this.state.Invoice[0].amount_received
                                  : 0
                              ).toLocaleString(navigator.language)}
                            </span>
                            <br />
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                              <i className="fa fa-arrow-down" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="2" xl="2">
                    <Card className="card-stats">
                      <CardBody style={{ padding: "0.5rem 0.75rem" }}>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Balancedue
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {parseInt(
                                this.state.Invoice.length >= 1
                                  ? this.state.Invoice[0].balancedue
                                  : 0
                              ).toLocaleString(navigator.language)}
                            </span>
                            <br />
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                              <i className="fa fa-arrow-up" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3" xl="3">
                    <Card className="card-stats">
                      <CardBody style={{ padding: "0.5rem 0.75rem" }}>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              CompanyName
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {this.state.Invoice.length >= 1 ? (
                                <h5>
                                  {
                                    this.state.Invoice[0].invoice_company
                                      .companyname
                                  }
                                </h5>
                              ) : (
                                0
                              )}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                              <i
                                className="fa fa-building"
                                aria-hidden="true"
                              />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : null}

              {this.state.Invoice.length >= 1 ? (
                <Row>
                  <div className=" col">
                    <br />
                    <Card className="shadow">
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th className="text-center"> Action </th>
                            <th className="text-center"> Date </th>
                            <th className="text-center"> Amount </th>
                            <th className="text-center"> Paymentmode </th>
                            <th className="text-center"> PaymentReference </th>
                          </tr>
                        </thead>
                        <tbody>{transactiodetails}</tbody>
                      </Table>
                    </Card>
                  </div>
                </Row>
              ) : null}
              {this.state.InvoiceTransaction.length === 0 ? null : (
                <Row>
                  <Col md="10"></Col>
                  <Col md="2">
                    <button
                      className="btn btn-dark"
                      onClick={this.updateTransaction_Invoice}
                    >
                      Update
                    </button>
                  </Col>
                </Row>
              )}
            </div>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    username :state.username,
    allusers :state.allusers
  };
};
export default connect(mapStateToProps, null)(InvoiceCorrection);
