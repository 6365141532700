import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 50,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
  rows: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    fontStyle: "bold",
  },
  clientId: {
    width: "10%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
  },
  clientname: {
    width: "50%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
  },
  invoice: {
    width: "20%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
  },
  balance: {
    width: "20%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
  },
});

const InvoiceDetailsRow = ({ outstandingBal }) => {
  const invoice = outstandingBal.map((i) => (
    <React.Fragment>
      <View style={styles.rows}>
        <Text style={styles.clientId}> {i.invoicesummaryclient_id} </Text>
        <Text style={styles.clientname}> {i.clientname} </Text>
        <Text style={styles.invoice}>
          
          {parseFloat(i.invoice_amount).toFixed(2)}
        </Text>
        <Text style={styles.balance}>
          
          {parseFloat(i.balancedue).toFixed(2)}
        </Text>
      </View>
    </React.Fragment>
  ));

  return <React.Fragment>{invoice}</React.Fragment>;
};

export default InvoiceDetailsRow;
