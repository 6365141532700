import React, { Component } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import Report from "./Report";
import {Row, Card} from "reactstrap";
import moment from "moment";

let group_data = ""

class ReportAppDB extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ManageData : this.props.generateData,
      TotalTime : this.props.totalTime,
      jsonObject: "",
      startDate : "",
      endDate : "",
      loading : true,
    };
  }

  componentDidMount(){
    group_data = this.multipleGroupByArray(this.props.generateData, (property) => [property.clientName, property.date, property.username, property.maintaskname]);
    this.CalculateTaskTime(group_data)
    const a = moment(this.props.dateStart).format("YYYY-MM-DD");
    const b = moment(this.props.dateEnd).format("YYYY-MM-DD");
    this.setState({
      startDate: a,
      endDate: b,
      loading: false
    })
  }

  multipleGroupByArray = (dataArray, groupPropertyArray) => {
    const groups = {};
    dataArray.forEach(item => {
        const group = JSON.stringify(groupPropertyArray(item));
        groups[group] = groups[group] || [];
        groups[group].push(item);
    });
    return Object.keys(groups).map(function(group) {
        return groups[group];
    });
  }

  CalculateTaskTime(body){
    body.forEach((e) => {
        e.totaltime = this.calculateSubtaskTime(e);
      })
  }
  
  calculateSubtaskTime(e){
    let total=0 , mins=0,times=0,stringtime=0 ,h=0,m=0,subTime="00:00";
    
    e.forEach((d) => {
      stringtime=d.time;
      times=stringtime.split(':');
      mins= times[0]*60 + +times[1];
      total+= mins;
      h = (total/60 |0);
      m = total % 60;
      function z(n){return (n<10? '0':'') + n;}
        subTime = z(h) + ':' + z(m);
      });
    return subTime;
}
  
  render() {
    if(this.state.loading)
    {
      return null;
    }
    else
    {
      return (
        <React.Fragment>
          <button className="x" onClick={this.props.closebtn}>
            x
          </button>
          <div className="header">
            Report
          </div>
          <Row>
            <div className=" col">
              <div className="Scrolls-Contents">
                <Card className="shadow">
                  <PDFViewer width="100%" height="600" className="app">
                    <Report 
                      TimeCal = {this.state.TotalTime}
                      sD = { this.state.startDate }
                      eD = { this.state.endDate }
                      group_data = {group_data}
                    />
                  </PDFViewer>
                </Card>
              </div>
            </div>
          </Row>
        </React.Fragment>
      );
    }
  }
  }

export default ReportAppDB;