export default function getDateTimeField() {
  let datetime_field = "";
  const created_date = new Date();
  const year = created_date.getFullYear();
  const month = (created_date.getMonth() + 1).toString().padStart(2, "0");
  const day = created_date.getDate().toString().padStart(2, "0");
  const hours = created_date.getHours().toString().padStart(2, "0");
  const minutes = created_date.getMinutes().toString().padStart(2, "0");
  const seconds = created_date.getSeconds().toString().padStart(2, "0");

  datetime_field = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return datetime_field;
}
