import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ReactTooltip from "react-tooltip";
import ConPreLoader from "../../../../components/Loader/Con_PreLoader";
import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";
import AddStockgrp from "./AddStockgroup";
import EditStockgrp from "./EditStockgroup";
import { connect } from 'react-redux';
import config from "react-global-configuration";
import '../../../../config';
import { Link } from "react-router-dom";
import classnames from 'classnames';
import {
  Card,
  Row, NavLink, Nav, NavItem, Container
} from "reactstrap";

const authApi = config.get('apiDomain');
let currentActiveTab = '1'

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPage: 50,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
        entries.
      </label>
    </div>
  ),
});

function StockGroups(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const doFetch = async () => {
      const response = await fetch(`${authApi}stockgroupfilter/`, {
        method: 'GET',
        headers: {
          'content-type': "application/json",
          'Authorization': `token ${props.token}`,
        }
      })
      const myTaskData = await response.json();
      if (myTaskData.length === 0) {
        alert("No Task available for My Tasks.!");
        setLoading(false);
      }
      else {
        const details = myTaskData.sort((a, b) => (a.id - b.id));
        setData(details);
        setLoading(false);
      }
    }
    doFetch();
  },
    // eslint-disable-next-line  
    []
  );

  function stockupdate() {
    const doFetch = async () => {
      const response = await fetch(`${authApi}stockgroupfilter/`, {
        method: 'GET',
        headers: {
          'content-type': "application/json",
          'Authorization': `token ${props.token}`,
        }
      })
      const myTaskData = await response.json();
      if (myTaskData.length === 0) {
        alert("No Task available for My Tasks.!");
        setLoading(false);
      }
      else {
        const details = myTaskData.sort((a, b) => (a.id - b.id));
        setData(details);
        setLoading(false);
      }
    }
    doFetch();
  }

  const expandRow = {
    renderer: row => (
      <div>
        <ToolkitProvider
          data={row.stockgroup}
          keyField="name"
          columns={[
            {
              dataField: "stockname",
              text: "Stock Name",
            },
            {
              dataField: "unit",
              text: "Unit",
            },
            {
              dataField: "defaultrate",
              text: "Default Rate",
            },
            {
              dataField: "productcode",
              text: "Stock Code",
            }
          ]}
          search
        >
          {(props) => (
            <div className="py-4 table-responsive">
              <BootstrapTable
                {...props.baseProps}
                keyField='id'
                bootstrap4={true}
                bordered={false}
                filter={filterFactory()}
                striped
                hover
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    ),
    showExpandColumn: true,
    expandByColumnOnly: true
  };

  function stockgroupActionFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <React.Fragment>
        <Popup
          modal
          trigger={
            <button className="btn btn-primary btn-sm" style={{ marginRight: '5px' }}>
              <i className="fa fa-edit" />
            </button>
          }
          position="center"
        >
          {(close) => (
            <EditStockgrp
              EditRowSend={row}
              closebtn={close}
              callbackfunction={stockupdate}
            />
          )}
        </Popup>
      </React.Fragment>
    );
  }

  return (
    <>
      <div style={{ marginTop: 0 }}>
        <Nav tabs style={{ padding: "25px 20px 0", fontSize: 20, backgroundColor: '#11cdef' }}>
          <NavItem>
            <NavLink style={{ color: 'white' }}
              className={classnames({
                active:
                  currentActiveTab === '2'
              })}
              to="/admin/stocklist/stocks"
              tag={Link}
            >
              Stocks
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active:
                  currentActiveTab === '1'
              })}
              to="/admin/stocklist/stockgroups"
              tag={Link}
            >
              Stock Groups
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink style={{ color: 'white' }}
              className={classnames({
                active:
                  currentActiveTab === '3'
              })}
              to="/admin/stocklist/unittype"
              tag={Link}
            >
              Unit Type
            </NavLink>
          </NavItem>
        </Nav>
        <Container className="mt--6" fluid>
          <br />
          <Row>
            <div className="addRow" style={{ position: "absolute", right: "65px" }}>
              <ReactTooltip id="addstocks-Tip" place="left" type="dark" effect="solid">
                Add New Stockgroup
              </ReactTooltip>
              <Popup
                modal
                position="center"
                trigger={
                  <button className="btn btn-primary btn-sm"
                    style={{ width: "135%", height: 40 }} data-tip data-for="addstocks-Tip">
                    <i className="ni ni-single-02"></i>
                  </button>
                }
              >
                {
                  (close) => (
                    <AddStockgrp
                      closebtn={close}
                      callbackfunction={stockupdate}
                    />
                  )}
              </Popup>
            </div>
          </Row>
          <br /><br /><br />
          <Row>
            {loading === true ? <div> <ConPreLoader /></div> :
              <div className="col">
                <Card>
                  <ToolkitProvider
                    data={data}
                    keyField="name"
                    columns={[
                      {
                        dataField: "edit",
                        text: "Edit",
                        sort: false,
                        formatter: stockgroupActionFormatter,
                      },
                      {
                        dataField: "id",
                        text: "Id",
                        sort: true,
                      },
                      {
                        dataField: "name",
                        text: "StockGroup Name",
                        sort: true,
                        filter: textFilter()
                      }
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="table-responsive">
                        <BootstrapTable
                          {...props.baseProps}
                          keyField='id'
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                          filter={filterFactory()}
                          expandRow={expandRow}
                          striped
                          hover
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </Card>
              </div>
            }
          </Row>
        </Container>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    token: state.token,
  }
}

export default connect(mapStateToProps, null)(StockGroups);