import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";
import PurchaseHsnHeader from "./PurchaseHsnHeader";

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: 2,
        borderWidth: 1,
        borderColor: "#bff0fd"
    },
    rows: {
        flexDirection: "row",
        alignItems: "center",
        fontStyle: "bold"
    },
});

const PurchaseHsnTable = ({ hsndata }) => {
    // const hsnamount = hsndata.filter((d) => d.name !== "Total").reduce((i, p) => i + parseInt(p.amount, 0), 0);
    // const hsncgst = hsndata.reduce((i, p) => i + parseInt(p.cgstdiscount, 0), 0);
    // const hsnsgst = hsndata.reduce((i, p) => i + parseInt(p.sgstdiscount, 0), 0);
    // const totalamount = hsnsgst + hsncgst

    const tableRows = hsndata.map((t) => (
        <View style={styles.rows}>
            <Text style={{ width: "10%", textAlign: "left", paddingLeft: 8, paddingTop: 4, }}>{t[0].hsncode}</Text>
            <Text style={{ width: "20%", textAlign: "center", paddingRight: 8, paddingTop: 4, }}>{t.filter((d) => d.name !== "Total").reduce((i, p) => i + parseInt(p.amount, 0), 0)}</Text>
            <Text style={{ width: "25%", textAlign: "center", paddingLeft: 8, paddingTop: 4, }}>{t.filter((i) => i.name !== "Total").reduce((i, p) => i + parseFloat(p.cgstdiscount, 0), 0).toFixed(2)}</Text>
            <Text style={{ width: "15%", textAlign: "center", paddingRight: 8, paddingTop: 4, }}>{t.filter((i) => i.name !== "Total").reduce((i, p) => i + parseFloat(p.sgstdiscount, 0), 0).toFixed(2)}</Text>
            <Text style={{ width: "15%", textAlign: "center", paddingRight: 8, paddingTop: 4, }}>{parseFloat(t.filter((i) => i.name !== "Total").reduce((i, p) => i + parseFloat(p.cgstdiscount, 0), 0).toFixed(2)) + parseFloat(t.filter((i) => i.name !== "Total").reduce((i, p) => i + parseFloat(p.sgstdiscount, 0), 0).toFixed(2))}</Text>
        </View>
    ))

    return (
        <View style={styles.tableContainer}>
            <PurchaseHsnHeader />
            {tableRows}
        </View>
    )
};

export default PurchaseHsnTable;