import React, { Component } from "react";
import moment from "moment";
import Popup from "reactjs-popup";
import {
  Row,
  Col,
  Input,
  Container,
  Card,
  Table,
  CardHeader,
} from "reactstrap";
import config from "react-global-configuration";
import "../../../../config";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import CreateNewProjectTask from "./CreateNewProjectTask";
import RestoreProjectTask from "./RestoreProjectTask";
import getDateTimeField from "../../../../Utils";

const authApi = config.get("apiDomain");
let remove_task = [],
  projecttotalamount = 0,
  taskids = [],
  taxyearstartdate = "",
  taxyearenddate = "";
class EditProjectInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Enddate: moment(new Date()).format("YYYY-MM-DD"),
      ClosingBlance: 0,
      invoicedetails: false,
      id: "",
      ChangeDate: "",
      ChangeProject: this.props.Editdata.projectname,
      ChangeStart: moment(new Date()).format("YYYY-MM-DD"),
      ChangeEnd: moment(new Date()).format("YYYY-MM-DD"),
      ChangeAssign: this.props.assigneeName,
      ChangePriority: "Medium",
      ChangeStatus: this.props.Editdata.status,
      ChangeTime: "",
      ChangeIsdeleted: 0,
      subclient: this.props.Editdata.subclient,
      file_path: "",
      advanceamount: 0,
      phonenumber: "",
      completed_date: null,
      Clientname: this.props.Editdata.clientname,
      clientid: [],
      id_value: this.props.Editdata.clientid,
      plan: [],
      currentuser: this.props.username,
      nickname: [],
      Client_id: "",
      client_alldetails: this.props.clientdetails,
      subclient_details: [],
      select_clientName: [],
      select_client_id: [],
      companydetails: [],
      Phonenum: this.props.Editdata.phonenumber,
      todaydate: moment(new Date()).format("YYYYMMDD"),
      enableprinting: 0,
      border: "1px solid #dee2e6",
      clientidborder: "1px solid #dee2e6",
      subclientborder: "1px solid #dee2e6",
      companyborder: "1px solid #dee2e6",
      isButtonDisabled: false,
      companyid: this.props.Editdata.company,
      company_name: this.props.Editdata.companyid,
      projectnameborder: "1px solid #dee2e6",
      q: "",
      taskdetails: "",
      users: this.props.allusers,
      userid: "",
      Adjustamount: 0,
      ProjectdetailsAmount: 0,
      projectdata: [],
      data_list: [],
      jsonprintingdraftingdetails: [],
      adjustmentamoundhide: false,
    };
  }

  async componentDidMount() {
    let maxdata = 0,
      max = 0;
    const responseyear = await this.checkTaxYear();
    responseyear.forEach((currentyear) => {
      if (currentyear.id > max) {
        max = currentyear.id;
        maxdata = currentyear;
      }
    });
    taxyearstartdate = maxdata.startdate;
    taxyearenddate = maxdata.enddate;
    this.filterProjectdetails(this.props.Editdata.taskproject);
    this.calculateclosingBalance();
  }

  checkTaxYear = () => {
    return fetch(`${authApi}taxyear/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());
  };

  calculateclosingBalance = async (clientid) => {
    axios({
      method: "post",
      url: `${authApi}sp_calculateclosingbalance/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        client_id: this.props.Editdata.clientid,
        company_id: this.props.Editdata.company,
        startdate: taxyearstartdate,
        enddate: this.state.Enddate,
      },
    })
      .then((res) => {
        this.setState({
          ClosingBlance:
            res.data.balance != null || res.data.balance !== ""
              ? res.data.balance
              : 0,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  payforunpaidinvoice = () => {
    if (this.state.ClosingBlance < 0) {
      axios({
        method: "post",
        url: `${authApi}sp_payforunpaidinvoice/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          client_id: this.props.Editdata.clientid,
          company_id_value: this.props.Editdata.company,
          startdate: taxyearstartdate,
          enddate: taxyearenddate,
          userid: this.state.userid,
          received_amount: 0,
          description: "Available client balance used for this invoice",
        },
      })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  filterProjectdetails = async (taskdetails) => {
    let totaltasks = taskdetails.filter((data) => {
      console.log(data);
      return (
        data.isdeleted !== true &&
        data.status !== "InProgress" &&
        data.status !== "ToDo" &&
        (data.invoiceidno === null || data.invoiceidno === "")
      );
    });

    this.setState({
      data_list: totaltasks,
      adjustmentamoundhide: totaltasks.length > 1 ? true : false,
    });
    totaltasks.forEach((e) => {
      e.time = this.calculateSubtaskTime(e);
    });
    let currentclientdetails = this.props.allusers.filter((currentclient) => {
      return currentclient.name === this.props.username;
    });
    this.setState({
      userid: currentclientdetails[0].id,
    });
    await this.createJsonObjectForDraftingCharges(totaltasks);
    await this.selectClient_Name(this.props.Editdata.clientname);
  };

  createJsonObjectForDraftingCharges = async (taskproject) => {
    taskids = [];
    let idnum = 0;
    await taskproject.forEach(async (element) => {
      let draftingdetails = [],
        totalamount = 0;
      let printingdetails = await this.getPrintingdetails(element.id);
      let printingtotal = 0;
      if (printingdetails.length !== 0) {
        printingtotal = printingdetails.reduce(
          (a, curr) => a + Math.round(curr.amount),
          0
        );
      }
      await element.subtask.forEach(async (subtaskdetails) => {
        let Rate = 0,
          times = [],
          mins = 0,
          amount = 0;
        await this.state.users.forEach(async (element) => {
          if (element.name === subtaskdetails.assignto) {
            Rate = element.default_rate;
          }
        });
        times = subtaskdetails.time.split(":");
        mins = times[0] * 60 + +times[1];
        amount = Math.round((mins * Rate) / 60);
        totalamount = amount + totalamount;
        let data = {
          username: subtaskdetails.assignto,
          status: subtaskdetails.status,
          time: subtaskdetails.time,
          defaultRate: Rate,
          amount: amount,
          isdeleted: 0,
        };
        if (subtaskdetails.time !== "00:00") {
          draftingdetails = [...draftingdetails, data];
        }
      });
      if (draftingdetails.length !== 0) {
        let data = {
          username: "",
          status: "",
          time: "",
          defaultRate: "Total",
          amount: totalamount,
          isdeleted: 0,
        };
        draftingdetails = [...draftingdetails, data];
      }
      if (printingdetails.length !== 0) {
        let data = {
          amount: printingtotal,
          id: 9999,
          quantity: "",
          rate: "Total",
          tasklistrow: "",
          types: "",
          unit: "",
        };
        printingdetails = [...printingdetails, data];
      }
      let draftingstockjson = {
        id: idnum + 1,
        taskname: element.task,
        draftingdetails: draftingdetails,
        printingdetails: printingdetails,
        tasktotalamount: printingtotal + totalamount,
        taskid: element.id,
      };
      projecttotalamount = projecttotalamount + printingtotal + totalamount;
      this.setState({
        ProjectdetailsAmount: projecttotalamount,
      });
      this.setState({
        jsonprintingdraftingdetails: [
          ...this.state.jsonprintingdraftingdetails,
          draftingstockjson,
        ],
      });
      idnum = idnum + 1;
      if (printingdetails.length !== 0 || draftingdetails.length !== 0) {
        taskids = [...taskids, element.id];
      }
    });
  };

  getPrintingdetails = async (id) => {
    let response = await fetch(
      `${authApi}stockinvoicefilter/?tasklistrow=${id}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    ).then((res) => res.json());
    return response;
  };

  calculateSubtaskTime = async (e) => {
    let timeSub = "00:00";
    let timetotal = 0,
      mins = 0,
      times = 0,
      stringtime = 0,
      h = 0,
      m = 0;
    e.subtask.forEach(async (st) => {
      if (st.isdeleted === false) {
        st.time = await this.calculateSecondLayerSubtaskTime(st);
        if (st.time === 0) {
          timeSub = "00:00";
        } else {
          stringtime = st.time;
          times = stringtime.split(":");
          mins = times[0] * 60 + +times[1];
          timetotal += mins;
          h = (timetotal / 60) | 0;
          m = timetotal % 60;
          function z(n) {
            return (n < 10 ? "0" : "") + n;
          }
          timeSub = z(h) + ":" + z(m);
        }
      }
    });
    return timeSub;
  };

  calculateSecondLayerSubtaskTime = async (st2) => {
    let total = 0,
      mins = 0,
      times = 0,
      stringtime = 0,
      h = 0,
      m = 0,
      subTime = "00:00";
    st2.subtaskTime.forEach((d) => {
      stringtime = d.time;
      times = stringtime.split(":");
      mins = times[0] * 60 + +times[1];
      total += mins;
      h = (total / 60) | 0;
      m = total % 60;
      function z(n) {
        return (n < 10 ? "0" : "") + n;
      }
      subTime = z(h) + ":" + z(m);
    });
    return subTime;
  };

  Formvalidation = () => {
    this.createProjectInvoice();
  };

  updateProjectStatus = () => {
    let check = null;
    if (check !== this.props.Editdata.id) {
      axios({
        method: "put",
        url: `${authApi}updateprojectstatus/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          taskproject_id: this.props.Editdata.id,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            console.log(res.status);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  createProjectInvoice = async () => {
    let draftingdetails = [];
    this.state.jsonprintingdraftingdetails.forEach((element) => {
      if (element.draftingdetails.length !== 0) {
        let taskdraftdata = {
          draftingdetails: element.draftingdetails,
          taskid: element.taskid,
        };
        draftingdetails = [...draftingdetails, taskdraftdata];
      }
    });
    let currentdate = moment(new Date()).format("YYYY-MM-DD");
    if (this.state.ProjectdetailsAmount > 0) {
      let datetime_field = getDateTimeField();
      axios({
        method: "post",
        url: `${authApi}sp_createprojectinvoicedetails/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          userid: this.state.userid,
          client: this.state.id_value,
          invoicesummaryclientid: this.state.id_value,
          amount_received: 0,
          discount:
            this.state.data_list.length > 1 ? this.state.Adjustamount : 0,
          duedate: moment(new Date()).format("YYYY-MM-DD"),
          comments: "",
          invoicestatus: "Unpaid",
          invoice_amount: this.state.ProjectdetailsAmount,
          balancedue: this.state.ProjectdetailsAmount,
          subtotal: projecttotalamount,
          date: currentdate,
          companyid: this.state.companyid,
          invoice_companyid: this.state.companyid,
          projectid: this.props.Editdata.id,
          tbltasklistid: null,
          projecttaskids: taskids,
          draftingdetails: draftingdetails,
          modifiedby: this.props.username,
          modifiedat: datetime_field,
          createdby: this.props.username,
          createdat: datetime_field,
        },
      })
        .then((res) => {
          console.log(res.status);
          if (res.status === 200) {
            this.setState({
              jsonprintingdraftingdetails: [],
            });
            remove_task = [];
            projecttotalamount = 0;
            taskids = "";
            this.updateProjectStatus();
            this.props.backtoProjects();
            this.payforunpaidinvoice();
          }
        })
        .catch((err) => {
          console.log(err);
          this.props.backtoProjects();
        });
    } else {
      alert("Cannot Create invoice for Zero Balance");
    }
  };

  selectClient_Name = (name) => {
    if (name === "General-Printing" || name === "General") {
      let startdate = moment(this.state.todaydate).format("YYYY-MM-DD");
      let enddate = moment(this.state.todaydate).format("YYYY-MM-DD");
      this.setState({
        enableprinting: 1,
        ChangeStart: startdate,
        ChangeEnd: enddate,
      });
    } else {
      this.setState({
        enableprinting: 0,
      });
    }
    let currentclientdata = this.props.clientdetails.filter((clientdata) => {
      return clientdata.company_name === name;
    });
    this.setState(
      {
        select_clientName: currentclientdata,
      },
      function () {
        this.updateClientID();
      }
    );
  };

  updateClientID = () => {
    this.state.select_clientName.map((item) => {
      this.setState({
        Client_id: item.clientid,
        subclient_details: item.clients,
        id_value: item.id,
        Phonenum: item.phoneno,
      });
      return this.state.Client_id;
    });
  };

  keyEventOperation = (event) => {
    if (!event.shiftKey && event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if (form.elements[index + 1] !== undefined) {
        form.elements[index + 1].focus();
      }
      event.preventDefault();
    } else if (event.key.toLowerCase() === "enter" && event.shiftKey) {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if (form.elements[index - 1] !== undefined) {
        form.elements[index - 1].focus();
      }
      event.preventDefault();
    }
  };

  closeInvoice = async () => {
    this.setState({
      jsonprintingdraftingdetails: [],
    });
    remove_task = [];
    projecttotalamount = 0;
    taskids = "";
    await this.props.backtoProjects();
  };

  newTask = () => {
    this.setState({
      jsonprintingdraftingdetails: [],
    });
    this.props.backtoProjects();
  };

  adjustAmount = (event) => {
    this.setState({
      Adjustamount: event.target.value,
      ProjectdetailsAmount: isNaN(
        parseInt(projecttotalamount) + parseInt(event.target.value)
      )
        ? 0
        : parseInt(projecttotalamount) + parseInt(event.target.value),
    });
  };

  editProjectinvoice = () => {
    this.setState({
      invoicedetails: true,
    });
  };

  removetaskdetails = async (task) => {
    let totalamount = 0;
    let remainingdata = this.state.jsonprintingdraftingdetails.filter(
      (element) => element.taskid !== task.taskid
    );
    remainingdata.forEach((element) => {
      totalamount = totalamount + element.tasktotalamount;
    });
    projecttotalamount = totalamount;
    remove_task = [...remove_task, task];
    this.setState({
      ProjectdetailsAmount: totalamount,
      jsonprintingdraftingdetails:
        this.state.jsonprintingdraftingdetails.filter(
          (element) => element.taskid !== task.taskid
        ),
    });
    taskids = taskids.filter((element) => element !== task.taskid);
  };

  backtodetails = async () => {
    projecttotalamount = 0;
    this.setState({
      jsonprintingdraftingdetails: [],
      ProjectdetailsAmount: 0,
    });
    this.setState({
      invoicedetails: false,
    });
    await fetch(`${authApi}projectfilter/?id=${this.props.Editdata.id}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        this.setState(
          {
            projectdata: json[0],
          },
          function () {
            let data = json[0].taskproject;
            remove_task.forEach((element) => {
              data = data.filter((data) => element.taskid !== data.id);
            });
            this.filterProjectdetails(data);
          }
        );
      });
  };
  restoreTask = async () => {};

  restoredetails = (data) => {
    remove_task = remove_task.filter((element) => element.id !== data.id);
    let totalamount = 0;
    this.state.jsonprintingdraftingdetails.forEach((element) => {
      totalamount = totalamount + element.tasktotalamount;
    });
    totalamount = parseInt(data.tasktotalamount) + totalamount;
    projecttotalamount = totalamount;
    this.setState({
      ProjectdetailsAmount: totalamount,
      jsonprintingdraftingdetails: [
        ...this.state.jsonprintingdraftingdetails,
        data,
      ],
    });
    taskids = [...taskids, data.taskid];
  };

  render() {
    console.log(this.state.adjustmentamoundhide);
    return (
      <React.Fragment>
        {this.state.invoicedetails === false ? (
          <Container className="mt--7" fluid>
            <div className="Scroll-Content" style={{ marginTop: 10 }}>
              <br />
              <Row>
                <Col md="11">
                  <CardHeader
                    style={{
                      backgroundColor: "transparent",
                      padding: 10,
                      marginTop: 20,
                    }}
                  >
                    <h3 className="mb-0">
                      <i
                        style={{ marginRight: 5 }}
                        class="fas fa-cart-plus"
                      ></i>
                      Create Project Invoice
                    </h3>
                  </CardHeader>
                </Col>

                <ReactTooltip
                  id="restore-Task"
                  place="left"
                  type="dark"
                  effect="solid"
                >
                  Restore Task
                </ReactTooltip>
                <Popup
                  modal
                  position="center"
                  trigger={
                    <button
                      className="btn btn-primary btn-sm"
                      style={{
                        position: "absolute",
                        right: "190px",
                        marginTop: 16,
                        height: 39,
                        width: 40,
                      }}
                      data-tip
                      data-for="restore-Task"
                      onClick={this.restoreTask}
                    >
                      <i className="fas fa-trash-restore fa-1x"></i>
                    </button>
                  }
                >
                  {(close) => (
                    <RestoreProjectTask
                      closebtn={close}
                      deldata={remove_task}
                      restoredata={this.restoredetails}
                    />
                  )}
                </Popup>

                <button
                  className="btn btn-primary btn-sm"
                  style={{
                    position: "absolute",
                    right: "145px",
                    marginTop: 16,
                    height: 39,
                    width: 40,
                  }}
                  data-tip
                  data-for="completed-Task"
                  onClick={this.editProjectinvoice}
                >
                  <i className="fas fa-plus-circle fa-1x"></i>
                </button>

                <ReactTooltip
                  id="completed-Task"
                  place="left"
                  type="dark"
                  effect="solid"
                >
                  Add New Task
                </ReactTooltip>
                <div
                  className="addRow"
                  style={{ position: "absolute", right: "90px", marginTop: 16 }}
                >
                  <button
                    className="btn btn-primary btn-sm"
                    style={{ width: "135%", height: 40 }}
                    data-tip
                    data-for="completed-Tip"
                    onClick={this.closeInvoice}
                  >
                    Back
                  </button>
                  <ReactTooltip
                    id="completed-Tip"
                    place="left"
                    type="dark"
                    effect="solid"
                  >
                    Back To Projects
                  </ReactTooltip>
                </div>
              </Row>
              <form>
                <Row style={{ marginTop: 10 }}>
                  <Col md="4">
                    <label className="form-control-label">Client Id</label>
                    <Input
                      type="option"
                      list="id_value-list"
                      className="form-control-alternative"
                      value={this.state.Client_id}
                      style={{ border: this.state.clientidborder }}
                      onKeyDown={this.keyEventOperation}
                    />
                    <datalist id="id_value-list">
                      {this.state.client_alldetails.map((item, key) => (
                        <option key={key} value={item.clientid} />
                      ))}
                    </datalist>
                    {this.state.clientidborder !== "1px solid #dee2e6" ? (
                      <h5 style={{ color: "#fb6340" }}>
                        Please select clientid
                      </h5>
                    ) : null}
                  </Col>
                  <Col md="4">
                    <label className="form-control-label">Client Name</label>
                    <Input
                      type="option"
                      list="nickname-list"
                      className="form-control-alternative"
                      defaultValue={this.state.Clientname}
                      style={{ border: this.state.clientidborder }}
                      onChange={this.selectClient_Name}
                      onKeyDown={this.keyEventOperation}
                    />
                    <datalist id="nickname-list">
                      {this.state.client_alldetails.map((item, name) => (
                        <option key={name} value={item.company_name} />
                      ))}
                    </datalist>
                    {this.state.clientidborder !== "1px solid #dee2e6" ? (
                      <h5 style={{ color: "#fb6340" }}>Please select name</h5>
                    ) : null}
                  </Col>
                  <Col md="4">
                    <label className="form-control-label">Subclient</label>
                    <Input
                      type="option"
                      list="subclient-list"
                      className="form-control-alternative"
                      style={{ border: this.state.subclientborder }}
                      value={this.state.subclient}
                      onKeyDown={this.keyEventOperation}
                    ></Input>
                    <datalist id="subclient-list">
                      {this.state.subclient_details.map((item, name) => (
                        <option key={name} value={item.name} />
                      ))}
                    </datalist>
                    {this.state.subclientborder !== "1px solid #dee2e6" ? (
                      <h5 style={{ color: "#fb6340" }}>
                        Please enter subclient
                      </h5>
                    ) : null}
                  </Col>
                </Row>
                &nbsp;
                <Row style={{ marginTop: -5 }}>
                  <Col md="4">
                    <label className="form-control-label">Phonenumber</label>
                    <Input
                      className="form-control-alternative"
                      type="text"
                      style={{ border: "1px solid #dee2e6" }}
                      value={this.state.Phonenum}
                      onChange={this.HandlePhonenum}
                      onKeyDown={this.keyEventOperation}
                    />
                  </Col>
                  <Col md="4">
                    <label className="form-control-label">Company</label>
                    <Input
                      type="text"
                      className="form-control-alternative"
                      value={this.state.company_name}
                      style={{ border: this.state.companyborder }}
                      onKeyDown={this.keyEventOperation}
                    ></Input>
                    {this.state.companyborder !== "1px solid #dee2e6" ? (
                      <h5 style={{ color: "#fb6340" }}>
                        Please select companyname
                      </h5>
                    ) : null}
                  </Col>
                  <Col md="4">
                    <label className="form-control-label">Project Name</label>
                    <Input
                      type="text"
                      style={{ border: "1px solid #dee2e6" }}
                      className="form-control-alternative"
                      value={this.state.ChangeProject}
                    />
                    {this.state.projectnameborder === "1px solid #fb6340" ? (
                      <h5 style={{ color: "#fb6340" }}>
                        Please Enter Projectname
                      </h5>
                    ) : null}
                  </Col>
                </Row>
              </form>
              <Row>
                <div className=" col">
                  <br />
                  <Card className="shadow">
                    <Table
                      className="align-items-center table-flush"
                      responsive-sm
                    >
                      <thead className="thead-light">
                        <tr>
                          <th className="text-center">ACTION</th>
                          <th className="text-center">TASK NAME</th>
                          <th className="text-center">TASK ID</th>
                          <th className="text-center">TASK DETAILS</th>
                          <th className="text-center">TOTAL</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.jsonprintingdraftingdetails.map((task) => (
                          <>
                            {task.printingdetails.length !== 0 ||
                            task.draftingdetails.length !== 0 ? (
                              <tr>
                                <td>
                                  <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => this.removetaskdetails(task)}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </td>
                                <td>{task.taskid}</td>
                                <td>{task.taskname}</td>
                                <td>
                                  {task.draftingdetails.length !== 0 ? (
                                    <Card className="shadow">
                                      <Table
                                        className="align-items-center table-flush"
                                        responsive-sm
                                      >
                                        <thead className="thead-light">
                                          <tr>
                                            <th className="text-center">
                                              USERNAME
                                            </th>
                                            <th className="text-center">
                                              STATUS
                                            </th>
                                            <th className="text-center">
                                              TIME
                                            </th>
                                            <th className="text-center">
                                              RATE
                                            </th>
                                            <th className="text-center">
                                              AMOUNT
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {task.draftingdetails.map((draft) => (
                                            <tr>
                                              <td className="text-center">
                                                {draft.username}
                                              </td>
                                              <td className="text-center">
                                                {draft.status}
                                              </td>
                                              <td className="text-center">
                                                {draft.time}
                                              </td>
                                              <td className="text-center">
                                                {draft.defaultRate}
                                              </td>
                                              <td className="text-center">
                                                {draft.amount}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                    </Card>
                                  ) : null}
                                  {task.printingdetails.length !== 0 ? (
                                    <Card className="shadow">
                                      <Table
                                        className="align-items-center table-flush"
                                        responsive-sm
                                      >
                                        <thead className="thead-light">
                                          <tr>
                                            <th className="text-center">
                                              STOCKNAME
                                            </th>
                                            <th className="text-center">
                                              UNIT
                                            </th>
                                            <th className="text-center">
                                              QUANTITY
                                            </th>
                                            <th className="text-center">
                                              RATE
                                            </th>
                                            <th className="text-center">
                                              AMOUNT
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {task.printingdetails.map((print) => (
                                            <tr>
                                              <td className="text-center">
                                                {print.types}
                                              </td>
                                              <td className="text-center">
                                                {print.unit}
                                              </td>
                                              <td className="text-center">
                                                {print.quantity}
                                              </td>
                                              <td className="text-center">
                                                {print.rate}
                                              </td>
                                              <td className="text-center">
                                                {print.amount}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                    </Card>
                                  ) : null}
                                </td>
                                <td className="text-center">
                                  {task.tasktotalamount}
                                </td>
                              </tr>
                            ) : null}
                          </>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                </div>
              </Row>
              <Row>
                <Col md="8"></Col>
                <Col md="2">
                  <ul
                    class="list-group list-group-flush"
                    style={{ paddingLeft: 90, width: 290 }}
                  >
                    <li class="list-group-item">Amount</li>
                    {this.state.adjustmentamoundhide === true ? (
                      ""
                    ) : (
                      <li class="list-group-item">AdjustAmount</li>
                    )}
                    <li class="list-group-item">Total</li>
                  </ul>
                </Col>
                <Col md="2">
                  <ul
                    class="list-group list-group-flush align-center"
                    style={{ paddingLeft: 60 }}
                  >
                    <li class="list-group-item">{projecttotalamount}</li>
                    {this.state.adjustmentamoundhide === true ? (
                      ""
                    ) : (
                      <li class="list-group-item">
                        <Input
                          type="text"
                          style={{
                            border: this.state.ValidAdjustamount,
                            textAlign: "left",
                            position: "relative",
                            display: "block",
                            top: 0,
                            fontSize: "15px",
                            margin: "0",
                            left: -12,
                            height: 33,
                            width: "110%",
                          }}
                          Value={this.state.Adjustamount}
                          onChange={this.adjustAmount}
                        />
                      </li>
                    )}
                    <li class="list-group-item">
                      {this.state.ProjectdetailsAmount}
                    </li>
                  </ul>
                </Col>
              </Row>
              <Row>
                <Col md="10"></Col>
                <Col md="2" style={{ textAlign: "center" }}>
                  <label className="form-control-label">
                    &nbsp;&nbsp;
                    <button
                      id="buttonsave"
                      className="btn btn-dark"
                      onClick={this.Formvalidation}
                      disabled={this.state.isButtonDisabled}
                    >
                      Save
                    </button>
                  </label>
                </Col>
              </Row>
            </div>
          </Container>
        ) : (
          <CreateNewProjectTask
            projecttotalamount={projecttotalamount}
            backtodetails={this.backtodetails}
            project_name={this.state.ChangeProject}
            task_name={this.state.taskname}
            Editdata={this.props.Editdata}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    propsusername: state.username,
    clientdetails: state.clientdetails,
    allusers: state.allusers,
    username: state.username,
  };
};
export default connect(mapStateToProps, null)(EditProjectInvoice);
