import React, { Component } from "react";
import { Table, Row, Col, Container } from "reactstrap";
import Header from "components/Headers/SimpleHeader";
import config from "react-global-configuration";
import consoleFunction from "../../../../Helpers";
import "../../../../config";
import { connect } from "react-redux";
import InwardTableRow from "./InwardTableRow";
import OutwardTableRow from "./OutwardTableRow";
import moment from "moment";
import axios from "axios";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { CardHeader } from "reactstrap";
import getDateTimeField from "../../../../Utils";

const authApi = config.get("apiDomain");
let makeOutwardData = "";

class ConversionTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inwardlist: [
        {
          id: 1,
          stockname: "",
          unit: "",
          quantity: "",
          productcode: "",
          availablequantity: "",
          stockbatchname: "",
          stockbatchamount: "",
          inwardrowtotal: "",
        },
      ],
      outwardlist: [
        {
          id: 1,
          stockname: "",
          unit: "",
          quantity: "",
          productcode: "",
          stockbatchamount: "",
          outwardrowtotal: "",
        },
      ],
      inwardId: 1,
      outwardId: 1,
      currentDate: moment(new Date()).format("YYYY-MM-DD"),
      currentUser: "",
      isButtonDisabled: false,
      alert: false,
    };
  }

  componentDidMount() {
    this.getCurrentUser();
  }

  getCurrentUser = () => {
    this.getUserid(this.props.username);
  };

  getUserid = (user) => {
    fetch(`${authApi}userfilter/?name=${user}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          currentUser: json[0].id,
        });
      });
  };

  handleInwardAddRow = () => {
    const inlist = {
      id: this.state.inwardId + 1,
      stockname: "",
      unit: "",
      quantity: "",
      productcode: "",
      availablequantity: "",
      stockbatchname: "",
      stockbatchamount: "",
      inwardrowtotal: "",
    };
    this.setState({
      inwardId: inlist.id,
      inwardlist: [...this.state.inwardlist, inlist],
    });
  };

  handleOutwardAddRow = () => {
    const outlist = {
      id: this.state.outwardId + 1,
      stockname: "",
      unit: "",
      quantity: "",
      productcode: "",
      stockbatchamount: "",
      outwardrowtotal: "",
    };
    this.setState({
      outwardId: outlist.id,
      outwardlist: [...this.state.outwardlist, outlist],
    });
  };

  updationInwardlist = (x) => {
    this.setState((prevState) => {
      const updatedInData = prevState.inwardlist.map((iw) => {
        if (iw.id === x.id) {
          iw.id = x.id;
          iw.stockname = x.stocknames;
          iw.unit = x.units;
          iw.quantity = x.quantities;
          iw.productcode = x.productcodes;
          iw.availablequantity = x.stockavailables;
          iw.stockbatchname = x.stockbatchnames;
          iw.stockbatchamount = x.stockbatchamounts;
          iw.inwardrowtotal = x.inwardrowtotals;
        }
        return iw;
      });
      return {
        inwardlist: updatedInData,
      };
    });
  };

  updationOutwardlist = (x) => {
    this.setState((prevState) => {
      const updatedOutData = prevState.outwardlist.map((ow) => {
        if (ow.id === x.id) {
          ow.id = x.id;
          ow.stockname = x.stocknames;
          ow.unit = x.units;
          ow.quantity = x.quantities;
          ow.productcode = x.productcodes;
          ow.stockbatchamount = x.stockbatchamounts;
          ow.outwardrowtotal = x.outwardrowtotals;
        }
        return ow;
      });
      return {
        outwardlist: updatedOutData,
      };
    });
  };

  handleInwardRemoveRow = (i) => {
    const DelData = this.state.inwardlist.filter((item) => item.id !== i.id);
    this.setState({
      inwardlist: [...DelData],
    });
  };

  handleOutwardRemoveRow = (i) => {
    const DelData = this.state.outwardlist.filter((item) => item.id !== i.id);
    this.setState({
      outwardlist: [...DelData],
    });
  };

  onConvertStocks = async () => {
    const a = this.state.inwardlist.reduce(
      (i, i_bal) => i + parseInt(i_bal.inwardrowtotal, 0),
      0
    );
    const b = this.state.outwardlist.reduce(
      (i, i_bal) => i + parseInt(i_bal.outwardrowtotal, 0),
      0
    );

    if (parseInt(a) === parseInt(b)) {
      await this.storeInwardStocks(this.state.inwardlist);
      await this.makeOutwardStringdata(this.state.outwardlist);
      await this.successAlert("Successfully Conversion Updated");
    } else {
      this.checkBalanceAlert(
        "Sorry, your inward and outward stocks balance are not equal. Please check manually.!"
      );
    }
  };

  storeInwardStocks = async (iwl) => {
    iwl.forEach((i) => {
      if (i.stockname !== "" && i.quantity !== "" && parseInt(i.quantity) > 0) {
        let datetime_field = getDateTimeField();
        axios({
          method: "post",
          url: `${authApi}inventorytransaction/`,
          headers: {
            "content-type": "application/json",
            Authorization: `token ${this.props.token}`,
          },
          data: {
            date: this.state.currentDate,
            username: this.state.currentUser,
            stockname: i.stockname,
            productcode: i.productcode,
            quantity: -Math.abs(i.quantity),
            status: "Converted",
            invoiceno: "",
            purchaseorderid: "",
            length: "",
            rate: i.stockbatchamount,
            amount: i.stockbatchamount,
            cgstpercentage: 0,
            sgstpercentage: 0,
            total: -Math.abs(i.inwardrowtotal),
            batch: i.stockbatchname,
            location: 0,
            purchaseinvoice: "",
            createdby: this.props.username,
            createdat: datetime_field,
            modifiedby: this.props.username,
            modifiedat: datetime_field,
          },
        })
          .then((res) => {
            if (res.status) {
            }
          })
          .catch((err) => {
            consoleFunction(
              "Convert issue on Inward stocks ",
              err.response.request.response
            );
          });
      } else {
        console.log("this is empty row", i);
      }
    });
  };

  makeOutwardStringdata = async (owl) => {
    makeOutwardData = [];
    const stocksOutdata = await owl.filter(
      (ow) =>
        ow.stockname !== "" && ow.quantity !== "" && parseInt(ow.quantity) > 0
    );
    stocksOutdata.forEach((element) => {
      let data = {
        stockname: element.stockname,
        productcode: element.productcode,
        quantity: element.quantity,
        rate: element.stockbatchamount,
        total: element.outwardrowtotal,
      };
      makeOutwardData = [...makeOutwardData, data];
    });
    axios({
      method: "post",
      url: `${authApi}sp_storeoutwardstock/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        username: this.state.currentUser,
        outward_details: makeOutwardData,
      },
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  successAlert = async (message) => {
    const getAlert = () => (
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success!"
        onConfirm={() => this.hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );

    this.setState({
      alert: getAlert(),
    });
  };

  hideAlert() {
    this.setState({
      alert: false,
    });
    this.props.history.push("/admin/management/inventory");
  }

  warningAlert = () => {
    const getwarningAlert = () => (
      <ReactBSAlert
        warning
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        confirmBtnText="Yes, Go back"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        cancelBtnText="Cancel"
        cancelBtnBsStyle="warning"
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.setState({ alert: false })}
      ></ReactBSAlert>
    );

    this.setState({
      alert: getwarningAlert(),
    });
  };

  checkBalanceAlert = (message) => {
    const getwarningAlert = () => (
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Warning"
        onConfirm={() => this.hideBalanceAlert()}
        confirmBtnBsStyle="warning"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );

    this.setState({
      alert: getwarningAlert(),
    });
  };

  hideBalanceAlert() {
    this.setState({
      alert: false,
    });
  }

  render() {
    const inwardRowList = [...this.state.inwardlist].map((iw) => (
      <InwardTableRow
        key={iw.id}
        id={iw.id}
        stockname={iw.stockname}
        unit={iw.unit}
        quantity={iw.quantity}
        productcode={iw.productcode}
        availablequantity={iw.availablequantity}
        stockbatchname={iw.stockbatchname}
        stockbatchamount={iw.stockbatchamount}
        inwardrowtotal={iw.inwardrowtotal}
        deleteRow={this.handleInwardRemoveRow}
        updateAddRow={this.updationInwardlist}
      />
    ));

    const outwardRowList = [...this.state.outwardlist].map((ow) => (
      <OutwardTableRow
        key={ow.id}
        id={ow.id}
        stockname={ow.stockname}
        unit={ow.unit}
        quantity={ow.quantity}
        productcode={ow.productcode}
        stockbatchamount={ow.stockbatchamount}
        outwardrowtotal={ow.outwardrowtotal}
        deleteRow={this.handleOutwardRemoveRow}
        updateAddRow={this.updationOutwardlist}
      />
    ));

    return (
      <>
        {this.state.alert}
        <Header />
        <Container className="mt--6" fluid>
          <br />
          <Row>
            <Col md="12">
              <CardHeader style={{ backgroundColor: "#f7fafc", padding: 10 }}>
                <h3 className="mb-0 text-center">Inward</h3>
              </CardHeader>
              <Table className="table-flush table-info table-hover" responsive>
                <thead className="thead-light">
                  <tr align="centre">
                    <th className="text-center">Name</th>
                    <th className="text-center">In Stock</th>
                    <th className="text-center">Batch</th>
                    <th className="text-center">Qty</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>{inwardRowList}</tbody>
              </Table>
              <div>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={this.handleInwardAddRow}
                >
                  <i className="fas fa-plus-circle fa-1x"></i>
                </button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <CardHeader style={{ backgroundColor: "#f7fafc", padding: 10 }}>
                <h3 className="mb-0 text-center">Outward</h3>
              </CardHeader>
              <Table
                className="table-flush table-success table-hover"
                responsive
              >
                <thead className="thead-light">
                  <tr align="centre">
                    <th className="text-center" align="left">
                      Name
                    </th>
                    <th className="text-center">Qty</th>
                    <th className="text-center">Amount</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>{outwardRowList}</tbody>
              </Table>
              <div>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={this.handleOutwardAddRow}
                >
                  <i className="fas fa-plus-circle fa-1x"></i>
                </button>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col md="12">
              <h3 className="mb-0 text-center"> Conversion Stocks List</h3>
              <br />
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th className="text-center">Stockname</th>
                    <th className="text-center">Productcode</th>
                    <th className="text-center">Quantity</th>
                    <th className="text-center">Unit / Subunit</th>
                    <th className="text-center">Batch</th>
                    <th className="text-center">Rate</th>
                    <th className="text-center">Amount</th>
                    <th className="text-center">In / Out</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.inwardlist.map((iw) =>
                    iw.stockname !== "" &&
                    iw.quantity !== "" &&
                    parseInt(iw.quantity) > 0 &&
                    iw.unit !== "" ? (
                      <tr>
                        <td className="text-center">{iw.stockname}</td>
                        <td className="text-center">{iw.productcode}</td>
                        <td className="text-center">
                          {-Math.abs(iw.quantity)}
                        </td>
                        <td className="text-center">{iw.unit}</td>
                        <td className="text-center">{iw.stockbatchname}</td>
                        <td className="text-center">{iw.stockbatchamount}</td>
                        <td className="text-center">
                          {-Math.abs(iw.inwardrowtotal)}
                        </td>
                        <td className="text-center">
                          <i className="ni ni-bold-down text-danger" />
                        </td>
                      </tr>
                    ) : null
                  )}
                  {this.state.outwardlist.map((ow) =>
                    ow.stockname !== "" &&
                    ow.quantity !== "" &&
                    parseInt(ow.quantity) > 0 &&
                    ow.unit !== "" ? (
                      <tr>
                        <td className="text-center">{ow.stockname}</td>
                        <td className="text-center">{ow.productcode}</td>
                        <td className="text-center">{ow.quantity}</td>
                        <td className="text-center">{ow.unit}</td>
                        <td className="text-center"></td>
                        <td className="text-center">{ow.stockbatchamount}</td>
                        <td className="text-center">{ow.outwardrowtotal}</td>
                        <td className="text-center">
                          <i className="ni ni-bold-up text-success" />
                        </td>
                      </tr>
                    ) : null
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md="3">
              <button
                className="btn btn-primary btn-sm"
                style={{ width: 65, height: 40 }}
                onClick={this.warningAlert}
              >
                Back
              </button>
            </Col>
            <Col md="6"></Col>
            <Col md="3">
              <button
                className="btn btn-dark btn-sm"
                style={{ width: 65, height: 40, float: "right" }}
                onClick={this.onConvertStocks}
                disabled={this.state.isButtonDisabled}
              >
                Convert
              </button>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    username: state.username,
  };
};
export default connect(mapStateToProps, null)(ConversionTable);
