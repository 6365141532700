import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ReactTooltip from "react-tooltip";
import AddStock from "./AddStocks";
import ConPreLoader from "../../../components/Loader/Con_PreLoader";
import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";
import EditStock from "./EditStocks";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../config";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { Card, Row, Nav, NavItem, NavLink, Container } from "reactstrap";

const authApi = config.get("apiDomain");
let currentActiveTab = "1";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPage: 50,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
        entries.
      </label>
    </div>
  ),
});

function StockDetails(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(
    () => {
      const doFetch = async () => {
        const response = await fetch(`${authApi}stockfilter/`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${props.token}`,
          },
        });
        const myTaskData = await response.json();
        if (myTaskData.length === 0) {
          alert("No Task available for My Tasks.!");
          setLoading(false);
        } else {
          setData(myTaskData);
          setLoading(false);
        }
      };
      doFetch();
    },
    // eslint-disable-next-line
    []
  );

  function refreshfunction() {
    const doFetch = async () => {
      const response = await fetch(`${authApi}stockfilter/`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${props.token}`,
        },
      });
      const myTaskData = await response.json();
      if (myTaskData.length === 0) {
        alert("No Task available for My Tasks.!");
        setLoading(false);
      } else {
        setData(myTaskData);
        setLoading(false);
      }
    };
    doFetch();
  }

  function stockActionFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <React.Fragment>
        <Popup
          modal
          trigger={
            <button
              className="btn btn-primary btn-sm"
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-edit" />
            </button>
          }
          position="center"
        >
          {(close) => (
            <EditStock
              EditRowSend={row}
              closebtn={close}
              callbackfunction={refreshfunction}
            />
          )}
        </Popup>
      </React.Fragment>
    );
  }

  return (
    <>
      <div style={{ marginTop: 0 }}>
        <Nav tabs style={{ padding: "25px 20px 0", fontSize: 20, backgroundColor: '#11cdef' }}>
          <NavItem>
            <NavLink
              className={classnames({
                active: currentActiveTab === "1",
              })}
              to="/admin/stocklist/stocks"
              tag={Link}
            >
              Stocks
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink style={{ color: 'white' }}
              className={classnames({
                active: currentActiveTab === "2",
              })}
              to="/admin/stocklist/stockgroups"
              tag={Link}
            >
              Stock Groups
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink style={{ color: 'white' }}
              className={classnames({
                active: currentActiveTab === "3",
              })}
              to="/admin/stocklist/unittype"
              tag={Link}
            >
              Unit Type
            </NavLink>
          </NavItem>
        </Nav>
        <Container className="mt--6" fluid>
          <br />
          <Row>
            <div
              className="addRow"
              style={{ position: "absolute", right: "65px" }}
            >
              <ReactTooltip
                id="stocks-Tip"
                place="left"
                type="dark"
                effect="solid"
              >
                Add New Stock
              </ReactTooltip>
              <Popup
                modal
                trigger={
                  <button
                    className="btn btn-primary btn-sm"
                    style={{ width: "135%", height: 40 }}
                    data-tip
                    data-for="stocks-Tip"
                  >
                    <i className="fas fa-plus-circle fa-1x"></i>
                  </button>
                }
              >
                {(close) => (
                  <AddStock
                    closebtn={close}
                    callbackfunction={refreshfunction}
                  />
                )}
              </Popup>
            </div>
          </Row>
          <br />
          <br />
          <br />
          <Row>
            {loading === true ? (
              <div>
                
                <ConPreLoader />
              </div>
            ) : (
              <div className="col">
                <Card>
                  <ToolkitProvider
                    data={data}
                    keyField="name"
                    columns={[
                      {
                        dataField: "edit",
                        text: "Edit",
                        sort: false,
                        formatter: stockActionFormatter,
                      },
                      {
                        dataField: "stockname",
                        text: "Stockname",
                        sort: true,
                        filter: textFilter(),
                      },
                      {
                        dataField: "unit",
                        text: "Unit",
                      },
                      {
                        dataField: "defaultrate",
                        text: "Defaultrate",
                      },
                      {
                        dataField: "productcode",
                        text: "Stockcode",
                      },
                      {
                        dataField: "hsncode",
                        text: "Hsncode",
                      },
                      {
                        dataField: "cgstpercentage",
                        text: "CGST(%)",
                      },
                      {
                        dataField: "sgstpercentage",
                        text: "SGST(%)",
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="table-responsive">
                        <BootstrapTable
                          {...props.baseProps}
                          keyField="id"
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                          filter={filterFactory()}
                          striped
                          hover
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </Card>
              </div>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, null)(StockDetails);
