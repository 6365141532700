import React from "react";
import { View, StyleSheet,Text } from "@react-pdf/renderer";
import DispatchTableHeader from "./DispatchTableHeader";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 2,
    borderWidth: 1,
    borderColor: "#bff0fd"
  },
  rows: {
    flexDirection: "row",
    alignItems: "center",
    fontStyle: "bold"
  },
});

const DispatchTable = ({ manageData }) => {

  const tableRows = manageData.salesdispatch.filter((d) => d.stockname !== "Total").map((t) => (
    <View style={styles.rows} wrap={false}>
      <Text style={{ width: "10%",textAlign: "left",paddingLeft: 8,paddingTop: 4,flexWrap: 'wrap'}} wrap={true}>{t.productcode}</Text>
      <Text style={{ width: "20%",textAlign: "left",paddingLeft: 8,paddingTop: 4,flexWrap: 'wrap'}} wrap={true}>{t.stockname}</Text>
      <Text style={{ width: "10%",textAlign: "left",paddingLeft: 8,paddingTop: 4,flexWrap: 'wrap'}} wrap={true}>{t.batch}</Text>
      <Text style={{ width: "10%",textAlign: "center",paddingLeft: 8,paddingTop: 4,flexWrap: 'wrap'}} wrap={true}>{t.location}</Text>
      <Text style={{ width: "10%",textAlign: "center",paddingRight: 8,paddingTop: 4,flexWrap: 'wrap'}} >{t.quantity}</Text>
      <Text style={{ width: "10%",textAlign: "center",paddingRight: 8,paddingTop: 4,flexWrap: 'wrap'}}>{t.rate}</Text>
      <Text style={{ width: "15%",textAlign: "center",paddingRight: 8,paddingTop: 4,flexWrap: 'wrap'}} wrap={true}>{t.cgstpercentage} / {t.sgstpercentage}</Text>
      <Text style={{ width: "15%",textAlign: "center",paddingRight: 0,paddingTop: 4,flexWrap: 'wrap'}}>{Number.parseFloat(t.total).toFixed(2)}</Text>
    </View>
  ))

  return( 
    <View style={styles.tableContainer}>
      <DispatchTableHeader />
      {tableRows}
    </View>
  )
};

export default DispatchTable;