import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 50,
  },
  rows: {
    flexDirection: "row",
    // borderBottomColor: "#b8b894",
    // borderBottomWidth: 1,
    alignItems: "center",
    fontStyle: "bold"
  },
  openingbalance: {
    width: "25%",
    textAlign: "center",
    paddingTop: 4,
  },
  totalinvoice: {
    width: "25%",
    textAlign: "center",
    paddingTop: 4,
  },
  totalreceive: {
    width: "25%",
    textAlign: "center",
    paddingTop: 4,
  },
  closingbalance: {
    width: "25%",
    textAlign: "center",
    paddingTop: 4,
  }
});

const PaymentRow = ({ taskData }) => 
{
   const filterdata = taskData.filter((i) => { return i.transactiontype === "invoice" || i.transactiontype === "received" });
   const totalinvoiced = filterdata.reduce(function(i, record) { return (record.transactiontype !== 'invoice') ? i : i + parseInt(record.Amount, 0) }, 0);
   const totalreceived = filterdata.reduce(function(i, record) { return (record.transactiontype !== 'received') ? i : i + parseInt(record.Amount, 0) }, 0);
   const ob = taskData.openingbalance
   const cb = taskData.closingbalance


  return( 
    <React.Fragment>
      <View style={styles.rows} wrap={false}>
        <Text style={styles.openingbalance}>{ob}</Text>
        <Text style={styles.totalinvoice}>{totalinvoiced}</Text>
        <Text style={styles.totalreceive}>{totalreceived}</Text>
        <Text style={styles.closingbalance}>{cb}</Text>
      </View>
    </React.Fragment>
  )
};

export default PaymentRow;
