import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTask from "./InvoiceFolder/InvoiceTaskName";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: -15,
    borderWidth: 1,
    borderColor: "#bff0fd",
  }
});

const InvoiceTable = ({ manageData }) => {
  const detailsInvoice = manageData.map((d) => <InvoiceTask task={d.TaskDetails} invoiceid={d.invoiceno} invoicedate={d.invoicedate} discount={d.discount} invoice_amount={d.invoice_amount} /> )

  return( 
    <View style={styles.tableContainer} >
      <InvoiceTableHeader />
      {detailsInvoice}
    </View>
  )
};

export default InvoiceTable;