import React, { Component } from "react";
import MydayRow from "./MydayRow";
import { Container, Card, Row, Table } from "reactstrap";
import Header from "./../../../components/Headers/SimpleHeader";
import Taskdetails from "./TaskDetails";
import ConPreLoader from "../../../components/Loader/Con_PreLoader";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../config";
import "./MyDay.css";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import Addtask from "../Tasklist/Addtask";
import ReactTooltip from "react-tooltip";

const authApi = config.get("apiDomain");
class MyDay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TodayDate: new Date(),
      items: [],
      CurrentTaskName: "",
      Priority: "",
      Status: "",
      currentTask: false,
      currentDetails: "",
      subtaskdetails: "",
      playTask: true,
      loading: true,
      Checkdayfilter: "1",
      dayreport: true,
      componentwillmount: false,
      assignee: "",
      Enabletask: false,
    };
  }

  async componentDidMount() {
    fetch(
      `${authApi}sp_mydaytask/?user_name=${this.props.username}&status=ToDo,InProgress,Pending`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        this.setState(
          {
            items: json.sort((a, b) => a.id - b.id),
            loading: false,
          },
          function () {
            this.InprogressFilter();
          }
        );
      });
  }

  InprogressFilter = () => {
    this.state.items.forEach((data) => {
      if (data.status === "InProgress") {
        this.setState({
          playTask: false,
        });
      }
    });
  };

  MydayRowtaskOpen = (x) => {
    
    this.setState({
      dayreport: x.report,
      playTask: x.playTaskButton,
      currentTask: !this.state.currentTask,
      currentDetails: x,
    });
  };

  TaskdetailstaskOpen = (x) => {
    this.updateComponent("TaskdetailstaskOpen");
    this.setState({
      currentTask: !this.state.currentTask,
      currentDetails: x,
      dayreport: true,
      playTask: !x.playTaskButton,
    });
  };

  updateComponent = (Position) => {
    if (Position === "AddTask") {
      this.EnabletasktoCreate();
    }
    fetch(
      `${authApi}sp_mydaytask/?user_name=${this.props.username}&status=ToDo,InProgress,Pending`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        this.setState(
          {
            items: json.sort((a, b) => a.id - b.id),
            loading: false,
          },
          function () {
            this.UpdateFilterInprogress();
          }
        );
      });
  };

  UpdateFilterInprogress = () => {
    let length = this.state.items.length;
    let count = 0;
    this.state.items.forEach((data) => {
      count = count + 1;
      if (data.status === "InProgress") {
        this.setState({
          playTask: false,
        });
      } else {
        if (count === length) {
          this.setState({
            report: true,
          });
        }
      }
    });
  };

  EnabletasktoCreate = () => {
    this.setState({
      Enabletask: !this.state.Enabletask,
    });
  };

  render() {
    const ChecklistData = [...this.state.items]
      .reverse()
      .sort((a, b) => b.favourites - a.favourites)
      .map((item) => (
        <MydayRow
          key={item.id}
          totalsubtask={item}
          taskOpenButton={this.MydayRowtaskOpen}
          isDisabled={this.state.playTask}
          report={this.state.dayreport}
          rerender={this.updateComponent}
        />
      ));
    return (
      <React.Fragment>
        <Header />
        {this.state.Enabletask === false ? (
          <Container className="mt--7" fluid>
            <br />
            {this.state.dayreport ? (
              <div className="addpurchasestock" style={{ marginTop: 5 }}>
                <NavLink
                  style={{ marginTop: 6 }}
                  to="/admin/requestpurchasestock/requestpurchasestock"
                  tag={Link}
                >
                  <button
                    className="btn btn-primary btn-sm"
                    style={{ width: "135%", height: 40 }}
                    data-tip
                    data-for="completed-Tip"
                  >
                   
                    <i class="fas fa-cart-plus fa-1x"></i>
                  </button>
                </NavLink>
                <ReactTooltip
                  id="completed-Tip"
                  place="right"
                  type="dark"
                  effect="solid"
                >
                  Add Purchase Request
                </ReactTooltip>
              </div>
            ) : null}
            {this.state.dayreport ? (
              <div
                className="addsalesstock"
                style={{ marginTop: 5, marginRight: -5 }}
              >
                <NavLink
                  style={{ marginTop: 6 }}
                  to="/admin/salestock/salesrequest"
                  tag={Link}
                >
                  <button
                    className="btn btn-primary btn-sm"
                    style={{ width: "135%", height: 40 }}
                    data-tip
                    data-for="salesrequest-Tip"
                  >
                    
                    <i class="fas fa-comment-dollar fa-1x"></i>
                  </button>
                </NavLink>
                <ReactTooltip
                  id="salesrequest-Tip"
                  place="left"
                  type="dark"
                  effect="solid"
                >
                  Add Quotation
                </ReactTooltip>
              </div>
            ) : null}
            {this.state.dayreport ? (
              <div className="mydayaddtask" style={{ marginTop: 15 }}>
                <ReactTooltip
                  id="addTask-Tip"
                  place="left"
                  type="dark"
                  effect="solid"
                >
                  Add New Task
                </ReactTooltip>
                <button
                  className="btn btn-primary btn-sm"
                  style={{ width: "135%", height: 40 }}
                  data-tip
                  data-for="addTask-Tip"
                  onClick={this.EnabletasktoCreate}
                >
                  <i className="fas fa-plus-circle fa-1x"></i>
                </button>
              </div>
            ) : null}
            {this.state.dayreport ? (
              <div>
                <ReactTooltip
                  id="achievement-Tip"
                  place="right"
                  type="dark"
                  effect="solid"
                >
                  Go to Achieved
                </ReactTooltip>
                <Row>
                  <NavLink
                    style={{ marginTop: 10 }}
                    to="/admin/management/dayworktime"
                    tag={Link}
                  >
                    <button
                      className="btn btn-primary btn-sm"
                      style={{ width: "135%", height: 40 }}
                      data-tip
                      data-for="achievement-Tip"
                    >
                      <i className="fa fa-calendar" />
                    </button>
                  </NavLink>
                </Row>
              </div>
            ) : null}
            {this.state.currentTask ? (
              <Taskdetails
                taskOpenButton={this.TaskdetailstaskOpen}
                isDisabled={this.state.playTask}
                currentTaskDetails={this.state.currentDetails}
                rerender={this.updateComponent}
              />
            ) : null}
            <br />
            {this.state.loading === true ? (
              <div>
             
                <ConPreLoader />
              </div>
            ) : (
              <Row style={{ marginTop: -5 }}>
                <div className=" col">
                  <Card className="shadow">
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th className="text-center"> Action </th>
                          <th className="text-center"> Client Name </th>
                          <th className="text-center"> Plan Type</th>
                          <th className="text-center"> Task </th>
                          <th className="text-center"> SubTask </th>
                          <th className="text-center"> Status </th>
                          <th className="text-center"> Time </th>
                        </tr>
                      </thead>
                      <tbody>{ChecklistData}</tbody>
                    </Table>
                  </Card>
                </div>
              </Row>
            )}
          </Container>
        ) : (
          <Container className="mt--7" fluid>
            <Addtask
              assigneeName={this.state.assignee}
              calbackfunction={this.updateComponent}
              data="Myday"
            
            />
          </Container>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    username: state.username,
  };
};
export default connect(mapStateToProps, null)(MyDay);
