import React, { Component } from 'react';

class ClientpanelInvoicerow extends Component {
    render() {
        return (
                <tbody>
                  <tr>
                    <td>{this.props.invoice.date}</td>
                    <td>{this.props.invoice.deliverynoteid}</td>  
                    <td>{this.props.invoice.balancedue}</td>
                    <td>{this.props.invoice.invoicestatus}</td>
                  </tr>
                </tbody>
        );
    }
}

export default ClientpanelInvoicerow;