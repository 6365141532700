import React, { useEffect, useState, useMemo } from "react";
import { Container, Row, NavLink, Form, Table, Button } from "reactstrap";
import { Link } from "react-router-dom";
import TableContainerExpand from "../Filters/TableContainerExpand";
import TableContainerExpandTeam from "../Filters/TableContainerExpandTeam";
import { SelectColumnFilter } from "../Filters/filters";
import ConPreLoader from "../../../components/Loader/Con_PreLoader";
import AccountPopup from "../ReadyToBill/AccountPopup";
import Popup from "reactjs-popup";
import Addsubtask from "./Addsubtask";
import "./Todotask.css";
import TeamEditRow from "./TeamEditRow";
import AddTime from "./AddTime";
import consoleFunction from "../../../Helpers";
import Editsubtask from "./Editsubtask";
import axios from "axios";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../config";
import getDateTimeField from "../../../Utils";
import ReactTooltip from "react-tooltip";
import Header from "components/Headers/SimpleHeader";
import { CurrentLocation } from "../../../components/loginRedux/loginReducers";
import store from "../../../components/loginRedux/Store";

const authApi = config.get("apiDomain");
let adimicheck = "0";
let assigneeName = "";
let subtask = [],
  maintaskid = "",
  plantype = "",
  maintask = "";

function Teamtask(props) {
  const [data, setData] = useState([]);
  const [q, setQ] = useState("");
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(true);
  const [btnClass, setBtnClass] = useState("btn btn-danger btn-sm");
  const [billingpanel, showBillingpanel] = useState(false);

  useEffect(
    () => {
      const doFetch = async () => {
        store.dispatch({
          type: CurrentLocation,
          data: props.location.pathname,
        });
        const response = await fetch(
          `${authApi}tasklistfilter/?isdeleted=false&status__in=ToDo%2CPending%2CInProgress%2CReadyToBill`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${props.token}`,
            },
          }
        );
        const body = await response.json();
        if (body.length === 0) {
          setData(body);
          setLoading(false);
        } else {
          checkadmin();
          CalculateTaskTime(body.reverse());
          UpdateClientName(body);
        }
      };
      doFetch();
    },
    // eslint-disable-next-line
    []
  );

  function getFilterButton(event) {
    if (event === true) {
      setChecked(false);
      setBtnClass("btn btn-success btn-sm");
    } else {
      setChecked(true);
      setBtnClass("btn btn-danger btn-sm");
    }
  }

  function checkadmin() {
    const doFetch = async () => {
      assigneeName = props.username;
      let currentuser = props.allusers.filter((userdetails) => {
        return userdetails.name === props.username;
      });
      adimicheck = currentuser[0].isadmin;
    };
    doFetch();
  }

  function refreshfunction() {
    setLoading(true);
    const doFetch = async () => {
      const response = await fetch(
        `${authApi}tasklistfilter/?isdeleted=false&status__in=ToDo%2CPending%2CInProgress%2CReadyToBill`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${props.token}`,
          },
        }
      );
      const body = await response.json();
      if (body.length === 0) {
        setData(body);
        setLoading(false);
      } else {
        checkadmin();
        CalculateTaskTime(body.reverse());
        UpdateClientName(body);
      }
    };
    doFetch();
  }

  function CalculateTaskTime(body) {
    body.forEach((e) => {
      e.time = calculateSubtaskTime(e);
    });
  }

  function calculateSubtaskTime(e) {
    let timeSub = "00:00";
    let timetotal = 0,
      mins = 0,
      times = 0,
      stringtime = 0,
      h = 0,
      m = 0;

    e.subtask.forEach((st) => {
      if (st.isdeleted === false) {
        st.time = calculateSecondLayerSubtaskTime(st);
        if (st.time === 0) {
          timeSub = "00:00";
        } else {
          stringtime = st.time;
          times = stringtime.split(":");
          mins = times[0] * 60 + +times[1];
          timetotal += mins;
          h = (timetotal / 60) | 0;
          m = timetotal % 60;
          function z(n) {
            return (n < 10 ? "0" : "") + n;
          }
          timeSub = z(h) + ":" + z(m);
        }
      }
    });
    return timeSub;
  }

  function calculateSecondLayerSubtaskTime(st2) {
    let total = 0,
      mins = 0,
      times = 0,
      stringtime = 0,
      h = 0,
      m = 0,
      subTime = "00:00";
    st2.subtaskTime.forEach((d) => {
      stringtime = d.time;
      times = stringtime.split(":");
      mins = times[0] * 60 + +times[1];
      total += mins;
      h = (total / 60) | 0;
      m = total % 60;
      function z(n) {
        return (n < 10 ? "0" : "") + n;
      }
      subTime = z(h) + ":" + z(m);
    });
    return subTime;
  }

  function UpdateClientName(body) {
    callback(body);
  }

  function callback(body) {
    const details = body.sort((a, b) => b.status.localeCompare(a.status));
    setData(details);
    setLoading(false);
  }

  function search(rows) {
    const cols = rows[0] && Object.keys(rows[0]);
    return rows.filter((r) =>
      cols.some((c) =>
        r[c]
          ? r[c].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          : null
      )
    );
  }

  function callbackfunction() {
    setLoading(true);
    const doFetch = async () => {
      const response = await fetch(
        `${authApi}tasklistfilter/?isdeleted=false&status__in=ToDo%2CPending%2CInProgress%2CReadyToBill`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${props.token}`,
          },
        }
      );
      const body = await response.json();
      CalculateTaskTime(body.reverse());
      UpdateClientName(body);
    };
    doFetch();
  }

  function deleteRow(d) {
    let datetime_field = getDateTimeField();
    const link = `${authApi}subtasks/${d.id}/`;
    axios({
      method: "put",
      url: link,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${props.token}`,
      },
      data: {
        date: d.date,
        subtask: d.subtask,
        assignto: d.assignto,
        priority: d.priority,
        status: d.status,
        time: d.time,
        isdeleted: true,
        completed_date: d.completed_date,
        task_starttime: d.task_starttime,
        tasklist: d.tasklist,
        modifiedby: this.props.username,
        modifiedat: datetime_field,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          callbackfunction();
        }
      })
      .catch((err) => {
        consoleFunction(
          "Delete subtask in teamtask error",
          err.response.request.response
        );
      });
  }

  function DownloadData(json) {
    const items = json;
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(items[0]).filter(
      (field) =>
        field !== "id" &&
        field !== "subtask" &&
        field !== "isdeleted" &&
        field !== "task_starttime" &&
        field !== "time"
    );
    let csv = items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(header.join(","));
    csv = csv.join("\r\n");
    const ConvertData = csv;
    const element = document.createElement("a");
    const file = new Blob([ConvertData], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "TeamTask.csv";
    document.body.appendChild(element);
    element.click();
  }

  const showbillingdetails = (editdata) => {
    subtask = editdata.subtask;
    maintaskid = editdata.id;
    plantype = editdata.plantype;
    maintask = editdata;
    showBillingpanel(true);
  };

  const showtaskdetails = () => {
    showBillingpanel(false);
  };

  const renderRowSecondSubComponent = (row) => {
    return (
      <React.Fragment>
        <br />
        <h6 className="thead-light">Working Details :</h6>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th className="text-center">Date</th>
              <th className="text-center">StartTime</th>
              <th className="text-center">EndTime</th>
              <th className="text-center">Time</th>
              <th className="text-center">Comments</th>
            </tr>
          </thead>
          {row.original.subtaskTime.map((r, id) => {
            return (
              <tbody key={id}>
                <tr>
                  <td className="text-center">{r.date}</td>
                  <td className="text-center">{r.start_time}</td>
                  <td className="text-center">{r.endtime}</td>
                  <td className="text-center">{r.time}</td>
                  <td className="text-center">{r.comments}</td>
                </tr>
              </tbody>
            );
          })}
        </Table>
        <br />
      </React.Fragment>
    );
  };

  const columnSecondLayer = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander",
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <i className="ni ni-bold-down" />
            ) : (
              <i className="ni ni-bold-right" />
            )}
          </span>
        ),
      },
      {
        Header: "Actions",
        Cell: (row) => {
          const row_data = row.row.original;
          return (
            <React.Fragment>
              {adimicheck === "1" ? (
                <Popup
                  modal
                  trigger={
                    <button
                      className="btn btn-primary btn-sm"
                      style={{ marginRight: "5px" }}
                    >
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    </button>
                  }
                >
                  {(close) => (
                    <AddTime
                      EditRowSend={row_data}
                      closebtn={close}
                      functionreload={callbackfunction}
                    />
                  )}
                </Popup>
              ) : null}
              <Popup
                modal
                trigger={
                  <button
                    className="btn btn-primary btn-sm"
                    style={{ marginRight: "5px" }}
                  >
                    <i className="fa fa-edit" aria-hidden="true"></i>
                  </button>
                }
              >
                {(close) => (
                  <Editsubtask
                    EditRowSend={row_data}
                    closebtn={close}
                    functionreload={callbackfunction}
                  />
                )}
              </Popup>
              {adimicheck === "1" ? (
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => deleteRow(row_data)}
                  style={{ marginRight: "5px" }}
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </button>
              ) : null}
            </React.Fragment>
          );
        },
      },
      {
        Header: "Subtask",
        accessor: "subtask",
      },
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Assignto",
        accessor: "assignto",
      },
      {
        Header: "Time",
        accessor: "time",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Priority",
        accessor: "priority",
      },
    ],
    // eslint-disable-next-line
    []
  );

  const renderRowSubComponent = (row) => {
    const rowdata = row.original.subtask.filter(function (item) {
      return item.isdeleted !== true;
    });
    return (
      <React.Fragment>
        <br />
        <h5 className="thead-light">Subtask Details :</h5>
        <TableContainerExpandTeam
          columns={columnSecondLayer}
          data={rowdata}
          renderRowSubComponent={renderRowSecondSubComponent}
        />
        <br />
      </React.Fragment>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander",
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <i className="ni ni-bold-down" />
            ) : (
              <i className="ni ni-bold-right" />
            )}
          </span>
        ),
      },
      {
        Header: "Actions",
        Cell: (row) => {
          const row_data = row.row.original;
          return (
            <React.Fragment>
              <Popup
                modal
                data-backdrop="static"
                trigger={
                  <button
                    className="btn btn-primary btn-sm"
                    style={{ marginRight: "5px" }}
                  >
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                  </button>
                }
              >
                {(close) => (
                  <Addsubtask
                    EditRowSend={row_data.id}
                    Totaltask={row_data}
                    ClientId={row_data.client}
                    AssigneeName={assigneeName}
                    closebtn={close}
                    functionreload={callbackfunction}
                  />
                )}
              </Popup>
              {adimicheck === "1" ? (
                <Popup
                  modal
                  trigger={
                    <button
                      className="btn btn-primary btn-sm"
                      style={{ marginRight: "5px" }}
                    >
                      <i className="fa fa-edit" aria-hidden="true"></i>
                    </button>
                  }
                >
                  {(close) => (
                    <TeamEditRow
                      EditRowSend={row_data}
                      closebtn={close}
                      functionreload={callbackfunction}
                    />
                  )}
                </Popup>
              ) : null}
              {row_data.taskproject === null &&
              (row_data.status === "ReadyToBill" ||
                (row_data.status === "Pending" &&
                  !(
                    row_data.plantype === "16Stadd" ||
                    row_data.plantype === "03Structural Plan" ||
                    row_data.plantype === "20EDCR"
                  ))) ? (
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => showbillingdetails(row_data)}
                >
                  <i className="ni ni-cart"></i>
                </button>
              ) : null}
            </React.Fragment>
          );
        },
      },
      {
        Header: "ClientName",
        accessor: "clientname",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
        ReferencePage: "TeamTask",
      },
      {
        Header: "CompanyName",
        accessor: "companyname",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
        ReferencePage: "TeamTask",
      },
      {
        Header: "PlanType",
        accessor: "plantype",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
        ReferencePage: "TeamTask",
      },
      {
        Header: "Task",
        accessor: "task",
        disableSortBy: true,
        Cell: (row) => {
          return <span data-tip={row.value}>{row.value}</span>;
        },
      },
      {
        Header: "AssignTo",
        accessor: "assignto",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
        ReferencePage: "TeamTask",
      },
      {
        Header: "Time",
        accessor: "time",
        disableSortBy: true,
      },
      {
        Header: "Status",
        accessor: "status",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
        ReferencePage: "TeamTask",
      },
      {
        Header: "ProjectName",
        accessor: "projectname",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
        ReferencePage: "TeamTask",
      },
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <React.Fragment>
      <Header />
      {billingpanel === false ? (
        <Container className="mt--7" fluid>
          <br />
          <Row>
            <ReactTooltip
              id="billableTask-Tip"
              place="right"
              type="dark"
              effect="solid"
            >
              Go to Billable Tasks
            </ReactTooltip>
            <NavLink
              style={{ marginTop: 8 }}
              to="/admin/filter/teamfilter"
              tag={Link}
            >
              <button
                className="btn btn-primary btn-sm"
                data-tip
                data-for="billableTask-Tip"
                style={{ width: "135%", height: 40 }}
              >
                <i className="fa fa-arrow-right"></i>
              </button>
            </NavLink>
            <NavLink style={{ marginTop: 8, marginLeft: -5 }}>
              <button
                className="btn btn-primary btn-sm"
                style={{ width: "135%", height: 40 }}
                onClick={(e) => {
                  refreshfunction();
                }}
              >
                <i className="fa fa-refresh"></i>
              </button>
            </NavLink>
            <NavLink
              style={{ marginTop: 14, marginLeft: -5 }}
              to="/admin/kanban/teamtaskkanbanboard"
              tag={Link}
            >
              <Button className="mr-4" color="dark" size="sm">
                Kanban View
              </Button>
            </NavLink>
            <Form
              className="searchForm"
              style={{ marginTop: 12, right: "137px" }}
            >
              <input
                className="searchBox "
                type="search"
                placeholder="Search"
                value={q}
                onChange={(e) => setQ(e.target.value)}
              />
            </Form>
            <div
              className="addRow"
              style={{ position: "absolute", right: "85px", marginTop: 2 }}
            >
              <NavLink
                style={{ marginTop: 8 }}
                to="/admin/filter/Completedteamfilter"
                tag={Link}
              >
                <button
                  className="btn btn-primary btn-sm"
                  style={{ width: "135%", height: 40 }}
                  data-tip
                  data-for="completed-Tip"
                >
                  <i className="fa fa-list-alt" aria-hidden="true"></i>
                </button>
              </NavLink>
              <ReactTooltip
                id="completed-Tip"
                place="left"
                type="dark"
                effect="solid"
              >
                Go to Complete Tasks
              </ReactTooltip>
            </div>
            <div
              className="invoicegenerate"
              style={{
                position: "absolute",
                right: "50px",
                marginTop: 14,
                marginRight: -4,
              }}
            >
              <button
                className={btnClass}
                style={{ width: "135%", height: 40 }}
                onClick={(e) => getFilterButton(checked)}
              >
                <i className="fa fa-filter" aria-hidden="true"></i>
              </button>
            </div>
          </Row>
          <br />
          {loading === true ? (
            <div>
              <ConPreLoader />
            </div>
          ) : (
            <Row>
              <div className=" col">
                <ReactTooltip place="top" type="dark" effect="float" />
                <TableContainerExpand
                  columns={columns}
                  data={search(data)}
                  renderRowSubComponent={renderRowSubComponent}
                  checkedToggle={checked}
                />
              </div>
            </Row>
          )}
          <br />
          <button
            className="Export btn-info"
            id="download"
            style={{ marginRight: "5px" }}
            onClick={() => DownloadData(data)}
          >
            Export
          </button>
          <br />
          <br />
        </Container>
      ) : (
        <AccountPopup
          closebtn={showtaskdetails}
          EditRowSend={subtask}
          maintaskid={maintaskid}
          functionreload={callbackfunction}
          itsmytask="0"
          plantype={plantype}
          maintask={maintask}
        />
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    allusers: state.allusers,
    username: state.username,
  };
};
export default connect(mapStateToProps, null)(Teamtask);
