import React from "react";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  Label,
  CardHeader,
  Table,
  Input,
  NavLink,
} from "reactstrap";
import config from "react-global-configuration";
import "../../../config";
import Header from "../../../components/Headers/SimpleHeader";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import axios from "axios";
const authApi = config.get("apiDomain");
let stockid = 0,
  StoreprocData = [],
  Client = [],
  Company = [];
class Addquotation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quotationdetails: [],
      clientname: [],
      companyname: [],
      selectedclientname: "",
      selectedcompanyname: "",
      selectedclientid: "",
      selectedcompanyid: "",
      stockdetails: [],
      Inventorydata: [],
      Inventorytransactiontotalqty: 0,
      salesordertotalqty: 0,
      purchaseordertotalqty: 0,
      totalqty: 0,
      ValidQuantity: "none",
      count: 0,
      ValidateCompany: "none",
      ValidateClient: "none",
      userid: 0,
    };
  }
  async componentDidMount() {
    const response = await axios({
      method: "post",
      url: `${authApi}getavailableqtytemplate/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    });
    let body = await response.json();
    const companyfulldetails = await this.getCompanydetails();
    Company = companyfulldetails;
    Client = this.props.clientdetails;
    let stocknamedetails = await this.getTotalStockdetails();
    this.setState({
      stockdetails: stocknamedetails,
      Inventorydata: body,
    });
    let totalclientname = this.props.clientdetails.map((q) => q.company_name);
    let clientnamedetails = totalclientname.filter(
      (q, idx) => totalclientname.indexOf(q) === idx
    );
    let totalcompany = companyfulldetails.map((q) => q.companyname);
    let companydetails = totalcompany.filter(
      (q, idx) => totalcompany.indexOf(q) === idx
    );
    let data = {
      id: 9999,
      stockname: "",
      productcode: "",
      inventoryqty: 0,
      purchaseorderqty: 0,
      salesorderqty: 0,
      availableqty: 0,
      aqantity: 0,
    };
    this.setState({
      quotationdetails: [...this.state.quotationdetails, data],
      clientname: clientnamedetails,
      companyname: companydetails,
    });

    this.getUserDetails();

  }

  getTotalStockdetails = async () => {
    let response = await fetch(`${authApi}stocks/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());
    return response;
  };


  getCompanydetails = async () => {
    const response = await fetch(`${authApi}companyfilter/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    });
    const body = await response.json();
    return body;
  };

  addRequest = () => {
    let Totaldata = this.state.quotationdetails;
    stockid = stockid + 1;
    let data = {
      id: stockid,
      stockname: "",
      productcode: "",
      inventoryqty: 0,
      purchaseorderqty: 0,
      salesorderqty: 0,
      availableqty: 0,
      quantity: 0,
    };
    Totaldata = [...Totaldata, data];
    let sortingarray = Totaldata.sort((a, b) => (a.id > b.id ? 1 : -1));
    this.setState({
      quotationdetails: sortingarray,
    });
  };

  removeRequest = (request) => {
    let remainingdata = this.state.quotationdetails.filter(
      (data) => data.id === request.id
    );
    this.setState({
      quotationdetails: remainingdata,
    });
  };

  getUserDetails = async () => {
    const response = await fetch(
      `${authApi}userfilter/?name=${this.props.name}&isapproved=1`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    ).then((res) => res.json());
    this.setState({
      userid: response[0].id,
    });
  };

  selectClient = (event) => {
    this.setState({
      selectedclientname: event.target.value,
    });
    Client.forEach((element) => {
      if (element.company_name === event.target.value) {
        this.setState({
          selectedclientid: element.id,
        });
      }
    });
  };

  selectCompany = (event) => {
    this.setState({
      selectedcompanyname: event.target.value,
    });
    Company.forEach((element) => {
      if (event.target.value === element.companyname) {
        this.setState({
          selectedcompanyid: element.id,
        });
      }
    });
  };

  selectStock = (event, data) => {
    let productcode = 0,
      Inventoryqty = 0,
      saleorderqty = 0,
      purchaseorderqty = 0,
      totalqty = 0;
    this.state.stockdetails.forEach((stock) => {
      if (stock.stockname === event.target.value) {
        productcode = stock.productcode;
      }
    });

    this.state.Inventorydata.forEach((inventory) => {
      if (inventory.StockName === event.target.value) {
        Inventoryqty = inventory.InventoryQty;
        saleorderqty = inventory.SOQty;
        purchaseorderqty = inventory.POQty;
        totalqty = inventory.InventoryQty;
      }
    });

    this.state.quotationdetails.forEach((element) => {
      if (element.id === data.id) {
        element.inventoryqty = Inventoryqty;
        element.purchaseorderqty = purchaseorderqty;
        element.salesorderqty = saleorderqty;
        element.availableqty = totalqty;
        element.stockname = event.target.value;
        element.productcode = productcode;
      }
    });

    this.setState({
      count: this.state.count + 1,
    });
 
  };

  getQuantity = (event, data) => {
    this.state.quotationdetails.forEach((element) => {
      if (element.id === data.id) {
        this.setState({
          count: this.state.count + 1,
        });
        element.quantity = event.target.value;
      }
    });
  };

  formValidation = () => {
    this.StoreprocDataPreparetion();
    this.setState(
      {
        ValidateCompany:
          this.state.selectedcompanyname === "" ? "1px solid red" : "none",
        ValidateClient:
          this.state.selectedclientname === "" ? "1px solid red" : "none",
      },
      function () {
        this.createquotations();
      }
    );
  };

  StoreprocDataPreparetion = () => {
    let stockquantity = this.state.quotationdetails.filter(
      (item) => item.id !== 9999
    );
    stockquantity.forEach((quotation) => {
      let newquotationdetails = {
        stockname: quotation.stockname,
        productcode: quotation.productcode,
        quantity: quotation.quantity,
      };
      StoreprocData = [...StoreprocData, newquotationdetails];
    });
  };

  createquotations = async () => {

    if (
      this.state.ValidateClient === "none" ||
      this.state.ValidateCompany === "none"
    ) {
      await axios({
        method: "post",
        url: `${authApi}sp_createquotationwithclientdetails/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          companyid: this.state.selectedcompanyid,
          clientid: this.state.selectedclientid,
          userid: this.state.userid,
          quotation: StoreprocData,
        },
      })
        .then((res) => {
          console.log(res);
          if (res.status === 201) {
            StoreprocData = [];
            this.props.history.push("/admin/salestock/salesrequest");
          }
        })
        .catch((err) => {
          StoreprocData = [];
          alert("error purchase order", err.response.request.response);
        });
    }
  };

  render() {
    let totalstock = this.state.stockdetails.map((q) => q.stockname);
    let orderstocks = totalstock.filter(
      (q, idx) => totalstock.indexOf(q) === idx
    );
    return (
      <React.Fragment>
        <Header />
        <Container className="mt--7" fluid>
          <form>
            <Row>
              <Col md="11">
                <CardHeader
                  style={{
                    backgroundColor: "transparent",
                    padding: 10,
                    marginTop: 50,
                  }}
                >
                  <h3 className="mb-0">
                    <i style={{ marginRight: 5 }} class="fas fa-cart-plus"></i>
                    Create New Quotation
                  </h3>
                </CardHeader>
              </Col>
              <Col md="1">
                <div style={{ marginTop: 35, float: "right", marginRight: 30 }}>
                  <NavLink
                    style={{ marginTop: 6 }}
                    to="/admin/salestock/salesrequest"
                    tag={Link}
                  >
                    <button
                      className="btn btn-primary btn-sm"
                      style={{ width: "135%", height: 40 }}
                      data-tip
                      data-for="completed-Tip"
                    >
                      Back
                    </button>
                  </NavLink>
                  <ReactTooltip
                    id="completed-Tip"
                    place="left"
                    type="dark"
                    effect="solid"
                  >
                    Go Back to MyDay
                  </ReactTooltip>
                </div>
              </Col>
            </Row>
          </form>
        </Container>
        <div className="card-wrapper" style={{ marginLeft: 20 }}>
          <Row style={{ marginTop: 20 }}>
            <Col md="2" style={{ marginTop: 12, marginLeft: 20 }}>
              <Label>company</Label>
            </Col>
            <Col md="3">
              <Input
                type="select"
                value={this.state.selectedcompanyname}
                style={{ border: this.state.ValidateCompany }}
                onChange={this.selectCompany}
              >
                <option value="Select Company">Select Company</option>
                {this.state.companyname.map((item, key) => (
                  <option key={key} value={item}>
                    {item}
                  </option>
                ))}
              </Input>
              {this.state.ValidateCompany !== "none" ? (
                <h6 style={{ color: "red" }}>Please Select Company</h6>
              ) : null}
            </Col>
            <Col md="2" style={{ marginTop: 12, marginLeft: 20 }}>
              <Label>ClientName</Label>
            </Col>
            <Col md="3">
              <Input
                type="select"
                value={this.state.selectedclientname}
                style={{
                  border: this.state.ValidateClient,
                }}
                onChange={this.selectClient}
              >
                <option value="Select Client">Select Client</option>
                {this.state.clientname
                  .sort((a, b) => a.localeCompare(b))
                  .map((item, key) => (
                    <option key={key} value={item}>
       
                      {item}
                    </option>
                  ))}
              </Input>
              {this.state.ValidateClient === "none" ? null : (
                <h6 style={{ color: "red" }}>Please Select Client</h6>
              )}
            </Col>
          </Row>
        </div>
        <div style={{ marginLeft: 30 }}>
          <Row style={{ margintLeft: 50, marginTop: 20 }}>
            <div className=" col">
              <Card>
                <Table className="align-items-center table-flush" responsive-sm>
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center">ACTIONS</th>
                      <th className="text-center">STOCKNAME</th>
                      <th className="text-center">PRODUCTCODE</th>
                      <th className="text-center">AVAI.QTY(INV+PO-SO)</th>
                      <th className="text-center">QUANTITY</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.quotationdetails.map((Request) => (
                      <tr>
                        <td className="text-center">
                          {Request.id === 9999 ? (
                            <button
                              className="btn btn-primary btn-sm"
                              style={{ marginRight: "5px" }}
                              onClick={this.addRequest}
                            >
                              <i className="fas fa-plus-circle fa-1x" />
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary btn-sm"
                              style={{ marginRight: "5px" }}
                              onClick={() => this.removeRequest(Request)}
                            >
                              <i className="fa fa-trash" />
                            </button>
                          )}
                        </td>
                        <td className="text-center">
                          {Request.id !== 9999 ? (
                            <Input
                              type="select"
                              value={Request.stockname}
                              style={{ border: this.state.ValidStockname }}
                              onChange={(event) =>
                                this.selectStock(event, Request)
                              }
                            >
                              <option value="Select All">Select Stock</option>
                              {orderstocks.map((item, key) => (
                                <option key={key} value={item}>
                               
                                  {item}
                                </option>
                              ))}
                            </Input>
                          ) : (
                            <label></label>
                          )}
                        </td>
                        {Request.id !== 9999 ? (
                          <td className="text-center">
                            <Input
                              type="option"
                              list="nickname-list"
                              style={{ border: "1px solid #dee2e6" }}
                              className="form-control-alternative"
                              value={Request.productcode}
                              onChange={this.selectProductCode}
                            />
                            <datalist id="nickname-list">
                              {this.state.stockdetails.map((item, name) => (
                                <option key={name} value={item.productcode} />
                              ))}
                            </datalist>
                          </td>
                        ) : (
                          <td className="text-center"></td>
                        )}
                        <td className="text-center">
                          {Request.id !== 9999 ? (
                            <Label>
                              {Request.inventoryqty +
                                " + " +
                                Request.purchaseorderqty +
                                " - " +
                                Request.salesorderqty}
                              ={" "}
                              <button
                                className="btn btn-sm btn-default"
                                type="button"
                              >
                                {Request.availableqty}
                              </button>
                            </Label>
                          ) : (
                            <label></label>
                          )}
                        </td>
                        <td className="text-center">
                          {Request.id !== 9999 ? (
                            <Input
                              type="text"
                              style={{
                                fontSize: "16px",
                                border: this.state.ValidQuantity,
                                textAlign: "center",
                              }}
                              value={Request.quantity}
                              onChange={(event) =>
                                this.getQuantity(event, Request)
                              }
                            />
                          ) : (
                            <label></label>
                          )}
                          {this.state.ValidQuantity !== "none" ? (
                            <h5 style={{ color: "#fb6340" }}>
                              Please enter stockquantity
                            </h5>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Row>
                  <Col md="10"></Col>
                  <Col md="1">
                    <button
                      id="buttonsave"
                      className="btn btn-dark"
                      style={{
                        marginTop: 2,
                        widht: 20,
                        height: 40,
                        left: 20,
                        margintLeft: 20,
                      }}
                      onClick={this.formValidation}
                    >
                      Submit
                    </button>
                  </Col>
                  <Col md="1"></Col>
                </Row>
              </Card>
            </div>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
    name: state.name,
    clientdetails:state.clientdetails
  };
};
export default connect(mapStateToProps, null)(Addquotation);
