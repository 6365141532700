import React, { Component } from "react";
import moment from "moment";
import axios from "axios";
import { Row, Col, Input, Container, Card, Table } from "reactstrap";
import config from "react-global-configuration";
import "../../../../config";
import { connect } from "react-redux";
import { AllClientDetails } from "../../../../components/loginRedux/loginReducers";
import store from "../../../../components/loginRedux/Store";
import ReactTooltip from "react-tooltip";
import EditTaskRow from "./EditTaskRow";
import getDateTimeField from "../../../../Utils";
let StoreprocData = [];
const authApi = config.get("apiDomain");
let rowid = 0;
class EditProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      ChangeDate: "",
      ChangeProject: this.props.Editdata.projectname,
      ChangeStart: moment(new Date()).format("YYYY-MM-DD"),
      ChangeEnd: moment(new Date()).format("YYYY-MM-DD"),
      ChangeAssign: this.props.Editdata.projectlead,
      ChangePriority: "Medium",
      ChangeStatus: this.props.Editdata.status,
      ChangeTime: "",
      ChangeIsdeleted: 0,
      subclient: this.props.Editdata.subclient,
      file_path: "",
      advanceamount: 0,
      phonenumber: "",
      completed_date: null,
      Clientname: this.props.Editdata.clientname,
      clientid: [],
      id_value: this.props.Editdata.clientid,
      plan: [],
      items: [],
      nickname: [],
      Client_id: "",
      client_alldetails: this.props.clientdetails,
      subclient_details: [],
      current_client_details: [],
      select_clientName: [],
      select_client_id: [],
      companydetails: [],
      planId: "",
      Phonenum: this.props.Editdata.phonenumber,
      pgcaddTaskNames: [],
      todaydate: moment(new Date()).format("YYYYMMDD"),
      error: null,
      Maintaskid: "",
      enableprinting: 0,
      border: "1px solid #dee2e6",
      checked: false,
      PrinterName: "",
      checkclient: 0,
      userid: 0,
      drawingTitle: "",
      checkClientid: 0,
      clientidborder: "1px solid #dee2e6",
      subclientborder: "1px solid #dee2e6",
      companyborder: "1px solid #dee2e6",
      Unpaiddetails: [],
      isButtonDisabled: false,
      companyid: this.props.Editdata.company,
      company_name: this.props.Editdata.companyid,
      projectnameborder: "1px solid #dee2e6",
      q: "",
      taskRow: [],
      taskdetails: "",
      showclientid: true,
      showclientname: true,
    };
  }

  async componentDidMount() {
    await this.PlanTypeFunction();
    let currentuser = this.props.allusers.filter((userdetails) => {
      return userdetails.name === this.props.username;
    });
    this.props.Editdata.taskproject.forEach((element) => {
      if (element.isdeleted !== true) {
        let addrowData = {
          id: element.id,
          plantype: element.plantype,
          drawingtitle: element.drawingtitle,
          taskname: element.task,
          date: element.date,
        };
        this.setState({
          taskRow: [...this.state.taskRow, addrowData],
        });
      }
    });
    let max = 0;
    this.state.taskRow.forEach((stock) => {
      if (stock.id > max) {
        max = stock.id;
      }
    });
    rowid = max + 1;
    let rowData = {
      id: 999999,
      plantype: "",
      drawingtitle: "",
      taskname: "",
      date: moment(new Date()).format("YYYY-MM-DD"),
    };
    this.setState(
      {
        taskRow: [...this.state.taskRow, rowData],
        items: this.props.allusers,
        userid: currentuser[0].id,
        Client_id: currentuser[0].clientid,
      },
      function () {
        this.CompanyDetails();
        this.filtereditclient();
      }
    );
  }

  PlanTypeFunction = () => {
    fetch(`${authApi}Plans/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          plan: json,
        });
      });
  };

  CompanyDetails = () => {
    fetch(`${authApi}companyfilter/?isactive=1`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          companydetails: json,
        });
      });
  };

  filtereditclient = () => {
    if (this.state.id_value !== "" && this.state.id_value !== undefined) {
      let currentclient = this.props.clientdetails.filter((clientdata) => {
        return clientdata.id === parseInt(this.state.id_value);
      });
      this.setState(
        {
          current_client_details: currentclient,
        },
        function () {
          this.updatenickname();
        }
      );
    }
  };

  HandleChangeProject = (event) => {
    this.setState({
      ChangeProject: event.target.value,
    });
  };

  HandleChangeStatus = (event) => {
    const g = event.target.value;
    this.setState({
      ChangeStatus: g,
    });
  };

  Formvalidation = () => {
    this.prepareData();
    //this.updateProjectStatus();
    if (this.state.id_value !== "") {
      if (this.state.subclient !== "") {
        if (this.state.company_name !== "") {
          if (this.state.ChangeProject !== "") {
            this.setState({
              isButtonDisabled: true,
            });
            this.subclientUpdation();
            this.UpdationRow();
          } else {
            this.setState({
              projectnameborder: "1px solid #fb6340",
              companyborder: "1px solid #dee2e6",
              subclientborder: "1px solid #dee2e6",
              clientidborder: "1px solid #dee2e6",
            });
          }
        } else {
          this.setState({
            companyborder: "1px solid #fb6340",
            subclientborder: "1px solid #dee2e6",
            clientidborder: "1px solid #dee2e6",
          });
        }
      } else {
        this.setState({
          subclientborder: "1px solid #fb6340",
          clientidborder: "1px solid #dee2e6",
        });
      }
    } else {
      this.setState({
        clientidborder: "1px solid #fb6340",
      });
    }
  };

  updateProjectStatus = () => {
    let check = null;
    if (check !== this.props.Editdata.id) {
      axios({
        method: "put",
        url: `${authApi}updateprojectstatus/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          taskproject_id: this.props.Editdata.id,
        },
      })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            console.log(res.status);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  prepareData = () => {
    let otherthenadd = this.state.taskRow.filter((item) => item.id !== 999999);
    let data = otherthenadd.filter((data) => data.plantype !== "");
    data.forEach((task) => {
      let newtask = {
        id: task.id,
        plantype: task.plantype,
        drawingtitle: task.drawingtitle,
        taskname: task.taskname,
      };
      StoreprocData = [...StoreprocData, newtask];
    });
  };

  subclientUpdation = () => {
    let SubName = this.state.subclient;
    let currentsubclient = this.state.subclient_details.filter(function (item) {
      return item.name === SubName;
    });
    if (currentsubclient.length === 0) {
      let datetime_field = getDateTimeField();
      axios({
        method: "post",
        url: `${authApi}subclients/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          name: this.state.subclient,
          phoneno: this.state.Phonenum,
          clients: this.state.id_value,
          createdby: this.props.username,
          createdat: datetime_field,
          modifiedby: this.props.username,
          modifiedat: datetime_field,
        },
      });
      this.updateclientdetails();
    }
  };

  updateclientdetails = () => {
    fetch(`${authApi}clientfilter_redux/?isdeleted=False`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        localStorage.setItem(
          "clientdetails",
          JSON.stringify(json.sort((a, b) => a.clientid - b.clientid))
        );
        store.dispatch({
          type: AllClientDetails,
          data: json.sort((a, b) => a.clientid - b.clientid),
        });
      });
  };

  update_taskname = () => {
    let totaltask = this.state.taskRow;
    let updatedtaskname = [];
    totaltask.forEach((element) => {
      let addrowData = {
        id: element.id,
        plantype: element.plantype,
        drawingtitle: element.drawingtitle,
        taskname:
          this.state.Client_id +
          element.plantype.substr(0, 2) +
          this.state.subclient +
          element.drawingtitle +
          moment(new Date(element.date)).format("YYYYMMDD"),
        date: element.date,
      };
      updatedtaskname = [...updatedtaskname, addrowData];
    });
    this.setState({
      taskRow: updatedtaskname,
    });
  };

  reorderTask = () => {
    let sortedRowdata = this.state.taskRow.sort((a, b) =>
      a.id > b.id ? 1 : -1
    );
    this.setState({
      taskRow: sortedRowdata,
    });
  };

  UpdationRow = async () => {
    let statuscount = this.props.Editdata.taskproject.filter((taskstatus) => {
      return (
        taskstatus.status === "ReadytoInvoice" ||
        taskstatus.status === "InProgress" ||
        taskstatus.status === "Completed"
      );
    });
    axios({
      method: "post",
      url: `${authApi}sp_editprojcts/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        id: this.props.Editdata.id,
        clientid: this.state.id_value,
        clientname: this.state.Clientname,
        subclient: this.state.subclient,
        phonenumber: this.state.Phonenum,
        status: statuscount.length > 0 ? "InProgress" : "initiate",
        company: this.state.companyid,
        companyname: this.state.company_name,
        projectname: this.state.ChangeProject,
        projectlead: this.state.ChangeAssign,
        tasks: StoreprocData,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          StoreprocData = [];
          this.updateProjectStatus();
          this.props.callbackfunction();
        }
      })
      .catch((err) => {
        StoreprocData = [];
        console.log(err);
        this.props.calbackfunction("AddTasks");
      });
  };

  selectClient_Name = (event) => {
    const currentclient = event.target.value;
    let currentclientdetails = this.props.clientdetails.filter((clientdata) => {
      return clientdata.company_name === currentclient;
    });
    if (currentclientdetails.length > 0) {
      this.setState(
        {
          select_clientName: currentclientdetails,
          Clientname: event.target.value,
          Client_id: currentclientdetails[0].clientid,
          subclient_details: currentclientdetails[0].clients,
          id_value: currentclientdetails[0].id,
        },
        () => {
          this.update_taskname();
        }
      );
    } else {
      this.setState({
        select_clientName: [],
        Clientname: event.target.value,
        Client_id: "",
        subclient_details: [],
        id_value: "",
      });
    }
  };

  selectid = (event) => {
    const currentclient = event.target.value;
    let currentclientdetails = this.props.clientdetails.filter((clientdata) => {
      return clientdata.clientid === currentclient;
    });
    if (currentclientdetails.length > 0) {
      this.setState(
        {
          select_client_id: currentclientdetails,
          Client_id: event.target.value,
          Clientname: currentclientdetails[0].company_name,
          subclient_details: currentclientdetails[0].clients,
          id_value: currentclientdetails[0].id,
        },
        () => {
          this.update_taskname();
        }
      );
    } else {
      this.setState({
        select_client_id: "",
        Client_id: event.target.value,
        Clientname: "",
        subclient_details: [],
        id_value: "",
      });
    }
  };

  updatenickname = () => {
    this.state.current_client_details.map((item) => {
      if (item.clientid === "00300") {
        this.setState({
          Clientname: item.company_name,
          Client_id: item.clientid,
          enableprinting: 1,
        });
      } else {
        this.setState({
          Clientname: item.company_name,
          Client_id: item.clientid,
        });
      }
      return this.state.Clientname;
    });
  };

  handlesubclient = (event) => {
    this.setState(
      {
        subclient: event.target.value,
        Phonenum: "",
        phonenumber: "",
      },
      function () {
        this.PhonenumberUpd();
        this.update_taskname();
      }
    );
  };

  PhonenumberUpd = () => {
    this.state.subclient_details.forEach((ph) => {
      if (ph.name === this.state.subclient) {
        this.setState({
          Phonenum: ph.phoneno,
          phonenumber: ph.phoneno,
        });
      }
    });
  };

  HandlePhonenum = (event) => {
    this.setState({
      Phonenum: event.target.value,
      phonenumber: event.target.value,
    });
  };

  HandleCompany = (event) => {
    event.persist();
    this.setState({
      company_name: event.target.value,
    });
    fetch(`${authApi}companyfilter/?companyname=${event.target.value}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.length !== 0) {
          this.setState({
            companyid: json[0].id,
          });
        }
      });
  };

  addTaskrow = () => {
    let totalRowdata = this.state.taskRow;
    let rowData = {
      id: rowid + 1,
      plantype: "",
      drawingtitle: "General",
      taskname: "",
      date: moment(new Date()).format("YYYY-MM-DD"),
    };
    totalRowdata = [...totalRowdata, rowData];
    this.setState({
      taskRow: [],
    });
    let sortedRowdata = totalRowdata.sort((a, b) => (a.id > b.id ? 1 : -1));
    this.setState({
      taskRow: sortedRowdata,
    });
    rowid = rowid + 1;
  };

  removeTaskRow = (id) => {
    let remainingdata = this.state.taskRow.filter((item) => item.id !== id);
    this.setState({
      taskRow: remainingdata,
    });
    this.props.Editdata.taskproject.forEach((element) => {
      if (element.id === id) {
        axios({
          method: "post",
          url: `${authApi}sp_deleteprojecttask/`,
          headers: {
            "content-type": "application/json",
            Authorization: `token ${this.props.token}`,
          },
          data: {
            task_id: id,
          },
        })
          .then((res) => {
            if (res.status === 201) {
            }
          })
          .catch((err) => {
            this.setState({ error: err.response.request.response });
          });
      }
    });
    this.updateprojectamountcalculation();
  };

  updateprojectamountcalculation = () => {
    axios({
      method: "post",
      url: `${authApi}sp_updateprojecttotalamount/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        projectid: this.props.Editdata.id,
      },
    })
      .then((res) => {
        if (res.status === 201) {
        }
      })
      .catch((err) => {
        this.setState({ error: err.response.request.response });
      });
  };

  updateJsondataObject = (id, plantype, drawingtitle, taskname) => {
    this.state.taskRow.forEach((element) => {
      if (element.id === id) {
        element.plantype = plantype;
        element.drawingtitle = drawingtitle;
        element.taskname = taskname;
      }
    });
  };

  keyEventOperation = (event) => {
    if (!event.shiftKey && event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if (form.elements[index + 1] !== undefined) {
        form.elements[index + 1].focus();
      }
      event.preventDefault();
    } else if (event.key.toLowerCase() === "enter" && event.shiftKey) {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if (form.elements[index - 1] !== undefined) {
        form.elements[index - 1].focus();
      }
      event.preventDefault();
    }
  };

  HandleChangeAssign = (event) => {
    this.setState({
      ChangeAssign: event.target.value,
    });
  };

  Showclientiddatalist = () => {
    this.setState({
      showclientid: false,
    });
  };

  hideclientiddatalist = () => {
    this.setState({
      showclientid: true,
    });
  };

  ShowClientnamedatalist = () => {
    this.setState({
      showclientname: false,
    });
  };

  hideclientnamedatalist = () => {
    this.setState({
      showclientname: true,
    });
  };

  render() {
    const rowDetails = this.state.taskRow.map((q) => (
      <EditTaskRow
        data={q}
        addTaskrow={this.addTaskrow}
        removeTaskRow={this.removeTaskRow}
        updateJsondataObject={this.updateJsondataObject}
        taskRow={this.state.taskRow}
        plan={this.state.plan}
        Client_id={this.state.Client_id}
        subclient={this.state.subclient}
        date={this.state.todaydate}
      />
    ));
    return (
      <React.Fragment>
        <Container className="mt--7" fluid>
          <div className="Scroll-Content" style={{ marginTop: 10 }}>
            <br />
            <Row>
              <div
                className="addRow"
                style={{ position: "absolute", right: "90px", marginTop: 16 }}
              >
                <button
                  className="btn btn-primary btn-sm"
                  style={{ width: "135%", height: 40 }}
                  data-tip
                  data-for="completed-Tip"
                  onClick={this.props.callbackfunction}
                >
                  Back
                </button>
                <ReactTooltip
                  id="completed-Tip"
                  place="left"
                  type="dark"
                  effect="solid"
                >
                  Back To Projects
                </ReactTooltip>
              </div>
            </Row>
            <form>
              <Row style={{ marginTop: 60 }}>
                {this.state.showclientid === true ? (
                  <Col md="3">
                    <label className="form-control-label">Client Id</label>
                    <Input
                      className="form-control-alternative"
                      type="text"
                      value={this.state.Client_id}
                      style={{ border: this.state.clientidborder }}
                      onFocus={this.Showclientiddatalist}
                      onKeyDown={this.keyEventOperation}
                    />
                    {this.state.clientidborder !== "1px solid #dee2e6" ? (
                      <h5 style={{ color: "#fb6340" }}>
                        Please select clientid
                      </h5>
                    ) : null}
                  </Col>
                ) : (
                  <Col md="3">
                    <label className="form-control-label">Client Id</label>
                    <Input
                      type="option"
                      list="id_value-list"
                      className="form-control-alternative"
                      value={this.state.Client_id}
                      style={{ border: this.state.clientidborder }}
                      onChange={this.selectid}
                      onBlur={this.hideclientiddatalist}
                      onKeyDown={this.keyEventOperation}
                    />
                    <datalist id="id_value-list">
                      {this.state.client_alldetails.map((item, key) => (
                        <option key={key} value={item.clientid} />
                      ))}
                    </datalist>
                    {this.state.clientidborder !== "1px solid #dee2e6" ? (
                      <h5 style={{ color: "#fb6340" }}>
                        Please select clientid
                      </h5>
                    ) : null}
                  </Col>
                )}
                {this.state.showclientname === true ? (
                  <Col md="3">
                    <label className="form-control-label">Client Name</label>
                    <Input
                      className="form-control-alternative"
                      type="text"
                      value={this.state.Clientname}
                      style={{ border: this.state.clientidborder }}
                      onFocus={this.ShowClientnamedatalist}
                      onKeyDown={this.keyEventOperation}
                    />
                    {this.state.clientidborder !== "1px solid #dee2e6" ? (
                      <h5 style={{ color: "#fb6340" }}>
                        Please select clientid
                      </h5>
                    ) : null}
                  </Col>
                ) : (
                  <Col md="3">
                    <label className="form-control-label">Client Name</label>
                    <Input
                      type="option"
                      list="nickname-list"
                      className="form-control-alternative"
                      defaultValue={this.state.Clientname}
                      style={{ border: this.state.clientidborder }}
                      onChange={this.selectClient_Name}
                      onBlur={this.hideclientnamedatalist}
                      onKeyDown={this.keyEventOperation}
                    />
                    <datalist id="nickname-list">
                      {this.state.client_alldetails.map((item, name) => (
                        <option key={name} value={item.company_name} />
                      ))}
                    </datalist>
                    {this.state.clientidborder !== "1px solid #dee2e6" ? (
                      <h5 style={{ color: "#fb6340" }}>Please select name</h5>
                    ) : null}
                  </Col>
                )}
                <Col md="3">
                  <label className="form-control-label">Subclient</label>
                  <Input
                    type="option"
                    list="subclient-list"
                    className="form-control-alternative"
                    style={{ border: this.state.subclientborder }}
                    value={this.state.subclient}
                    onChange={this.handlesubclient}
                    onKeyDown={this.keyEventOperation}
                  ></Input>
                  <datalist id="subclient-list">
                    {this.state.subclient_details.map((item, name) => (
                      <option key={name} value={item.name} />
                    ))}
                  </datalist>
                  {this.state.subclientborder !== "1px solid #dee2e6" ? (
                    <h5 style={{ color: "#fb6340" }}>Please enter subclient</h5>
                  ) : null}
                </Col>
                <Col md="3">
                  <label className="form-control-label">Phonenumber</label>
                  <Input
                    className="form-control-alternative"
                    type="text"
                    style={{ border: "1px solid #dee2e6" }}
                    value={this.state.Phonenum}
                    onChange={this.HandlePhonenum}
                    onKeyDown={this.keyEventOperation}
                  />
                </Col>
              </Row>
              &nbsp;
              <Row style={{ marginTop: -5 }}>
                <Col md="4">
                  <label className="form-control-label">Project Lead</label>
                  <Input
                    type="select"
                    className="form-control-alternative"
                    style={{ border: "1px solid #dee2e6" }}
                    value={this.state.ChangeAssign}
                    onChange={this.HandleChangeAssign}
                    onKeyDown={this.handleEnter}
                  >
                    <option>Select</option>
                    {this.state.items.map((obj, assign) => (
                      <option key={assign} value={obj.name}>
                        {obj.name}
                      </option>
                    ))}
                  </Input>
                </Col>
                <Col md="4">
                  <label className="form-control-label">Company</label>
                  <Input
                    type="select"
                    className="form-control-alternative"
                    value={this.state.company_name}
                    style={{ border: this.state.companyborder }}
                    onChange={this.HandleCompany}
                    onKeyDown={this.keyEventOperation}
                  >
                    <option value="">Select Company</option>
                    {this.state.companydetails.map((item, key) => (
                      <option key={key} value={item.companyname}>
                        {item.companyname}
                      </option>
                    ))}
                  </Input>
                  {this.state.companyborder !== "1px solid #dee2e6" ? (
                    <h5 style={{ color: "#fb6340" }}>
                      Please select companyname
                    </h5>
                  ) : null}
                </Col>
                <Col md="4">
                  <label className="form-control-label">Project Name</label>
                  <Input
                    type="text"
                    style={{ border: "1px solid #dee2e6" }}
                    className="form-control-alternative"
                    value={this.state.ChangeProject}
                    onChange={this.HandleChangeProject}
                  />
                  {this.state.projectnameborder === "1px solid #fb6340" ? (
                    <h5 style={{ color: "#fb6340" }}>
                      Please Enter Projectname
                    </h5>
                  ) : null}
                </Col>
              </Row>
            </form>
            <Row>
              <div className=" col">
                <br />
                <Card className="shadow">
                  <Table
                    className="align-items-center table-flush"
                    responsive-sm
                  >
                    {" "}
                    <thead className="thead-light">
                      <tr>
                        <th className="text-center">ACTION</th>
                        <th className="text-center">PLAN TYPE</th>
                        <th className="text-center">DRAWING TITLE</th>
                        <th className="text-center">TASK NAME</th>
                      </tr>
                    </thead>
                    <tbody>{rowDetails}</tbody>
                  </Table>
                </Card>
              </div>
            </Row>
            <Row>
              <Col md="10"></Col>
              <Col md="2" style={{ textAlign: "center" }}>
                <label className="form-control-label">
                  &nbsp;&nbsp;
                  <button
                    id="buttonsave"
                    className="btn btn-dark"
                    onClick={this.Formvalidation}
                    disabled={this.state.isButtonDisabled}
                  >
                    Save
                  </button>
                </label>
              </Col>
            </Row>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    clientdetails: state.clientdetails,
    allusers: state.allusers,
    username: state.username,
  };
};
export default connect(mapStateToProps, null)(EditProjects);
