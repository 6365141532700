import React from "react";
import { Route, Switch } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import AuthNavbar from "../components/Navbars/AuthNavbar.js";
import AuthFooter from "../components/Footers/AuthFooter.js";
import routes from "../routes.js";
import config from "react-global-configuration";
import "../config";
import * as firebase from "firebase/app";

const FireBase_CDN = config.get("firebaseCDN");

if (!firebase.apps.length) {
  firebase.initializeApp(FireBase_CDN);
} else {
  firebase.app();
}

class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Users: this.props.Users,
    };
  }

  getRoutes = (routes) => {
    return routes.map((index, key) => {
      if (index.layout === "/auth") {
        return (
          <Route
            path={index.layout + index.path}
            component={index.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="main-content">
          <AuthNavbar />
          <div className="header bg-gradient-info py-7 py-lg-8">
            <Container>
              <div className="header-body text-center mb-7">
                <Row className="justify-content-center">
                  <Col lg="5" md="6">
                    <h1 className="text-white">Welcome!</h1>
                      <p className="text-lead text-light">PG Cadd Structures</p>
                  </Col>
                </Row>
              </div>
            </Container>
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-default"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </div>
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Switch>{this.getRoutes(routes)}</Switch>
            </Row>
          </Container>
        </div>
        <AuthFooter />
      </React.Fragment>
    );
  }
}

export default Auth;
