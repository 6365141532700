import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#bff0fd";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "bold"
  },
  description: {
    width: "85%",
    textAlign: "right",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    paddingRight: 8,
    paddingTop: 4,
  },
  total: {
    width: "15%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
    borderRightColor: borderColor,
    borderRightWidth: 1,
  }
});

const DispatchTableFooter = ({ itemsTotal }) => {

  return (
    <React.Fragment>
      <View style={styles.row}>
        <Text style={styles.description}>Total</Text>
        <Text style={styles.total}>{Number.parseFloat(itemsTotal.amount).toFixed(2)}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>Received Amount</Text>
        <Text style={styles.total}>{Number.parseFloat(itemsTotal.amountreceived).toFixed(2)}</Text>
      </View>
    </React.Fragment>
  );
};

export default DispatchTableFooter;
