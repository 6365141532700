import React, { Component } from 'react';
import '../../../../config';
import "./Worktime.css";
import {connect} from "react-redux";
import config from "react-global-configuration";
const authApi = config.get('apiDomain');

class WorktimeRow extends Component {
    constructor(props){
        super(props);
        this.state={
            id:this.props.id,
            date:this.props.date,
            time:this.props.time,
            subtask:this.props.subtask,
            username:this.props.username,
            subtaskname:"",
            Manitaskid:"",
            maintaskname:"", 
            clientid:"",
            clientName:"",
        }
    }
componentDidMount(){
    this.subtaskfilter();
}

subtaskfilter=()=>{
    fetch(`${authApi}subtasks/${this.state.subtask}/`, {
    method: 'GET',
    headers: {
    'content-type': "application/json",
    'Authorization': `token ${this.props.token}`,
    }
   })
    .then(res=>res.json())
    .then(json=>{
        this.setState({
            subtaskname:json.subtask,
            Manitaskid:json.tasklist,
        },function(){
            this.Filtertaskname();
        })
    })
}

Filtertaskname=()=>{
    fetch(`${authApi}Tasklist/${this.state.Manitaskid}/`, {
    method: 'GET',
    headers: {
    'content-type': "application/json",
    'Authorization': `token ${this.props.token}`,
    }
     })
    .then(res=>res.json())
    .then(json=>{
        this.setState({
            clientid:json.client,
            maintaskname:json.task,
        },function(){
            this.Clientnamefilter();
        })
    })
}

Clientnamefilter=()=>{
    let currentdata = this.props.clientdetails.filter((clientdata)=>{
        return clientdata.id === this.state.clientid
    })
    this.setState({
        clientName:currentdata[0].company_name,
    },function(){
        this.props.Storealldata(this.state);
    })
}

render() {
        return (
           <tr>
               <td className="text-center">
                   <label>{this.state.date}</label>
               </td>
               <td className="text-center">
                   <label>{this.state.clientName}</label>
               </td> 
               <td className="text-center">
                   <label>{this.state.maintaskname}</label>
               </td>
               <td  className="text-center">
                   <label>{this.state.subtaskname}</label>
               </td>
               <td  className="text-center">
                   <label>{this.state.time}</label>
               </td>   
            </tr>
        )
    }
}
const mapStateToProps = state => {
    return {
      token:state.token,
      clientdetails:state.clientdetails
    }
  }
export default connect(mapStateToProps, null)(WorktimeRow);