import React, { Component } from "react";
import { Table, Row, Card } from "reactstrap";
import config from "react-global-configuration";
import "../../../../config";
import { connect } from "react-redux";
import AccountPopupRow from "./AccountPopupRow";
import axios from "axios";
import consoleFunction from "../../../../Helpers";
import "./AccountPopup.css";
import getDateTimeField from "../../../../Utils";

const authApi = config.get("apiDomain");

class StockPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      items: [],
      subtask: this.props.EditRowSend,
      error: "",
      Rate: "",
      maintask: [],
      length: "",
      CheckData: [],
      deletedRows: [],
      isButtonDisabled: false,
    };
  }

  componentDidMount() {
    this.Maintaskdetails();
    this.invoiceStock();
    let data = "";
    data = this.state.subtask.filter(function (item) {
      return item.isdeleted !== true;
    });
    this.setState({
      subtask: data,
    });
  }

  invoiceStock = () => {
    fetch(
      `${authApi}stockinvoicefilter/?tasklistrow=${this.props.maintaskid}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        const body = json;
        const data = body.map((e) => {
          e.availablequantity = 0;
          return e;
        });
        this.setState({
          items: data,
        });
        this.updateAvailableQuantity(data);
      });
  };

  updateId = () => {
    let max = 0;
    this.state.items.forEach((ms) => {
      if (ms.id > max) {
        max = ms.id;
        this.setState({
          id: max,
        });
      }
    });
  };

  updateAvailableQuantity = (body) => {
    const promise1 = body.map((e) => this.handleAvailableQuantity(e));
    Promise.all(promise1).then((result) => {
      this.setState(
        {
          length: result.length,
          items: result,
          CheckData: result,
        },
        function () {
          this.updateId();
        }
      );
    });
  };

  handleAvailableQuantity = async (e) => {
    let a = await this.getAvailablequantity(e.types);
    e.availablequantity = a;
    return e;
  };

  getAvailablequantity = async (e) => {
    await axios({
      method: "post",
      url: `${authApi}sp_getavailablestockquantity/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        stockname: e,
      },
    }).then((res) => {
      console.log(res.data);
      if (res.data.length !== 0) {
        console.log(res.data[0].quantity);
        return res.data[0].quantity;
      } else {
        return 0;
      }
    });
  };

  Maintaskdetails = () => {
    fetch(`${authApi}Tasklist/${this.props.maintaskid}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          maintask: json,
        });
      });
  };

  handleAddRow = () => {
    const item = {
      id: this.state.id + 1,
      types: "",
      availablequantity: "",
      unit: "",
      quantity: "",
      rate: "",
      amount: "",
      tasklistrow: "",
    };
    this.setState({
      id: item.id,
      items: [...this.state.items, item],
    });
  };

  ChangeTodoValue = (x) => {
    this.setState((prevState) => {
      const updatedTodoData = prevState.items.map((todo) => {
        if (todo.id === x.id) {
          todo.id = x.id;
          todo.types = x.stockname;
          todo.availablequantity = x.availablequantitys;
          todo.rate = x.defaultRate;
          todo.unit = x.units;
          todo.quantity = x.quantitys;
          todo.amount = x.amount;
        }
        return todo;
      });
      return {
        items: updatedTodoData,
      };
    });
  };

  OnSubmitStocks = async () => {
    this.setState({
      isButtonDisabled: true,
    });
    let count = 1,
      len = this.state.items.length;
    const parentTaskId = this.props.maintaskid;
    this.deletestock(len);
    if (this.state.length === 0) {
      if (len === 0) {
        this.props.closebtn();
      } else {
        for (const i of this.state.items) {
          if (i.types !== "") {
            let datetime_field = getDateTimeField();
            await axios({
              method: "post",
              url: `${authApi}stockinvoice/`,
              headers: {
                "content-type": "application/json",
                Authorization: `token ${this.props.token}`,
              },
              data: {
                types: i.types,
                unit: i.unit,
                quantity: i.quantity,
                rate: i.rate,
                amount: i.amount,
                tasklistrow: parentTaskId,
                createdby: this.props.username,
                createdat: datetime_field,
                modifiedby: this.props.username,
                modifiedat: datetime_field,
              },
            });
            if (len === count) {
              this.props.closebtn();
            } else {
              count = count + 1;
            }
          }
        }
      }
    } else {
      for (const i of this.state.items) {
        let task = 0;
        task = this.state.CheckData.filter(function (item) {
          return item.id === i.id && item.tasklistrow === i.tasklistrow;
        });
        if (task.length !== 0) {
          if (i.types !== "") {
            let datetime_field = getDateTimeField();
            await axios({
              method: "put",
              url: `${authApi}stockinvoice/${i.id}/`,
              headers: {
                "content-type": "application/json",
                Authorization: `token ${this.props.token}`,
              },
              data: {
                types: i.types,
                unit: i.unit,
                quantity: i.quantity,
                rate: i.rate,
                amount: i.amount,
                tasklistrow: parentTaskId,
                modifiedby: this.props.username,
                modifiedat: datetime_field,
              },
            });
            if (len === count) {
              this.props.closebtn();
            } else {
              count = count + 1;
            }
          }
        } else {
          if (i.types !== "") {
            let datetime_field = getDateTimeField();
            await axios({
              method: "post",
              url: `${authApi}stockinvoice/`,
              headers: {
                "content-type": "application/json",
                Authorization: `token ${this.props.token}`,
              },
              data: {
                types: i.types,
                unit: i.unit,
                quantity: i.quantity,
                rate: i.rate,
                amount: i.amount,
                tasklistrow: parentTaskId,
                createdby: this.props.username,
                createdat: datetime_field,
                modifiedby: this.props.username,
                modifiedat: datetime_field,
              },
            });
            if (len === count) {
              this.props.closebtn();
            } else {
              count = count + 1;
            }
          } else {
            this.props.closebtn();
          }
        }
      }
    }
  };

  handleRemoveRow = (i) => {
    const DelData = this.state.items.filter((item) => item.id !== i.id);
    this.setState({ items: [...DelData] });
    this.state.CheckData.forEach((item) => {
      if (item.id === i.id && item.tasklistrow === i.tasklistrow) {
        let row = {
          id: i.id,
          types: i.stockname,
          availablequantity: i.availablequantitys,
          unit: i.units,
          quantity: i.quantitys,
          rate: i.defaultRate,
          amount: i.amount,
          tasklistrow: i.tasklistrow,
        };
        this.setState({
          deletedRows: [...this.state.deletedRows, row],
        });
      }
    });
  };

  deletestock = (x) => {
    this.state.deletedRows.forEach((i) => {
      axios({
        method: "delete",
        url: `${authApi}stockinvoice/${i.id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      })
        .then((res) => {
          if (x === 0) {
            this.props.closebtn();
          }
        })
        .catch((err) => {
          consoleFunction("Stock removal error", err.message);
        });
    });
  };

  render() {
    const AddRowDetails = [...this.state.items].map((item) => (
      <AccountPopupRow
        key={item.id}
        id={item.id}
        types={item.types}
        availablequantity={item.availablequantity}
        unit={item.unit}
        quantity={item.quantity}
        rate={item.rate}
        amount={item.amount}
        tasklistrow={item.tasklistrow}
        addRowstock={this.ChangeTodoValue}
        deleteRow={this.handleRemoveRow}
        con={item}
      />
    ));

    return (
      <React.Fragment>
        <button className="x" onClick={this.props.closebtn}>
          x
        </button>
        <div className="header">Printing Details</div>
        <Row>
          <div className="col">
            <div className="Scrolls-Contents">
              <Card className="shadow">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr
                      style={{
                        borderBottomWidth: 2,
                        borderBottomStyle: "solid ",
                      }}
                    >
                      <th className="text-center"> Types </th>
                      <th className="text-center"> Unit </th>
                      <th className="text-center"> Quantity </th>
                      <th className="text-center">Inv Qty</th>
                      <th className="text-center"> Rate </th>
                      <th className="text-center"> Amount </th>
                      <th className="text-center"> Action </th>
                    </tr>
                  </thead>
                  <tbody>{AddRowDetails}</tbody>
                </Table>
                <br />
                <div>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={this.handleAddRow}
                  >
                    <i className="fas fa-plus-circle fa-1x"></i>
                  </button>
                </div>
                <div>
                  <button
                    id="buttonsave"
                    className="btn btn-dark"
                    style={{ marginRight: "10px", float: "right" }}
                    onClick={this.OnSubmitStocks}
                    disabled={this.state.isButtonDisabled}
                  >
                    Done
                  </button>
                </div>
              </Card>
            </div>
          </div>
        </Row>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
    username: state.username,
  };
};
export default connect(mapStateToProps, null)(StockPopup);
