import React from "react";
import { connect } from "react-redux";
import "../../../../config";
import config from "react-global-configuration";
import { Input } from "reactstrap";
const authApi = config.get("apiDomain");

let totalQuantity = 0;

class PrintingDetailsRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      stockname: this.props.types,
      units: this.props.unit,
      quantitys: this.props.quantity,
      availablequantitys: this.props.availablequantity,
      defaultRate: this.props.rate,
      amount: this.props.amount,
      tasklistrow: this.props.tasklistrow,
      stockItems: [],
    };
  }

  componentDidMount() {
    fetch(`${authApi}stocks/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          stockItems: json,
        });
      });
  }

  selectStock = (event) => {
    fetch(
      `${authApi}inventorytransactionfilter/?stockname=${event.target.value}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        const data = json;
        totalQuantity = data.reduce(
          (i, i_bal) => i + parseInt(i_bal.quantity, 0),
          0
        );
        this.setState({
          availablequantitys: totalQuantity,
        });
      });
    this.state.stockItems.forEach((i) => {
      if (i.stockname === event.target.value) {
        this.setState({
          stockname: i.stockname,
          defaultRate: i.defaultrate,
          units: "1",
          quantitys: "1",
          amount: i.defaultrate,
        });
      }
    });
    this.setState(
      {
        stockname: event.target.value,
      },
      function () {
        this.props.addRowstock(this.state);
      }
    );
  };

  selectStockUnits = (event) => {
    let preamount = this.state.defaultRate;
    let updatedecimal = event.target.value * preamount * this.state.quantitys;
    this.setState(
      {
        units: event.target.value,
        amount: updatedecimal.toFixed(2),
      },
      function () {
        this.props.addRowstock(this.state);
      }
    );
  };

  selectStockQuantity = (event) => {
    let preamount = this.state.defaultRate;
    let updatedecimal = event.target.value * preamount * this.state.units;
    this.setState(
      {
        quantitys: event.target.value,
        amount: updatedecimal.toFixed(2),
      },
      function () {
        this.props.addRowstock(this.state);
      }
    );
  };

  ChangeRate = (event) => {
    let preQuantity = this.state.units;
    let updatedecimal = event.target.value * preQuantity * this.state.quantitys;
    this.setState(
      {
        defaultRate: event.target.value,
        amount: updatedecimal.toFixed(2),
      },
      function () {
        this.props.addRowstock(this.state);
      }
    );
  };

  deleteStocks = () => {
    this.props.deleteRow(this.state);
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      id: nextProps.id,
      stockname: nextProps.types,
      availablequantitys: nextProps.availablequantity,
      quantitys: nextProps.quantity,
      units: nextProps.unit,
      defaultRate: nextProps.rate,
      amount: nextProps.amount,
      tasklistrow: nextProps.tasklistrow,
    });
  }

  render() {
    return (
      <React.Fragment>
        <tr style={{ borderBottomWidth: 1, borderBottomStyle: "solid " }}>
          <td>
            <Input
              type="option"
              list="stock_list"
              className="form-control-alternatives text-center"
              style={{
                border: "1px solid #dee2e6",
                width: "120%",
                marginLeft: -20,
              }}
              value={this.state.stockname}
              onChange={this.selectStock}
            />
            <datalist id="stock_list">
              {this.state.stockItems.map((item, i) => (
                <option key={i} value={item.stockname} />
              ))}
            </datalist>
          </td>
          <td>
            <Input
              type="text"
              className="form-controls-alternatives text-center"
              style={{ border: "1px solid #dee2e6", width: "100%" }}
              value={this.state.units}
              onChange={this.selectStockUnits}
            />
          </td>
          <td>
            <Input
              type="text"
              className="form-controls-alternatives text-center"
              style={{ borderBottom: "1px solid #dee2e6", width: "100%" }}
              value={this.state.quantitys}
              onChange={this.selectStockQuantity}
            />
          </td>
          <td>
            <Input
              type="text"
              className="form-controls-alternatives text-center"
              style={{ border: "1px solid #dee2e6", width: "100%" }}
              value={this.state.availablequantitys}
              defaultValue={this.state.availablequantitys}
            />
          </td>
          <td>
            <Input
              type="text"
              className="form-controls-alternatives text-center"
              style={{ border: "1px solid #dee2e6", width: "100%" }}
              defaultValue={this.state.defaultRate}
              onChange={this.ChangeRate}
            />
          </td>
          <td>
            <Input
              type="text"
              className="form-controls-alternatives text-center"
              style={{ border: "1px solid #dee2e6", width: "100%" }}
              value={this.state.amount}
            />
          </td>
          <td>
            <button
              className="btn btn-primary btn-sm"
              onClick={this.deleteStocks}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, null)(PrintingDetailsRow);
