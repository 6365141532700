import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  descriptions: {
    width: "42%",
    textAlign: "center",
    paddingLeft: 8,
    paddingTop: 4,
  },
  rows: {
    flexDirection: "row",
    alignItems: "center",
    fontStyle: "bold"
  },
  amounts: {
    width: "19.33%",
    textAlign: "center",
    paddingRight: 20,
    paddingTop: 4,
  },
  discount: {
    width: "20.33%",
    textAlign: "center",
    paddingRight: 16,
    paddingTop: 4,
  
  },
  invoice_amount: {
    width: "20.33%",
    textAlign: "right",
    paddingRight: 20,
    paddingTop: 4,
  },
});

const InvoicePrinting = ({ printChar }) => 
{

  const invoicePrintTot = printChar.reduce((i,t) => i + parseInt(t.amount, 0), 0);

  return( 
    <React.Fragment>
      { invoicePrintTot === 0 ? null :
        <View style={styles.rows}>
          <Text style={styles.descriptions}>Printing Charges</Text>
          <Text style={styles.amounts}>{invoicePrintTot}</Text>
          <Text style={styles.discount}></Text>
          <Text style={styles.invoice_amount}>{parseFloat(invoicePrintTot).toFixed(2)}</Text>
        </View>
      }
    </React.Fragment>
  )
};

export default InvoicePrinting;
