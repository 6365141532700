import React, { Component } from "react";
import { Input } from "reactstrap";
import { connect } from "react-redux";
import "../../../../config";

class EditProjectInvoiceRowNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userdetails: this.props.userdetails,
      id: this.props.id,
      amount: this.props.amount,
      user: this.props.user,
      unit: this.props.unit,
      rate: this.props.rate,
      refresh: this.props.refresh,
      border: this.props.data.border,
    };
  }

  componentDidMount() {

  }

  async componentWillReceiveProps(nextProps) {
    this.setState({
      id: nextProps.id,
      amount: nextProps.amount,
      user: nextProps.user,
      unit: nextProps.unit,
      rate: nextProps.rate,
      border: nextProps.border,
    });
  }

  updateDraftingdetails = () => {
    this.props.draftingdetails(
      this.state.id,
      this.state.user,
      this.state.unit,
      this.state.rate,
      this.state.amount
    );
  };

  handleUser = (event) => {
    this.setState(
      {
        user: event.target.value,
      },
      function () {
        this.updateDraftingdetails();
      }
    );
    this.props.userdetails.forEach(element => {
    if(event.target.value === element.name){
      this.setState({
        rate:element.default_rate,
      })
    }
    });
   
  };

  handleTime = (event) => {
    let times = event.target.value.split(":");
    let mins = times[0] * 60 + +times[1];
    let new_amount = parseInt((mins * this.state.rate) / 60);
    let valid = true;
    if (parseInt(times[1]) > 59) {
      valid = false;
    }
    if (times.length === 2 && valid === true) {
      this.setState(
        {
          unit: event.target.value,
          amount: new_amount,
          border: "1px solid #dee2e6",
        },
        function () {
          this.updateDraftingdetails();
        }
      );
    } else {
      this.setState(
        {
          unit: event.target.value,
          amount: new_amount,
          border: "1px solid #fb6340",
        },
        function () {
          this.updateDraftingdetails();
        }
      );
    }

  };

  deleteDraftingrow1 = () => {
    
    this.props.deleteDraftingrowdetails(this.state);
  };

  render() {
    const { userdetails } = this.state;
    return (
      <>
        <tr>
          <td>
            <Input
              type="option"
              list="name_list"
              className="form-control-alternatives text-center"
              style={{
                border: this.state.border,
                width: "100%",
                marginLeft: -20,
              }}
              value={this.state.user}
              onChange={this.handleUser}
            />
            <datalist id="name_list">
              {userdetails.map((user, key) => (
                <option key={key} value={user.name} />
              ))}
            </datalist>
          </td>
          <td>
            <Input
              type="text"
              value={this.state.unit}
              onChange={this.handleTime}
              className="form-control-alternatives text-center"
              style={{
                width: "100%",
                marginLeft: -20,
                border: this.state.border,
              }}
            />
          </td>
          <td>
            <Input
              type="text"
              className="form-control-alternatives text-center"
              style={{
                border: this.state.border,
                width: "100%",
                marginLeft: -20,
              }}
              value={this.state.rate}
            />
          </td>
          <td>
            <Input
              type="text"
              className="form-control-alternatives text-center"
              style={{
                border: this.state.border,
                width: "100%",
                marginLeft: -20,
              }}
              value={this.state.amount}
            ></Input>
          </td>
          <td className="text-center"></td>
          <button
            className="btn btn-primary btn-sm"
            style={{ marginTop: 25, marginLeft: -70 }}
            onClick={this.deleteDraftingrow1}
          >
            <i className="fa fa-trash" aria-hidden="true"></i>
          </button>
        </tr>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, null)(EditProjectInvoiceRowNew);
