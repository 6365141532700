import React, { Component } from "react";
import Popup from "reactjs-popup";
import "../../../config";
import { connect } from "react-redux";
import "./invoiceRow.css";
import InvoiceEditRowpopup from "./InvoiceEditRowpopup";
import CancelInvoice from "./CancelInvoice";
import MakeInvoice from "./MakeInvoice";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";

class InvoiceRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceno: this.props.data.id,
      invoicedate: this.props.data.date,
      client: this.props.data.invoicesummaryclient_id,
      project_id: this.props.data.project_id,
      invoice_amount: this.props.data.invoice_amount,
      amount_received: this.props.data.amount_received,
      invoicestatus: this.props.data.invoicestatus,
      duedate: this.props.data.duedate,
      discount: this.props.data.discount,
      subtotal: this.props.data.subtotal,
      balancedue: this.props.data.balancedue,
      comments: this.props.data.comments,
      clientname: this.props.data.engineer_name,
      taskid: this.props.data.tbltasklist_id,
      clientCompany: "",
      clientphoneno: "",
      clientemail: "",
      fontcolor: "",
      taskname: "",
      taskdetails: [],
      isButtonDisabled: false,
      checkbox: false,
      companyinvoice: this.props.data.deliverynoteid,
    };
  }

  componentDidMount() {
    this.calculateBalance();
    this.checkCancelledInvoice();
  }

  checkCancelledInvoice = () => {
    if (
      this.props.data.InvoiceStatus === "Cancelled" ||
      this.props.data.InvoiceStatus === "Paid"
    ) {
      this.setState({
        isButtonDisabled: true,
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      amount_received: nextProps.data.receivedamount,
      invoicestatus: nextProps.data.InvoiceStatus,
      duedate: nextProps.data.duedate,
      balancedue: nextProps.data.Balancedue,
      taskid: nextProps.data.tbltasklist_id,
    });
  }

  calculateBalance = () => {
    this.setState({
      balancedue:
        parseInt(this.state.invoice_amount) -
        parseInt(this.state.amount_received),
    });
  };

  onChangeChaeckbox = () => {
    if (this.state.checkbox === true) {
      let select = 0;
      this.setState({
        checkbox: false,
      });
      this.props.getselectedInvoice(
        this.props.data.deliverynoteid,
        select,
        this.props.data.companyid
      );
    } else {
      let select = 1;
      this.setState({
        checkbox: true,
      });
      this.props.getselectedInvoice(
        this.props.data.deliverynoteid,
        select,
        this.props.data.companyid
      );
    }
  };

  render() {
    let develiverynoteid =
      this.props.data.vehiclenumber !== null
        ? this.props.data.companycode + 1 + "-" + this.props.data.deliverynoteid
        : this.props.data.companycode + "-" + this.props.data.deliverynoteid;
    let develiverynoteiddata =
      this.props.data.project_id !== null
        ? this.props.data.companycode + 2 + "-" + this.props.data.deliverynoteid
        : develiverynoteid;
    return (
      <tr>
        {this.props.data.companyinvoiceid === null &&
        this.props.data.InvoiceStatus !== "Cancelled" ? (
          <td>
            <input
              type="checkbox"
              style={{ width: "20px", height: "20px" }}
              checked={this.state.checkbox}
              onChange={this.onChangeChaeckbox}
            />
          </td>
        ) : (
          <td>
            <input
              type="checkbox"
              style={{ width: "20px", height: "20px" }}
              disabled
            />
          </td>
        )}
        <td>
          {this.props.isadmin === "1" ? (
            <Popup
              modal
              trigger={
                <button
                  className="btn btn-primary btn-sm"
                  style={{ marginRight: "5px" }}
                  disabled={this.state.isButtonDisabled}
                >
                  <i className="fa fa-trash" />
                </button>
              }
            >
              {(close) => (
                <CancelInvoice
                  deliverynoteid={this.state.companyinvoice}
                  invoicedetails={this.state}
                  projectid={this.props.data.project_id}
                  currentuserid={this.props.currentuserid}
                  callbackfunction={this.props.updatedata}
                  companyid={this.props.data.companyid}
                  closebtn={close}
                />
              )}
            </Popup>
          ) : (
            <Popup
              modal
              trigger={
                <button
                  className="btn btn-primary btn-sm"
                  style={{ marginRight: "5px" }}
                  disabled={this.state.isButtonDisabled}
                >
                  <i className="fa fa-trash" />
                </button>
              }
            >
              {(close) => (
                <canc
                  deliverynoteid={this.state.companyinvoice}
                  invoicedetails={this.state}
                  currentuserid={this.props.currentuserid}
                  callbackfunction={this.props.updatedata}
                  companyid={this.props.data.companyid}
                  closebtn={close}
                />
              )}
            </Popup>
          )}
          <Popup
            modal
            trigger={
              <button
                className="btn btn-primary btn-sm"
                style={{ marginRight: "5px" }}
                disabled={this.state.isButtonDisabled}
              >
                <i className="fa fa-edit" />
              </button>
            }
          >
            {(close) => (
              <InvoiceEditRowpopup
                EditRowSend={this.state}
                CompanyData={this.props.companydetails}
                closebtn={close}
                company_id={this.props.data.companyid}
                callbackfunction={this.props.updatedata}
                invoicepropsdata={this.props.data}
              />
            )}
          </Popup>
          {this.props.isadmin === "1" &&
          this.props.data.companyinvoiceid === null &&
          this.props.data.InvoiceStatus !== "Cancelled" ? (
            <Popup
              modal
              trigger={
                <button
                  className="btn btn-primary btn-sm"
                  style={{ marginRight: "5px" }}
                >
                  <i className="ni ni-send" />
                </button>
              }
            >
              {(close) => (
                <MakeInvoice
                  deliverynoteid={this.props.data.deliverynoteid}
                  callbackfunction={this.props.updatedata}
                  companyid={this.props.data.companyid}
                  closebtn={close}
                />
              )}
            </Popup>
          ) : null}
          <button
            className="btn btn-primary btn-sm"
            style={{ width: 30, height: 28 }}
            disabled={this.state.isButtonDisabled}
          >
            <NavLink
              style={{ marginTop: -5 }}
              to={{
                pathname: "/admin/management/print-details",
                state: {
                  invoiceRowData: this.state,
                  invoicepropsdata: this.props.data,
                  clientdata: this.props.invoiceClientData,
                },
              }}
              tag={Link}
            >
              <i
                style={{ marginLeft: -13, marginBottom: 500 }}
                className="fa fa-eye text-white"
              />
            </NavLink>
          </button>
        </td>
        <td>{develiverynoteiddata}</td>
        <td>{this.props.data.companyinvoiceid}</td>
        <td style={{ maxWidth: 150, overflow: "hidden", textAlign: "left" }}>
          {this.props.data.task === ""
            ? this.props.data.projectname
            : this.props.data.task}
        </td>
        <td>{this.props.data.date}</td>
        <td style={{ maxWidth: 100, overflow: "hidden", textAlign: "left" }}>
          {this.props.data.engineer_name}
        </td>
        <td style={{ maxWidth: 100, overflow: "hidden", textAlign: "left" }}>
          {this.props.data.company_name}
        </td>
        <td style={{ maxWidth: 50, overflow: "hidden", textAlign: "center" }}>
          {this.props.data.invoice_amount}
        </td>
        <td style={{ maxWidth: 50, overflow: "hidden", textAlign: "center" }}>
          {this.props.data.amount_received}
        </td>
        <td style={{ maxWidth: 50, overflow: "hidden", textAlign: "center" }}>
          {this.props.data.balancedue}
        </td>
        {this.props.data.invoicestatus === "Partially Paid" ? (
          <td>
            <button className="partiallypaid">
              {this.props.data.invoicestatus}
            </button>
          </td>
        ) : null}
        {this.props.data.invoicestatus === "Unpaid" ? (
          <td>
            <button className="Unpaid">{this.props.data.invoicestatus}</button>
          </td>
        ) : null}
        {this.props.data.invoicestatus === "Paid" ? (
          <td>
            <button className="paid">{this.props.data.invoicestatus}</button>
          </td>
        ) : null}
        {this.props.data.invoicestatus === "Cancelled" ? (
          <td>
            <button className="Cancelled">
              {this.props.data.invoicestatus}
            </button>
          </td>
        ) : null}
        <td style={{ maxWidth: 150, overflow: "hidden", textAlign: "left" }}>
          {this.props.data.comments}
        </td>
      </tr>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};
export default connect(mapStateToProps, null)(InvoiceRow);
