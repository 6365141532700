import React, { Component } from 'react';

export default class InvoicePopupRow extends Component {
    constructor(props){
        super(props);
        this.state={
            id:this.props.id,
            types:this.props.types,
            unit:this.props.unit,
            rate:this.props.rate,
            amount:this.props.amount,
        }
    }
    render() {
        return (
            <tr>
                <td className="text-center">{this.state.types}</td>
                <td className="text-center">{this.state.unit}</td>
                <td className="text-center">{this.state.rate}</td>
                <td className="text-center">{this.state.amount}</td>
            </tr>

        )
    }
}
