import React, { useEffect, useState, useMemo } from "react";
import { Container, Row, Form, FormGroup } from "reactstrap";
import TableContainerExpand from "../Filters/TableContainerExpand";
import Header from "../../../components/Headers/SimpleHeader";
import ConPreLoader from "../../../components/Loader/Con_PreLoader";
import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";
import AddCompany from "./AddCompany";
import EditCompany from "./EditCompany";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../config";
import "./Company.css";
import ReactTooltip from "react-tooltip";
import { CurrentLocation } from "../../../components/loginRedux/loginReducers";
import store from "../../../components/loginRedux/Store";

const authApi = config.get("apiDomain");

function CompanyDetails(props) {
  const [data, setData] = useState([]);
  const [q, setQ] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(
    () => {
      const doFetch = async () => {
        store.dispatch({
          type: CurrentLocation,
          data: props.location.pathname,
        });
        const response = await fetch(
          `${authApi}companyfilter/?isdeleted=false`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${props.token}`,
            },
          }
        );
        const companies = await response.json();
        if (companies.length === 0) {
          setLoading(false);
          alert("No Company Details Available");
        } else {
          const details = companies.sort((a, b) => a.id - b.id);
          setData(details);
          setLoading(false);
        }
      };
      doFetch();
    },
    // eslint-disable-next-line
    []
  );

  function RefreshCompany() {
    const doFetch = async () => {
      const response = await fetch(`${authApi}companyfilter/?isdeleted=false`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${props.token}`,
        },
      });
      const companies = await response.json();
      if (companies.length === 0) {
        alert("No Company Available !");
        setLoading(false);
      } else {
        const details = companies.sort((a, b) => a.id - b.id);
        setData(details);
        setLoading(false);
      }
    };
    doFetch();
  }

  function search(rows) {
    const cols = rows[0] && Object.keys(rows[0]);
    return rows.filter((r) =>
      cols.some((c) =>
        r[c]
          ? r[c].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          : null
      )
    );
  }

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: (row) => {
          const row_data = row.row.original;
          return (
            <React.Fragment>
              <Popup
                modal
                trigger={
                  <button
                    className="btn btn-primary btn-sm"
                    style={{ marginRight: "5px", textAlign: "center" }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                }
              >
                {(close) => (
                  <EditCompany
                    EditRowSend={row_data}
                    closebtn={close}
                    callbackfunction={RefreshCompany}
                  />
                )}
              </Popup>
            </React.Fragment>
          );
        },
      },
      {
        Header: "Company Code",
        accessor: "companycode",
        disableSortBy: false,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "CompanyName",
        accessor: "companyname",
        disableSortBy: true,
        headerClassName: "rt-tr-align-right",
        filter: "equals",
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "IsActive",
        accessor: (d) => {
          return d.isactive ? "IsActive" : "InActive";
        },
        disableSortBy: true,
        filter: "equals",
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "GST",
        accessor: (d) => {
          return d.isgst ? "Gst" : "NoGst";
        },
        disableSortBy: true,
        filter: "equals",
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <React.Fragment>
      <Header />
      <Container className="mt--7" fluid>
        <br />
        <Row>
          <Form
            className="searchForm"
            style={{ marginTop: 16, right: "105px" }}
          >
            <FormGroup className="mb-0">
              <input
                className="searchBox "
                type="search"
                placeholder="Search"
                value={q}
                onChange={(e) => setQ(e.target.value)}
              />
            </FormGroup>
          </Form>
          <div
            className="addRow"
            style={{ position: "absolute", right: "65px", marginTop: 18 }}
          >
            <ReactTooltip
              id="addClients-Tip"
              place="left"
              type="dark"
              effect="solid"
            >
              Add New Company
            </ReactTooltip>
            <Popup
              modal
              position="center"
              trigger={
                <button
                  className="btn btn-primary btn-sm"
                  style={{ width: "135%", height: 40 }}
                  data-tip
                  data-for="addClients-Tip"
                >
                  <i class="fas fa-building"></i>
                </button>
              }
            >
              {(close) => (
                <AddCompany
                  closebtn={close}
                  callbackfunction={RefreshCompany}
                />
              )}
            </Popup>
          </div>
          <br />
          <br />
          <br />
        </Row>
        {loading === true ? (
          <div>
            {" "}
            <ConPreLoader />
          </div>
        ) : (
          <Row>
            <div className=" col">
              <ReactTooltip place="top" type="dark" effect="float" />
              <TableContainerExpand
                columns={columns}
                data={search(data)}
              ></TableContainerExpand>
            </div>
          </Row>
        )}
        <br />
        <br />
      </Container>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};
export default connect(mapStateToProps, null)(CompanyDetails);
