import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import WorktimeDetails from "./WorktimeDetails";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 50,
    borderWidth: 1,
    borderColor: "#bff0fd"
  },
  rows: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    fontStyle: "bold"
  },
  dates: {
    width: "10%",
    textAlign: "left",
    paddingRight: 4,
    paddingLeft: 2,
    paddingTop: 4,
  },
  tasks: {
    width: "25%",
    textAlign: "left",
    paddingRight: 8,
    paddingLeft: 2,
    paddingTop: 4,
  },
  clients: {
    width: "15%",
    textAlign: "left",
    paddingRight: 4,
    paddingLeft: 2,
    paddingTop: 4,
  },
  users: {
    width: "15%",
    textAlign: "center",
    paddingRight: 4,
    paddingLeft: 2,
    paddingTop: 4,
  },
  details: {
    width: "25%",
    textAlign: "left",
    paddingRight: 8,
  },
  total: {
    width: "10%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
  },
});

const WorkTask = ({ task }) => 
{
  const details = task.sort((a, b) => a[0].username.localeCompare(b[0].username));

   const taskdetails = details.map((t) => (
    <React.Fragment>
      <View style={styles.rows} wrap={false}>
      <Text style={styles.dates}>{t[0].date}</Text>
        <Text style={styles.tasks}>{t[0].maintaskname}</Text>
        <Text style={styles.clients}>{t[0].clientName}</Text>
        <Text style={styles.users}>{t[0].username}</Text>
        <View style={styles.details}>
          <View>
            <WorktimeDetails subtasktime = {t} />
          </View>  
        </View>
        <Text style={styles.total}>{t.totaltime}</Text>
      </View>
    </React.Fragment>
   ));

  return( 
    <React.Fragment>
      {taskdetails}
    </React.Fragment>
  )
};

export default WorkTask;
