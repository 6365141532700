import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  waterMarkdiv : {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 50,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  waterMark : {
    color: '#e6e2d3',
    fontSize: 64,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    transform: 'rotate(-45deg)',
    userSelect: 'none',
  }
});

const SalesorderWatermark = ({ data }) => {
  return (
    <View style={styles.waterMarkdiv}>
      <Text style={styles.waterMark}>{data.status}</Text>
    </View>
  );
};

export default SalesorderWatermark;
