import React, { Component } from "react";
import Header from "../../../components/Headers/SimpleHeader";
import WorktimeRow from "./WorktimeRow";
import {
  Container,
  Row,
  Col,
  Table,
  Input,
  Form,
  FormGroup,
  InputGroupText,
  DropdownMenu,
  DropdownItem,
  InputGroupAddon,
  InputGroup,
  NavLink,
  Card,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import "./Worktime.css";
import config from "react-global-configuration";
import "../../../config";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import ConPreLoader from "../../../components/Loader/Con_PreLoader";
import { connect } from "react-redux";
import Popup from "reactjs-popup";
import ReportAppDF from "./DetailedForm/ReportApp";
import ReportAppDB from "./Daybookform/ReportApp";

let clientDetails = 0,
  taskDetails = 0,
  clientList = [],
  taskList = [];
const authApi = config.get("apiDomain");

class Worktime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userreportList: [],
      userList: this.props.allusers,
      userName: "",
      clientName: "",
      taskName: "",
      start_date: new Date(),
      end_date: new Date(),
      workingTotalTime: "00:00",
      loading: false,
    };
  }

  defaultTime = () => {
    let PresentDay = new Date();
    let LastPresentDay = new Date(
      PresentDay.getFullYear(),
      PresentDay.getMonth(),
      PresentDay.getDate() - 0
    );
    let D1 = moment(LastPresentDay).format("YYYY-MM-DD");
    fetch(`${authApi}sp_userreport/?date__gte=${D1}&date__lte=${D1}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.length === 0) {
          alert("No Task available for the Selected Filter.!");
          clientList = [];
          taskList = [];
          this.setState({
            start_date: PresentDay,
            end_date: LastPresentDay,
            userreportList: [],
            workingTotalTime: "00:00",
            loading: false,
          });
        } else {
          this.setState(
            {
              start_date: PresentDay,
              end_date: LastPresentDay,
              userreportList: json,
            },
            function () {
              this.updateClientFilterlist();
              this.updateTaskFilterlist();
              this.handleTotalTime();
            }
          );
        }
      });
  };

  getUsername = (event) => {
    let selectevent = event.target.value;
    let PresentDay = new Date();
    let LastPresentDay = new Date(
      PresentDay.getFullYear(),
      PresentDay.getMonth(),
      PresentDay.getDate() - 0
    );
    let D1 = moment(LastPresentDay).format("YYYY-MM-DD");
    this.setState(
      {
        userName: event.target.value,
        start_date: PresentDay,
        end_date: LastPresentDay,
        clientName: "",
        taskName: "",
        loading: true,
      },
      function () {
        if (selectevent === "") {
          fetch(`${authApi}sp_userreport/?date__gte=${D1}`, {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${this.props.token}`,
            },
          })
            .then((res) => res.json())
            .then((json) => {
              if (json.length === 0) {
                alert("No Task available for the Selected Filter.!");
                clientList = [];
                taskList = [];
                this.setState({
                  userreportList: [],
                  loading: false,
                  workingTotalTime: "00:00",
                });
              } else {
                this.setState(
                  {
                    userreportList: json,
                  },
                  function () {
                    this.updateClientFilterlist();
                    this.updateTaskFilterlist();
                    this.handleTotalTime();
                  }
                );
              }
            });
        } else {
          fetch(
            `${authApi}sp_userreport/?username=${this.state.userName}&date__gte=${D1}`,
            {
              method: "GET",
              headers: {
                "content-type": "application/json",
                Authorization: `token ${this.props.token}`,
              },
            }
          )
            .then((res) => res.json())
            .then((json) => {
              if (json.length === 0) {
                alert("No Task available for the Selected Filter.!");
                clientList = [];
                taskList = [];
                this.setState({
                  userreportList: [],
                  loading: false,
                  workingTotalTime: "00:00",
                });
              } else {
                this.setState(
                  {
                    userreportList: json,
                  },
                  function () {
                    this.updateClientFilterlist();
                    this.updateTaskFilterlist();
                    this.handleTotalTime();
                  }
                );
              }
            });
        }
      }
    );
  };

  getSelectDate = (event, picker) => {
    const sd = picker.startDate;
    const ed = picker.endDate;
    this.setState({
      loading: true,
      userreportList: [],
      start_date: picker.startDate,
      end_date: picker.endDate,
      clientName: "",
      taskName: "",
    });
    let d_sd = moment(sd).format("YYYY-MM-DD");
    let d_ed = moment(ed).format("YYYY-MM-DD");
    if (this.state.userName === "") {
      fetch(`${authApi}sp_userreport/?date__gte=${d_sd}&date__lte=${d_ed}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.length === 0) {
            alert("No Task available for the Selected Filter.!");
            clientList = [];
            taskList = [];
            this.setState({
              userreportList: [],
              loading: false,
              workingTotalTime: "00:00",
            });
          } else {
            this.setState(
              {
                userreportList: json,
              },
              function () {
                this.updateClientFilterlist();
                this.updateTaskFilterlist();
                this.handleTotalTime();
              }
            );
          }
        });
    } else {
      fetch(
        `${authApi}sp_userreport/?date__gte=${d_sd}&date__lte=${d_ed}&username=${this.state.userName}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${this.props.token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((json) => {
          if (json.length === 0) {
            alert("No Task available for the Selected Filter.!");
            clientList = [];
            taskList = [];
            this.setState({
              userreportList: [],
              loading: false,
              workingTotalTime: "00:00",
            });
          } else {
            this.setState(
              {
                userreportList: json,
              },
              function () {
                this.updateClientFilterlist();
                this.updateTaskFilterlist();
                this.handleTotalTime();
              }
            );
          }
        });
    }
  };

  selectClientName = (event) => {
    this.setState({
      loading: true,
      userreportList: [],
      clientName: event.target.value,
      taskName: "",
    });
    let d_sd = moment(this.state.start_date).format("YYYY-MM-DD");
    let d_ed = moment(this.state.end_date).format("YYYY-MM-DD");
    if (this.state.userName === "") {
      fetch(`${authApi}sp_userreport/?date__gte=${d_sd}&date__lte=${d_ed}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.length === 0) {
            alert("No Task available for the Selected Filter.!");
            taskList = [];
            this.setState({
              userreportList: [],
              loading: false,
              workingTotalTime: "00:00",
            });
          } else {
            let filteredData = json.filter(
              (r) =>
                r.clientName
                  .toString()
                  .toLowerCase()
                  .indexOf(this.state.clientName.toLowerCase()) > -1
            );
            this.setState(
              {
                userreportList: filteredData,
              },
              function () {
                this.updateTaskFilterlist();
                this.handleTotalTime();
              }
            );
          }
        });
    } else {
      fetch(
        `${authApi}sp_userreport/?date__gte=${d_sd}&date__lte=${d_ed}&username=${this.state.userName}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${this.props.token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((json) => {
          if (json.length === 0) {
            alert("No Task available for the Selected Filter.!");
            taskList = [];
            this.setState({
              userreportList: [],
              loading: false,
              workingTotalTime: "00:00",
            });
          } else {
            let filteredData = json.filter(
              (r) =>
                r.clientName
                  .toString()
                  .toLowerCase()
                  .indexOf(this.state.clientName.toLowerCase()) > -1
            );
            this.setState(
              {
                userreportList: filteredData,
              },
              function () {
                this.updateTaskFilterlist();
                this.handleTotalTime();
              }
            );
          }
        });
    }
  };

  selectTaskName = (event) => {
    this.setState({
      loading: true,
      userreportList: [],
      taskName: event.target.value,
    });
    let d_sd = moment(this.state.start_date).format("YYYY-MM-DD");
    let d_ed = moment(this.state.end_date).format("YYYY-MM-DD");
    if (this.state.userName === "") {
      fetch(`${authApi}sp_userreport/?date__gte=${d_sd}&date__lte=${d_ed}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.length === 0) {
            alert("No Task available for the Selected Filter.!");
            this.setState({
              userreportList: [],
              loading: false,
              workingTotalTime: "00:00",
            });
          } else {
            let filteredData = json.filter(
              (r) =>
                r.clientName
                  .toString()
                  .toLowerCase()
                  .indexOf(this.state.clientName.toLowerCase()) > -1
            );
            filteredData = filteredData.filter(
              (r) =>
                r.maintaskname
                  .toString()
                  .toLowerCase()
                  .indexOf(this.state.taskName.toLowerCase()) > -1
            );
            this.setState(
              {
                userreportList: filteredData,
              },
              function () {
                this.handleTotalTime();
              }
            );
          }
        });
    } else {
      fetch(
        `${authApi}sp_userreport/?date__gte=${d_sd}&date__lte=${d_ed}&username=${this.state.userName}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${this.props.token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((json) => {
          if (json.length === 0) {
            alert("No Task available for the Selected Filter.!");
            this.setState({
              userreportList: [],
              loading: false,
              workingTotalTime: "00:00",
            });
          } else {
            let filteredData = json.filter(
              (r) =>
                r.clientName
                  .toString()
                  .toLowerCase()
                  .indexOf(this.state.clientName.toLowerCase()) > -1
            );
            filteredData = filteredData.filter(
              (r) =>
                r.maintaskname
                  .toString()
                  .toLowerCase()
                  .indexOf(this.state.taskName.toLowerCase()) > -1
            );
            this.setState(
              {
                userreportList: filteredData,
              },
              function () {
                this.updateTaskFilterlist();
                this.handleTotalTime();
              }
            );
          }
        });
    }
  };

  updateClientFilterlist() {
    clientDetails = this.state.userreportList.map((q) => q.clientName);
    clientList = clientDetails.filter(
      (q, idx) => clientDetails.indexOf(q) === idx
    );
  }

  updateTaskFilterlist() {
    taskDetails = this.state.userreportList.map((q) => q.maintaskname);
    taskList = taskDetails.filter((q, idx) => taskDetails.indexOf(q) === idx);
  }

  handleTotalTime() {
    let total = 0,
      mins = 0,
      times = 0,
      stringtime = 0,
      h = 0,
      m = 0;
    this.state.userreportList.forEach((d) => {
      stringtime = d.time;
      times = stringtime.split(":");
      mins = times[0] * 60 + +times[1];
      total += mins;
      h = (total / 60) | 0;
      m = total % 60;
      function z(n) {
        return (n < 10 ? "0" : "") + n;
      }
      this.setState({
        workingTotalTime: z(h) + ":" + z(m),
        loading: false,
      });
    });
  }

  render() {
    const ChecklistData = this.state.userreportList
      .sort((a, b) => (new Date(a.date) < new Date(b.date) ? 1 : -1))
      .map((item) => (
        <WorktimeRow
          key={item.id}
          id={item.id}
          date={item.date}
          username={item.username}
          time={item.time}
          endtime={item.endtime}
          start_time={item.start_time}
          subtask={item.subtask}
          subtaskname={item.subtaskname}
          maintaskname={item.maintaskname}
          clientName={item.clientName}
        />
      ));

    return (
      <React.Fragment>
        <Header />
        <Container className="mt--7" fluid>
          <br />
          <div className="invoicegenerate" style={{ marginTop: 12 }}>
            <UncontrolledDropdown nav style={{ marginLeft: 100 }}>
              <DropdownToggle nav>
                <button
                  className="btn btn-sm btn-dark align-items-center"
                  style={{ width: "135%", height: 40 }}
                >
                  <i className="fa fa-download" aria-hidden="true"></i>
                </button>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem size="sm">
                  <Popup
                    modal
                    data-backdrop="static"
                    trigger={<h5 className="text-center">Detailed Reports</h5>}
                  >
                    {(close) => (
                      <ReportAppDF
                        generateData={this.state.userreportList}
                        totalTime={this.state.workingTotalTime}
                        dateStart={this.state.start_date}
                        dateEnd={this.state.end_date}
                        closebtn={close}
                      />
                    )}
                  </Popup>
                </DropdownItem>
                <DropdownItem size="sm">
                  <Popup
                    modal
                    data-backdrop="static"
                    trigger={<h5 className="text-center">Day-Book Reports</h5>}
                  >
                    {(close) => (
                      <ReportAppDB
                        generateData={this.state.userreportList}
                        totalTime={this.state.workingTotalTime}
                        dateStart={this.state.start_date}
                        dateEnd={this.state.end_date}
                        closebtn={close}
                      />
                    )}
                  </Popup>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          <Row>
            <Col md="3">
              <Form style={{ marginTop: 20, marginLeft: -10 }}>
                <FormGroup className="mb-0">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-filter" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      style={{
                        height: "35px",
                        overflow: "hidden",
                        padding: "2px 6px 2px",
                        border: "none",
                      }}
                      type="select"
                      className="form-control-alternative"
                      value={this.state.userName}
                      onChange={this.getUsername}
                    >
                      <option value="">Select All</option>
                      {this.state.userList
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((obj, assign) => (
                          <option key={assign} value={obj.name}>
                            {obj.name}
                          </option>
                        ))}
                    </Input>
                  </InputGroup>
                </FormGroup>
              </Form>
            </Col>
            <Col md="3">
              <NavLink style={{ marginTop: 16, marginLeft: -30 }}>
                <DateRangePicker
                  autoUpdateInput={false}
                  onApply={this.getSelectDate}
                  initialSettings={{
                    locale: {
                      format: "DD/MM/YYYY",
                    },
                  }}
                >
                  <button
                    className="form-control"
                    style={{
                      height: "35px",
                      width: "100%",
                      overflow: "hidden",
                      padding: "2px 2px 2px",
                      backgroundColor: "white",
                      border: "none",
                    }}
                  >
                    <i
                      className="fa fa-calendar-o"
                      style={{ paddingRight: 15 }}
                      aria-hidden="true"
                    ></i>
                    {moment(this.state.start_date).format("DD/MM/YYYY")} -{" "}
                    {moment(this.state.end_date).format("DD/MM/YYYY")}
                  </button>
                </DateRangePicker>
              </NavLink>
            </Col>
            <Col md="3">
              <Form style={{ marginTop: 20, marginLeft: -30 }}>
                <FormGroup className="mb-0">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-filter" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      style={{
                        height: "35px",
                        overflow: "hidden",
                        padding: "2px 6px 2px",
                        border: "none",
                      }}
                      type="select"
                      className="form-control-alternative"
                      value={this.state.clientName}
                      onChange={this.selectClientName}
                    >
                      <option value="">Select All</option>
                      {clientList
                        .sort((a, b) => a.localeCompare(b))
                        .map((item, key) => (
                          <option key={key} value={item}>
                            {" "}
                            {item}{" "}
                          </option>
                        ))}
                    </Input>
                  </InputGroup>
                </FormGroup>
              </Form>
            </Col>
            <Col md="3">
              {taskList.length === 0 || this.state.clientName === "" ? null : (
                <Form style={{ marginLeft: -15, marginTop: 20 }}>
                  <FormGroup className="mb-0">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-filter" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        style={{
                          height: "35px",
                          overflow: "hidden",
                          padding: "2px 6px 2px",
                          border: "none",
                        }}
                        type="select"
                        className="form-control-alternative"
                        value={this.state.taskName}
                        onChange={this.selectTaskName}
                      >
                        <option value="">Select Task</option>
                        {taskList.map((item, key) => (
                          <option key={key} value={item}>
                            {" "}
                            {item}{" "}
                          </option>
                        ))}
                      </Input>
                    </InputGroup>
                  </FormGroup>
                </Form>
              )}
            </Col>
          </Row>
          <br />
          <Row>
            <div className=" col">
              <Card className="shadow">
                {this.state.loading === true ? (
                  <div>
                    {" "}
                    <ConPreLoader />{" "}
                  </div>
                ) : (
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="text-center">Date</th>
                        <th className="text-center">ClientName</th>
                        <th className="text-center">Task</th>
                        <th className="text-center">SubTask</th>
                        <th className="text-center">Assignee</th>
                        <th className="text-center">StartTime</th>
                        <th className="text-center">EndTime</th>
                        <th className="text-center">Time </th>
                      </tr>
                    </thead>
                    <tbody>{ChecklistData}</tbody>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="text-info text-center"> Total : </td>
                        <td className="text-info text-center">
                          {" "}
                          {this.state.workingTotalTime}{" "}
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
    allusers: state.allusers,
    username: state.username,
  };
};
export default connect(mapStateToProps, null)(Worktime);
