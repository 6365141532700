import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import InvoiceDrafting from "./InvoiceDraftingCharges";
import InvoicePrinting from "./InvoicePrintingCharges";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 50,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
  rows: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    fontStyle: "bold",
  },
  tasks: {
    width: "15%",
    textAlign: "left",
    paddingRight: 10,
    paddingLeft: 2,
    paddingTop: 4,
    textOverflow: "hidden",
  },
  details: {
    width: "65%",
    textAlign: "left",
    paddingRight: 8,
  },
  dates: {
    width: "10%",
    textAlign: "left",
    paddingRight: 4,
    paddingLeft: 2,
    paddingTop: 4,
  },
  invoiceno: {
    width: "10%",
    textAlign: "center",
    paddingRight: 4,
    paddingLeft: 2,
    paddingTop: 4,
  },
});

const InvoiceTask = ({
  task,
  invoicedate,
  invoiceid,
  discount,
  invoice_amount,
}) => {
  const InvoicetaskName = task.map((t) => (
    <React.Fragment>
      <View style={styles.rows} wrap={false}>
        <Text style={styles.dates}>{invoicedate}</Text>
        <Text style={styles.invoiceno}>{invoiceid}</Text>
        <Text style={styles.tasks} break>
          {t.Name}
        </Text>
        <View style={styles.details}>
          <View>
            <InvoiceDrafting
              draftChar={t.DraftingCharge}
              draft={t.DrawingCharge}
              discount={discount}
              invoice_amount={invoice_amount}
              printChar={t.PrintingCharge}
            />
          </View>
          <View>
            <InvoicePrinting
              printChar={t.PrintingCharge}
              draftChar={t.DraftingCharge}
              discount={discount}
            />
          </View>
        </View>
      </View>
    </React.Fragment>
  ));

  return <React.Fragment>{InvoicetaskName}</React.Fragment>;
};

export default InvoiceTask;
