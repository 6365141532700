/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */

export default {
  apiKey: "AIzaSyCRRVz1Nh_F7icPINNQXQI6fM-zwOiuHeU",
  authDomain: "string-consu.firebaseapp.com",
  databaseURL: "https://string-consu.firebaseio.com",
  projectId: "string-consu",
  storageBucket: "string-consu.appspot.com",
  messagingSenderId: "475328757422",
  appId: "1:475328757422:web:b82d585088e4515934773f",
  measurementId: "G-JH5YBGR4HY",
};
