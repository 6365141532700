import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        borderBottomColor: "#bff0fd",
        backgroundColor: "#bff0fd",
        borderBottomWidth: 1,
        alignItems: "center",
        height: 24,
        fontStyle: "bold",
        flexGrow: 1
    },
});

const PurchaseHsnHeader = () => (
    <View style={styles.container}>
        <Text style={{ width: '10%', textAlign: "center" }}>HSN/SAC</Text>
        <Text style={{ width: '25%', textAlign: "center" }}>Taxable Value</Text>
        <Text style={{ width: '15%', textAlign: "center" }}>CGST</Text>
        <Text style={{ width: '15%', textAlign: "center" }}>SGST</Text>
        <Text style={{ width: '25%', textAlign: "center" }}>Total Tax Amount</Text>
    </View>
);

export default PurchaseHsnHeader;